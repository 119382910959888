import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {TrainingWithProgress} from 'src/app/features/e-learning/e-learning.selectors';
import {InfoDialogComponent} from 'src/app/features/e-learning/info-dialog/info-dialog.component';
import {TrainingLock} from 'src/app/features/e-learning/learningpath/learningpath.component';
import {TrainingCardOverlayComponent} from '../training-card-overlay/training-card-overlay.component';
import {VideoDialogComponent} from '../video-dialog/video-dialog.component';

@Component({
  selector: 'app-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingCardComponent {
  @Input() training?: TrainingWithProgress;
  @Input() isLocked?: TrainingLock | null;

  @ViewChild('overlayComponent') overlayComponent?: TrainingCardOverlayComponent;

  constructor(private readonly _translate: TranslateService, private readonly _dialog: MatDialog) {}

  get showPreview() {
    return this.isLocked || !this.training?.isAccessible;
  }

  getTrainingLink(slug: string, force = false): string | null {
    // Don't return a link for locked trainings. This disables the link.
    return force || !(this.isLocked || this.showPreview) ? `/e-learning/trainings/${slug}` : null;
  }

  isDone(training: TrainingWithProgress) {
    return !!training.certificateIssued;
  }

  getLockReasonTooltip() {
    if (this.isLocked) {
      if (this.isLocked.notice) {
        const date = this.isLocked.notice;
        return this._translate.instant(`TRAININGS.LOCKED.${this.isLocked.reason}`, {date: `${date.getDate()}.${date.getMonth() + 1}.`});
      }
      return this._translate.instant(`TRAININGS.LOCKED.${this.isLocked.reason}`);
    }
  }

  openInfoOverlay() {
    if (this.overlayComponent) {
      this.overlayComponent.open();
    }
  }

  openVideoDialog() {
    if (this.training && this.training.lead_video) {
      this._dialog.open(VideoDialogComponent, {
        data: this.training.lead_video,
        autoFocus: false,
        backdropClass: 'cdk-overlay-darker-backdrop',
      });
    }
  }

  openLockedInfoDialog() {
    this._dialog.open(InfoDialogComponent);
  }
}
