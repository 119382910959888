<div class="container">
  <div class="headline">Teilnehmer hinzufügen</div>
  <form #form="ngForm" (ngSubmit)="addParticipant(form)">
    <div class="content">
      <div class="form-field-wrapper">
        <mat-form-field appearance="standard">
          <mat-label>E-Mail-Adresse</mat-label>
          <input
            matInput
            type="email"
            id="email-input"
            [(ngModel)]="emailAddress"
            class="form-control"
            required
            email
            #mail="ngModel"
            name="email" />
          <mat-error *ngIf="mail.invalid && (mail.dirty || mail.touched) && mail.errors?.required">
            Bitte eine E-Mail-Adresse eingeben.
          </mat-error>
          <mat-error *ngIf="mail.invalid && (mail.dirty || mail.touched) && mail.errors?.email">
            Bitte eine gültige E-Mail-Adresse eingeben.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <button type="button" mat-flat-button mat-dialog-close>Abbrechen</button>
      <button type="submit" [disabled]="form.invalid" mat-flat-button color="primary">Speichern</button>
    </div>
  </form>
</div>
