import {Component, Inject} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AbstractAssessmentDialogComponent} from '../abstract-assessment-dialog/abstract-assessment-dialog.component';
import {addExternalAssessmentParticipant} from '../assessment.actions';
import {ExternalAssessmentViewModel} from '../assessment.data.service';
import {AssessmentStateSlice} from '../assessment.reducer';

@Component({
  selector: 'app-assessment-add-participant-dialog',
  templateUrl: './assessment-add-participant-dialog.component.html',
  styleUrls: [
    './assessment-add-participant-dialog.component.scss',
    '../abstract-assessment-dialog/abstract-assessment-dialog.component.scss',
  ],
})
export class AssessmentAddParticipantDialogComponent extends AbstractAssessmentDialogComponent<AssessmentAddParticipantDialogComponent> {
  emailAddress?: string;

  constructor(
    private readonly _store: Store<AssessmentStateSlice>,
    ref: MatDialogRef<AssessmentAddParticipantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly _assessment?: ExternalAssessmentViewModel
  ) {
    super(ref);
  }

  addParticipant(form: NgForm) {
    if (!form.valid || !this._assessment || !this.emailAddress) return;

    this._store.dispatch(
      addExternalAssessmentParticipant({
        assessmentId: this._assessment.id,
        emailAddress: this.emailAddress,
      })
    );

    this._ref.close();
  }
}
