import {RouterReducerState} from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {get} from 'lodash-es';
import {Entitlement} from '../features/user/user.data.service';
import {selectUserEntitlements} from '../features/user/user.selectors';
import {RouterStateUrl} from './custom-url-serializer';
import {PageInfoState} from './shared.reducer';

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectRouterUrl = createSelector(selectRouterState, (state) => {
  if (!state) return null;
  return state.state.url;
});

/**
 * Checks whether current route should show a subnavigation.
 * If a subnav should be shown, this returns the 'name' of the corresponding subheading,
 * e. g. 'trainings' for showing the subheader corresponding to the different traininglists.
 */
export const selectHasSubNavigation = createSelector(selectRouterUrl, (url) => {
  if (!!url) {
    /*
     * Checked via a regexp with grouping.
     * NOT instantiated as `RegExp`, as it won't change on runtime and static regexps have better performance.
     * In order to still understand this two weeks later: :)
     * - currently, match `/trainings` itself and the subroutes `/all`, `/available`, `/grouped`, but not the details-pages
     * - subroutes are in non-catching groups for better performance
     * - route itself is catched and should be returned
     * IF another route should be added, consider something like `^\/(?:e-learning\/(trainings)(?:...)|(anotherroute)(...))$`
     */
    // TODO: evaluate if removing 'useless' escapes keeps the same functionality
    // eslint-disable-next-line no-useless-escape
    const regexp = '^/e-learning/(trainings)(?:/all|/available|/grouped)?$';
    const match = url.match(regexp);
    if (!!match) {
      return match[1];
    }
  }
  return null;
});

/**
 * Checks the current URL for pages contained in the header and returns corresponding strings.
 * If nothing was found, returns undefined.
 */
export const selectActiveNavigation = createSelector(selectRouterUrl, (url) => {
  if (!!url) {
    // check for the relevant parts of the url, put in named group
    const regexp = /\/e-learning\/(?<nav>trainings|notes|learningpaths|dep-projects).*$/;
    const match = url.match(regexp);
    if (!!match?.groups) {
      return match.groups['nav'];
    }
  }
});

export const selectRouteParams = createSelector(selectRouterState, (state) => {
  if (!state) return null;
  return state.state.params;
});

export const selectQueryParams = createSelector(selectRouterState, (state) => {
  if (!state) return null;
  return state.state.queryParams;
});

export const selectTokenParam = createSelector(selectQueryParams, (params) => get(params, 'token') as undefined | string);

export const selectCampaignParam = createSelector(selectQueryParams, (params) => get(params, 'campaign') as undefined | string);

export const selectRouteData = createSelector(selectRouterState, (state) => {
  if (!state) return null;
  return state.state.data;
});

export const selectRouteFragment = createSelector(selectRouterState, (state) => {
  if (!state) return null;
  return state.state.fragment;
});

export const selectRouteParamsTrainingSlug = createSelector(
  selectRouterState,
  (state) => (get(state, 'state.params.trainingSlug') as undefined | string) || undefined
);

export const selectRouteParamsSessionId = createSelector(selectRouterState, (state) => {
  return get(state, 'state.params.sessionId') as undefined | string;
});

export const selectRouteParamsSlideId = createSelector(selectRouterState, (state) => {
  return get(state, 'state.params.slideId') as undefined | string;
});

export const selectScrollToParam = createSelector(selectQueryParams, (params) => {
  return get(params, 'scrollTo') as undefined | string;
});

export const selectSharedState = createFeatureSelector<PageInfoState>('shared');

export const selectPagetitle = createSelector(selectSharedState, (state) => state.pagetitle.results);

export const selectAfterSignInPath = createSelector(selectSharedState, (state) => state.afterSignIn.results);

export const selectIsEntitledTo = (e: Entitlement) => createSelector(selectUserEntitlements, (entitlements) => entitlements.includes(e));

export const selectCanAccessAssessment = selectIsEntitledTo('assessment_access');

export const selectCanAccessRecommendation = selectIsEntitledTo('recommendation_access');

export const selectLanguage = createSelector(selectSharedState, (state) => state.selectedLanguage.results);

export const selectPrivacySettingsState = createSelector(selectSharedState, (state) => state.privacySettings);

export const selectEssentialsAccepted = createSelector(selectPrivacySettingsState, (state) => {
  return state.loaded && state.results?.essential;
});

export const selectMatomoSelected = createSelector(selectPrivacySettingsState, (state) => {
  return state.loaded && !state.loading && state.results?.matomo;
});

export const selectOptionalsAccepted = createSelector(selectMatomoSelected, (matomo) => {
  return {
    matomo,
  };
});

export const selectSignUpContent = createSelector(selectSharedState, (state) => state.signupCampaignContent);
