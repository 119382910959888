import {PostContactSalesRequest} from '@backend-api/user/post-contact-sales.request';
import {createAction, props} from '@ngrx/store';
import {createExecuteActions, ExecuteActions} from '@zeit-dev/ngrx-util';
import {Language, User} from './user.data.service';

export const downloadInfoActions: ExecuteActions<boolean> = createExecuteActions('downloadInfo');

export const loadUser = createAction('[USER] Load User');

export const loadUserSuccess = createAction('[USER] Load User Success', props<{user: User}>());

export const loadUserError = createAction('[USER] Load User Error', props<{message: string}>());

export const setUserData = createAction('[USER] Set User Data', props<{firstName: string; lastName: string; language: Language}>());

export const setUserSuccess = createAction('[USER] Set User Data Success', props<{user: User}>());

export const expirationDialogShown = createAction('[User] Expiration dialog shown', props<{shown: boolean}>());

export const sendUserContactSales = createAction('[User] Contact sales', props<{selection: PostContactSalesRequest['type']}>());

export const showExpirationInfoDialog = createAction('[User] Show expiration info dialog');

export const loadDownloadInfoShownSuccess = createAction('[User] Load Download-Info success', props<{show: boolean}>());

/**
 * Trigger updating the 'settings' for showing the download-info.
 * Triggered e. g. after user-interaction with the dialog.
 * prop `persist` indicates storing the flag in the DB!
 */
export const downloadInfoUpdate = createAction('[User] Download-Info user update', props<{persist: boolean}>());
