<div class="reset-password my-auto">
  <mat-card class="reset-password-card my-4">
    <ng-container *ngIf="!error && (resetToken$ | async) as token">
      <div class="title" translate>{{getStringKey('TITLE')}}</div>
      <div class="subtitle mb-3" translate>{{getStringKey('INSTRUCTIONS')}}</div>
      <mat-card-content>
        <form [formGroup]="resetPasswordForm" class="reset-password-form" (ngSubmit)="resetPassword(token)">
          <mat-form-field appearance="fill">
            <mat-label translate>LABEL.NEW_PASSWORD</mat-label>
            <input matInput formControlName="password" type="password" required />
            <mat-error *ngIf="(password?.dirty || password?.touched) && password?.errors?.required">
              <span translate>ERROR.NEW_PASSWORD_REQUIRED</span>
            </mat-error>
            <mat-error *ngIf="(password?.dirty || password?.touched) && password?.errors?.minlength">
              <span translate>ERROR.NEW_PASSWORD_MINLENGTH</span>
            </mat-error>
            <mat-error *ngIf="(password?.dirty || password?.touched) && password?.errors?.pattern">
              <span translate>ERROR.NEW_PASSWORD_PATTERN</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label translate>LABEL.PASSWORD_CONFIRM</mat-label>
            <input matInput formControlName="passwordConfirm" type="password" required />
            <mat-error *ngIf="(passwordConfirm?.dirty || passwordConfirm?.touched) && passwordConfirm?.errors?.required">
              <span translate>ERROR.NEW_PASSWORD_REQUIRED</span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="resetPasswordForm.errors?.passwordsMatch && (passwordConfirm?.dirty || passwordConfirm?.touched)">
            <span translate>ERROR.PASSWORDS_MATCH</span>
          </mat-error>
          <div class="button-container">
            <button mat-flat-button [disabled]="resetPasswordForm.invalid" type="submit" color="accent" translate>
              <span translate>{{getStringKey('BUTTON')}}</span>
            </button>
          </div>
        </form>
      </mat-card-content>
    </ng-container>
    <!-- Error is shown if BE returns any error or no token is given via params -->
    <ng-container *ngIf="!!error || !(resetToken$ | async)">
      <mat-card-content class="error-card">
        <p class="error">
          <ng-container *ngIf="error; else genericError">
            <span translate>{{error.message}}.PRE</span>
            <a href="mailto:info@troodi.de">info@troodi.de</a>
            <span translate>{{error.message}}.POST</span>
          </ng-container>
          <ng-template #genericError>
            <span translate>MAPPABLE_BE_ERROR.GENERIC.PRE</span>
            <a href="mailto:info@troodi.de">info@troodi.de</a>
            <span translate>MAPPABLE_BE_ERROR.GENERIC.POST</span>
          </ng-template>
        </p>
        <a *ngIf="error && error.type === 'expired'" class="mt-3" mat-flat-button color="accent" routerLink="/forgot-password">
          <!-- special case: user uses an old token and is prompted to get a new one via 'forgot-password' -->
          <span translate>MAPPABLE_BE_ERROR.BUTTON.RESET</span>
        </a>
        <a *ngIf="!error || error.type !== 'expired'" class="mt-3" mat-flat-button color="accent" routerLink="/">
          <!-- else, just give the user the option to return to the homepage -->
          <span translate>MAPPABLE_BE_ERROR.BUTTON.HOME</span>
        </a>
      </mat-card-content>
    </ng-container>
  </mat-card>
</div>
