import {PostSignUpCampaignsByIdVerifyResponse} from '@backend-api/sign-up-campaigns-verify/post-sign-up-campaigns-by-id-verify.response';
import {ActionReducerMap, createReducer, on} from '@ngrx/store';
import {initialRS, ResourceState} from '@zeit-dev/ngrx-util';
import {Language} from '../features/user/user.data.service';
import {PrivacySettings} from './privacy.service';
import {
  changeUserLanguage,
  loadPrivacySettings,
  loadPrivacySettingsError,
  loadPrivacySettingsSuccess,
  setAfterSignInPath,
  setPageinfoTitle,
  updatePrivacySettings,
  verifySignupCampaign,
  verifySignupCampaignSuccess,
} from './shared.actions';

export interface SharedSlice {
  shared: PageInfoState;
}

export interface PageInfoState {
  pagetitle: ResourceState<string>;
  afterSignIn: ResourceState<string>;
  privacySettings: ResourceState<PrivacySettings | undefined>;
  selectedLanguage: ResourceState<Language | undefined>;
  signupCampaignContent: ResourceState<PostSignUpCampaignsByIdVerifyResponse>;
}

export const initialState: PageInfoState = {
  pagetitle: initialRS<string, void>(''),
  afterSignIn: initialRS<string, void>(''),
  privacySettings: initialRS<PrivacySettings | undefined, void>(undefined),
  selectedLanguage: initialRS<Language | undefined, void>(undefined),
  signupCampaignContent: initialRS<PostSignUpCampaignsByIdVerifyResponse, void>({
    content: {de: {benefits: [], headline: ''}, en: {benefits: [], headline: ''}},
  }),
};

export const reducers: ActionReducerMap<{
  pagetitle: PageInfoState['pagetitle'];
  afterSignIn: PageInfoState['afterSignIn'];
  privacySettings: PageInfoState['privacySettings'];
  selectedLanguage: PageInfoState['selectedLanguage'];
  signupCampaignContent: PageInfoState['signupCampaignContent'];
}> = {
  pagetitle: createReducer(
    initialState['pagetitle'],
    on(setPageinfoTitle, (state, {title}) => {
      return {
        ...state,
        loaded: true,
        loading: false,
        results: title,
      };
    })
  ),
  afterSignIn: createReducer(
    initialState['afterSignIn'],
    on(setAfterSignInPath, (state, {path}) => {
      return {
        ...state,
        loaded: true,
        loading: false,
        results: path,
      };
    })
  ),
  privacySettings: createReducer(
    initialState['privacySettings'],
    on(loadPrivacySettings, (state) => {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }),
    on(loadPrivacySettingsSuccess, (state, {settings}) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results: settings,
      };
    }),
    on(updatePrivacySettings, (state, {settings}) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results: settings,
      };
    }),
    on(loadPrivacySettingsError, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results: undefined,
      };
    })
  ),
  selectedLanguage: createReducer(
    initialState['selectedLanguage'],
    on(changeUserLanguage, (state, {language}) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results: language,
      };
    })
  ),
  signupCampaignContent: createReducer(
    initialState['signupCampaignContent'],
    on(verifySignupCampaign, (state) => {
      return {
        ...state,
        ...initialState.signupCampaignContent.results,
      };
    }),
    on(verifySignupCampaignSuccess, (state, content) => {
      return {
        ...state,
        results: content,
      };
    })
  ),
};
