import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PostContactSalesRequest} from '@backend-api/user/post-contact-sales.request';

@Component({
  selector: 'app-expiration-notice',
  templateUrl: './expiration-notice.component.html',
  styleUrls: ['./expiration-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpirationNoticeComponent {
  @Input()
  readonly hasCancelButton = false;
  @Output()
  readonly sendClicked = new EventEmitter<{selection: PostContactSalesRequest['type']}>();
  @Output()
  readonly cancelClicked = new EventEmitter();

  selected = 0;

  select(selection: number) {
    this.selected = selection;
  }

  cancel() {
    if (this.hasCancelButton) {
      this.cancelClicked.emit();
    }
  }

  send() {
    const selection: PostContactSalesRequest['type'] = this.selected === 1 ? 'interested_in_other_trainings' : 'unlock_account';
    this.sendClicked.emit({selection});
  }
}
