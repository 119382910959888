import {DOCUMENT} from '@angular/common';
import {ApplicationRef, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {PostContactSalesRequest} from '@backend-api/user/post-contact-sales.request';
import {Actions, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATED} from '@ngrx/router-store';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {delay, distinctUntilChanged, filter, first, map, take, tap, withLatestFrom} from 'rxjs/operators';
import {startApplicationUpdate} from './app.actions';
import {getAppBlockerActive, selectUserHomePath} from './app.selectors';
import {CookieBannerComponent} from './cookie-banner/cookie-banner.component';
import {CookieSettingsDialogComponent} from './cookie-settings-dialog/cookie-settings-dialog.component';
import {loadPreviews, trainingActions} from './features/e-learning/e-learning.actions';
import {ExpirationDialogComponent} from './features/e-learning/expiration-dialog/expiration-dialog.component';
import {SearchStore} from './features/e-learning/training-list/trainings-subheader/search.store';
import {languageMapping} from './features/user/user-profile/user-profile.component';
import {expirationDialogShown, sendUserContactSales, showExpirationInfoDialog} from './features/user/user.actions';
import {Language} from './features/user/user.data.service';
import {
  selectExpirationDialogShown,
  selectNotifyExpiredUser,
  selectUser,
  selectUserActive,
  selectUserContactedSales,
} from './features/user/user.selectors';
import {checkOnboarding, openOnboarding} from './onboarding/store/actions';
import {showOnboarding} from './onboarding/store/selectors';
import {PrivacyService} from './shared/privacy.service';
import {changeUserLanguage} from './shared/shared.actions';
import {SharedSlice} from './shared/shared.reducer';
import {
  selectActiveNavigation,
  selectCanAccessAssessment,
  selectCanAccessRecommendation,
  selectHasSubNavigation,
  selectLanguage,
  selectPagetitle,
  selectPrivacySettingsState,
  selectRouteData,
  selectRouterUrl,
} from './shared/shared.selectors';
import {TrackingService} from './shared/tracking.service';
import {GenericDialogComponent} from './ui-components/generic-dialog/generic-dialog.component';

// Accent-color of the custom palette
export const COLOR_ACCENT = '#F95F4E';
export const allowedLanguages: Language[] = ['de', 'en'];
export const defaultLanguage: Language = 'de';

interface AppComponentViewModel {
  canAccessAssessment: boolean;
  canAccessRecommendation: boolean;
  userHomePath: string | null;
  pageTitle: string;
}

// TODO: check if there is something similar existing already? :)
export interface Link {
  url: string;
  linktitle: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SearchStore],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('appContainer') appContainer?: ElementRef;
  private readonly _subscriptions = new Subscription();

  readonly user$ = this._store.select(selectUser);
  readonly subNavigationShown$ = this._store.select(selectHasSubNavigation);
  public readonly showOnboarding$ = this._store.select(showOnboarding);
  private readonly _routerData$ = this._store.select(selectRouteData);
  private readonly _selectedLanguage$ = this._store.select(selectLanguage);
  private readonly _privacySettingsState$ = this._store.select(selectPrivacySettingsState);
  private readonly _notifyUser$ = this._store.select(selectUserActive);

  headerShown = true;
  // Some pages are supposed to only show the logo
  blankHeader = false;
  languageMapping = languageMapping;
  showExpirationNotification = false;
  hideContactFloat = false;
  splashShown = true;

  readonly vm$: Observable<AppComponentViewModel> = this._store
    .select((s) => ({
      canAccessAssessment: selectCanAccessAssessment(s),
      canAccessRecommendation: selectCanAccessRecommendation(s),
      pageTitle: selectPagetitle(s),
      userHomePath: selectUserHomePath(s) || null,
      currentNav: selectActiveNavigation(s) || '',
    }))
    .pipe(distinctUntilChanged(), delay(0));

  readonly showExpirationDialog$ = combineLatest([
    this._store.select(selectNotifyExpiredUser),
    this._store.select(selectExpirationDialogShown),
    this._store.select(selectRouterUrl),
  ]).pipe(
    // subpage 'available' will show a notice of itself, don't open the dialog automatically
    filter(([, , url]) => !!url && url !== '/e-learning/trainings/available'),
    distinctUntilChanged(),
    map(
      ([notify, dialogShown]) =>
        // IF user has to be notified (see selector), show the dialog once per session on this component
        !!notify && !dialogShown
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<SharedSlice>,
    private readonly _translate: TranslateService,
    private readonly _privacyService: PrivacyService,
    private readonly _cookieBanner: MatBottomSheet,
    private readonly _cookieDialog: MatDialog,
    private readonly _zone: NgZone,
    private readonly _appref: ApplicationRef,
    private readonly _expiryDialog: MatDialog,
    private readonly _searchStore: SearchStore,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _tracker: TrackingService
  ) {
    /*
    // DO NOT FORGET TO WRAP WHATEVER SIDE-EFFECT YOU WANT TO HAPPEN HERE INSIDE NG-ZONE!!!!!!!!!
    // DO NOT FORGET TO WRAP WHATEVER SIDE-EFFECT YOU WANT TO HAPPEN HERE INSIDE NG-ZONE!!!!!!!!!
    // DO NOT FORGET TO WRAP WHATEVER SIDE-EFFECT YOU WANT TO HAPPEN HERE INSIDE NG-ZONE!!!!!!!!!
     */
    this._appref.isStable.pipe(take(1)).subscribe(() => this._zone.run(() => this._store.dispatch(startApplicationUpdate())));

    // this language will be used as a fallback when a translation isn't found in the current language
    _translate.setDefaultLang(defaultLanguage);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const browserLang = _translate.getBrowserLang() as Language;
    if (browserLang && allowedLanguages.includes(browserLang)) {
      _translate.use(browserLang);
    } else {
      _translate.use(defaultLanguage);
    }
  }

  ngOnInit(): void {
    this._store
      .select(getAppBlockerActive)
      .pipe(tap((isActive) => (isActive ? this._showLoading() : this._hideLoading())))
      .subscribe();

    // trigger reading privacy-service from localStorage and managing it in state
    this._privacyService.getState();
    this._subscriptions.add(
      this._routerData$.subscribe((data) => {
        // hide header if it is specified
        if (data?.hideHeader) {
          this.headerShown = false;
        } else {
          if (data?.blankHeader) {
            this.blankHeader = true;
          } else {
            this.blankHeader = false;
          }
          this.headerShown = true;
        }
        // hide contact-button on specific pages
        this.hideContactFloat = !!data?.hideContactFloat;
      })
    );
    this._subscriptions.add(
      this._actions$.pipe(ofType(ROUTER_NAVIGATED)).subscribe(() => {
        if (!this.appContainer) return;
      })
    );
    this._subscriptions.add(
      this.user$.subscribe((user) => {
        if (user) {
          this._store.dispatch(checkOnboarding());
          // set users' language
          this._translate.use(user.language);
          if (user.editor) {
            // Inject the Prismic-Script only for editors
            this._privacyService.injectPrismic();
            // Editors should load the Training-Previews
            this._store.dispatch(loadPreviews());
            // As trainings get invalidated, trigger loading them wherever we are
            this._store.dispatch(trainingActions.execute({params: undefined}));
          }
        }
      })
    );

    this._subscriptions.add(
      // different actions are needed in accordance to the user's privacy settings
      this._privacySettingsState$.subscribe((state) => {
        if (state.loaded && !state.loading) {
          if (!state.results) {
            // No selection found in store
            // show Cookie-Bar
            this.openCookieBanner();
          } else {
            if (state.results.essential) {
              // user accepted essential cookies
              // handle consent for matomo-tracking
              this._tracker.handleConsent(state.results.matomo);
            }
            // else, user did not consent to cookies
            // UI keeps locked, showing the cookie-banner because we delete all stored info upon rejection
          }
        }
      })
    );
    this._subscriptions.add(
      this._selectedLanguage$.subscribe((language) => {
        if (!!language) {
          this._translate.use(language);
        }
      })
    );

    this._subscriptions.add(
      this.showExpirationDialog$
        .pipe(
          // don't have to react until dialog should be shown
          first((show) => !!show)
        )
        .subscribe(() => {
          this._store.dispatch(showExpirationInfoDialog());
        })
    );

    // checks whether the user has to be notified about his subscription
    this._subscriptions.add(
      this._notifyUser$.pipe(distinctUntilChanged()).subscribe((userActive) => {
        // flag can render undefined if some data isn't loaded yet, then ignore
        if (userActive !== undefined) {
          // for the info-badge in the header
          this.showExpirationNotification = !userActive;
        }
      })
    );

    // handles the need to open the expiration-dialog.
    // Checks whether user already contacted sales and opens either the 'contact sales' or the 'you already contacted us' dialog
    this._subscriptions.add(
      this._actions$
        .pipe(ofType(showExpirationInfoDialog), withLatestFrom(this._store.select(selectUserContactedSales)))
        .subscribe(([, contactedSales]) => {
          if (!!contactedSales) {
            // open info dialog stating that user contacted sales already
            this._expiryDialog.open(GenericDialogComponent, {
              data: {
                title: this._translate.instant('EXPIRED_USER.INFO_DIALOG.HEADLINE'),
                message: this._translate.instant('EXPIRED_USER.INFO_DIALOG.TEXT_PRE'),
                suffix: this._translate.instant('EXPIRED_USER.INFO_DIALOG.TEXT_POST'),
              },
            });
          } else {
            // open selection dialog and react on close:
            this._expiryDialog
              .open(ExpirationDialogComponent)
              .afterClosed()
              .pipe(take(1))
              .subscribe((data?: {selection: PostContactSalesRequest['type']}) => {
                // remember that the user saw this, so the dialog won't open unwanted
                this._store.dispatch(expirationDialogShown({shown: true}));
                if (data) {
                  // user selected one of the presented options: send to BE
                  this._store.dispatch(sendUserContactSales({selection: data.selection}));
                }
              });
          }
        })
    );
  }

  private _showLoading(): void {
    const splash = this._document.getElementsByClassName('splash').item(0);
    if (!splash) return;

    splash.classList.remove('hide-splash', 'hidden');
    this.splashShown = true;
  }

  private _hideLoading(): void {
    const splash = this._document.getElementsByClassName('splash').item(0);
    if (!splash) return;

    setTimeout(() => {
      splash.classList.add('hidden');
      splash.classList.add('hide-splash');
      this.splashShown = false;
    }, 1000);
  }

  selectLanguage(language: Language) {
    this._store.dispatch(changeUserLanguage({language}));
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  getAccentColor() {
    return COLOR_ACCENT;
  }

  openCookieBanner() {
    this._cookieBanner.open(CookieBannerComponent, {
      disableClose: true,
      panelClass: 'cookie-banner',
    });
  }

  openCookieDialog() {
    this._cookieDialog.open(CookieSettingsDialogComponent);
    // we do not have to react on further interaction, as the dialog refreshes the page on decline to safely unload all scripts
  }

  showExpirationInformation() {
    if (this.showExpirationNotification) {
      // user clicks the badge (resp. containing element), open the info-dialog via action!
      this._store.dispatch(showExpirationInfoDialog());
    }
  }

  search(input: string) {
    this._searchStore.setSearchTerm(input);
  }

  openOnboardingTour() {
    this._store.dispatch(openOnboarding());
  }
}
