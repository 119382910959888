export const SLIDES_SELECT: string[] = [
  'ONBOARDING.SLIDES.INTRO',
  'ONBOARDING.SLIDES.LERNPROGRAMM',
  'ONBOARDING.SLIDES.KAPITELUEBERSICHT',
  'ONBOARDING.SLIDES.KAPITELSLIDE',
  'ONBOARDING.SLIDES.VIDEO_SPEED',
  'ONBOARDING.SLIDES.NOTIZEN',
  'ONBOARDING.SLIDES.MATERIALS',
  'ONBOARDING.SLIDES.PHOTO_DOCUMENTATION',
  'ONBOARDING.SLIDES.FILTER',
  'ONBOARDING.SLIDES.PROFIL',
  'ONBOARDING.SLIDES.START',
];
export const SLIDES_HYBRID: string[] = [
  'ONBOARDING.SLIDES.INTRO_HYBRID',
  'ONBOARDING.SLIDES.LERNPROGRAMM_HYBRID',
  'ONBOARDING.SLIDES.LIVESESSION',
  'ONBOARDING.SLIDES.KAPITELUEBERSICHT',
  'ONBOARDING.SLIDES.KAPITELSLIDE',
  'ONBOARDING.SLIDES.VIDEO_SPEED',
  'ONBOARDING.SLIDES.NOTIZEN',
  'ONBOARDING.SLIDES.MATERIALS',
  'ONBOARDING.SLIDES.PHOTO_DOCUMENTATION',
  'ONBOARDING.SLIDES.PROFIL',
  'ONBOARDING.SLIDES.START',
];
