import {DOCUMENT} from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {filter, first, map, take} from 'rxjs/operators';
import {UserTrainingRating} from 'src/app/backend/elearning-api/trainings-user-training-rating/post-trainings-by-id-user-training-rating.request';
import {LeanSession, Training} from 'src/app/backend/elearning-api/trainings/get-trainings.response';
import {Dictionary} from 'src/app/shared/data.service';
import {selectRouteFragment, selectRouteParamsTrainingSlug} from 'src/app/shared/shared.selectors';
import {SharedSlice} from '../../../shared/shared.reducer';
import {Language} from '../../user/user.data.service';
import {selectCmi5HideCertificate} from '../../user/user.selectors';
import {
  bookingsActions,
  loadCertificateAvailabilityIfNeeded,
  loadUserTrainingRatings,
  progressesActions,
  trainingActions,
} from '../e-learning.actions';
import {LeanSlide, Slide} from '../e-learning.data.service';
import {ELearningSlice} from '../e-learning.reducer';
import {
  Progress,
  TrainingWithAccessibility,
  filterTitleSlides,
  selectCertificateAvailableForCurrentTraining,
  selectCurrentTraining,
  selectCurrentUserTrainingRating,
  selectMostRecentSessionSlug,
  selectNextSlideSlugs,
  selectSessionProgresses,
  selectSlugRedirectForCurrentSlug,
  selectTrainingsByTrainers,
  selectTrainingsState,
  selectUserTrainingProgressesState,
  selectVisitedSlides,
} from '../e-learning.selectors';
import {InfoDialogComponent} from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-single-training',
  templateUrl: './single-training.component.html',
  styleUrls: ['./single-training.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleTrainingComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  constructor(
    private readonly _sharedStore: Store<SharedSlice>,
    private readonly _store: Store<ELearningSlice>,
    private readonly _actions$: Actions,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    private readonly _translate: TranslateService
  ) {
    // get height of app-header
    const header = _document.getElementById('navbar');
    if (header) {
      this.headerHeight = header.getBoundingClientRect().height;
    }
    // get height of app-footer
    const footer = _document.getElementById('footer');
    if (footer) {
      this.footerHeight = footer.getBoundingClientRect().height;
    }
  }

  readonly viewModel$ = this._store.select((state) => ({
    training: selectCurrentTraining(state),
    trainingsState: selectTrainingsState(state),
    sessionProgresses: selectSessionProgresses(state) || undefined,
    sessionProgressState: selectUserTrainingProgressesState(state),
    otherTrainingsFromTrainer: selectTrainingsByTrainers(state) || undefined,
    mostRecentSession: selectMostRecentSessionSlug(state) || undefined,
    nextAvailableSlide: selectNextSlideSlugs(state),
    visitedSlides: selectVisitedSlides(state) || undefined,
    scrollToId: selectRouteFragment(state) || undefined,
    trainingRated: selectCurrentUserTrainingRating(state),
    certificateAvailable: selectCertificateAvailableForCurrentTraining(state),
    slugLookup: selectSlugRedirectForCurrentSlug(state),
    hideCertificate: selectCmi5HideCertificate(state),
  }));
  readonly trainingCheck$ = this.viewModel$.pipe(
    first((viewModel) => !!viewModel.trainingsState.loaded && viewModel.training !== undefined),
    map((viewModel) => {
      // nope, mache ich mit einem gezielten Selector für currentslug oder so
      return {training: !!viewModel.training, lookup: viewModel.slugLookup};
    })
  );

  readonly _subscriptions = new Subscription();

  disableAnimation = true;
  private readonly headerHeight: number = 0;
  private readonly footerHeight: number = 0;
  private userLanguage?: Language;
  private trainingLanguage?: Language;

  @ViewChildren('sessionPanels', {read: ElementRef}) panelList?: QueryList<ElementRef>;
  prevQueryListLength = 0;

  ICON_MAPPING = new Map([
    ['Video_slide', 'video_slide'],
    ['Certificate_slide', 'certificate_slide'],
    ['Link_slide', 'task_slide'],
    ['Quiz_slide', 'nextgen_quiz_slide'],
    ['User_training_rating_slide', 'feedback_slide'],
    ['Passive_content_slide', 'task_slide'],
    ['Interactive_content_slide', 'interactive_slide'],
  ]);

  // unfortunately, extracting the values from the generated type is not possible with the given nested structure
  // so, keep this in sync with the API
  SPECIAL_ICON_MAPPING = new Map<string, string>([
    ['anekdote', 'icon_extra_anecdote'],
    ['handbook', 'handbook'],
  ]);

  isInViewport(elem: HTMLElement) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= this.headerHeight &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || this._document.documentElement.clientHeight) - this.footerHeight &&
      bounding.right <= (window.innerWidth || this._document.documentElement.clientWidth)
    );
  }

  ngAfterViewInit() {
    this.disableAnimation = false;
  }

  ngAfterViewChecked() {
    if (this.panelList && this.prevQueryListLength !== this.panelList.length) {
      // list was updated
      this.prevQueryListLength = this.panelList.length;
      const mostRecent = this.panelList
        .toArray()
        .find((panel) => panel.nativeElement.getAttribute('most-recent') === 'true')?.nativeElement;
      if (mostRecent && !this.isInViewport(mostRecent)) {
        setTimeout(() => mostRecent.scrollIntoView(), 50);
      }
    }
  }

  ngOnInit() {
    // idempotent loading is realized in the effects
    this._store.dispatch(trainingActions.execute({params: undefined}));
    this._store.dispatch(progressesActions.execute({params: undefined}));
    this._store.dispatch(bookingsActions.execute({params: undefined}));
    this._store.dispatch(loadCertificateAvailabilityIfNeeded());

    // we need to redirect in case the requested training does not exist
    this._subscriptions.add(
      this.trainingCheck$.subscribe((existing) => {
        if (!existing.training) {
          // A number of slugs have been renamed, instead of just throwing the 404,
          // we'll lookup if the requested slug has been renamed (e.g. for users coming from old links or bookmarks)
          if (!!existing.lookup) {
            this._router.navigate([`e-learning/trainings/${existing.lookup}`], {replaceUrl: true});
          } else {
            this._router.navigate(['notfound'], {skipLocationChange: true});
          }
        }
      })
    );

    this._subscriptions.add(
      // for the possible language-switch: act, when the training is available
      this.viewModel$
        .pipe(
          filter((vm) => !!vm.training),
          take(1)
        )
        .subscribe((vm) => {
          // store both languages for resetting values on destruction of the component
          this.userLanguage = this._translate.currentLang as Language;
          this.trainingLanguage = vm.training?.language as Language;
          // in case we have everything setup and the training is english, we temporarily set the language to english
          // also, in case the user also has english selected, we can omit the API-call
          if (this.userLanguage && this.trainingLanguage === 'en' && this.userLanguage !== 'en') {
            // ensure that this wil be reset on destruction of this component!
            this._translate.use(this.trainingLanguage);
          }
        })
    );

    this._subscriptions.add(
      this._store
        .select(selectRouteParamsTrainingSlug)
        .pipe(first((slug) => !!slug && slug !== ''))
        .subscribe((slug) => {
          if (!!slug) {
            this._store.dispatch(loadUserTrainingRatings({slug}));
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    // in case we changed the language for this training, reset it to the originally user-selected language!
    if (this.userLanguage && this.trainingLanguage === 'en' && this.userLanguage !== 'en') {
      this._translate.use(this.userLanguage);
    }
  }

  sessionsAvailable(training: Training): boolean {
    return !!training.sessions && training.sessions.length > 0;
  }

  trainersAvailable(training: Training): boolean {
    return !!training.trainers && training.trainers.length > 0;
  }

  getSlideLink(training: TrainingWithAccessibility, session: LeanSession, slide: LeanSlide): string | undefined {
    if (!training || !session || !slide || !training.isAccessible) return;

    return `/e-learning/trainings/${training.slug}/${session.id}/${slide.id}`;
  }

  isExpanded(sessionId: string, sessionProgress?: Dictionary<Progress, number>, mostRecentSessionId?: string | null, scrollToId?: string) {
    if (sessionProgress && sessionProgress[sessionId].total === sessionProgress[sessionId].current) {
      // if this is the most recent session, it is always expanded!
      if ((mostRecentSessionId && mostRecentSessionId === sessionId) || (scrollToId && scrollToId === sessionId)) {
        return true;
      }
      // else, close it if progress is 100%
      return false;
    }
    // expand it in every other case
    return true;
  }

  getFormattedDurationForSlide(slide: {video_duration?: number | null; duration?: number | null}): string | undefined {
    let duration = slide.video_duration ?? slide.duration;
    const isInSeconds = !!slide.video_duration;

    if (!duration) {
      return undefined;
    }

    // Convert duration to minutes if it's given in seconds
    if (isInSeconds) {
      duration = Math.floor(duration / 60);
    }

    // Format the duration, ensuring it's at least '1 min'
    return duration < 1 ? '1 min' : `${duration} min`;
  }

  /**
   * Checks whether this slide should be available to the user.
   * Already 'visited' slides (i.e. interacted with) are always available.
   * For non-visited, always *the first non-visited slide* is supposed to be available, this is checked with the `nextSlide`-Array.
   * Another edgecase is the feedback-slide that is not represented via the userprogress, but checked with another state-piece.
   * If the user has a certificate (decided by BE), the certificate should also be always available!
   * @param slide The slide to be checked
   * @param nextSlide An Array containing all the 'first clickable non-visited slides' of this training
   * @param visitedSlides An Array containing the visited slides of this training
   * @param trainingRated The rating the user has given this training if any. `null` otherwise
   * @param certificateAvailable The Date the user got access to the certificate, `null` if they don't have access.
   * @returns boolean - Whether the passed slide should be available to the user.
   */
  isSlideAvailable(
    slide: Slide,
    nextSlide?: string[] | null,
    visitedSlides?: string[] | null,
    trainingRated?: UserTrainingRating | null,
    certificateAvailable?: Date | null
  ) {
    return (
      (nextSlide && nextSlide.includes(slide.id)) ||
      (visitedSlides && visitedSlides.includes(slide.id)) ||
      (slide.type === 'User_training_rating_slide' && !!trainingRated) ||
      (slide.type === 'Certificate_slide' && !!certificateAvailable)
    );
  }

  progressOfSession(progresses: Dictionary<Progress> | undefined, session: LeanSession): Progress | undefined {
    if (!progresses || !session) {
      return;
    }
    return progresses[session.id];
  }

  moreThanSingleTrainer(training: Training) {
    return training.trainers.length > 1;
  }

  titleForSlide(slide: LeanSlide): string | null {
    if (slide.type === 'Interactive_content_slide' && !!slide.quiz) {
      return this._translate.instant('QUIZ_SLIDE.TITLE');
    }

    return slide.title;
  }

  iconForSlide(slide: LeanSlide): string | undefined {
    if (slide.type === 'Video_slide' && !!slide.special_slide_type) {
      // there is a subgroup of slides that need special icons
      // if field is present, get icon from 'special'-map
      return this.SPECIAL_ICON_MAPPING.get(slide.special_slide_type);
    }

    if (slide.type === 'Interactive_content_slide' && slide.quiz) {
      return this.ICON_MAPPING.get('Quiz_slide');
    }

    if (slide.type === 'Passive_content_slide' && slide.handbook) {
      return this.SPECIAL_ICON_MAPPING.get('handbook');
    }

    return this.ICON_MAPPING.get(slide.type);
  }

  isSlideVisited(slide: Slide, visitedSlides: string[] | null, trainingRated: UserTrainingRating | null) {
    if (slide.type === 'User_training_rating_slide' && !!trainingRated) {
      // if this is the rating-slide, this slide is 'visited' if the user rated this training
      return true;
    }
    if (visitedSlides) {
      // else, check the visited slides
      return visitedSlides.includes(slide.id);
    }
    return false;
  }

  isMostRecent(sessionId: string, mostRecentSession: string, scrollToId?: string) {
    if (scrollToId) {
      return sessionId === scrollToId;
    } else {
      return sessionId === mostRecentSession;
    }
  }

  openInfo() {
    this._dialog.open(InfoDialogComponent);
  }

  filterSlides(slides: LeanSession['slides'], hideCertificate: boolean): LeanSession['slides'] {
    // here, we only want to filter title-slides! Use exported function from selectors
    const filteredSlides = filterTitleSlides(slides);

    // filter certificate slides if cmi5 is configured to hide troodi's certificates
    if (hideCertificate) {
      return filteredSlides.filter((slide) => slide.type !== 'Certificate_slide');
    }
    return filteredSlides;
  }
}
