import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Widget, createWidget} from '@typeform/embed';
import {Subscription} from 'rxjs';
import {PrismicLink, QuizSlide} from 'src/app/backend/elearning-api/trainings/get-by-training-id-sessions-by-id.response';
import {PrivacyService} from 'src/app/shared/privacy.service';
import {configurationHelper} from 'src/configuration/configuration';
import {GetQuizzesByTypeformIdResponse} from '../../../backend/elearning-api/quizzes/get-quizzes-by-typeform-id.response';
import {UserState} from '../../user/user.reducer';
import {selectUser} from '../../user/user.selectors';
import {loadQuizResult, loadQuizResultForEventId} from '../e-learning.actions';
import {ELearningState} from '../e-learning.reducer';
import {selectCurrentTypeformResponse} from '../e-learning.selectors';
import {SlideUtilService} from '../slide-utils.service';

type QuizState = 'ARTICULATE' | 'INTRO' | 'LOADING' | 'QUIZ' | 'RESULT';

// Type comes from https://github.com/Typeform/embed/blob/main/packages/embed/src/base/actionable-options.ts#L32, but are not exported - unfortunately
export type TypeformOnSubmitEvent = {formId: string; responseId: string};

@Component({
  selector: 'app-prismic-quiz',
  templateUrl: './prismic-quiz.component.html',
  styleUrls: ['./prismic-quiz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrismicQuizComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data?: QuizSlide;
  @Input() nextLink?: string;
  @Input() backLink?: string;
  @Output() trackProgress = new EventEmitter();
  // Is actually always just a single element, but this way we can easily subscribe on changes, as this is dynamically rendered.
  @ViewChildren('embedTypeform') embedElement = new QueryList<ElementRef>(true);

  private readonly _subscriptions = new Subscription();
  readonly viewModel$ = this._store$.select((state) => ({user: selectUser(state), typeformResponse: selectCurrentTypeformResponse(state)}));
  readonly cookiesEnabled$ = this._privacyService.areCookiesEnabled();

  typeformId?: string | null;
  typeformProjectId?: string;
  userId?: string;
  userIdHmac?: string;
  typeformMaxScore: number | null | undefined;
  state: QuizState = 'INTRO';
  typeformResponse: GetQuizzesByTypeformIdResponse | null = null;
  isIntroShown = true;
  private restart = false;
  private typeformEmbed?: Widget;

  constructor(
    private readonly _store$: Store<UserState & ELearningState>,
    private readonly _ref: ChangeDetectorRef,
    private readonly _slideUtilService: SlideUtilService,
    private readonly _translateService: TranslateService,
    private readonly _privacyService: PrivacyService
  ) {}
  ngOnInit() {
    this._slideUtilService.setNavbar(false);
    if (this.hasTypeformId(this.data)) {
      this.typeformId = this.data?.typeform_form_id;
      this.typeformMaxScore = this.data?.maximum_score;
      this.typeformProjectId = configurationHelper.needConfig('TYPEFORM_PROJECT_ID');
      this._subscriptions.add(
        this.viewModel$.subscribe(({user, typeformResponse}) => {
          if (user) {
            this.userId = user.id;
            this.userIdHmac = user.user_id_hmac;
          }
          if (typeformResponse && !this.restart) {
            this.state = 'RESULT';
            this.typeformResponse = typeformResponse;
            // FIXME unsure why i have to call markForCheck manually. There must
            // be a better way of doing this. Probably tying all the required
            // state into observables and doing a combineLatest and figuring out
            // what to render.
            this._ref.markForCheck();
          }
        })
      );
      this._store$.dispatch(loadQuizResult({typeformQuizId: this.typeformId || ''}));
    } else {
      this.state = 'ARTICULATE';
    }
  }

  ngAfterViewInit(): void {
    this._subscriptions.add(
      // Workaround for injecting the typeform-widget without having to use `setTimeout` and entering zone.js-Hell
      this.embedElement.changes.subscribe((components: QueryList<ElementRef>) => {
        // `componenent` contains only our wanted viewchild, inject typeform-widget
        if (components.length > 0) this.embedWidget(components.first);
      })
    );
  }

  ngOnDestroy(): void {
    this._slideUtilService.setNavbar(true);
    this._subscriptions.unsubscribe();
    // unmount typeform-widget if it was embedded
    this.typeformEmbed?.unmount();
  }

  typeformUrl() {
    return `https://${this.typeformProjectId}.typeform.com/to/${this.typeformId}?user_id=${this.userId}&user_id_hmac=${this.userIdHmac}&max_score=${this.typeformMaxScore}`;
  }

  restartQuiz() {
    this.restart = true;
    this.startQuiz();
  }

  startQuiz() {
    // track progress for this slide
    this.trackProgress.emit();
    this.state = 'QUIZ';
    this._slideUtilService.setNavbar(false);
  }

  /**
   * Embeds the typeform-widget into the given element and sets the internal var,
   * so we can correctly unmount the embedded widget.
   * */
  private embedWidget(element: ElementRef) {
    this.typeformEmbed = createWidget(this.typeformUrl(), {
      container: element.nativeElement,
      onSubmit: (event: TypeformOnSubmitEvent) => this.completeQuiz(event),
      iframeProps: {
        width: '100%',
        height: '100%',
      },
    });
  }

  completeQuiz(event: TypeformOnSubmitEvent) {
    this._store$.dispatch(loadQuizResultForEventId({typeformQuizId: this.typeformId || '', typeformEventId: event.responseId}));
    this.restart = false;
    this.state = 'LOADING';
    this._ref.markForCheck();
  }

  hasTypeformId(slide?: QuizSlide): boolean {
    return !!slide?.typeform_form_id;
  }

  hasArticulateUrl(slide?: QuizSlide) {
    return !!(slide?.url as PrismicLink)?.url;
  }

  getScoreText(typeformResponse?: GetQuizzesByTypeformIdResponse | null) {
    return this._translateService.instant('QUIZ_SLIDE.SCORE', {
      achieved: typeformResponse?.payload?.form_response?.calculated?.score || 0,
      maximum: typeformResponse?.payload?.form_response?.hidden?.max_score || 0,
    });
  }
}
