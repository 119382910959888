/* tslint:disable */
import {NgModule} from '@angular/core';
import {ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {API_HOST} from './api-host';
import {UserGateway} from './user/user.gateway';
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [UserGateway],
})
export class UserApiModule {
  static forRoot(apiHost: string): ModuleWithProviders<UserApiModule> {
    return {
      ngModule: UserApiModule,
      providers: [{provide: API_HOST, useValue: apiHost}],
    };
  }
}
