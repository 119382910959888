import {AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {LiveTrainingSession} from 'src/app/backend/elearning-api/learning-paths/get-learning-paths.response';
import {CarouselComponent} from 'src/app/ui-components/carousel/carousel.component';
import {EnrichedLearningPath, EnrichedLearningPathModule, EnrichedLearningPathTraining} from '../e-learning.selectors';

export enum TrainingLockReason {
  UNSET = 'UNSET',
  OVER = 'OVER',
  NOT_STARTED = 'NOT_STARTED',
}

export interface TrainingLock {
  reason: TrainingLockReason;
  notice?: Date;
}

@Component({
  selector: 'app-learningpath',
  templateUrl: './learningpath.component.html',
  styleUrls: ['./learningpath.component.scss'],
})
export class LearningPathComponent implements AfterViewInit {
  @Input() learningPath?: EnrichedLearningPath;

  // The carousel provides scrolling to a specific element
  @ViewChild('moduleCarousel') moduleCarousel?: CarouselComponent;
  @ViewChildren('trainingCards', {read: ElementRef}) trainingCards?: QueryList<ElementRef>;

  ngAfterViewInit() {
    if (this.trainingCards && this.moduleCarousel) {
      // every training/lts that has already started is a 'scroll-candidate'
      const candidates = this.trainingCards.filter((card) => card.nativeElement.getAttribute('current') === 'true');
      if (candidates.length > 0) {
        // get the last one, as this is the most current available training
        // tell the carousel to scroll this into view if needed upon initializing the view
        this.moduleCarousel.initialHorizontalScrollIntoView(candidates[candidates.length - 1].nativeElement);
      }
      // pass the cards as references to enable card-wise scrolling via the buttons
      this.moduleCarousel.setScrollTargets(this.trainingCards.toArray());
    }
  }

  /**
   * Returns either an enum-entry explaining why a card is not yet available or returns `null` if it is available.
   * @param startDate the models starts_at
   * @param endDate the models ends_at. Only available for LTS
   */
  isLocked(startDate: string | null, endDate?: string | null): TrainingLock | null {
    const now = new Date(Date.now());
    if (endDate === undefined) {
      // this is a normal training
      if (startDate) {
        const start = new Date(startDate);
        // trainings that start in the future are not available yet
        return now < start ? {reason: TrainingLockReason.NOT_STARTED, notice: start} : null;
      }
      // if no date is set, it should also not be available
      return {reason: TrainingLockReason.UNSET};
    } else {
      // this is a live training
      if (startDate && endDate) {
        const end = new Date(endDate);
        if (end < now) {
          // end is in the past, thus the session is already over
          return {reason: TrainingLockReason.OVER, notice: end};
        }
        // everything's fine, future lts can be shown normally
        return null;
      }
      // no start or end was set, so there is no date picked, yet
      return {reason: TrainingLockReason.UNSET};
    }
  }

  isCurrentCard(item: LiveTrainingSession | EnrichedLearningPathTraining) {
    // trainings that are available are potential candidates to be scrolled to
    if (item.starts_at && new Date(item.starts_at) < new Date(Date.now())) {
      return true;
    }
    return false;
  }

  trackModule(index: number, module: EnrichedLearningPathModule) {
    return module.name;
  }
}
