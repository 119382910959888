import {Component, HostBinding, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {fadeInAnimation, staggerElementsAnimation} from 'src/app/animations';
import {AssessmentAddParticipantDialogComponent} from '../assessment-add-participant-dialog/assessment-add-participant-dialog.component';
import {AssessmentCancelDialogComponent} from '../assessment-cancel-dialog/assessment-cancel-dialog.component';
import {AssessmentNewDialogComponent} from '../assessment-new-dialog/assessment-new-dialog.component';
import {loadExternalAssessments} from '../assessment.actions';
import {ExternalAssessmentViewModel} from '../assessment.data.service';
import {AssessmentStateSlice} from '../assessment.reducer';
import {selectExternalAssessments} from '../assessment.selectors';

@Component({
  selector: 'app-assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrls: ['./assessment-list.component.scss'],
  animations: [staggerElementsAnimation, fadeInAnimation],
})
export class AssessmentListComponent implements OnInit {
  @HostBinding('@fadeInAnimation') loadAnimation = true;

  readonly assessments$ = this._store.select(selectExternalAssessments);

  constructor(private readonly _store: Store<AssessmentStateSlice>, private readonly _dialog: MatDialog) {}

  ngOnInit() {
    this._store.dispatch(loadExternalAssessments());
  }

  getDiameter(assessment: ExternalAssessmentViewModel) {
    return assessment.answerSetsCount / assessment.participantsCount + ', 100px';
  }

  getDurationString(days: number) {
    return `Noch ${days >= 0 ? days : 0} Tag${days > 1 ? 'e' : ''} aktiv`;
  }

  openAssessmentDialog() {
    this._dialog.open(AssessmentNewDialogComponent);
  }

  openCancelDialog(assessment: ExternalAssessmentViewModel) {
    this._dialog.open(AssessmentCancelDialogComponent, {
      data: {assessment},
    });
  }

  showCancelButton(assessment: ExternalAssessmentViewModel): boolean {
    return assessment.status === 'active' && assessment.answerSetsCount >= 3;
  }

  addParticipantTo(assessment: ExternalAssessmentViewModel): void {
    this._dialog.open(AssessmentAddParticipantDialogComponent, {
      data: assessment,
    });
  }
}
