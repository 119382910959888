/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {PostVisitorCookieSettingResponse} from './post-visitor-cookie-setting.response';
import {PostVisitorCookieSettingRequest} from './post-visitor-cookie-setting.request';
@Injectable()
export class VisitorCookieSettingGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  postVisitorCookieSetting(
    body: PostVisitorCookieSettingRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostVisitorCookieSettingResponse> {
    return this._httpClient.request('post', this._apiHost + '/visitor_cookie_setting', {
      ...options,
      observe: 'body',
      body,
    });
  }
}
