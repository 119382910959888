import {Action, createReducer, on} from '@ngrx/store';
import {cloneDeep} from 'lodash-es';
import {RecommendedTraining} from 'src/app/backend/recommendation-api/skill-profiles/get-by-skill-profile-id-recommendations.response';
import {Skill} from 'src/app/backend/recommendation-api/skills/get-skills.response';
import {IndexedObjects, indexObjects} from 'src/app/shared/data.service';
import {loadSkillProfilesSuccess, loadSkillSuccess, setRecommendations, setSkillValue} from './recommendation.actions';
import {SkillValue} from './recommendation.data.service';

export interface RecommendationSlice {
  recommendation: RecommendationState;
}

export interface RecommendationState {
  recommendations: IndexedObjects<RecommendedTraining, string>;
  externalSkillProfile: IndexedObjects<SkillValue, string>;
  skills: IndexedObjects<Skill, string>;
  selfSkillProfile: IndexedObjects<SkillValue, string>;
  currentSkillProfile: IndexedObjects<SkillValue, string>;
  skillsLoaded: boolean;
  lastSkillProfileLoaded: boolean;
}

export const initialState: RecommendationState = {
  recommendations: {ids: [], objects: {}},
  externalSkillProfile: {ids: [], objects: {}},
  skills: {ids: [], objects: {}},
  selfSkillProfile: {ids: [], objects: {}},
  currentSkillProfile: {ids: [], objects: {}},
  skillsLoaded: false,
  lastSkillProfileLoaded: false,
};

const recommendationReducer = createReducer(
  initialState,
  on(setSkillValue, (state, {id, value}) => {
    const newObjects = cloneDeep(state.currentSkillProfile.objects);
    newObjects[id] = {skill_id: id, value};
    return {
      ...state,
      currentSkillProfile: {
        ...state.currentSkillProfile,
        objects: newObjects,
      },
    };
  }),
  on(loadSkillSuccess, (state, {skills}) => {
    return {
      ...state,
      skills,
      // populate empty currentSkillProfile with ids (and value = 5)
      currentSkillProfile:
        state.currentSkillProfile.ids.length === 0
          ? indexObjects(
              skills.ids,
              (id) => id,
              (sId) => ({
                skill_id: sId,
                value: 5,
              })
            )
          : state.currentSkillProfile,
      skillsLoaded: true,
    };
  }),
  on(loadSkillProfilesSuccess, (state, {response}) => {
    const externalProfile = response.external_skill_profile && response.external_skill_profile.skill_values;
    const selfProfile = response.self_skill_profile && response.self_skill_profile.skill_values;
    const latestProfile = response.latest_skill_profile && response.latest_skill_profile.skill_values;
    return {
      ...state,
      externalSkillProfile: !!externalProfile
        ? indexObjects(
            externalProfile,
            (skillValue) => skillValue.skill_id,
            (skill) => skill
          )
        : state.externalSkillProfile,
      selfSkillProfile: !!selfProfile
        ? indexObjects(
            selfProfile,
            (skillValue) => skillValue.skill_id,
            (skill) => skill
          )
        : state.selfSkillProfile,
      currentSkillProfile: !!latestProfile
        ? indexObjects(
            latestProfile,
            (skillValue) => skillValue.skill_id,
            (skill) => skill
          )
        : state.currentSkillProfile,
      lastSkillProfileLoaded: true,
    };
  }),
  on(setRecommendations, (state, {recommendations}) => {
    return {
      ...state,
      recommendations,
    };
  })
);

export function reducer(state: RecommendationState | undefined, action: Action) {
  return recommendationReducer(state, action);
}
