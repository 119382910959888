/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetTrainingBookingsResponse} from './get-training-bookings.response';
import {GetTrainingBookingsRequest} from './get-training-bookings.request';
@Injectable()
export class TrainingBookingsGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getTrainingBookings(
    queryParams: GetTrainingBookingsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetTrainingBookingsResponse> {
    return this._httpClient.request('get', this._apiHost + '/training_bookings', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
