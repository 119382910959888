<ng-container *ngIf="(vm$ | async) as viewModel">
  <!-- Loading may occur multiple times, show nothing but the spinner -->
  <img *ngIf="viewModel.trainingRatingsLoading" class="troodi-spinner" src="assets/img/troodi_spinner.gif" />
  <!-- if data is not loading, show user the corresponding view -->
  <ng-container *ngIf="!viewModel.trainingRatingsLoading">
    <!-- Show a separator at first -->
    <app-slide-separator
      *ngIf="showIntro && !viewModel.currentTrainingRating"
      (buttonClicked)="hideIntro()"
      [type]="data?.type"
      [title]="'USER_RATING.INTRO.TITLE' | translate"
      [introText]="'USER_RATING.INTRO.TEXT' | translate"
      [buttonText]="'USER_RATING.INTRO.BUTTON' | translate"
      [skipLink]="nextLink"
      [backLink]="backLink">
    </app-slide-separator>
    <!-- If user submitted feedback for this training, show a separator without action -->
    <app-slide-separator
      *ngIf="viewModel.currentTrainingRating"
      [type]="data?.type"
      [title]="'USER_RATING.LOCKED.TITLE' | translate"
      [introText]="'USER_RATING.LOCKED.TEXT' | translate"
      [skipLink]="nextLink"
      [skipText]="viewModel.hideCertificateSlide ? undefined : ('USER_RATING.TO_CERTIFICATE' | translate)"
      [backLink]="backLink">
    </app-slide-separator>
    <!-- Otherwise, show the feedback-slide -->
    <ng-container *ngIf="!showIntro && !viewModel.currentTrainingRating">
      <div class="row justify-content-center">
        <div class="rating col-12 col-md-6 col-xl-4 col-xxl-6 p-2" *ngFor="let id of numericRatingKeys">
          <div class="card px-3 py-1 h-100 d-flex flex-column">
            <span class="question" translate>{{'USER_RATING.QUESTIONS.' + id | uppercase}}</span>
            <div class="rating-buttons py-1 py-xxl-3">
              <!-- TODO: set iterable in component, maybe? -->
              <button *ngFor="let r of [1, 2, 3, 4, 5]" mat-icon-button [class.active]="isHighlighted(id, r)" (click)="rate(id, r)">
                <mat-icon svgIcon="rating"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="rating col-12 col-md-6 col-xl-4 col-xxl-6 p-2">
          <div class="card px-3 py-1 h-100 d-flex flex-column">
            <span class="question" translate>USER_RATING.QUESTIONS.FREETEXT.HEADLINE</span>
            <textarea
              class="mt-1 mt-xxl-3 px-2"
              matInput
              #freeTextRating
              placeholder="{{'USER_RATING.QUESTIONS.FREETEXT.PLACEHOLDER' | translate}}"
              maxlength="5000"></textarea>
            <mat-hint class="text-end">{{freeTextRating.value.length}} / 5000</mat-hint>
          </div>
        </div>
      </div>
      <!-- As there is no navbar, we have a similar CTA-Layout as in the separator -->
      <!-- TODO: looking at the current usage of the separators, we might want to rework the slide-concept? -->
      <div class="container cta-container mt-4 mt-xxl-5">
        <div class="row">
          <button class="col" (click)="sendRating()" mat-raised-button color="accent" [disabled]="!isValid()">
            <span translate>USER_RATING.SEND_FEEDBACK</span>
          </button>
        </div>
        <!-- lower row gets rearranged on smaller devices -->
        <div class="row justify-content-around mt-4">
          <a
            *ngIf="backLink"
            class="secondary-cta col-12 order-last order-sm-first col-sm-5"
            mat-stroked-button
            color="accent"
            [routerLink]="backLink">
            <span translate>INTRO_SLIDE.BACK</span>
          </a>
          <a
            *ngIf="nextLink"
            class="secondary-cta col-12 order-first order-sm-last mb-2 mb-sm-0 col-sm-5"
            mat-stroked-button
            color="accent"
            [routerLink]="nextLink">
            <span translate>INTRO_SLIDE.SKIP</span>
          </a>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
