import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RecommendedTraining} from 'src/app/backend/recommendation-api/skill-profiles/get-by-skill-profile-id-recommendations.response';
import {GetCurrentResponse, SkillProfile} from 'src/app/backend/recommendation-api/skill-profiles/get-current.response';
import {PostSkillProfilesRequest} from 'src/app/backend/recommendation-api/skill-profiles/post-skill-profiles.request';
import {PostSkillProfilesResponse} from 'src/app/backend/recommendation-api/skill-profiles/post-skill-profiles.response';
import {SkillProfilesGateway} from 'src/app/backend/recommendation-api/skill-profiles/skill-profiles.gateway';
import {Skill} from 'src/app/backend/recommendation-api/skills/get-skills.response';
import {SkillsGateway} from 'src/app/backend/recommendation-api/skills/skills.gateway';
import {IndexedObjects, indexObjects} from 'src/app/shared/data.service';

@Injectable({
  providedIn: 'root',
})
export class RecommendationDataService {
  constructor(private readonly _skillsGateway: SkillsGateway, private readonly _skillProfilesGateway: SkillProfilesGateway) {}

  getSkills(): Observable<IndexedObjects<Skill, string>> {
    return this._skillsGateway.getSkills({}).pipe(
      map((skills: Skill[]) => {
        return indexObjects(
          skills,
          (skill) => skill.id,
          (skill) => skill
        );
      })
    );
  }

  postSkillProfile(skills: SkillValue[]): Observable<PostSkillProfilesResponse> {
    const postData: PostSkillProfilesRequest = {
      skill_profile: {
        skill_values_attributes: skills,
      },
    };

    return this._skillProfilesGateway.postSkillProfiles(postData);
  }

  getRecommendations(profileID: string): Observable<IndexedObjects<RecommendedTraining, string>> {
    return this._skillProfilesGateway.getBySkillProfileIdRecommendations(profileID, {}).pipe(
      map((source: RecommendedTraining[]) => {
        return indexObjects(
          source,
          (recommendation) => recommendation.training_id,
          (recommendation) => recommendation
        );
      })
    );
  }

  getSkillProfiles(): Observable<GetCurrentResponse> {
    return this._skillProfilesGateway.getCurrent({});
  }
}

export type SkillValue = SkillProfile['skill_values'][0];
