<div *ngIf="contactWindowOpened" [@fadeInOnEnter] [@fadeOutOnLeave] class="contact-content">
  <div class="title">
    <img class="logo" src="assets/img/troodi_bw.png" />
    <span class="greeting" *ngIf="!user" translate>SUPPORT.FLOAT.GREETING</span>
    <span class="greeting" *ngIf="user" [translateParams]="{name: user.first_name}" translate>SUPPORT.FLOAT.GREETING_WITH_NAME</span>
    <span class="subheadline" translate>SUPPORT.FLOAT.SUBHEADLINE</span>
  </div>
  <div class="body">
    <div class="card">
      <div>
        <span translate>SUPPORT.FLOAT.BODY.PRE</span>
        <a class="textlink" href="mailto:info@troodi.de">info@troodi.de</a>
        <span translate>SUPPORT.FLOAT.BODY.POST</span>
      </div>
      <a href="mailto:info@troodi.de" mat-flat-button color="accent">
        <span translate>SUPPORT.FLOAT.BUTTONS.CONTACT</span>
      </a>
    </div>
    <div class="card" *ngIf="user">
      <span translate>SUPPORT.FLOAT.OTHER</span>
      <button mat-flat-button color="accent" (click)="openOnboardingTourClicked()">
        <span translate>SUPPORT.FLOAT.BUTTONS.TOUR</span>
      </button>
      <a mat-flat-button routerLink="/settings" (click)="toggleContactWindowOpened()" color="accent">
        <span translate>SUPPORT.FLOAT.BUTTONS.LANGUAGE</span>
      </a>
    </div>
  </div>
</div>
<button [class.opened]="contactWindowOpened" class="contact-button" mat-fab color="accent" (click)="toggleContactWindowOpened()">
  <mat-icon *ngIf="!contactWindowOpened" svgIcon="chat"></mat-icon>
  <i-feather *ngIf="contactWindowOpened" name="chevron-down"></i-feather>
</button>
