import {Routes} from '@angular/router';
import {AssessmentExternalComponent} from './assessment-external/assessment-external.component';
import {AssessmentFinishedComponent} from './assessment-finished/assessment-finished.component';
import {AssessmentListComponent} from './assessment-list/assessment-list.component';
import {AssessmentSelfComponent} from './assessment-self/assessment-self.component';
import {ErrorLogoutComponent} from './error-logout/error-logout.component';
import {ErrorSameUserComponent} from './error-same-user/error-same-user.component';

export const assessmentRoutesProtected: Routes = [
  {path: 'self-assessment/:page', component: AssessmentSelfComponent},
  {path: 'assessment-error/same-user', component: ErrorSameUserComponent},
  {path: 'assessment-error/logout', component: ErrorLogoutComponent},
  {path: 'assessments', component: AssessmentListComponent},
];

export const assessmentRoutesUnprotected: Routes = [
  {path: 'external-assessment/:page', component: AssessmentExternalComponent, data: {hideHeader: true}},
  {path: 'assessment-finished', component: AssessmentFinishedComponent, data: {hideHeader: true}},
];
