import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {MatRadioChange} from '@angular/material/radio';
import {fadeInAnimation, staggerElementsAnimation} from 'src/app/animations';
import {PageInfo} from '../assessment.selectors';
import {QuestionViewModel} from '../question.model';

const totalPages = 24;

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation, staggerElementsAnimation],
})
export class QuestionnaireComponent {
  @HostBinding('@fadeInAnimation') loadAnimation = true;

  @Input()
  questions?: QuestionViewModel[];

  @Input()
  pageNo?: number;

  @Input()
  pageSummary?: PageInfo[];

  @Input()
  baseRoute?: string;

  @Input()
  finishTargetLink?: string;

  @Output()
  readonly questionRatingChange = new EventEmitter<{id: string; value: number}>();

  @Output()
  readonly submitAnswers = new EventEmitter();

  isAssessmentComplete() {
    if (!this.pageSummary) return false;

    return this.pageSummary.every((page) => page.isComplete);
  }

  isFirst() {
    return this.pageNo === 1;
  }

  isLast() {
    return this.pageNo === totalPages;
  }

  getNextPage() {
    if (!this.pageNo) return null;

    return `${this.baseRoute}/${this.pageNo === totalPages ? totalPages : this.pageNo + 1}`;
  }

  getPrevPage() {
    if (!this.pageNo) return null;

    return `${this.baseRoute}/${this.pageNo > 1 ? this.pageNo - 1 : 1}`;
  }

  getPageLink(pageNo: number) {
    return `${this.baseRoute}/${pageNo}`;
  }

  getIndicatorTooltipText(page: PageInfo) {
    return page.isComplete || !page.isTouched || page.isCurrent ? '' : 'Auf dieser Seite sind noch nicht alle Fragen beantwortet worden.';
  }

  trackQuestion(index: number, question: QuestionViewModel) {
    return question.id;
  }

  trackPage(index: number, page: PageInfo) {
    return page.pageNo;
  }

  onChange(change: MatRadioChange, qid: string) {
    this.questionRatingChange.emit({id: qid, value: change.value});
  }

  sendAnswers() {
    this.submitAnswers.emit();
  }
}
