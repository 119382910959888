<div class="outer-wrapper" *ngIf="training">
  <div class="inner-wrapper">
    <div class="back-button">
      <a color="primary" routerLink="/e-learning/trainings/available" translate>
        <i-feather class="me-2" name="arrow-left"></i-feather>SINGLE_TRAINING.BACK_BUTTON
      </a>
    </div>
    <div class="container pb-4 pb-sm-4">
      <div class="row pt-0 pt-sm-5">
        <div class="col-md-12 col-lg-7 px-0 px-sm-3">
          <ng-container *ngIf="training.lead_video?.embed_url">
            <app-video [video]="training.lead_video"></app-video>
          </ng-container>
          <ng-container *ngIf="training.lead_image && !training.lead_video?.embed_url">
            <img
              *ngIf="training.lead_image.url"
              [appSrcset]="training.lead_image.url"
              [pxWidth]="600"
              [alt]="training.lead_image.alt"
              class="img-fluid" />
          </ng-container>
        </div>
        <div class="col-md-12 col-lg-5 pt-3 pt-lg-0">
          <span class="text-accent trainer-name mb-3 mb-sm-4" *ngFor="let trainer of training.trainers"> {{trainer.name}} </span>
          <h1 class="mt-4">{{training.name}}</h1>
          <div class="d-block mt-4 description" [innerHTML]="training.description | prismicHtml | hyphenateHtml | async"></div>
        </div>
      </div>

      <div class="row mt-4 d-block">
        <div class="col">
          <h4 translate>SINGLE_TRAINING.SELLING_POINTS_HEADER</h4>
        </div>
      </div>

      <div class="selling-points row d-flex">
        <div
          class="col-12 align-items-center align-items-md-start col-md-auto d-flex flex-row flex-md-column me-xl-5 key-data mb-2 mb-md-0">
          <span class="d-flex align-items-center mb-md-2">
            <mat-icon svgIcon="chapters"></mat-icon>
            <span>{{training.sessions?.length}} {{'SINGLE_TRAINING.CHAPTER_NO' | translate}}</span>
          </span>
          <app-training-duration
            class="ms-3 ms-md-0 d-block"
            *ngIf="training.duration"
            color="accent"
            [duration]="training.duration"></app-training-duration>
        </div>
        <div class="col learnings" *ngIf="learningsAvailable(training)">
          <ul class="column-list p-0 m-0">
            <li *ngFor="let learning of training.learnings" class="d-flex flex-align-items-start training-highlight">
              <mat-icon svgIcon="check"></mat-icon>
              <span>{{learning}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
