<div *ngIf="!disabled && link, else a" [routerLink]="link">
  <ng-container *ngTemplateOutlet="a"></ng-container>
</div>
<ng-template #a>
  <div
    class="slide-summary"
    [matTooltip]="'SINGLE_TRAINING.SLIDE_TOOLTIP' | translate"
    [matTooltipDisabled]="!disabled"
    matTooltipClass="primary-tooltip"
    [ngClass]="{disabled: disabled}"
    [class.visited]="visited">
    <div class="slide-summary-icon">
      <img [src]="iconPath" alt="Slide" />
    </div>
    <div class="slide-summary-title">{{ title }}</div>
    <div *ngIf="duration" class="d-none d-sm-flex slide-summary-duration">
      <mat-icon class="me-2" svgIcon="clock"></mat-icon>
      <span class="duration-width">{{ duration }}</span>
    </div>
  </div>
</ng-template>
