/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetDepProjectsResponse} from './get-dep-projects.response';
import {GetDepProjectsRequest} from './get-dep-projects.request';
@Injectable()
export class DepProjectsGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getDepProjects(
    queryParams: GetDepProjectsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetDepProjectsResponse> {
    return this._httpClient.request('get', this._apiHost + '/dep_projects', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
