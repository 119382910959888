import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NestedEllipsisModule} from 'ngx-nested-ellipsis';
import {PlyrModule} from 'ngx-plyr';
import {AppMaterialModule} from '../app-material.module';
import {IconsModule} from '../icons.module';
import {PipesModule} from '../pipes/pipes.module';
import {SharedModule} from '../shared/shared.module';
import {CarouselComponent} from './carousel/carousel.component';
import {CircleComponent} from './circle/circle.component';
import {CollapsibleCardComponent} from './collapsible-card/collapsible-card.component';
import {ContactFloatComponent} from './contact-float/contact-float.component';
import {CreateNoteDialogComponent} from './create-note-dialog/create-note-dialog.component';
import {EditableNoteComponent} from './editable-note/editable-note.component';
import {GenericDialogComponent} from './generic-dialog/generic-dialog.component';
import {LivetrainingCardComponent} from './livetraining-card/livetraining-card.component';
import {ScrollToTopComponent} from './scroll-to-top/scroll-to-top.component';
import {SlideSummaryComponent} from './slide-summary/slide-summary.component';
import {TrainerOtherTrainingCardComponent} from './trainer-other-training-card/trainer-other-training-card.component';
import {TrainingCardOverlayComponent} from './training-card-overlay/training-card-overlay.component';
import {TrainingCardComponent} from './training-card/training-card.component';
import {TrainingPreviewCardComponent} from './training-preview-card/training-preview-card.component';
import {VideoDialogComponent} from './video-dialog/video-dialog.component';
import {VideoComponent} from './video/video.component';
import {WelcomeHeaderComponent} from './welcome-header/welcome-header.component';

const COMPONENTS = [
  CarouselComponent,
  CircleComponent,
  CollapsibleCardComponent,
  ContactFloatComponent,
  CreateNoteDialogComponent,
  EditableNoteComponent,
  GenericDialogComponent,
  LivetrainingCardComponent,
  ScrollToTopComponent,
  SlideSummaryComponent,
  TrainerOtherTrainingCardComponent,
  TrainingCardComponent,
  TrainingCardOverlayComponent,
  TrainingPreviewCardComponent,
  VideoComponent,
  VideoDialogComponent,
  WelcomeHeaderComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    MatIconModule,
    MatTooltipModule,
    AppMaterialModule,
    CommonModule,
    RouterModule,
    SharedModule,
    PlyrModule,
    TranslateModule,
    IconsModule,
    PipesModule,
    OverlayModule,
    NestedEllipsisModule,
  ],
  exports: COMPONENTS,
})
export class UiComponentsModule {}
