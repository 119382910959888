<div
  class="header d-flex px-4"
  (click)="toggleExpand()"
  [matTooltipDisabled]="expanded"
  [matTooltip]="'SINGLE_TRAINING.EXPAND' | translate"
  matTooltipPosition="above">
  <div>
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="text-end">
    <mat-icon *ngIf="!expanded" svgIcon="expand"></mat-icon>
    <mat-icon *ngIf="expanded" svgIcon="reduce"></mat-icon>
  </div>
</div>
<div class="body px-5 py-4">
  <ng-content *ngIf="expanded" select="[body]"></ng-content>
</div>
