<ng-container *ngIf="training && training.has_content">
  <div class="d-flex card">
    <ng-container *ngIf="showPreview">
      <ng-container [ngSwitch]="isLocked?.reason">
        <div *ngSwitchCase="'NOT_STARTED'" class="lock-badge">{{getLockReasonTooltip()}}</div>
        <button mat-icon-button *ngSwitchDefault class="lock-icon" (click)="openLockedInfoDialog()">
          <mat-icon svgIcon="lock-filled"></mat-icon>
        </button>
      </ng-container>
    </ng-container>
    <div class="bg-wrapper" [class.done]="isDone(training)">
      <div class="card-image">
        <img class="w-100" [appSrcset]="training.lead_image?.url" pxWidth="600" alt="" />
        <div class="card-title d-flex justify-content-center align-items-center" *ngIf="training.name">
          <h1 class="px-3">{{training.name}}</h1>
        </div>
      </div>
      <div class="training-progress" *ngIf="!showPreview">
        <app-progress-bar type="full" [total]="training.total" [current]="training.current"></app-progress-bar>
      </div>
      <div class="footer m-2">
        <button
          mat-icon-button
          class="me-xxl-3 me-1"
          color="primary"
          (click)="openInfoOverlay()"
          cdkOverlayOrigin
          #CdkOverlayOrigin="cdkOverlayOrigin">
          <mat-icon svgIcon="info" inline="true"></mat-icon>
        </button>
        <button mat-stroked-button color="primary" class="rounded" [disabled]="!training.lead_video" (click)="openVideoDialog()">
          <mat-icon svgIcon="preview-play" class="me-2"></mat-icon>
          <span translate>TRAININGS.BUTTON.PREVIEW</span>
        </button>
        <a [routerLink]="getTrainingLink(training.slug)" mat-flat-button color="accent" class="enter-training" [disabled]="showPreview">
          <span translate>TRAININGS.BUTTON.START</span>
        </a>
      </div>
    </div>
  </div>
  <app-training-card-overlay #overlayComponent [cdkOverlayOrigin]="CdkOverlayOrigin" [training]="training"> </app-training-card-overlay>
</ng-container>
