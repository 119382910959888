/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetSelfResponse} from './get-self.response';
import {GetSelfRequest} from './get-self.request';
import {PostByQuestionnaireIdAnswerSetsResponse} from './post-by-questionnaire-id-answer-sets.response';
import {PostByQuestionnaireIdAnswerSetsRequest} from './post-by-questionnaire-id-answer-sets.request';
@Injectable()
export class QuestionnairesGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getSelf(queryParams: GetSelfRequest, options?: Parameters<HttpClient['request']>[2]): Observable<GetSelfResponse> {
    return this._httpClient.request('get', this._apiHost + '/questionnaires/self', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }

  postByQuestionnaireIdAnswerSets(
    questionnaire_id: string,
    body: PostByQuestionnaireIdAnswerSetsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostByQuestionnaireIdAnswerSetsResponse> {
    return this._httpClient.request('post', this._apiHost + `/questionnaires/${questionnaire_id}/answer_sets`, {
      ...options,
      observe: 'body',
      body,
    });
  }
}
