<div class="container">
  <div mat-dialog-content class="content">
    <textarea matInput #noteCreationText placeholder="{{'NOTES.PLACEHOLDER' | translate}}"></textarea>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-flat-button (click)="close()">
      <span translate>GENERIC.BUTTON_CANCEL</span>
    </button>
    <button mat-flat-button [disabled]="!noteCreationText.value" color="accent" (click)="close(noteCreationText.value)">
      <span translate>GENERIC.BUTTON_SAVE</span>
    </button>
  </div>
</div>
