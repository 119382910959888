/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetNotesResponse} from './get-notes.response';
import {GetNotesRequest} from './get-notes.request';
import {PostNotesResponse} from './post-notes.response';
import {PostNotesRequest} from './post-notes.request';
import {PutByNoteIdResponse} from './put-by-note-id.response';
import {PutByNoteIdRequest} from './put-by-note-id.request';
import {DeleteByNoteIdResponse} from './delete-by-note-id.response';
import {DeleteByNoteIdRequest} from './delete-by-note-id.request';
@Injectable()
export class NotesGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getNotes(queryParams: GetNotesRequest, options?: Parameters<HttpClient['request']>[2]): Observable<GetNotesResponse> {
    return this._httpClient.request('get', this._apiHost + '/notes', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }

  postNotes(body: PostNotesRequest, options?: Parameters<HttpClient['request']>[2]): Observable<PostNotesResponse> {
    return this._httpClient.request('post', this._apiHost + '/notes', {
      ...options,
      observe: 'body',
      body,
    });
  }

  putByNoteId(
    note_id: string,
    body: PutByNoteIdRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PutByNoteIdResponse> {
    return this._httpClient.request('put', this._apiHost + `/notes/${note_id}`, {
      ...options,
      observe: 'body',
      body,
    });
  }

  deleteByNoteId(
    note_id: string,
    queryParams: DeleteByNoteIdRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<DeleteByNoteIdResponse> {
    return this._httpClient.request('delete', this._apiHost + `/notes/${note_id}`, {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
