/* tslint:disable */
import {NgModule} from '@angular/core';
import {ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {API_HOST} from './api-host';
import {QuestionnairesGateway} from './questionnaires/questionnaires.gateway';
import {ExternalAssessmentsGateway} from './external-assessments/external-assessments.gateway';
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [QuestionnairesGateway, ExternalAssessmentsGateway],
})
export class AssessmentApiModule {
  static forRoot(apiHost: string): ModuleWithProviders<AssessmentApiModule> {
    return {
      ngModule: AssessmentApiModule,
      providers: [{provide: API_HOST, useValue: apiHost}],
    };
  }
}
