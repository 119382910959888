<div class="selection-welcome">
  <p class="selection-header">Willkommen bei troodi grow.</p>
  <p class="selection-intro">
    Wir helfen Dir, passende Lernprogramme für Deine persönliche Entwicklung zu finden. <br />
    Wie möchtest Du fortfahren?
  </p>
</div>

<div class="selection-grid">
  <div class="card" routerLink="/assessments">
    <div class="card-image"><img src="assets/img/undraw_co-workers.svg" /></div>
    <div class="card-text">
      <p class="card-title">Full Assessment</p>
      <p class="card-content">Beantworte einen ausführlichen Fragebogen und hole Dir Feedback von Kollegen oder Freunden.</p>
    </div>
  </div>

  <div class="card" routerLink="/self-assessment/1">
    <div class="card-image"><img src="assets/img/undraw_personal_settings.svg" /></div>
    <div class="card-text">
      <p class="card-title">Self Assessment</p>
      <p class="card-content">Beantworte einen ausführlichen Fragebogen und lass Dein Kompetenzprofil von uns bestimmen.</p>
    </div>
  </div>

  <div class="card" routerLink="/recommendation">
    <div class="card-image"><img src="assets/img/undraw_preferences.svg" /></div>
    <div class="card-text">
      <p class="card-title">Kompetenzprofil</p>
      <p class="card-content">Bestimme Dein Kompetenzprofil selbst.</p>
    </div>
  </div>
</div>
<div class="teaser" routerLink="/e-learning/trainings/all">
  <p>Du möchtest wissen, welche Lernprogramme wir anbieten? <span> Hier findest Du eine vollständige Liste.</span></p>
</div>
