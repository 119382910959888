import {Injectable} from '@angular/core';
// Interface for Router-State
import {Params, RouterStateSnapshot} from '@angular/router';
import {RouterStateSerializer} from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  fragment: string;
  data: Params;
}

// Serializer for the router
@Injectable()
export class CustomURLSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    // get the data in the lowest level
    const data = route.data;

    const {
      url,
      root: {queryParams, fragment},
    } = routerState;
    const {params} = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {url, params, queryParams, fragment: fragment ?? '', data};
  }
}
