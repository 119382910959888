import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';
import {NavigationStart, Params, Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {createExecuteEffect} from '@zeit-dev/ngrx-util';
import {CookieService} from 'ngx-cookie-service';
import {EMPTY, Observable, of, timer} from 'rxjs';
import {catchError, delayWhen, exhaustMap, filter, map, retryWhen, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {showSnackbar} from 'src/app/shared/shared.actions';
import {GetQuizzesByTypeformIdResponse} from '../../backend/elearning-api/quizzes/get-quizzes-by-typeform-id.response';
import {selectRouteParams} from '../../shared/shared.selectors';
import {
  addNote,
  bookingsActions,
  checkCurrentTraining,
  deleteNote,
  depProjectsActions,
  editNote,
  idempotentCertificateSuccess,
  learningPathActions,
  loadCertificateAvailability,
  loadCertificateAvailabilityIfNeeded,
  loadCertificateAvailabilitySuccess,
  loadError,
  loadQuizResult,
  loadQuizResultForEventId,
  loadQuizResultSuccess,
  loadSessionContent,
  loadSessionContentSuccess,
  loadUserTrainingRatings,
  loadUserTrainingRatingsError,
  loadUserTrainingRatingsSuccess,
  notesActions,
  progressesActions,
  trainingActions,
  updateUserTrainingProgress,
  updateUserTrainingProgressSuccess,
  updateUserTrainingRating,
} from './e-learning.actions';
import {ELearningDataService} from './e-learning.data.service';
import {ELearningSlice} from './e-learning.reducer';
import {
  selectCertificatesAvailabilityState,
  selectCurrentSessionContent,
  selectCurrentTraining,
  selectDepProjectsState,
  selectLearningPathState,
  selectLoadPreview,
  selectTrainingsState,
  selectUserBookingState,
  selectUserTrainingProgressesState,
} from './e-learning.selectors';

@Injectable()
export class ELearningEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _store$: Store<ELearningSlice>,
    private readonly _cookie: CookieService,
    private readonly _dataService: ELearningDataService,
    private readonly _router: Router,
    private readonly _errorHandler: ErrorHandler,
    private readonly _translate: TranslateService
  ) {}

  private get _ref(): string | undefined {
    return this._cookie.get('io.prismic.preview');
  }

  readonly loadTraining$ = createEffect(() =>
    this._actions$.pipe(
      createExecuteEffect(
        trainingActions,
        (params, state) => {
          const trainingState = selectTrainingsState(state);
          const shouldLoadPreview = selectLoadPreview(state);

          if (trainingState.loaded && trainingState.results) {
            return of(trainingState.results);
          }

          if (shouldLoadPreview && this._ref) {
            // if this flag is set in the state, this user should have the previews loaded
            return this._dataService.getTrainingPreviews(this._ref);
          } else {
            // otherwise, load the normal trainings
            return this._dataService.getTrainings();
          }
        },
        this._store$,
        this._errorHandler
      )
    )
  );

  readonly loadSessionContent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadSessionContent),
      withLatestFrom(this._store$.select(selectRouteParams).pipe(filter((x) => !!x)) as Observable<Params>),
      exhaustMap(([, routeParams]) =>
        this._dataService.getSessionContent(routeParams['trainingSlug'], routeParams['sessionId'], this._ref).pipe(
          map((resp) =>
            loadSessionContentSuccess({
              slug: routeParams['trainingSlug'],
              sessionId: routeParams['sessionId'],
              sessionContent: resp,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            switch (error.status) {
              case 402:
                // occurs when the user has no access to this training
                // should only happen if the user loses access and returns directly
                // via url
                this._router.navigateByUrl(`e-learning/trainings/${routeParams['trainingSlug']}`);
                return EMPTY;
              case 403:
                // occurs when user has no progress for this training
                // or when the requested session is beyond his progress
                // redirect him to the resume-view to handle this
                this._router.navigateByUrl(`e-learning/trainings/${routeParams['trainingSlug']}/resume`);
                return EMPTY;
              case 404:
                this._router.navigate([`notfound`], {skipLocationChange: true});
                return EMPTY;
              default:
                return of(loadError());
            }
          })
        )
      )
    )
  );

  readonly loadUserTrainingProgresses$ = createEffect(() =>
    this._actions$.pipe(
      createExecuteEffect(
        progressesActions,
        (params, state) => {
          const progressState = selectUserTrainingProgressesState(state);

          if (progressState.loaded && progressState.results) {
            return of(progressState.results);
          }

          return this._dataService.getUserTrainingProgresses();
        },
        this._store$,
        this._errorHandler
      )
    )
  );

  readonly updateUserTrainingProgress$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateUserTrainingProgress),
      switchMap((action) =>
        this._dataService.putUserTrainingProgress(action.slug, action.slideId).pipe(
          map((userTrainingProgress) => updateUserTrainingProgressSuccess({userTrainingProgress})),
          catchError((error: HttpErrorResponse) => {
            switch (error.status) {
              case 401:
                // if the user loses the session for whatever reason, redirect to the login
                this._router.navigateByUrl('login');
                return of(showSnackbar({message: this._translate.instant('ERROR.SESSION_LOST'), messageType: 'error'}));
              default:
                // in every other case, throw this error!
                throw error;
            }
          })
        )
      )
    )
  );

  checkSubscription$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(loadSessionContentSuccess, progressesActions.success),
        withLatestFrom(
          this._store$.select(selectCurrentSessionContent),
          this._store$.select(selectRouteParams).pipe(filter((x) => !!x)) as Observable<Params>
        ),
        switchMap(([, sessionContent, params]) => {
          // check if the requested slide exists
          const slide = params['slideId'] as string;
          if (slide && sessionContent && !sessionContent.slides.objects[slide]) {
            // This slug does not exist!
            // Currently, just bring the user to a 404, maybe add a specific error-page for this?
            this._router.navigate(['notfound'], {skipLocationChange: true});
          }
          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  readonly checkCurrentTraining$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(checkCurrentTraining),
        withLatestFrom(this._store$.select(selectCurrentTraining), this._store$.select(selectTrainingsState)),
        map(([, training, trainingState]) => {
          if (trainingState.loaded && !training && !trainingState.loading) {
            this._router.navigate(['notfound'], {skipLocationChange: true});
          }
          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  readonly loadQuizForEventIdResult$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadQuizResultForEventId),
      exhaustMap((action) =>
        this.loadTypeformResponseForTypeformEventId(action.typeformQuizId, action.typeformEventId).pipe(
          map((typeformQuizResult) => loadQuizResultSuccess(typeformQuizResult)),
          takeUntil(this._router.events.pipe(filter((event) => event instanceof NavigationStart)))
        )
      )
    )
  );

  readonly loadQuizResult$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadQuizResult),
      exhaustMap((action) =>
        this._dataService.getQuizResponse(action.typeformQuizId).pipe(
          map((typeformQuizResult) => loadQuizResultSuccess(typeformQuizResult)),
          catchError((error: HttpErrorResponse) => {
            switch (error.status) {
              case 404:
                // ignore 404s, as they are thrown whenever there is no result (yet)
                return EMPTY;
              default:
                // everything else should be thrown!
                throw error;
            }
          })
        )
      )
    )
  );

  readonly loadLearningPaths$ = createEffect(() =>
    this._actions$.pipe(
      createExecuteEffect(
        learningPathActions,
        (params, state) => {
          const learningPathState = selectLearningPathState(state);

          if (learningPathState?.loaded && learningPathState.results) {
            return of(learningPathState.results);
          }

          return this._dataService.getLearningPaths();
        },
        this._store$,
        this._errorHandler
      )
    )
  );

  readonly loadTrainingBookings$ = createEffect(() =>
    this._actions$.pipe(
      createExecuteEffect(
        bookingsActions,
        (params, state) => {
          const bookingState = selectUserBookingState(state);

          if (bookingState?.loaded && bookingState.results) {
            return of(bookingState.results);
          }

          return this._dataService.getBookedTrainings();
        },
        this._store$,
        this._errorHandler
      )
    )
  );

  /**
   * Loads the user-notes. As they're very volatile, we don't want idempotency here!
   * However, always make sure to load them depending on the feature-flag as of now!
   */
  readonly loadUserNotes$ = createEffect(() =>
    this._actions$.pipe(
      createExecuteEffect(
        notesActions,
        () => {
          // notes can and should always be reloaded, no idempotency needed
          return this._dataService.getUserNotes();
        },
        this._store$,
        this._errorHandler
      )
    )
  );

  readonly putUserNote$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addNote),
      exhaustMap((action) =>
        this._dataService.putNote(action.note).pipe(
          // load notes from BE, we always want to sync with the BE after an action
          map(() => notesActions.execute({params: undefined}))
        )
      )
    )
  );

  readonly deleteNote$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteNote),
      exhaustMap((action) =>
        this._dataService.deleteNote(action.id).pipe(
          // load notes from BE, we always want to sync with the BE after an action
          map(() => notesActions.execute({params: undefined}))
        )
      )
    )
  );

  readonly editNote$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editNote),
      exhaustMap((action) =>
        this._dataService.editNote(action.id, action.text).pipe(
          // load notes from BE, we always want to sync with the BE after an action
          map(() => notesActions.execute({params: undefined}))
        )
      )
    )
  );

  /**
   * Loads the certificate-Availability from the BE forcefully. This ensures we always have the latest data!
   */
  readonly loadCertificateAvailability$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadCertificateAvailability, updateUserTrainingProgressSuccess),
      switchMap(() => this._dataService.getCertificates().pipe(map((certificates) => loadCertificateAvailabilitySuccess({certificates}))))
    )
  );

  /**
   * As opposed to loadCertificateAvailability$, this checks if there is something in the state first.
   * If we have something in the state already, just do nothing to save traffic. Else, load the data.
   */
  readonly loadCertificateAvailabilityIfNeeded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadCertificateAvailabilityIfNeeded),
      withLatestFrom(this._store$.select(selectCertificatesAvailabilityState)),
      switchMap(([, certificateState]) => {
        if (!!certificateState.loaded && !!certificateState.results) {
          // there is data in the state, we don't need to update anything
          return of(idempotentCertificateSuccess());
        }
        // there is no data in state, force the loading by using the existing action
        return of(loadCertificateAvailability());
      })
    )
  );

  readonly loadUserTrainingRatings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadUserTrainingRatings),
      switchMap((action) =>
        this._dataService.getUserTrainingRatings(action.slug).pipe(
          map((rating) => loadUserTrainingRatingsSuccess({slug: action.slug, rating})),
          catchError((e: HttpErrorResponse) => {
            switch (e.status) {
              case 404:
                // BE returns a 404 if the user has no rating for this training (yet), so this is no error
                return of(loadUserTrainingRatingsSuccess({slug: action.slug, rating: null}));
              default:
                // fetch every other error!
                loadUserTrainingRatingsError();
                throw e;
            }
          })
        )
      )
    )
  );

  readonly updateUserTrainingRatings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateUserTrainingRating),
      switchMap((action) =>
        this._dataService
          .updateUserTrainingRatings(action.slug, action.rating)
          .pipe(map((rating) => loadUserTrainingRatingsSuccess({slug: action.slug, rating})))
      )
    )
  );

  readonly loadDepProjects$ = createEffect(() =>
    this._actions$.pipe(
      createExecuteEffect(
        depProjectsActions,
        (params, state) => {
          const depState = selectDepProjectsState(state);

          if (depState.loaded && depState.results) {
            return of(depState.results);
          }

          return this._dataService.getDepProjects();
        },
        this._store$,
        this._errorHandler
      )
    )
  );

  loadTypeformResponseForTypeformEventId(typeformFormId: string, typeformEventId: string): Observable<GetQuizzesByTypeformIdResponse> {
    return this._dataService.getQuizResponse(typeformFormId, typeformEventId).pipe(
      retryWhen((errors) =>
        errors.pipe(
          delayWhen((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return timer(1000);
            }
            throw error;
          })
        )
      )
    );
  }
}
