<app-slide-separator
  *ngIf="isIntroShown"
  [type]="data?.type"
  [title]="data?.title"
  [introText]="data?.video_pre_text"
  [buttonText]="'INTRO_SLIDE.BUTTON.VIDEO' | translate"
  (buttonClicked)="handleContinueClick()"
  [skipLink]="nextLink"
  [backLink]="backLink"
  [specialSlide]="data?.special_slide_type">
</app-slide-separator>
<app-video
  *ngIf="data && !isIntroShown"
  [video]="data?.video"
  [slideId]="data?.id"
  autoplay="true"
  [notes]="notes$ | async"
  (addNoteEvent)="addNewNote($event)"
  (editNoteEvent)="editNote($event)"
  (deleteNoteEvent)="deleteNote($event)"
  [trainingSlug]="training?.slug"
  [timestamp]="timestamp">
</app-video>
