/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetExternalAssessmentsResponse} from './get-external-assessments.response';
import {GetExternalAssessmentsRequest} from './get-external-assessments.request';
import {PostExternalAssessmentsResponse} from './post-external-assessments.response';
import {PostExternalAssessmentsRequest} from './post-external-assessments.request';
import {PostByIdAddParticipantResponse} from './post-by-id-add-participant.response';
import {PostByIdAddParticipantRequest} from './post-by-id-add-participant.request';
import {PostByIdFinishResponse} from './post-by-id-finish.response';
import {PostByIdFinishRequest} from './post-by-id-finish.request';
@Injectable()
export class ExternalAssessmentsGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getExternalAssessments(
    queryParams: GetExternalAssessmentsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetExternalAssessmentsResponse> {
    return this._httpClient.request('get', this._apiHost + '/external_assessments', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }

  postExternalAssessments(
    body: PostExternalAssessmentsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostExternalAssessmentsResponse> {
    return this._httpClient.request('post', this._apiHost + '/external_assessments', {
      ...options,
      observe: 'body',
      body,
    });
  }

  postByIdAddParticipant(
    id: string,
    body: PostByIdAddParticipantRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostByIdAddParticipantResponse> {
    return this._httpClient.request('post', this._apiHost + `/external_assessments/${id}/add_participant`, {
      ...options,
      observe: 'body',
      body,
    });
  }

  postByIdFinish(
    id: string,
    body: PostByIdFinishRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostByIdFinishResponse> {
    return this._httpClient.request('post', this._apiHost + `/external_assessments/${id}/finish`, {
      ...options,
      observe: 'body',
      body,
    });
  }
}
