import {createAction, props} from '@ngrx/store';
import {DraftAssessment, ExternalAssessmentViewModel, Questionnaire} from './assessment.data.service';

export const setQuestionRatingSelf = createAction('[ASSESSMENT] Set Self-Question Rating', props<{id: string; value: string}>());

export const loadQuestionsSelf = createAction('[ASSESSMENT] Load Self-Questions');

export const loadQuestionsSelfSuccess = createAction('[ASSESSMENT] Load Self-Questions success', props<{questionnaire: Questionnaire}>());

export const sendAnswersSelf = createAction('[ASSESSMENT] Send Self-Answers');

export const setQuestionRatingExternal = createAction('[ASSESSMENT] Set External-Question Rating', props<{id: string; value: string}>());

export const loadQuestionsExternal = createAction('[ASSESSMENT] Load External-Questions');

export const loadQuestionsExternalSuccess = createAction(
  '[ASSESSMENT] Load External-Questions success',
  props<{questionnaire: Questionnaire; token: string}>()
);

export const sendAnswersExternal = createAction('[ASSESSMENT] Send External-Answers');

export const loadExternalAssessments = createAction('[ASSESSMENT] Load External Assessments');

export const loadExternalAssessmentsSuccess = createAction(
  '[ASSESSMENT] Load External Assessments Success',
  props<{assessments: ExternalAssessmentViewModel[]}>()
);

export const addExternalAssessmentParticipant = createAction(
  '[ASSESSMENT] Add Participant',
  props<{assessmentId: ExternalAssessmentViewModel['id']; emailAddress: string}>()
);

export const addExternalAssessmentParticipantSuccess = createAction(
  '[ASSESSMENT] Add Participant Success',
  props<{assessmentId: ExternalAssessmentViewModel['id']}>()
);

export const createExternalAssessment = createAction('[ASSESSMENT] Create External Assessment', props<{assessment: DraftAssessment}>());

export const addExternalAssessment = createAction(
  '[ASSESSMENT] Add External Assessment',
  props<{assessment: ExternalAssessmentViewModel}>()
);

export const finishExternalAssessment = createAction('[ASSESSMENT] Finished External Assessment', props<{id: string}>());
