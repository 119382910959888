import {ScrollDispatcher} from '@angular/cdk/scrolling';
import {Component} from '@angular/core';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
})
export class ScrollToTopComponent {
  constructor(private readonly _scroll: ScrollDispatcher) {}

  scrollToTop(button: MatButton) {
    const scrollContainer = this._scroll.getAncestorScrollContainers(button._elementRef)[0];
    scrollContainer.getElementRef().nativeElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
