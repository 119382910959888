import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PostContactSalesRequest} from '@backend-api/user/post-contact-sales.request';

@Component({
  selector: 'app-expiration-dialog',
  templateUrl: './expiration-dialog.component.html',
  styleUrls: ['./expiration-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpirationDialogComponent {
  selected = 0;

  constructor(private readonly _ref: MatDialogRef<ExpirationDialogComponent>) {}

  close() {
    this._ref.close();
  }

  send(event: {selection: PostContactSalesRequest['type']}) {
    this._ref.close({selection: event.selection});
  }

  select(selection: number) {
    this.selected = selection;
  }
}
