<!-- because of layout and scrolling-behaviour, the subnav needs to be down here! -->
<mat-toolbar class="d-none d-lg-flex subnav">
  <div class="container justify-content-between d-flex flex-row">
    <div class="navlinks d-flex align-items-center px-3">
      <a *ngFor="let link of links" class="textlink mx-3" [routerLink]="link.url" [class.active]="link.url === (currentRoute$ | async)">
        <span translate>{{link.linktitle}}</span>
      </a>
    </div>
    <app-trainings-search layout="subheader"></app-trainings-search>
  </div>
</mat-toolbar>
