<ng-container *ngIf="!!depProject">
  <div class="dep py-3 py-xxl-5">
    <h1 class="dep-title p-3 pb-xxl-3 pb-2">{{depProject.name}}</h1>
    <app-carousel #moduleCarousel>
      <div class="module" *ngFor="let module of depProject.modules; trackBy: trackModule">
        <div class="module-title">
          <h2>{{module.name}}</h2>
        </div>
        <div class="trainings d-flex">
          <div
            *ngFor="let training of module.trainings; trackBy: trackDepTraining"
            #trainingCards
            [attr.current]="isCurrentCard(training)"
            class="p-3 d-flex card">
            <app-training-card
              [training]="training.training"
              [isLocked]="isLocked(training.starts_at)"
              cdkOverlayOrigin
              #CdkOverlayOrigin="cdkOverlayOrigin">
            </app-training-card>
          </div>
        </div>
      </div>
    </app-carousel>
  </div>
</ng-container>
