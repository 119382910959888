import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {fadeInAnimation, staggerElementsAnimation} from 'src/app/animations';
import {TitleSlide} from '../../../backend/elearning-api/trainings/get-by-training-id-sessions-by-id.response';
import {Training} from '../../../backend/elearning-api/trainings/get-trainings.response';
import {UserTrainingProgress} from '../../../backend/elearning-api/user-training-progresses/get-user-training-progresses.response';
import {Language} from '../../user/user.data.service';
import {selectUser} from '../../user/user.selectors';
import {loadCertificateAvailability} from '../e-learning.actions';
import {selectCertificateAvailableForCurrentTraining, selectCertificatesAvailabilityStateLoaded} from '../e-learning.selectors';

@Component({
  selector: 'app-certificate-slide',
  templateUrl: './certificate-slide.component.html',
  styleUrls: ['./certificate-slide.component.scss'],
  animations: [fadeInAnimation, staggerElementsAnimation],
})
export class CertificateSlideComponent implements OnInit {
  @Input() training?: Training;
  @Input() data?: TitleSlide;
  @Input() backLink?: string;
  @Input() currentProgress?: UserTrainingProgress;
  @Output() trackProgress = new EventEmitter();
  private readonly _subscriptions = new Subscription();
  readonly userModel$ = this._store$.select(selectUser);
  userId = '';
  userIdHmac = '';
  isIntroShown = true;
  readonly certificateStateLoaded$ = this._store$.select(selectCertificatesAvailabilityStateLoaded);
  readonly certificateAvailable$ = this._store$.select(selectCertificateAvailableForCurrentTraining);
  linkedInLanguage?: string;
  private readonly _LINKEDIN_ORG_ID = '56417693';

  constructor(private readonly _store$: Store, private readonly _translate: TranslateService) {}

  ngOnInit() {
    this._subscriptions.add(
      this.userModel$.subscribe((user) => {
        if (user) {
          this.userId = user.id;
          this.userIdHmac = user.user_id_hmac;
        }
      })
    );

    // trigger check whether the user is allowed to get the certificate
    if (this.training) {
      this._store$.dispatch(loadCertificateAvailability());
    }

    this.linkedInLanguage = (this._translate.currentLang as Language) === 'de' ? 'de_DE' : 'en_US';
  }

  certificateEmbedUrl() {
    return `/elearning/certificates/${this.training?.slug}.html?user_id=${this.userId}&user_id_hmac=${this.userIdHmac}`;
  }

  certificateShareUrl() {
    return encodeURI('https://troodi.de');
  }

  certificateDownloadUrl() {
    return `/elearning/certificates/${this.training?.slug}.pdf?user_id=${this.userId}&user_id_hmac=${this.userIdHmac}`;
  }

  shareCertificateUrl(available: Date) {
    if (this.training) {
      // encode the name to filter possibly weird symbols
      const encodedName = encodeURIComponent(this.training.name);
      // `getMonth` returns 0-11, so increment
      const adjustedMonth = available.getMonth() + 1;
      return `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodedName}&organizationId=${
        this._LINKEDIN_ORG_ID
      }&issueYear=${available.getFullYear()}&issueMonth=${adjustedMonth}&certUrl=${this.certificateShareUrl()}`;
    }
  }

  hideIntro() {
    this.isIntroShown = false;
    this.trackProgress.emit();
  }
}
