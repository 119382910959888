import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {loadQuestionsSelf, sendAnswersSelf, setQuestionRatingSelf} from '../assessment.actions';
import {AssessmentStateSlice} from '../assessment.reducer';
import {PageInfo, selectPage, selectPageSummarySelf, selectSelfQuestionsForPage} from '../assessment.selectors';
import {QuestionViewModel} from '../question.model';

@Component({
  selector: 'app-assessment-self',
  templateUrl: './assessment-self.component.html',
  styleUrls: ['./assessment-self.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentSelfComponent implements OnInit {
  questions$: Observable<QuestionViewModel[]> = this.store.select(selectSelfQuestionsForPage);
  pageNo$: Observable<number> = this.store.select(selectPage);
  pageSummary$: Observable<PageInfo[]> = this.store.select(selectPageSummarySelf);

  routerLink = '/self-assessment';

  constructor(private readonly store: Store<AssessmentStateSlice>) {}

  ngOnInit() {
    this.store.dispatch(loadQuestionsSelf());
  }

  onChange(e: {id: string; value: string}) {
    this.store.dispatch(setQuestionRatingSelf({id: e.id, value: e.value}));
  }

  sendAnswers() {
    this.store.dispatch(sendAnswersSelf());
  }
}
