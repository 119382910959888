import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {cloneDeep} from 'lodash-es';
import {Language} from 'src/app/features/user/user.data.service';
import {ExtractedTopic} from '../../e-learning.selectors';

export interface TopicFilterState {
  selectedTopics: Record<string, boolean>;
  referenceTopics: ExtractedTopic[];
  languageFilter: Language | null;
}

/**
 * This interface is used for the filter-chips.
 * It allows for easier rendering without excessive use of check-functions etc.
 */
export type TopicFilter = ExtractedTopic & {selected: boolean};

/**
 * Component-Store handling the filter-function for topics.
 * Used by the TopicFilter-Component, so be sure to `provide` this component-store in every component that uses this!
 */
@Injectable()
export class TopicFilterStore extends ComponentStore<TopicFilterState> {
  constructor() {
    super({
      // start just with an empty record
      selectedTopics: {},
      referenceTopics: [],
      languageFilter: null,
    });
  }

  /**
   * Toggles the `selected`-state for the given topic.
   */
  readonly toggleSelectTopic = this.updater((state, topic: string) => {
    const changedData = cloneDeep(state.selectedTopics);
    changedData[topic] = !changedData[topic];
    return {
      ...state,
      selectedTopics: changedData,
    };
  });

  /**
   * Takes the given topics and initializes this store (overwriting already given data).
   * This will add all IDs to the `selectedTopics`-Record with the value `false`
   * and set the reference topics for the other selectors.
   * Be sure to call this as soon as you have the data ready and also when it substantially changes!
   */
  readonly loadTopics = this.updater((state, topics: ExtractedTopic[]) => {
    const newData: Record<string, boolean> = {};
    topics.forEach((topic) => (newData[topic.id] = false));
    return {
      ...state,
      referenceTopics: topics,
      selectedTopics: newData,
    };
  });

  /**
   * (De-)Selects all the topics in the store, given by the passed boolean.
   */
  readonly setAllTopics = this.updater((state, select: boolean) => {
    const changedData = cloneDeep(state.selectedTopics);
    Object.keys(state.selectedTopics).forEach((id) => (changedData[id] = select));
    return {
      ...state,
      selectedTopics: changedData,
    };
  });

  /**
   * (De-)Selects the given language in the store, decided by the passed boolean.
   */
  readonly setLanguageSelection = this.updater((state, value: Language | null) => {
    return {
      ...state,
      languageFilter: value,
    };
  });

  /**
   * Returns an observable of the record. Mostly useful for other selectors.
   */
  readonly selectedTopics$ = this.select((state) => state.selectedTopics);

  /**
   * Returns an observable of the reference topics. Mostly useful for other selectors.
   */
  private readonly _referenceTopics$ = this.select((state) => state.referenceTopics);

  /**
   * Returns an observable of the language-selection. Mostly useful for other selectors.
   */
  readonly languageSelection$ = this.select((state) => state.languageFilter);

  /**
   * Returns whether or not the user has selected any filter.
   */
  private readonly _hasFilter$ = this.select(this.selectedTopics$, (selection) => Object.values(selection).some((value) => !!value));

  /**
   * Returns a filtered Array of topics based on the user's language-selection.
   * This selector is based on the store's reference-data and can be used in chained selectors
   * to represent the 'full data' with language-filtering.
   * As this may be relevant to further selectors and/or the UI,
   * this selector also recalculates the `accessibleTrainingsCount` of each topic.
   */
  readonly filteredReferenceTopics$ = this.select(this._referenceTopics$, this.languageSelection$, (reference, selection) => {
    // if user selected one language, filter the trainings accordingly
    if (!!selection) {
      return reference.map((topic) => {
        const filteredTrainings = topic.trainings.filter((training) => training.language === selection);
        return {
          ...topic,
          trainings: filteredTrainings,
          // also recalculate the number of accessible trainings based on language-filter
          accessibleTrainingsCount: filteredTrainings.filter((training) => training.isAccessible).length,
        };
      });
    }
    // If no language was selected, just return everything
    return reference;
  });

  /**
   * Returns an array of topics (with complete data), but removes the ones not selected by the user.
   * I. e. this represents the filter-results!
   * If the user has not selected any topic, this will just return all topics.
   * This uses the topics given by the parent component,
   * so be sure to call the `load`-updater beforehand with the needed data
   */
  readonly mappedSelectedTopics$ = this.select(
    this.filteredReferenceTopics$,
    this.selectedTopics$,
    this._hasFilter$,
    (topics, selected, isSelection) => {
      return isSelection ? topics.filter((topic) => !!selected[topic.id]) : topics;
    }
  );

  /**
   * Returns a mapping of topics used for the chips.
   * The returned type is a topic with an added boolean representing
   * whether or not this topic was selected by the user.
   * As this uses the topics given by the parent-component, they are sorted exactly as in the list.
   */
  readonly mappedTopics$ = this.select(this.filteredReferenceTopics$, this.selectedTopics$, (topics, selected) =>
    topics.map(
      (topic) =>
        ({
          ...topic,
          selected: !!selected[topic.id],
        } as TopicFilter)
    )
  );

  /**
   * Returns topics the user has selected despite not having available trainings in these topics.
   * Also includes topics that normally *would have* had accessible trainings, but are dropped due to the language-filter.
   */
  readonly selectedNonAvailableTopics$ = this.select(this.mappedTopics$, (topics) => {
    return topics.filter((topic) => topic.selected && topic.accessibleTrainingsCount === 0);
  });
}
