import {Component, HostBinding} from '@angular/core';
import {Store} from '@ngrx/store';
import {fadeInAnimation} from 'src/app/animations';
import {RecommendationSlice} from '../recommendation.reducer';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  animations: [fadeInAnimation],
})
export class ResultsComponent {
  @HostBinding('@fadeInAnimation') loadAnimation = true;
  resultVisible = false;

  constructor(private readonly store: Store<RecommendationSlice>) {}

  toogleResult() {
    this.resultVisible = !this.resultVisible;
  }
}
