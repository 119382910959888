<ng-container *ngIf="!!topic">
  <h1 class="topic-title pt-3 p-xxl-3">{{getTopicTitle(topic)}}</h1>
  <app-carousel #trainingCarousel>
    <div class="topic">
      <div class="trainings d-flex">
        <div *ngFor="let training of topic.trainings; trackBy: trackTraining" #trainingCards class="p-3 d-flex card">
          <app-training-card [@cardsAnimation] [training]="training"></app-training-card>
        </div>
      </div>
    </div>
  </app-carousel>
</ng-container>
