import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {PrivacyService, PrivacySettings} from '../shared/privacy.service';

@Component({
  selector: 'app-cookie-settings-dialog',
  templateUrl: './cookie-settings-dialog.component.html',
  styleUrls: ['./cookie-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieSettingsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  matomoChecked = true;
  collapsed = true;
  // Workaround for angular component issue #13870
  disableAnimation = true;
  private readonly _subscriptions = new Subscription();
  private readonly _optionalCookiesEnabled$ = this._privacyService.areOptionalsEnabled();

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  constructor(private readonly _ref: MatDialogRef<CookieSettingsDialogComponent>, private readonly _privacyService: PrivacyService) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this._optionalCookiesEnabled$.subscribe((state) => {
        if (state.matomo !== undefined) this.matomoChecked = state.matomo;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getOptionalState() {
    // we need a 'partially' state, so just use inline conversion
    return +this.matomoChecked;
  }

  decline() {
    this._privacyService.revokeAll();
    this._ref.close(false);
  }

  save() {
    const settings: PrivacySettings = {
      essential: true,
      matomo: this.matomoChecked,
    };
    this._privacyService.saveState(settings);
    this._ref.close(true);
  }

  toggleReadMore() {
    this.collapsed = !this.collapsed;
  }
}
