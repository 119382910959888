/**
 * Determine which ENVs are exposed to the frontend.
 *
 * !!! WARNING !!!
 * NEVER include any ENV variable that contains a secret, password, api-key etc!
 *
 * These ENVs are included/updated on every server restart.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IConfiguration {
  APPSIGNAL_PUSH_API_KEY_FRONTEND: undefined | string | null;
  APP_ENV: undefined | string | null;
  TYPEFORM_PROJECT_ID: undefined | string | null;
  MATOMO_TRACKING_URL: undefined | string | null;
  MATOMO_APP_ID: undefined | string | null;
}

export const configurationHelper = {
  /**
   * Get a feature-flag like ENV value.
   * @param key
   */
  getFlag(key: keyof IConfiguration): boolean {
    const val = configurationHelper.getConfig(key);

    return !!val && val.trim() !== 'false';
  },

  /**
   * Get a configuration value from injected ENVs
   * @param key
   */
  getConfig(key: keyof IConfiguration): undefined | string | null {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const config = (window as any)._appConfig as IConfiguration | undefined;
    if (!config) {
      return undefined;
    }

    const val = config[key];

    // Handle feature flags
    if (val === 'false') {
      return undefined;
    }

    return val;
  },

  /**
   * Get a configuration value from injected ENVs and raise an error if not set.
   * @param key
   */
  needConfig(key: keyof IConfiguration): string {
    const val = configurationHelper.getConfig(key);

    if (!val) {
      throw new Error('Missing config value: ' + key);
    }
    return val;
  },
};
