import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {EffectsModule} from '@ngrx/effects';
import {routerReducer} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {FeatherModule} from 'angular-feather';
import {Clock} from 'angular-feather/icons';
import {PipesModule} from '../pipes/pipes.module';
import {SrcsetDirective} from './directives/srcset.directive';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {SharedEffects} from './shared.effects';
import {reducers} from './shared.reducer';
import {TrainingDurationComponent} from './training-duration/training-duration.component';

@NgModule({
  declarations: [TrainingDurationComponent, ProgressBarComponent, SrcsetDirective],
  imports: [
    CommonModule,
    PipesModule,
    MatButtonModule,
    StoreModule.forFeature('shared', reducers),
    StoreModule.forFeature('router', routerReducer),
    EffectsModule.forFeature([SharedEffects]),
    FeatherModule.pick({
      Clock,
    }),
    MatIconModule,
  ],
  exports: [TrainingDurationComponent, ProgressBarComponent, SrcsetDirective],
})
export class SharedModule {}
