import {Injectable} from '@angular/core';
import {CouponsVerifyGateway} from '@backend-api/coupons-verify/coupons-verify.gateway';
import {OrderCampaignsVerifyGateway} from '@backend-api/order-campaigns-verify/order-campaigns-verify.gateway';
import {OrderGateway} from '@backend-api/order/order.gateway';
import {PostOrderRequest} from '@backend-api/order/post-order.request';
import {OrderableTrainingsGateway} from '@backend-api/orderable-trainings/orderable-trainings.gateway';

@Injectable({
  providedIn: 'root',
})
export class OrderDataService {
  constructor(
    private readonly _orderableTrainingsGateway: OrderableTrainingsGateway,
    private readonly _couponsGateway: CouponsVerifyGateway,
    private readonly _orderGateway: OrderGateway,
    private readonly _verifyOrderCampaignGateway: OrderCampaignsVerifyGateway
  ) {}

  getOrderableTrainings(campaignKey: string) {
    return this._orderableTrainingsGateway.getOrderableTrainings({campaign_key: campaignKey});
  }

  checkCoupon(code: string) {
    return this._couponsGateway.postCouponsByIdVerify(code, {});
  }

  sendOrder(orderData: PostOrderRequest) {
    return this._orderGateway.postOrder(orderData);
  }

  verifyOrderCampaign(campaignKey: string) {
    return this._verifyOrderCampaignGateway.postOrderCampaignsByIdVerify(campaignKey, {});
  }
}
