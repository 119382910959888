import {createFeatureSelector, createSelector} from '@ngrx/store';
import {selectCampaignParam} from 'src/app/shared/shared.selectors';
import {OrderState} from './order.reducer';

export const selectOrderState = createFeatureSelector<OrderState>('order');

export const selectAvailableTrainingsState = createSelector(selectOrderState, (state) => state.orderableTrainings);

export const selectAllAvailableTrainings = createSelector(selectAvailableTrainingsState, (trainingsState) => {
  if (!!trainingsState?.results) {
    return trainingsState.results;
  }
});

export const selectTrainingLoadHasError = createSelector(selectAvailableTrainingsState, (trainingsState) => {
  if (!!trainingsState.failed && !trainingsState.loading) {
    return true;
  }
  return undefined;
});

export const selectOrderCampaign = createSelector(selectCampaignParam, (campaign) => campaign);

export const selectCouponState = createSelector(selectOrderState, (state) => state.coupon);

export const selectCouponData = createSelector(selectCouponState, (couponState) => {
  if (!!couponState.loaded && !!couponState.results) {
    return couponState.results;
  }
});

export const selectCouponChecking = createSelector(selectCouponState, (couponState) => couponState.loading ?? false);

export const selectCouponInvalid = createSelector(selectCouponState, (couponState) => {
  if (!couponState.loading && couponState.failed) {
    return true;
  }
});

export const selectUserOrder = createSelector(selectOrderState, (state) => state.order);

export const selectIsOrderPlacedSuccessfully = createSelector(selectUserOrder, (order) => {
  if (!!order && !order.loading && !!order.loaded) {
    return true;
  }
  if (!!order && !order.loading && !!order.failed) {
    return false;
  }
  return undefined;
});

export const selectOrderErrorMessage = createSelector(selectUserOrder, (order) => {
  if (!!order && !order.loading && !!order.failed) {
    return order.results;
  }
});

export const selectOrderCampaignState = createSelector(selectOrderState, (state) => state.orderCampaign);
