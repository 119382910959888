import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {createExecuteEffect} from '@zeit-dev/ngrx-util';
import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {BeMappableErrorMessage} from 'src/app/shared/shared.effects';
import {
  checkCouponCode,
  couponInvalid,
  couponValid,
  orderDispatched,
  orderFailed,
  sendOrder,
  trainingActions,
  verifyOrderCampaign,
  verifyOrderCampaignError,
  verifyOrderCampaignSuccess,
} from './order.actions';
import {OrderDataService} from './order.data.service';
import {OrderSlice} from './order.reducer';
import {selectAvailableTrainingsState, selectOrderCampaign} from './order.selectors';

@Injectable()
export class OrderEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _store$: Store<OrderSlice>,
    private readonly _dataService: OrderDataService,
    private readonly _errorHandler: ErrorHandler
  ) {}

  readonly loadOrderableTrainings$ = createEffect(() =>
    this._actions$.pipe(
      createExecuteEffect(
        trainingActions,
        (params, state) => {
          const trainingState = selectAvailableTrainingsState(state);
          const campaign = selectOrderCampaign(state) || 'default';

          if (trainingState.loaded && trainingState.results) {
            return of(trainingState.results);
          }

          return this._dataService.getOrderableTrainings(campaign);
        },
        this._store$,
        this._errorHandler
      )
    )
  );

  readonly checkCouponCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(checkCouponCode),
      exhaustMap((action) =>
        this._dataService.checkCoupon(action.code).pipe(
          map((couponData) => couponValid({couponData})),
          catchError(() => of(couponInvalid()))
        )
      )
    )
  );

  readonly verifyOrderCampaign$ = createEffect(() =>
    this._actions$.pipe(
      ofType(verifyOrderCampaign),
      exhaustMap((action) =>
        this._dataService.verifyOrderCampaign(action.key).pipe(
          map((campaignData) => verifyOrderCampaignSuccess({campaignData})),
          catchError(() => of(verifyOrderCampaignError()))
        )
      )
    )
  );

  readonly sendOrder$ = createEffect(() =>
    this._actions$.pipe(
      ofType(sendOrder),
      exhaustMap((action) =>
        this._dataService.sendOrder(action.orderData).pipe(
          map(() => orderDispatched()),
          catchError((err: HttpErrorResponse) => {
            // if the BE responds with a MappableError, we want this, otherwise use undefined for generic errors
            // Errors are packed in an array... use the first one
            const error = (err.error as BeMappableErrorMessage[])[0].attribute ?? undefined;
            return of(orderFailed({error}));
          })
        )
      )
    )
  );
}
