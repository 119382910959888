import {CdkOverlayOrigin, ConnectionPositionPair, FlexibleConnectedPositionStrategy, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from 'angular-animations';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TrainingWithProgress} from '../../features/e-learning/e-learning.selectors';
import {TrainingLockReason} from '../../features/e-learning/learningpath/learningpath.component';

@Component({
  selector: 'app-training-card-overlay',
  templateUrl: './training-card-overlay.component.html',
  styleUrls: ['./training-card-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOnEnterAnimation({duration: 100}), fadeOutOnLeaveAnimation({duration: 100})],
})
export class TrainingCardOverlayComponent implements OnDestroy, OnInit {
  @ViewChild('template') template?: TemplateRef<HTMLDivElement>;
  @ViewChild('dialog') dialog?: ElementRef;
  @Input() training?: TrainingWithProgress;
  @Input() isLocked?: TrainingLockReason | null;
  @Input() cdkOverlayOrigin?: CdkOverlayOrigin;

  private readonly _destroy$ = new Subject<boolean>();

  private _overlayRef?: OverlayRef;
  private _positionStrategy?: FlexibleConnectedPositionStrategy;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _overlay: Overlay,
    private readonly _vcr: ViewContainerRef
  ) {}

  get showPreview() {
    return this.isLocked || !this.training?.isAccessible;
  }

  ngOnInit(): void {
    if (this.cdkOverlayOrigin) {
      this._positionStrategy = this._overlay
        .position()
        .flexibleConnectedTo(this.cdkOverlayOrigin.elementRef)
        .withPositions([new ConnectionPositionPair({originX: 'end', originY: 'bottom'}, {overlayX: 'start', overlayY: 'bottom'})])
        .withPush(true);

      this._overlayRef = this._overlay.create({
        positionStrategy: this._positionStrategy,
        hasBackdrop: true,
      });
    }
  }

  open() {
    this.changeState(true);
  }

  close() {
    this.changeState(false);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this.changeState(false);
  }

  connectedOverlayDetach() {
    this.changeState(false);
  }

  getTrainingLink(slug: string): string {
    return `/e-learning/trainings/${slug}`;
  }

  private _updatePositionStrategy() {
    if (this._overlayRef && this._positionStrategy) {
      this._overlayRef.updatePositionStrategy(this._positionStrategy);
    }
  }

  private changeState(isOpened: boolean) {
    if (isOpened && this._overlayRef && !this._overlayRef.hasAttached() && this.template) {
      const template = new TemplatePortal(this.template, this._vcr);

      this._overlayRef.attach(template);

      this._overlayRef
        .backdropClick()
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.close());
    }

    if (!isOpened) {
      this._overlayRef?.detach();
    }

    this._updatePositionStrategy();
    this._changeDetectorRef.markForCheck();
  }

  getLanguageHeader() {
    if (this.training) {
      return `TRAININGS.LANGUAGE_HEADER.${this.training.language.toUpperCase()}`;
    }
    return '';
  }
}
