<ng-container *ngIf="training$ | async as training">
  <ng-container *ngIf="session$ | async as session">
    <div class="wrapper" [@fadeInAnimation]>
      <div class="bg-wrapper ms-auto me-auto">
        <app-session-content
          [session]="session"
          [training]="training"
          [currentSessionId]="(currentSessionId$ | async)"
          [currentSlideId]="(currentSlideId$ | async)"
          [currentprogress]="currentprogress$ | async"
          [navbarShown]="navbarShown$ | async"
          [queryParams]="queryParams$ | async">
        </app-session-content>
      </div>
    </div>
  </ng-container>
  <div class="congrats" *ngIf="!!congratsText" [@fadeInOutAnimation]>
    <span translate>{{congratsText}}</span>
  </div>
</ng-container>
