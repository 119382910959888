<div class="container">
  <div class="headline">"{{data.assessment.name}}" wirklich beenden?</div>
  <div class="content">
    <p>Möchten Sie diese Fremdeinschätzung wirklich beenden? Weitere Teilnahmen sind dann nicht mehr möglich!</p>
    <p *ngIf="!isParticipantsCountSufficient()">
      Da noch nicht genügend Teilnehmer ihre Antworten abgegeben haben, wird keine Auswertung möglich sein!
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button (click)="onNoClick()" cdkFocusInitial>Nicht beenden</button>
    <button mat-flat-button color="warn" (click)="onYesClick()">Beenden</button>
  </div>
</div>
