<div class="container">
  <div class="headline" translate>COOKIE.SETTINGS.TITLE</div>
  <div class="content" [@.disabled]="disableAnimation">
    <div [class.collapsed]="collapsed">
      <ng-template nestedEllipsis nestedEllipsisWordBoundaries=" " [nestedEllipsisIndicator]="moreAnchor">
        <span [innerHTML]="'COOKIE.SETTINGS.NOTICE.GENERAL' | translate"></span>
        <a target="_blank" rel="noopener noreferrer" href="https://troodi.de/impressum/#ds" class="ms-1 textlink" translate>
          COOKIE.BAR.LINKTITLE
        </a>
      </ng-template>
      <ng-template #moreAnchor>
        <button mat-button class="ms-1 read-more" disableRipple="true" color="accent" (click)="toggleReadMore()">
          {{'SINGLE_TRAINING.SHOW_MORE' | translate}}
        </button>
      </ng-template>
    </div>
    <mat-accordion>
      <mat-expansion-panel class="mt-3">
        <mat-expansion-panel-header>
          <mat-panel-title translate> COOKIE.SETTINGS.NECESSARY </mat-panel-title>
          <mat-panel-description translate class="enabled"> COOKIE.SETTINGS.ALWAYS_ENABLED </mat-panel-description>
        </mat-expansion-panel-header>
        <p translate>COOKIE.SETTINGS.NOTICE.NECESSARY</p>
        <div class="row mt-3">
          <p class="col-12 col-md-2" translate>COOKIE.SETTINGS.VIMEO</p>
          <p class="col" translate>COOKIE.SETTINGS.NOTICE.VIMEO</p>
        </div>
        <div class="row mt-3">
          <p class="col-12 col-md-2" translate>COOKIE.SETTINGS.TYPEFORM</p>
          <p class="col" translate>COOKIE.SETTINGS.NOTICE.TYPEFORM</p>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title translate> COOKIE.SETTINGS.NON_NECESSARY </mat-panel-title>
          <!-- TODO: currently, we do not have a 'partially enabled' state, as we only have one optional cookie -->
          <mat-panel-description
            [class.partially]="getOptionalState() === -1"
            [class.enabled]="getOptionalState() === 1"
            [ngSwitch]="getOptionalState()">
            <p *ngSwitchCase="1" translate>COOKIE.SETTINGS.ENABLED</p>
            <p *ngSwitchCase="-1" translate>COOKIE.SETTINGS.PARTIALLY</p>
            <p *ngSwitchCase="0" translate>COOKIE.SETTINGS.DISABLED</p>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p translate>COOKIE.SETTINGS.NOTICE.NON_NECESSARY</p>
        <div class="d-flex flex-column flex-sm-row mt-3">
          <mat-slide-toggle [(ngModel)]="matomoChecked" class="pe-3">
            <p translate>COOKIE.SETTINGS.MATOMO</p>
          </mat-slide-toggle>
          <p class="flex-grow-1" translate>COOKIE.SETTINGS.NOTICE.MATOMO</p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div mat-dialog-actions class="mt-3">
    <a mat-flat-button (click)="decline()">
      <span translate>COOKIE.SETTINGS.DISCARD</span>
    </a>
    <button mat-flat-button color="accent" cdkFocusInitial (click)="save()">
      <span translate>COOKIE.SETTINGS.SAVE</span>
    </button>
  </div>
</div>
