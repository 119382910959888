import {Action, createReducer, on} from '@ngrx/store';
import {finishApplicationUpdate, hideAppBlocker, showAppBlocker, startApplicationUpdate} from './app.actions';

export enum AppUpdateStatus {
  UNKNOWN = 'UNKNOWN',
  IN_PROGRESS = 'IN PROGRESS',
  FINISHED = 'FINISHED',
}

export interface AppState {
  appBlockerActive: boolean;
  updateStatus: AppUpdateStatus;
}

export const initialState: AppState = {
  appBlockerActive: true,
  updateStatus: AppUpdateStatus.UNKNOWN,
};

export const appStateReducers = createReducer(
  initialState,
  on(showAppBlocker, (state) => ({
    ...state,
    appBlockerActive: true,
  })),
  on(hideAppBlocker, (state) => ({
    ...state,
    appBlockerActive: false,
  })),
  on(startApplicationUpdate, (state) => ({
    ...state,
    updateStatus: AppUpdateStatus.IN_PROGRESS,
  })),
  on(finishApplicationUpdate, (state) => ({
    ...state,
    updateStatus: AppUpdateStatus.FINISHED,
  }))
);

export function reducer(state: AppState | undefined, action: Action) {
  return appStateReducers(state, action);
}
