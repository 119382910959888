import {Injectable} from '@angular/core';
import {MatomoTracker} from '@ngx-matomo/tracker';

export enum EVENT_CATEGORIES {
  DOWNLOAD_DIALOG_INTERACTION = 'Download Dialog',
}

export enum EVENT_ACTIONS {
  DOWNLOAD_DIALOG_SHOW = 'Show Download Dialog',
  DOWNLOAD_DIALOG_CLOSE_COOKIE = 'Close Download Dialog (cookie)',
  DOWNLOAD_DIALOG_CLOSE_BE = 'Close Download Dialog (backend)',
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private _matomoBooted = false;

  constructor(private readonly _matomoTracker: MatomoTracker) {}

  handleConsent(optIn: boolean) {
    if (optIn) {
      this._matomoBooted = true;
      this._matomoTracker.setConsentGiven();
    } else {
      this._matomoBooted = false;
      this._matomoTracker.forgetConsentGiven();
    }
  }

  trackCustomEvent(category: EVENT_CATEGORIES, action: EVENT_ACTIONS, name?: string, value?: number) {
    if (this._matomoBooted) {
      this._matomoTracker.trackEvent(category, action, name, value);
    }
  }
}
