import {Injectable} from '@angular/core';
import {GetMeResponse} from '@backend-api/user/get-me.response';
import {PostContactSalesRequest} from '@backend-api/user/post-contact-sales.request';
import {PostContactSalesResponse} from '@backend-api/user/post-contact-sales.response';
import {PutUserResponse} from '@backend-api/user/put-user.response';
import {UserGateway} from '@backend-api/user/user.gateway';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  constructor(private readonly _userGateway: UserGateway) {}

  loadUser(): Observable<User> {
    return this._userGateway.getMe({}, {withCredentials: true}) as Observable<User>;
  }

  setUserData(firstName: string, lastName: string, language: Language, showInfoDialog?: boolean): Observable<PutUserResponse> {
    return this._userGateway.putUser({
      user: {
        first_name: firstName,
        last_name: lastName,
        language,
        // omit optional field if undefined
        ...(showInfoDialog !== undefined ? {show_download_info: showInfoDialog} : {}),
      },
    });
  }

  contactSales(selection: PostContactSalesRequest['type']): Observable<PostContactSalesResponse> {
    return this._userGateway.postContactSales({
      type: selection,
    });
  }
}

export type User = GetMeResponse;
export type Entitlement = User['entitlements'][0];
// extract languages from API
export type Language = User['language'];
