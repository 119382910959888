<ng-container *ngIf="viewModel$ | async as viewModel">
  <ng-container *ngIf="viewModel.training">
    <app-single-training-title-section [training]="viewModel.training" class="title-section"> </app-single-training-title-section>

    <div class="container">
      <ng-container *ngIf="viewModel.training.sessions?.length">
        <h1 class="session-cards-header" translate>SINGLE_TRAINING.SESSION_OVERVIEW</h1>

        <app-training-session-card
          *ngFor="let session of viewModel.training.sessions; let i = index"
          [session]="session"
          [index]="i"
          [sessionsCount]="viewModel.training.sessions.length"
          [sessionProgress]="progressOfSession(viewModel.sessionProgresses, session)"
          [expanded]="isExpanded(session.id, viewModel.sessionProgresses, viewModel.mostRecentSession, viewModel.scrollToId)"
          [locked]="!viewModel.training.isAccessible"
          (openInfoDialogEmitter)="openInfo()"
          [attr.most-recent]="isMostRecent(session.id, viewModel.mostRecentSession, viewModel.scrollToId)"
          #sessionPanels
          class="mb-4 mb-sm-5 session-card">
          <ng-container *ngIf="sessionsAvailable(viewModel.training)">
            <app-slide-summary
              *ngFor="let slide of filterSlides(session.slides, viewModel.hideCertificate)"
              [link]="getSlideLink(viewModel.training, session, slide)"
              [disabled]="!isSlideAvailable(slide, viewModel.nextAvailableSlide, viewModel.visitedSlides, viewModel.trainingRated, viewModel.certificateAvailable)"
              [title]="titleForSlide(slide)"
              [duration]="getFormattedDurationForSlide(slide)"
              [icon]="iconForSlide(slide)"
              [visited]="isSlideVisited(slide, viewModel.visitedSlides, viewModel.trainingRated)"
              class="px-3 px-sm-0 mb-4">
            </app-slide-summary>
          </ng-container>
        </app-training-session-card>
      </ng-container>

      <ng-container *ngIf="!viewModel.training.sessions?.length">
        <app-training-coming-soon [title]="viewModel.training.name"> </app-training-coming-soon>
      </ng-container>

      <ng-container *ngIf="trainersAvailable(viewModel.training)">
        <ng-container *ngIf="viewModel.otherTrainingsFromTrainer">
          <ng-container *ngIf="!moreThanSingleTrainer(viewModel.training)">
            <!-- If there is only one trainer: print correctly gendered headline -->
            <ng-container [ngSwitch]="viewModel.training.trainers[0].gender">
              <h1 *ngSwitchCase="'m'" class="trainer-header" translate>SINGLE_TRAINING.ABOUT_TRAINER_SINGULAR.MALE</h1>
              <h1 *ngSwitchCase="'f'" class="trainer-header" translate>SINGLE_TRAINING.ABOUT_TRAINER_SINGULAR.FEMALE</h1>
              <!-- In unspecified case (wanted, wrong data, ...), print a generic headline -->
              <h1 *ngSwitchDefault class="trainer-header" translate>SINGLE_TRAINING.ABOUT_TRAINER_SINGULAR.UNDEFINED</h1>
            </ng-container>
          </ng-container>
          <!-- If there are more trainers, use this headline -->
          <h1 class="trainer-header" *ngIf="moreThanSingleTrainer(viewModel.training)" translate>SINGLE_TRAINING.ABOUT_TRAINER_PLURAL</h1>

          <app-trainer-card
            *ngFor="let trainer of viewModel.training?.trainers"
            [trainer]="trainer"
            [otherTrainings]="viewModel.otherTrainingsFromTrainer[trainer.id]"
            class="mb-5">
          </app-trainer-card>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
