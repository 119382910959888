import {animate, query, style, transition, trigger} from '@angular/animations';
import {ScrollDispatcher} from '@angular/cdk/overlay';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PlyrComponent} from 'ngx-plyr';
import Plyr from 'plyr';
import {take} from 'rxjs/operators';
import {fadeInAnimation} from 'src/app/animations';
import {Note} from 'src/app/backend/elearning-api/notes/get-notes.response';
import {PostNotesRequest} from 'src/app/backend/elearning-api/notes/post-notes.request';
import {PrismicEmbed} from 'src/app/backend/elearning-api/trainings/get-trainings.response';
import {troodiPalette} from 'src/app/shared/palettes';
import {PrivacyService} from 'src/app/shared/privacy.service';
import {CreateNoteDialogComponent} from '../create-note-dialog/create-note-dialog.component';
import {GenericDialogComponent} from '../generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  animations: [
    fadeInAnimation,
    // Animation to highlight the increase of notes
    trigger('highlightAnimation', [
      transition(':increment', [
        style({
          // set a highlighting color first
          color: troodiPalette.accent,
        }),
        animate(
          '1s ease',
          style({
            // animate back to the original color
            color: '*',
          })
        ),
      ]),
    ]),
    // Animations for the notes
    trigger('notesAnimation', [
      // when the container is opened, "slide in"
      transition(':enter', [
        style({
          opacity: 0,
          width: 0,
          'min-width': 0,
        }),
        animate(
          '300ms ease-in-out',
          style({
            width: '*',
            'min-width': '*',
          })
        ),
        animate(
          '150ms',
          style({
            opacity: 1,
          })
        ),
      ]),
      // when the container is closed, "slide out"
      transition(':leave', [
        animate(
          '150ms',
          style({
            opacity: 0,
          })
        ),
        animate(
          '300ms ease-in-out',
          style({
            width: 0,
            'min-width': 0,
          })
        ),
      ]),
      // Note added: quickly "reserve space" and fade in
      transition(':increment', [
        query(':enter', [
          style({
            opacity: 0,
            height: 0,
          }),
          animate(
            '200ms ease-in-out',
            style({
              height: '*',
            })
          ),
          animate(
            '100ms',
            style({
              opacity: 1,
            })
          ),
        ]),
      ]),
      // Note removed: quickly fade out and collapse space
      transition(':decrement', [
        query(':leave', [
          animate(
            '100ms',
            style({
              opacity: 0,
            })
          ),
          animate(
            '200ms ease-in-out',
            style({
              height: 0,
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class VideoComponent implements AfterViewInit, OnInit, OnChanges, AfterViewChecked {
  @Input() trainingSlug?: string;
  @Input() video?: PrismicEmbed;
  @Input() slideId?: string;
  @Input() autoplay?: boolean;
  @Input() notes?: Note[];
  @Input() timestamp?: number;
  @Output() addNoteEvent = new EventEmitter<{data: PostNotesRequest}>();
  @Output() editNoteEvent = new EventEmitter<{id: string; note: string}>();
  @Output() deleteNoteEvent = new EventEmitter<{id: string}>();
  notesEnabled = false;
  videoPlaying = false;
  videoStarted = false;
  // used to re-set the video-URL
  private viewMustUpdate = true;
  noteCreation = false;
  noteEdit = false;
  showNotes = false;
  editId?: string;
  readonly cookiesEnabled$ = this._privacyService.areCookiesEnabled();

  // for setting the focus
  @ViewChild('noteCreationText') creationArea?: ElementRef;
  @ViewChild('noteEditText') editArea?: ElementRef;

  @ViewChild('notesContainer') notesContainer?: ElementRef;

  // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent)
  plyr?: PlyrComponent;

  options = {
    controls: [
      'play-large', // The large play button in the center
      'play', // Play/pause playback
      'progress', // The progress bar and scrubber for playback and buffering
      'current-time', // The current time of playback
      'duration', // The full duration of the media
      'volume', // Volume control
      'fullscreen', // Toggle fullscreen
      'settings', // Settings for quality, speed, ...
    ],
    settings: [
      'speed', // only show the setting for playback-speed
    ],
    speed: {
      selected: 1,
      options: [0.75, 1, 1.25, 1.5, 1.7, 2],
    },
    // plyr uses an old API-URL which throws errors for private videos!
    // redirect call to our backend
    urls: {
      vimeo: {
        api: '/vimeo_api/videos/{0}.json',
      },
    },
    // vimeo supports passing a 'do not track'-flag
    vimeo: {
      dnt: true,
      playsinline: true,
    },
    fullscreen: {
      iosNative: true,
    },
    // currently, only speed is directly shown in the UI, so this is sufficient
    // in future, maybe translate the other parts too for the sake of a11y
    i18n: {
      speed: this._translateService.instant('VIDEO.SPEED'),
    },
  };

  played(event: Plyr.PlyrEvent) {
    this.videoPlaying = true;
  }

  loaded(event: Plyr.PlyrEvent) {
    // the blur should only be visible when the video wasn't started yet
    if (!this.videoStarted) {
      this.videoStarted = true;
    }
  }

  paused(event: Plyr.PlyrEvent) {
    this.videoPlaying = false;
  }

  constructor(
    private readonly _privacyService: PrivacyService,
    private readonly _scrollDispatcher: ScrollDispatcher,
    private readonly _deviceDetector: DeviceDetectorService,
    private readonly _translateService: TranslateService,
    private readonly _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.notesEnabled = !!this.notes;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentValue || changes.video) {
      this.viewMustUpdate = true;
      this.videoStarted = false;
    }
  }

  ngAfterViewInit() {
    this.setVimeoSource();
  }

  ngAfterViewChecked() {
    // when user switches between two video-slides, we have to change the video-source!
    if (this.viewMustUpdate) {
      this.setVimeoSource();
    }
  }

  private setVimeoSource() {
    if (this.plyr && this.video && this.video.embed_url) {
      this.plyr.player.source = {
        type: 'video',
        sources: [
          {
            src: this.video.embed_url,
            provider: 'vimeo',
          },
        ],
      };
      this.plyr.player.poster = '';
      // view is updated
      this.viewMustUpdate = false;

      this.plyr.plyrReady.subscribe(() => {
        if (!!this.plyr) {
          if (!!this.timestamp) {
            this.plyr.player.embed.on('loaded', () => {
              // have to do this check again
              if (!!this.plyr && !!this.timestamp) {
                // if video is viewed with a timestamp in the query, jump to it
                this.plyr.player.currentTime = this.timestamp;
              }
            });
          }
          if (this.autoplay) {
            // try to autoplay this video if wanted
            this.handlePlay(this.plyr.player);
          }
        }
      });
    }
  }

  /**
   * Tries to play the video in the given Plyr-instance.
   * Used to handle the autoplay-functionality without throwing common errors.
   * play()'s promise returns an error if:
   * - Autoplay is disabled (iOS, manually set by user, ...)
   * - User didn't interact with the app first (e.g. directly access view via link without clicking during load)
   * - Some weird combination of switching tabs while video loads ('call to play() was interrupted ...')
   * We definitely don't want those to land in reporting, as they have no further impact on the page and should just be ignored.
   * @param player Bound instance of a Plyr
   */
  private async handlePlay(player: Plyr) {
    try {
      await player.play();
    } catch (e) {
      console.error(e);
    }
  }

  openEditor(edit = false) {
    if (this.plyr && this.plyr.player) {
      this.plyr.player.pause();
      if (edit) {
        this.noteEdit = true;
        // set focus on corresponding element
        // Timeout is needed to account for changes in UI
        setTimeout(() => {
          if (this.editArea) {
            this.editArea.nativeElement.focus();
          }
        }, 100);
      } else {
        // if user is on mobile, we show a dialog instead of the textarea-overlay
        if (this._deviceDetector.isMobile()) {
          this._dialog
            .open(CreateNoteDialogComponent)
            .afterClosed()
            .pipe(take(1))
            .subscribe((data?: string) => {
              // if dialog passes data on close, this is the saved note
              if (!!data) {
                this.saveNote(data);
              } else {
                // otherwise, he canceled
                this.resetNote();
              }
            });
        } else {
          // if not on mobile: open the overlay and focus the textarea
          this.noteCreation = true;
          setTimeout(() => {
            if (this.creationArea) {
              this.creationArea.nativeElement.focus();
            }
          }, 100);
        }
      }
    }
  }

  notesEmpty(notes: Note[]) {
    return notes.length === 0;
  }

  getNotesCount(notes?: Note[]) {
    return this.notes?.length;
  }

  saveNote(note: string, id?: string) {
    // TODO: maybe provide two checking-functions, might be benficial for the template, too
    if (this.slideId && this.video && this.video['video_id'] && this.plyr && this.plyr.player && !!this.trainingSlug) {
      if (!!id) {
        this.editNoteEvent.emit({
          id,
          // careful: use passed parameter and NOT the local var for oneway-binding!
          note,
        });
      } else {
        const newNote: PostNotesRequest = {
          seconds: Math.floor(this.plyr.player.currentTime),
          text: note,
          slide_id: this.slideId,
          video_id: this.video['video_id'],
          training_slug: this.trainingSlug,
        };
        this.addNoteEvent.emit({data: newNote});
      }
      this.resetNote();
    }
  }

  resetNote() {
    this.noteCreation = false;
    this.noteEdit = false;
    this.editId = undefined;
    if (this.plyr && this.plyr.player) {
      this.plyr.player.play();
    }
  }

  deleteNote(event: {id: string}) {
    this.deleteNoteEvent.emit({id: event.id});
  }

  toggleNotes() {
    this.showNotes = !this.showNotes;
    // Commented, because mobiles are out of scope right now
    // setTimeout(() => {
    //   if (this.notesContainer && this._deviceDetector.isMobile()) {
    //     // we have only one scrollable in the app
    //     // TODO: if this scroll is to be kept, at least use some class to identify
    //     this._scrollDispatcher.getAncestorScrollContainers(this.notesContainer)[0].getElementRef().nativeElement.scrollBy(0, 75);
    //   }
    // }, 500);
  }

  editNote(event: {edit: boolean}) {
    // this.editId = noteId;
    if (event.edit) {
      this.openEditor(true);
    } else {
      this.resetNote();
    }
  }

  jumpToTimestamp(event: {timestamp: number}) {
    if (this.plyr && this.plyr.player) {
      this.plyr.player.currentTime = event.timestamp;
    }
  }

  isNoteEdited(noteId: string) {
    return noteId === this.editId;
  }

  trackNote(index: number, note: Note) {
    return note.id;
  }

  isMobile() {
    return this._deviceDetector.isMobile();
  }

  // TODO: this is just a quick fix to include the new, reusable component. Cleanup!
  saveEditedNote(event: {id: string; note: string}) {
    this.saveNote(event.note, event.id);
  }

  openInfoDialog() {
    this._dialog.open(GenericDialogComponent, {
      data: {
        message: this._translateService.instant('NOTES.INFO_DIALOG.TEXT'),
      },
    });
  }
}
