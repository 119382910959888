import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Store} from '@ngrx/store';
import Fuse from 'fuse.js';
import {intersection} from 'lodash-es';
import {ELearningSlice} from '../../e-learning.reducer';
import {selectTrainingsWithProgress, TrainingWithProgress} from '../../e-learning.selectors';

export interface SearchState {
  searchTerm: string;
}

@Injectable({providedIn: 'root'})
export class SearchStore extends ComponentStore<SearchState> {
  private _fuse?: Fuse<TrainingWithProgress>;

  constructor(private readonly _store: Store<ELearningSlice>) {
    super({
      searchTerm: '',
    });
  }

  readonly setSearchTerm = this.updater((state, searchTerm: string) => {
    return {
      ...state,
      searchTerm,
    };
  });

  /**
   * Emits the currently set searchterm.
   */
  readonly searchTerm$ = this.select((state) => state.searchTerm);

  readonly isSearch$ = this.select(this.searchTerm$, (term) => term.length > 0);

  /**
   * Observable containing trainings sorted descending by relevance according to the currently set searchterm, if any.
   * If no searchterm is set, this observable emits undefined.
   */
  readonly searchResults$ = this.select(this._store.select(selectTrainingsWithProgress), this.searchTerm$, (trainings, searchTerm) => {
    if (!!trainings && searchTerm.length > 0) {
      // we might have to set up the search-library first
      if (!this._fuse) {
        this._fuse = new Fuse(
          trainings.filter((training) => training.has_content),
          {
            // TODO: adjust weights if needed
            /* Fields to search with corresponding weightings.
             * The higher the weight, the more important a match in this field is.
             * Weights will always be normalized to be within [0, 1] by the framework!
             */
            keys: [
              {
                name: 'name',
                weight: 1,
              },
              {
                name: 'description',
                weight: 0.4,
              },
              {
                name: 'learnings',
                weight: 0.6,
              },
              {
                name: 'trainers.name',
                weight: 0.8,
              },
            ],
            // lower threshold to decrease the fuzziness a bit
            threshold: 0.3,
            // otherwise this would only search not enough characters for matches
            ignoreLocation: true,
            // we need the score
            includeScore: true,
          }
        );
      }
      return this._search(searchTerm);
    }
  });

  /**
   * Handles the actual search with fuse.js
   * The search is supposed to be ANDed across the search-terms (whitespace separated),
   * but ORed across the searched fields.
   * The results are sorted by the given score descending by relevance.
   * @param input Whitespace-separated searchterms
   * @returns Array of trainings, sorted descending by relevance
   */
  private _search(input: string) {
    // We have to split the input into separate terms, as fuse.js doesn't provide the functionality we need
    const terms = input.split(' ');
    // As we have to conglomerate multiple results, keep track of the scores for each training
    const scores: Record<string, number> = {};
    // This is basically an array of results
    const trainings = new Array<TrainingWithProgress[]>();
    // As we want to AND each term (and fuse.js doesn't provide this), we have to start a search for every term
    terms
      .filter((term) => term.length > 0)
      .forEach((term) => {
        if (!!this._fuse) {
          const result = this._fuse.search(term);
          // Set / increase the score for every found training
          result.forEach((res) => {
            const scoreData = scores[res.item.slug];
            // score is possibly undefined
            const score = res.score ?? 0;
            /* Rationale: for fuse.js, a score of 0 is a _perfect match_.
             * Thus, the higher the score, the less relevant the match.
             * As we only include trainings that got hits for every single term,
             * we can safely sum up the scores and sort based on this metric
             */
            scores[res.item.slug] = !!scoreData ? scoreData + score : score;
          });
          // Populate our results
          trainings.push(result.map((r) => r.item));
        }
      });
    // Now for the AND-part: we intersect all results, leaving only the ones that matched all of our terms
    // Then, sort them based on the accumulated score of the searches. Rationale: the lower the score, the better the hit
    return intersection(...trainings).sort((a, b) => scores[a.slug] - scores[b.slug]);
  }
}
