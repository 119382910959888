import {ErrorHandler, Injectable} from '@angular/core';
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateParser} from '@ngx-translate/core';
import {Dictionary} from './shared/data.service';

export class MissingTranslationError extends Error {
  constructor(key: string, lang: string) {
    super(`Missing translation key ${key} for language ${lang}`);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReportMissingTranslationHandler implements MissingTranslationHandler {
  private readonly _reportedKeys: Dictionary<boolean> = {};

  constructor(private readonly _errorHandler: ErrorHandler) {}

  handle(params: MissingTranslationHandlerParams): string {
    const key = params.key;
    const lang = params.translateService.currentLang;
    const defaultLang = params.translateService.defaultLang;
    // Check if it is really a key. There may be glitches in code or from ngx-translate.
    if (key.toUpperCase() !== key || !/^[A-Z0-9]/.test(key)) return key;
    if (!this._reportedKeys[key]) {
      // Only report the same key for the same language once in a session. Change detector may call frequently.
      this._reportedKeys[key] = true;
      const error = new MissingTranslationError(key, lang);
      this._errorHandler.handleError(error);
    }
    // try to fallback to the defaultLang if another language was used
    if (lang !== defaultLang) return this._fallback(params);
    // otherwise, the translation is missing even for the default-language, just return the key
    return key;
  }

  /**
   * Tries to lookup the translation for the default language.
   */
  private _fallback(params: MissingTranslationHandlerParams): string {
    const defaultTranslations = params.translateService.translations[params.translateService.defaultLang];
    return params.translateService.getParsedResult(defaultTranslations, params.key, params.interpolateParams);
  }
}
