<app-topic-filter></app-topic-filter>
<ng-container *ngIf="selectedNonAvailable$ | async as selectedNonAvailable">
  <div class="container justify-content-center text-center p-3" *ngIf="selectedNonAvailable.length > 0">
    <span *ngIf="selectedNonAvailable.length === 1" translate [translateParams]="{title: getTitles(selectedNonAvailable)}">
      FILTER.NO_TRAININGS.SINGULAR
    </span>
    <span *ngIf="selectedNonAvailable.length > 1" translate [translateParams]="{titles: getTitles(selectedNonAvailable)}">
      FILTER.NO_TRAININGS.PLURAL
    </span>
    <span translate>FILTER.NO_TRAININGS.PRE_LINK</span>
    <a class="textlink" href="mailto:info@troodi.de">info@troodi.de</a>
    <span translate>FILTER.NO_TRAININGS.POST_LINK</span>
  </div>
</ng-container>
<ng-container *ngIf="(topics$ | async) as topics">
  <ng-container *ngIf="!!(emptyTopics$ | async); else topicList">
    <div class="container">
      <div class="empty-content d-flex flex-column flex-grow-1 justify-content-center align-items-center text-center m-5 p-3">
        <ng-container *ngIf="!(userContactedSales$ | async)">
          <app-expiration-notice [hasCancelButton]="false" (sendClicked)="send($event)"> </app-expiration-notice>
        </ng-container>
        <ng-container *ngIf="(userContactedSales$ | async)">
          <p class="fw-bold" translate>EXPIRED_USER.INFO_DIALOG.HEADLINE</p>
          <div>
            <span translate>EXPIRED_USER.INFO_DIALOG.TEXT_PRE</span>
            <a class="textlink" href="mailto:info@troodi.de">info@troodi.de</a>
            <span class="dialog-message mailto-suffix" translate>EXPIRED_USER.INFO_DIALOG.TEXT_POST</span>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #topicList>
    <ng-container *ngFor="let topic of topics$ | async; trackBy: trackTopic">
      <app-training-category *ngIf="topic.accessibleTrainingsCount > 0" [topic]="topic"></app-training-category>
    </ng-container>
  </ng-template>
</ng-container>
