/* tslint:disable */
import {NgModule} from '@angular/core';
import {ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {API_HOST} from './api-host';
import {userTrainingProgressesGateway} from './user-training-progresses/user-training-progresses.gateway';
import {TrainingsGateway} from './trainings/trainings.gateway';
import {QuizzesGateway} from './quizzes/quizzes.gateway';
import {NotesGateway} from './notes/notes.gateway';
import {LearningPathsGateway} from './learning-paths/learning-paths.gateway';
import {TrainingBookingsGateway} from './training-bookings/training-bookings.gateway';
import {CertificatesGateway} from './certificates/certificates.gateway';
import {TrainingsUserTrainingRatingGateway} from './trainings-user-training-rating/trainings-user-training-rating.gateway';
import {DepProjectsGateway} from './dep-projects/dep-projects.gateway';
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    userTrainingProgressesGateway,
    TrainingsGateway,
    QuizzesGateway,
    NotesGateway,
    LearningPathsGateway,
    TrainingBookingsGateway,
    CertificatesGateway,
    TrainingsUserTrainingRatingGateway,
    DepProjectsGateway,
  ],
})
export class ElearningApiModule {
  static forRoot(apiHost: string): ModuleWithProviders<ElearningApiModule> {
    return {
      ngModule: ElearningApiModule,
      providers: [{provide: API_HOST, useValue: apiHost}],
    };
  }
}
