import {Pipe, PipeTransform} from '@angular/core';
import PrismicDOM from 'prismic-dom';

@Pipe({
  name: 'prismicText',
})
export class PrismicTextPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): string {
    if (!value) return '';
    return PrismicDOM.RichText.asText(value);
  }
}
