<ng-container [ngSwitch]="slide?.type">
  <app-title-slide
    class="prismic-slide"
    *ngSwitchCase="'Title_slide'"
    [data]="slide"
    [training]="training"
    [nextLink]="nextLink"
    [backLink]="backLink">
  </app-title-slide>
  <app-prismic-video
    class="prismic-slide"
    *ngSwitchCase="'Video_slide'"
    [data]="slide"
    (trackProgress)="trackProgress()"
    [nextLink]="nextLink"
    [backLink]="backLink"
    [training]="training"
    [queryParams]="queryParams">
  </app-prismic-video>

  <app-prismic-link
    class="prismic-slide"
    *ngSwitchCase="'Link_slide'"
    [data]="slide"
    (trackProgress)="trackProgress()"
    [nextLink]="nextLink"
    [backLink]="backLink">
  </app-prismic-link>

  <app-prismic-quiz
    class="prismic-slide"
    *ngSwitchCase="'Quiz_slide'"
    [data]="slide"
    (trackProgress)="trackProgress()"
    [nextLink]="nextLink"
    [backLink]="backLink">
  </app-prismic-quiz>

  <ng-container *ngSwitchCase="'Certificate_slide'">
    <app-certificate-slide
      class="prismic-slide"
      *ngIf="(hideCertificate$ | async) === false; else cmi5Termination"
      [data]="slide"
      [training]="training"
      [currentProgress]="currentProgress"
      (trackProgress)="trackProgress()"
      [backLink]="backLink">
    </app-certificate-slide>
    <ng-template #cmi5Termination>
      <app-cmi5-termination-slide class="prismic-slide"> </app-cmi5-termination-slide>
    </ng-template>
  </ng-container>

  <app-prismic-rating
    class="prismic-slide"
    *ngSwitchCase="'User_training_rating_slide'"
    [data]="slide"
    [training]="training"
    (trackProgress)="trackProgress()"
    [nextLink]="nextLink"
    [backLink]="backLink">
  </app-prismic-rating>

  <app-prismic-interactive-content
    class="prismic-slide"
    *ngSwitchCase="'Interactive_content_slide'"
    [data]="slide"
    (trackProgress)="trackProgress()"
    [nextLink]="nextLink"
    [backLink]="backLink">
  </app-prismic-interactive-content>

  <app-prismic-link
    class="prismic-slide"
    *ngSwitchCase="'Passive_content_slide'"
    [data]="slide"
    (trackProgress)="trackProgress()"
    [nextLink]="nextLink"
    [backLink]="backLink">
  </app-prismic-link>
</ng-container>
