import {animate, style, transition, trigger} from '@angular/animations';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {collapseOnLeaveAnimation, expandOnEnterAnimation} from 'angular-animations';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {allowedLanguages} from 'src/app/app.component';
import {Language} from 'src/app/features/user/user.data.service';
import {ExtractedTopic} from '../../e-learning.selectors';
import {TopicFilterStore} from './topic-filter.store';

@Component({
  selector: 'app-topic-filter',
  templateUrl: './topic-filter.component.html',
  styleUrls: ['./topic-filter.component.scss'],
  animations: [
    expandOnEnterAnimation(),
    collapseOnLeaveAnimation(),
    trigger('filterAnimation', [
      transition(':enter', [
        style({
          'padding-top': 0,
          'padding-bottom': 0,
          'padding-left': 0,
          'padding-right': 0,
          opacity: 0,
          height: 0,
          'min-height': 0,
        }),
        animate(
          '300ms ease-in-out',
          style({
            'padding-top': '*',
            'padding-bottom': '*',
            'padding-left': '*',
            'padding-right': '*',
            height: '*',
            'min-height': '*',
          })
        ),
        animate(
          '150ms',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '150ms',
          style({
            opacity: 0,
          })
        ),
        animate(
          '300ms ease-in-out',
          style({
            'padding-top': 0,
            'padding-bottom': 0,
            'padding-left': 0,
            'padding-right': 0,
            height: 0,
            'min-height': 0,
          })
        ),
      ]),
    ]),
  ],
})
export class TopicFilterComponent implements OnInit, OnDestroy {
  readonly topics$ = this._componentStore.mappedTopics$;
  readonly languages = ['all', ...allowedLanguages];

  isFilterOpened = false;
  selectedLanguage: 'all' | Language = 'all';

  private readonly _subscription = new Subscription();

  constructor(private readonly _componentStore: TopicFilterStore, private readonly _translate: TranslateService) {}

  ngOnInit(): void {
    this._subscription.add(
      this._componentStore.languageSelection$.pipe(distinctUntilChanged()).subscribe((select) => {
        if (!!select) this.selectedLanguage = select;
        else this.selectedLanguage = 'all';
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  toggleFilterSelection(id: string) {
    this._componentStore.toggleSelectTopic(id);
  }

  toggleFilter() {
    this.isFilterOpened = !this.isFilterOpened;
  }

  getTopicTitle(topic: ExtractedTopic) {
    // TODO: this is just a duplicate, make a unDRY-solution for this!
    if (topic.generated && topic.title_en) {
      // in case this is generated, use the translator, as the title is set in the translations-file
      // indifferent whether de- or en-field is used, both contain the key in this case
      return this._translate.instant(topic.title_en);
    }
    // otherwise, the BE already delivers a translated title in the fields
    return this._translate.currentLang === 'de' ? topic.title_de : topic.title_en;
  }

  selectAllTopics() {
    this._componentStore.setAllTopics(true);
  }

  deselectAllTopics() {
    this._componentStore.setAllTopics(false);
  }

  selectLanguage(lang: 'all' | Language) {
    if (lang === 'all') {
      this._componentStore.setLanguageSelection(null);
    } else {
      this._componentStore.setLanguageSelection(lang);
    }
  }
}
