import {createAction} from '@ngrx/store';

export const showAppBlocker = createAction('[APP] Show blocker');

export const hideAppBlocker = createAction('[APP] Hide blocker');

export const startApplicationUpdate = createAction('[APP] Start application update');

export const finishApplicationUpdate = createAction('[APP] Finish application update');

export const initAngularRouting = createAction('[APP] Initialise Angular routing');

export const startAngularRouting = createAction('[APP] Start Angular Routing');
