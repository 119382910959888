import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {filter, take} from 'rxjs/operators';
import {UserSlice} from '../../user/user.reducer';
import {selectUser} from '../../user/user.selectors';
import {bookingsActions, learningPathActions, progressesActions, trainingActions} from '../e-learning.actions';
import {ELearningSlice} from '../e-learning.reducer';
import {selectLearningPathsWithProgress} from '../e-learning.selectors';

@Component({
  selector: 'app-learningpath-list',
  templateUrl: './learningpath-list.component.html',
  styleUrls: ['./learningpath-list.component.scss'],
})
export class LearningPathListComponent implements OnInit {
  readonly learningPaths$ = this._store.select(selectLearningPathsWithProgress);
  private readonly _user$ = this._store.select(selectUser);
  userName?: string;

  constructor(private readonly _store: Store<ELearningSlice & UserSlice>) {}

  ngOnInit(): void {
    // idempotent loading. Ensure that this data is loaded
    this._store.dispatch(trainingActions.execute({params: undefined}));
    this._store.dispatch(progressesActions.execute({params: undefined}));
    this._store.dispatch(learningPathActions.execute({params: undefined}));
    this._store.dispatch(bookingsActions.execute({params: undefined}));
    // currently, the greetings-banner is duplicated, this might change
    this._user$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe((user) => {
        if (!!user && !!user.first_name) {
          this.userName = user.first_name;
        }
      });
  }
}
