import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import Cookies from 'js-cookie';
import {Subscription} from 'rxjs';
import {fadeInAnimation} from '../animations';
import {allowedLanguages, defaultLanguage} from '../app.component';
import {loadUser} from '../features/user/user.actions';
import {Language} from '../features/user/user.data.service';
import {SharedSlice} from '../shared/shared.reducer';
import {selectAfterSignInPath, selectLanguage} from '../shared/shared.selectors';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [fadeInAnimation],
})
export class LoginPageComponent implements OnInit {
  @HostBinding('@fadeInAnimation') loadAnimation = true;

  readonly _selectedLanguage$ = this._store$.select(selectLanguage);
  private readonly _subscriptions = new Subscription();
  readonly afterSignInPath$ = this._store$.select(selectAfterSignInPath);
  selectedLanguage: Language = defaultLanguage;

  constructor(private readonly _store$: Store<SharedSlice>) {}

  ngOnInit() {
    // We need the csrf token's in this view as well
    this._store$.dispatch(loadUser());
    this._subscriptions.add(
      this._selectedLanguage$.subscribe((language) => {
        if (!!language && allowedLanguages.includes(language)) {
          this.selectedLanguage = language;
        }
      })
    );
  }

  get csrfParam(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return Cookies.get('XSRF-PARAM');
  }

  get csrfToken(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return Cookies.get('XSRF-TOKEN');
  }
}
