<div class="login my-auto">
  <mat-card class="login-card my-4">
    <div class="title" translate>LOGIN.TITLE</div>
    <mat-card-content>
      <form ngNoForm action="/sign_in" method="post" class="login-form">
        <mat-form-field appearance="fill">
          <mat-label translate>LABEL.EMAIL</mat-label>
          <input matInput name="user[email]" id="email-input" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label translate>LABEL.PASSWORD</mat-label>
          <input matInput type="password" name="user[password]" id="password-input" />
        </mat-form-field>
        <a class="forgot-password" routerLink="/forgot-password" translate>LOGIN.FORGOT_PASSWORD</a>

        <input type="hidden" [name]="csrfParam" [value]="csrfToken" />
        <input type="hidden" name="after_sign_in_path" [value]="afterSignInPath$ | async" />
        <input type="hidden" name="language" [value]="selectedLanguage" />
        <div class="button-container text-center">
          <button class="mb-4" mat-flat-button type="submit" color="accent">
            <span translate>LOGIN.BUTTON</span>
          </button>
          <a class="signup-link" routerLink="/signup" translate>LOGIN.SIGNUP</a>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
