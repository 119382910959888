<!-- We have subpages and want the search -->
<app-trainings-subheader></app-trainings-subheader>
<!-- FAB that enables "scroll to top"-function -->
<app-scroll-to-top></app-scroll-to-top>
<!-- Conditionally render either the router-outlet for the subpages
  OR the searchresults if the user did a search, the search should stay on top of all subpages! -->
<router-outlet *ngIf="(isSearch$ | async) === false; else searchResults"></router-outlet>
<ng-template #searchResults>
  <app-search-results [trainings]="results$ | async"></app-search-results>
</ng-template>
