import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {some} from 'lodash';
import {delay, distinctUntilChanged} from 'rxjs/operators';
import {UserTrainingRating} from 'src/app/backend/elearning-api/trainings-user-training-rating/post-trainings-by-id-user-training-rating.request';
import {UserTrainingRatingSlide} from 'src/app/backend/elearning-api/trainings/get-by-training-id-sessions-by-id.response';
import {Training} from 'src/app/backend/elearning-api/trainings/get-trainings.response';
import {updateUserTrainingRating} from '../e-learning.actions';
import {selectCurrentUserTrainingRating, selectUserRatingLoading} from '../e-learning.selectors';
import {SlideUtilService} from '../slide-utils.service';
import {selectCmi5HideCertificate} from '../../user/user.selectors';

@Component({
  selector: 'app-prismic-rating',
  templateUrl: './prismic-rating.component.html',
  styleUrls: ['./prismic-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrismicRatingComponent implements OnInit {
  @Input() training?: Training;
  @Input() data?: UserTrainingRatingSlide;
  @Input() nextLink?: string;
  @Input() backLink?: string;
  @Output() trackProgress = new EventEmitter();

  @ViewChild('freeTextRating') freeText?: ElementRef;

  showIntro = true;

  // for convenience and safety, if something in the API changes, the compiler will nag here
  readonly numericRatingKeys: Array<keyof UserTrainingRating> = ['overall_score', 'trainer_score', 'usefulness_score', 'worksheets_score'];

  // these are handled in FE
  ratings: UserTrainingRating = {
    overall_score: 0,
    trainer_score: 0,
    usefulness_score: 0,
    worksheets_score: 0,
    text: '',
  };

  // Bundling existing rating and loading-state together for more readbility in template
  readonly vm$ = this._store
    .select((state) => ({
      currentTrainingRating: selectCurrentUserTrainingRating(state),
      trainingRatingsLoading: selectUserRatingLoading(state),
      hideCertificateSlide: selectCmi5HideCertificate(state),
    }))
    .pipe(distinctUntilChanged(), delay(0));

  constructor(private readonly _store: Store, private readonly _slideUtils: SlideUtilService) {}

  ngOnInit() {
    // as the view might init without a set separator, the view disables the navbar to be safe
    this._slideUtils.setNavbar(false);
  }

  rate(id: numericRatingKeysType, rating: number) {
    this.ratings[id] = rating;
  }

  /**
   * When a rating is set, every button representing a smaller value schould be highlighted, too.
   */
  isHighlighted(id: numericRatingKeysType, rating: number) {
    return rating <= this.ratings[id];
  }

  /**
   * User can only send his/her rating, when all questions are answered.
   */
  isValid() {
    return !some(this.ratings, (rating) => rating === 0);
  }

  hideIntro() {
    this.showIntro = false;
  }

  sendRating() {
    if (this.training && this.isValid()) {
      if (this.freeText && this.freeText.nativeElement) {
        this.ratings.text = this.freeText.nativeElement.value;
      }
      // dispatch this users rating.
      // The view will react on the loading-state and the new state after updating, nothing more to do here
      this._store.dispatch(
        updateUserTrainingRating({
          slug: this.training.slug,
          rating: this.ratings,
        })
      );
    }
  }
}

// used as types for the methods altering the numerical ratings, text is omitted
type numericRatingKeysType = 'overall_score' | 'trainer_score' | 'usefulness_score' | 'worksheets_score';
