import {noop} from 'lodash-es';
import {AsyncSubject, Observable} from 'rxjs';

export function createScript(
  document: Document,
  src: string,
  loadAsync: boolean = false,
  onload: () => void = noop,
  onerror: ($event: Event | string) => void = noop,
): HTMLElement {
  const scriptElm = document.createElement('script');

  scriptElm.setAttribute('src', src);

  if (loadAsync) {
    scriptElm.setAttribute('async', '');
  }

  scriptElm.onload = onload;
  scriptElm.onerror = onerror;
  document.body.appendChild(scriptElm);

  return scriptElm;
}

export function createLink(document: Document, href: string, rel: string): HTMLElement {
  const linkElm = document.createElement('link');

  linkElm.setAttribute('href', href);
  linkElm.setAttribute('rel', rel);

  document.body.appendChild(linkElm);

  return linkElm;
}

export function createScriptAndWait(
  document: Document,
  src: string,
): Observable<void> {
  const subject$ = new AsyncSubject<void>();

  createScript(document, src, true, () => {
    subject$.next();
    subject$.complete();
  }, ($event) => subject$.error($event));

  return subject$.asObservable();
}
