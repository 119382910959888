<div class="container d-flex flex-wrap justify-content-center">
  <ng-container *ngIf="!!trainings && trainings.length > 0; else emptyresults">
    <ng-container *ngFor="let training of trainings; trackBy: trackTraining">
      <app-training-card class="card p-3" [training]="training"></app-training-card>
    </ng-container>
  </ng-container>
  <ng-template #emptyresults>
    <div class="justify-content-center text-center flex-grow-1 align-items-center p-5 empty" translate>SEARCH.EMPTY_RESULT</div>
  </ng-template>
</div>
