import {GetDepProjectsResponse} from '@backend-api/dep-projects/get-dep-projects.response';
import {ActionReducerMap, createReducer, on} from '@ngrx/store';
import {ResourceState, createExecuteReducer, initialRS} from '@zeit-dev/ngrx-util';
import {cloneDeep} from 'lodash-es';
import {LearningPath} from 'src/app/backend/elearning-api/learning-paths/get-learning-paths.response';
import {Note} from 'src/app/backend/elearning-api/notes/get-notes.response';
import {GetTrainingBookingsResponse} from 'src/app/backend/elearning-api/training-bookings/get-training-bookings.response';
import {UserTrainingRating} from 'src/app/backend/elearning-api/trainings-user-training-rating/post-trainings-by-id-user-training-rating.request';
import {Training} from 'src/app/backend/elearning-api/trainings/get-trainings.response';
import {UserTrainingProgress} from 'src/app/backend/elearning-api/user-training-progresses/get-user-training-progresses.response';
import {Dictionary, IndexedObjects} from 'src/app/shared/data.service';
import {GetQuizzesByTypeformIdResponse} from '../../backend/elearning-api/quizzes/get-quizzes-by-typeform-id.response';
import {
  bookingsActions,
  depProjectsActions,
  invalidateTrainings,
  learningPathActions,
  loadCertificateAvailability,
  loadCertificateAvailabilitySuccess,
  loadPreviews,
  loadQuizResult,
  loadQuizResultForEventId,
  loadQuizResultSuccess,
  loadSessionContent,
  loadSessionContentSuccess,
  loadUserTrainingRatings,
  loadUserTrainingRatingsError,
  loadUserTrainingRatingsSuccess,
  notesActions,
  progressesActions,
  trainingActions,
  updateUserTrainingProgressSuccess,
  updateUserTrainingRating,
  updateUserTrainingRatingSuccess,
} from './e-learning.actions';
import {SessionResponse} from './e-learning.data.service';

export interface ELearningSlice {
  elearning: ELearningState;
}

export interface ELearningState {
  currentQuiz: ResourceState<GetQuizzesByTypeformIdResponse | undefined>;
  trainings: ResourceState<IndexedObjects<Training, string> | undefined>;
  sessionContent: ResourceState<Dictionary<Dictionary<SessionResponse>>>;
  userTrainingProgresses: ResourceState<IndexedObjects<UserTrainingProgress, string>>;
  userNotes: ResourceState<IndexedObjects<Note, string> | undefined>;
  learningPaths: ResourceState<IndexedObjects<LearningPath, string> | undefined>;
  bookings: ResourceState<GetTrainingBookingsResponse>;
  loadPreviews: ResourceState<boolean>;
  certificatesAvailable: ResourceState<Dictionary<Date, string>>;
  userTrainingRatings: ResourceState<Dictionary<UserTrainingRating, string>>;
  depProjects: ResourceState<GetDepProjectsResponse | undefined>;
  slugRedirects: ResourceState<Record<string, string>>;
}

export const initialState: ELearningState = {
  currentQuiz: initialRS<GetQuizzesByTypeformIdResponse | undefined, void>(undefined),
  trainings: initialRS<IndexedObjects<Training, string> | undefined, undefined>(undefined),
  sessionContent: initialRS<Dictionary<Dictionary<SessionResponse>>, void>({}),
  userTrainingProgresses: initialRS<IndexedObjects<UserTrainingProgress, string>, void>({ids: [], objects: {}}),
  userNotes: initialRS<IndexedObjects<Note, string> | undefined, void>(undefined),
  learningPaths: initialRS<IndexedObjects<LearningPath, string> | undefined, undefined>(undefined),
  bookings: initialRS<GetTrainingBookingsResponse, void>([]),
  loadPreviews: initialRS<boolean, void>(false),
  certificatesAvailable: initialRS<Dictionary<Date, string>, void>({}),
  userTrainingRatings: initialRS<Dictionary<UserTrainingRating>, void>({}),
  depProjects: initialRS<GetDepProjectsResponse, void>([]),
  // this is currently static data
  slugRedirects: initialRS<Record<string, string>, void>({
    'agilitaet-verstehen': 'agilitaet-verstehen-de',
    'konflikte-bewaltigen': 'konflikte-verstehen-und-loesen-de',
    verstanden: 'bewusst-und-wirkungsvoll-kommunizieren-de',
    'handling-fear-and-uncertainty': 'fear-and-uncertainty-empowering-yourself-eng',
    'handling-fear-and-uncertainty-a-leaders-perspective': 'fear-and-uncertainty-empowering-others-eng',
    selbstwirksamkeit: 'selbstwirksamkeit-staerken-de',
    'resilienz-widerstandsfahigkeit-starken': 'resilienz-de',
    netzwerken: 'erfolgreich-netzwerken-de',
    'strategy-development': 'strategy-development-eng',
    'understanding-agile': 'understanding-agile-eng',
    'agile-frameworks-scrum': 'scrum-de',
    'leading-change': 'leading-in-times-of-change-eng',
    change: 'designing-change-eng',
    'bad-news': 'delivering-bad-news-eng',
    'bad-news-v2': 'delivering-bad-news-eng-v2',
    'goals-okr': 'okrs-eng',
    'idee-strategie': 'unternehmensstrategie-entwickeln-de',
    'successful-networking': 'successful-networking-eng',
    scrum: 'scrum-eng',
    veraenderungskompetenz: 'fuehren-in-veraenderung-de',
    veraenderungsprozesse: 'veraenderungsprozesse-gestalten-de',
    projektmanagement: 'klassisches-projektmanagement-de',
    'home-office': 'arbeiten-im-home-office-de',
    'virtuelle-zusammenarbeit': 'virtuelle-zusammenarbeit-de',
    'remote-collaboration': 'remote-collaboration-eng',
    storytelling: 'storytelling-de',
    zeitmanagement: 'effektives-zeitmanagement-de',
    'working-from-home': 'working-from-home-eng',
    'systemisch-denken-und-handeln': 'systemisch-denken-und-handeln-de',
    'effektive-besprechungsmoderation': 'effektive-besprechungsmoderation-de',
    'taktischer-einkauf': 'taktischer-einkauf-de',
    selbstmanagement: 'selbstmanagement-de',
    'schlechte-nachrichten-ueberbringen': 'bad-news-de',
    'storytelling-en': 'storytelling-eng',
    'caring-en': 'caring-and-daring-leadership-eng',
    'caring-daring': 'caring-and-daring-leadership-de',
    'leading-in-a-coaching-mode': 'leading-in-a-coaching-mode-eng',
    'she-is-strong-de': 'frauen-in-verantwortung-de',
    'she-is-strong-women-at-work': 'women-at-work-eng',
    'self-management-boosting-your-productivity': 'self-management-eng',
    'new-work-verstehen': 'new-work-verstehen-de',
    'understanding-new-work': 'understanding-new-work-eng',
    'okrs-effektive-zielsetzung': 'okrs-de',
    'rhetrorik---verstandlich-vertreten-wofur-du-stehst': 'rhetorik-praesentationstechniken-de',
    'sich-selbst-empowern-in-unsicheren-zeiten': 'sich-selbst-empowern-in-unsicheren-zeiten-de',
    'andere-empowern-in-unsicheren-zeiten': 'andere-empowern-in-unsicheren-zeiten-de',
    '4-saeulen-der-gesundheit': '4-saeulen-der-gesundheit-de',
    'self-care-gelingender-umgang-mit-stress': 'self-care-umgang-mit-stress-de',
    'self-care-dealing-with-stress-and-preventing-burnout': 'self-care-dealing-with-stress-eng',
    'resilience---cultivating-your-inner-strengths': 'resilience-eng',
    'mindful-communication--strengthening-connections-and': 'mindful-communication-eng',
    'kulturelle-achtsamkeit-und-interkulturelle-zusammenarbeit': 'interkulturelle-zusammenarbeit-de',
    'service--und-kundenorientierung': 'service-und-kundenorientierung-de',
    'effective-meeting-facilitation': 'meeting-facilitation-eng',
    'effective-time-management': 'time-management-eng',
    'managing-crises': 'managing-crises-eng',
    'rhetoric-powerful-presentation-techniques': 'rhetoric-presentation-techniques-eng',
    'conflict-management': 'conflict-management-eng',
    'fuehren-im-coaching-modus': 'fuehren-im-coaching-modus-de',
    'power-of-diversity-and-inclusion': 'diversity-and-inclusion-eng',
    'mediative-konfliktmoderation': 'mediative-konfliktmoderation-de',
    'unconscious-bias--unbewusste-voreingenommenheiten': 'unconscious-bias-de',
    'unconscious-bias--raising-awareness-and-reducing-harm': 'unconscious-bias-eng',
    'die-kraft-von-diversitat-und-inklusion': 'diversitaet-und-inklusion-de',
    'entrepreneurial-thinking': 'entrepreneurial-thinking-eng',
    'positive-leadership--strengthening-teams-and-individuals': 'positive-leadership-eng',
    'design-thinking--innovation-und-kreativitat-steigern': 'design-thinking-de',
    'design-thinking--boosting-innovation-and-creativity': 'design-thinking-eng',
    'mental-health-psychische-belastung-im-arbeitskontext': 'mental-health-de',
    'kanban-fundamentals--stop-starting-start-finishing': 'kanban-fundamentals-eng',
    'goal-setting': 'goal-setting-eng',
    'embodiment-move-your-thinking': 'embodiment-eng',
    'self-efficacy--the-power-of-believing-in-yourself': 'self-efficacy-eng',
    'unternehmerisches-denken-und-handeln': 'unternehmerisches-denken-und-handeln-de',
    'successful-intercultural-cooperation': 'intercultural-cooperation-eng',
    'positive-leadership': 'positive-leadership-de',
    'embodiment-de': 'embodiment-de',
    'kanban-einfach-erklaert': 'kanban-einfach-erklaert-de',
    'project-management': 'project-management-eng',
    'effective-communication': 'effective-communication-eng',
    '4-pillars-of-health': '4-pillars-of-health-eng',
    'zielsetzung-mit-klaren-zielen-zum-erfolg': 'zielsetzung-de',
    'erfolgreich-verhandeln': 'erfolgreich-verhandeln-de',
    feedback: 'feedback-geben-und-nehmen-de',
    'teams-verstehen': 'winning-teams-teamentwicklung-de',
    'winning-teams-v2': 'winning-teams-teamentwicklung-de-v2',
    'winning-teams': 'developing-winning-teams-eng',
    'developing-winning-teams-v2': 'developing-winning-teams-eng-v2',
    'good-boss': 'good-boss-souveraen-fuehren-de',
    'goodboss-en': 'good-boss-people-leadership-eng',
    'motivation-mich-andere-motivieren': 'motivation-de',
    'motivation-mich-andere-motivieren-v2': 'motivation-de-v2',
    'giving-receiving-feedback': 'giving-and-receiving-feedback-eng',
    'successful-negotiation': 'successful-negotiation-eng',
    'motivating-myself-and-others': 'motivation-eng',
    'motivation-motivating-myself-and-others-v2': 'motivation-eng-v2',
    'fuehrung-ueber-distanz': 'fuehren-ueber-distanz-de',
    fuehrungsgespraeche: 'fuehrungsgespraeche-de',
    'leading-remotely': 'leading-remotely-eng',
    'grid-4-faktoren-erfolgreicher-teams': 'grid-faktoren-erfolgreicher-teams-de',
    grid: 'grid-factors-of-successful-teams-eng',
    'leadership-conversations': 'leadership-conversations-eng',
    'verbindende-kommunikation': 'verbindende-kommunikation-de',
    krisenmanagement: 'krisenmanagement-de',
  }),
};

export const reducers: ActionReducerMap<{
  trainings: ELearningState['trainings'];
  currentQuiz: ELearningState['currentQuiz'];
  sessionContent: ELearningState['sessionContent'];
  userTrainingProgresses: ELearningState['userTrainingProgresses'];
  userNotes: ELearningState['userNotes'];
  learningPaths: ELearningState['learningPaths'];
  bookings: ELearningState['bookings'];
  loadPreviews: ELearningState['loadPreviews'];
  certificatesAvailable: ELearningState['certificatesAvailable'];
  userTrainingRatings: ELearningState['userTrainingRatings'];
  depProjects: ELearningState['depProjects'];
  slugRedirects: ELearningState['slugRedirects'];
}> = {
  trainings: createReducer(
    initialState['trainings'],
    ...createExecuteReducer<IndexedObjects<Training, string>, ELearningState['trainings']>(trainingActions),
    on(invalidateTrainings, loadPreviews, (state) => {
      return {
        // empty state to force trainings-reload
        // also triggered when `loadPreviews` is dispatched to force reload
        ...state,
        loaded: false,
        loading: false,
        results: undefined,
      };
    })
  ),
  currentQuiz: createReducer(
    initialState['currentQuiz'],
    on(loadQuizResult, loadQuizResultForEventId, (state) => {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }),
    on(loadQuizResultSuccess, (state, results) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results,
      };
    }),
    on(loadSessionContentSuccess, (state) => {
      // reset currentQuiz on new SessionContent
      return {
        ...state,
        results: undefined,
        loaded: false,
        loading: false,
      };
    })
  ),
  sessionContent: createReducer(
    // sessionContents are loaded piecewise, thus need to be handled differently
    initialState['sessionContent'],
    on(loadSessionContent, (state) => {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }),
    on(loadSessionContentSuccess, (state, {slug, sessionId, sessionContent}) => {
      const newSessionContent = cloneDeep(state.results);
      const sessions = newSessionContent[slug] || {};
      sessions[sessionId] = sessionContent;
      newSessionContent[slug] = sessions;
      return {
        ...state,
        results: newSessionContent,
        loaded: true,
        loading: false,
      };
    })
  ),
  userTrainingProgresses: createReducer(
    initialState['userTrainingProgresses'],
    ...createExecuteReducer<IndexedObjects<UserTrainingProgress, string>, ELearningState['userTrainingProgresses']>(progressesActions),
    on(updateUserTrainingProgressSuccess, (state, {userTrainingProgress}) => {
      // abort if data is not loaded at all
      if (!state.loaded) return state;
      // after update, set specific part of the state
      const newProgresses = cloneDeep(state.results?.objects) || {};
      newProgresses[userTrainingProgress.slug] = {
        ...userTrainingProgress,
        slide_ids: userTrainingProgress.slide_ids.filter((slideId) => !slideId.endsWith('title')),
      };
      return {
        ...state,
        results: {
          ...state.results,
          objects: newProgresses,
        },
      };
    })
  ),
  userNotes: createReducer(
    initialState['userNotes'],
    ...createExecuteReducer<IndexedObjects<Note, string>, ELearningState['userNotes']>(notesActions)
  ),
  learningPaths: createReducer(
    initialState['learningPaths'],
    ...createExecuteReducer<IndexedObjects<LearningPath, string>, ELearningState['learningPaths']>(learningPathActions)
  ),
  bookings: createReducer(
    initialState['bookings'],
    ...createExecuteReducer<GetTrainingBookingsResponse, ELearningState['bookings']>(bookingsActions)
  ),
  loadPreviews: createReducer(
    initialState['loadPreviews'],
    on(loadPreviews, (state) => {
      return {
        ...state,
        loaded: true,
        loading: false,
        results: true,
      };
    })
  ),
  certificatesAvailable: createReducer(
    initialState['certificatesAvailable'],
    on(loadCertificateAvailability, (state) => {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }),
    on(loadCertificateAvailabilitySuccess, (state, {certificates}) => {
      // overwrite all data to have clean state synced with BE
      const newData: Dictionary<Date, string> = {};
      certificates.forEach((certificate) => {
        newData[certificate.training_id] = new Date(certificate.issued_at);
      });
      return {
        ...state,
        results: newData,
        loaded: true,
        loading: false,
      };
    })
  ),
  userTrainingRatings: createReducer(
    initialState['userTrainingRatings'],
    on(loadUserTrainingRatings, updateUserTrainingRating, (state) => {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }),
    on(loadUserTrainingRatingsSuccess, updateUserTrainingRatingSuccess, (state, {slug, rating}) => {
      const updatedData = cloneDeep(state.results);
      if (!!rating) {
        // if there is no rating present, ignore it and just set the loading/loaded-state
        updatedData[slug] = rating;
      }
      return {
        ...state,
        results: updatedData,
        loaded: true,
        loading: false,
      };
    }),
    on(loadUserTrainingRatingsError, (state) => {
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    })
  ),
  depProjects: createReducer(
    initialState['depProjects'],
    ...createExecuteReducer<GetDepProjectsResponse, ELearningState['depProjects']>(depProjectsActions)
  ),
  slugRedirects: createReducer(initialState['slugRedirects']),
};
