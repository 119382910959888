<app-slide-separator
  *ngIf="training"
  [type]="data?.type"
  [title]="data?.title"
  [introText]="getSessionDescription() | prismicHtml"
  [skipLink]="nextLink"
  [backLink]="backLink"
  [skipText]="'SLIDE_NAVBAR.NEXT_SLIDE' | translate">
</app-slide-separator>
<!-- <div *ngIf="training">
  <h1 class="head-title">{{data?.title}}</h1>
  <div class="tag" [innerHTML]="getSessionDescription() | prismicHtml"></div>
</div> -->
