import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Params} from '@angular/router';
import {Store} from '@ngrx/store';
import {Note} from 'src/app/backend/elearning-api/notes/get-notes.response';
import {Training} from '../../../backend/elearning-api/trainings/get-trainings.response';
import {UserTrainingProgress} from '../../../backend/elearning-api/user-training-progresses/get-user-training-progresses.response';
import {SharedSlice} from '../../../shared/shared.reducer';
import {selectCmi5HideCertificate} from '../../user/user.selectors';
import {Slide} from '../e-learning.data.service';
import {SlideUtilService} from '../slide-utils.service';

@Component({
  selector: 'app-prismic-container',
  templateUrl: './prismic-container.component.html',
  styleUrls: ['./prismic-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrismicContainerComponent {
  @Input() training?: Training;
  @Input() slide?: Slide;
  @Input() currentProgress?: UserTrainingProgress;
  @Input() nextLink?: string;
  @Input() backLink?: string;
  @Input() userNotes?: Note[];
  @Input() queryParams?: Params;

  readonly hideCertificate$ = this._store.select(selectCmi5HideCertificate);

  constructor(private readonly _store: Store<SharedSlice>, private readonly _slideUtilService: SlideUtilService) {}

  trackProgress() {
    if (this.training && this.slide) {
      this._slideUtilService.trackProgress(this.training.slug, this.slide.id);
    }
  }
}
