import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-trainer-other-training-card',
  templateUrl: './trainer-other-training-card.component.html',
  styleUrls: ['./trainer-other-training-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainerOtherTrainingCardComponent {
  @Input() title?: string;
  @Input() description?: string;
  @Input() trainingCoverImageUrl?: string;
  @Input() trainingUrl?: string;
}
