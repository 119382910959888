<div class="recommendations-title">Vorschläge</div>
<div class="training-list" *ngIf="(trainings$ | async) as recommendations" [@staggerElementsAnimation]="recommendations">
  <div
    class="training-container"
    *ngFor="let recommendation of recommendations; trackBy: trackTraining"
    [routerLink]="getTrainingLink(recommendation.training_id)">
    <div class="diameter">
      <svg viewBox="0 0 32 32">
        <circle r="16" cx="16" cy="16" [style.stroke-dasharray]="recommendation.score + ' 100'" />
      </svg>
      <div class="diameter-inner">
        <div class="training-match">{{recommendation.score}}%</div>
      </div>
    </div>
    <div class="content">
      <div class="text">
        <div class="training-title">{{recommendation.training_name}}</div>
      </div>
    </div>
    <div class="actions">
      <button mat-icon-button>
        <img src="assets/img/chevron-right.svg" alt="chevron icon" />
      </button>
    </div>
  </div>
</div>
