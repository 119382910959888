<ng-container *ngIf="!!note">
  <div class="d-flex justify-content-between">
    <p class="note-timestamp" (click)="timestampClicked()">
      <mat-icon svgIcon="clock"></mat-icon>
      <span translate [translateParams]="{timestamp: note.seconds * 1000 | date:'m:ss'}">NOTES.TIMESTAMP</span>
    </p>
    <p class="note-actions">
      <a
        mat-stroked-button
        *ngIf="hasNavLink && !!note.route"
        color="primary"
        class="rounded me-2"
        [routerLink]="note.route.url"
        [queryParams]="{'timestamp': note.route.timestamp}">
        <mat-icon svgIcon="preview-play" class="me-2"></mat-icon>
        <span translate>NOTES.BUTTON.NAVIGATE</span>
      </a>
      <button mat-icon-button (click)="editNote()" [matTooltip]="'NOTES.TOOLTIPS.EDIT' | translate">
        <mat-icon svgIcon="edit"> </mat-icon>
      </button>
      <button mat-icon-button (click)="delete()" [matTooltip]="'NOTES.TOOLTIPS.DELETE' | translate">
        <mat-icon svgIcon="delete"> </mat-icon>
      </button>
    </p>
  </div>
  <p *ngIf="!edit" class="note-text text-start">{{note.text}}</p>
  <div *ngIf="edit" class="notes-editor d-flex flex-column">
    <textarea matInput #noteEditText [value]="note.text"></textarea>
    <div class="button-container d-flex justify-content-end p-2">
      <button mat-flat-button (click)="reset()">
        <span translate>GENERIC.BUTTON_CANCEL</span>
      </button>
      <button mat-flat-button color="accent" [disabled]="!noteEditText.value" (click)="saveNote(noteEditText.value)">
        <span translate>GENERIC.BUTTON_SAVE</span>
      </button>
    </div>
  </div>
</ng-container>
