import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {EMPTY} from 'rxjs';
import {exhaustMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {PostSkillProfilesResponse} from 'src/app/backend/recommendation-api/skill-profiles/post-skill-profiles.response';
import {extractObjects} from '../../shared/data.service';
import {
  loadSkillProfiles,
  loadSkillProfilesSuccess,
  loadSkills,
  loadSkillSuccess,
  setRecommendations,
  setSkillValue,
} from './recommendation.actions';
import {RecommendationDataService} from './recommendation.data.service';
import {RecommendationSlice} from './recommendation.reducer';
import {selectRecommendationState} from './recommendation.selectors';

@Injectable()
export class RecommendationEffects {
  loadSkills$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadSkills),
      exhaustMap(() => this._dataService.getSkills()),
      map((skills) => loadSkillSuccess({skills}))
    )
  );

  loadSkillprofiles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadSkillProfiles),
      exhaustMap(() => this._dataService.getSkillProfiles()),
      map((response) => loadSkillProfilesSuccess({response}))
    )
  );

  setSkillValue$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setSkillValue, loadSkillProfilesSuccess, loadSkillSuccess),
      withLatestFrom(this._store$.select(selectRecommendationState)),
      switchMap(([, recommendationState]) => {
        // if both of these actions haven't run yet, do nothing as this could break the state!
        if (!recommendationState.lastSkillProfileLoaded || !recommendationState.skillsLoaded) {
          return EMPTY;
        }
        return this._dataService.postSkillProfile(extractObjects(recommendationState.currentSkillProfile)).pipe(
          switchMap((responseFromService: PostSkillProfilesResponse) => {
            return this._dataService.getRecommendations(responseFromService.id).pipe(map((response) => response));
          })
        );
      }),
      map((recommendations) => setRecommendations({recommendations}))
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store$: Store<RecommendationSlice>,
    private readonly _dataService: RecommendationDataService
  ) {}
}
