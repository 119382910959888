<ng-container *ngIf="trainer">
  <div class="row d-flex">
    <div class="col-12 col-lg-5 align-self-center text-center">
      <img *ngIf="trainer.image as image" class="img-fluid" [appSrcset]="image.url" [alt]="image.alt" pxWidth="640" />
    </div>
    <div class="col-12 col-lg-7">
      <h3 class="px-4 px-sm-0 d-block mt-3 mt-lg-0">{{trainer.name}}</h3>
      <div class="px-4 px-sm-0 mb-3 mb-sm-0 description" [class.collapsed]="collapsed">
        <span *ngIf="!isMobile()" [innerHTML]="trainer.description | prismicText | hyphenateHtml | async"></span>
        <ng-container *ngIf="isMobile()">
          <ng-template nestedEllipsis nestedEllipsisWordBoundaries=" " [nestedEllipsisIndicator]="moreAnchor">
            <span [innerHTML]="trainer.description | prismicText"></span>
          </ng-template>
          <ng-template #moreAnchor>
            <button mat-button class="ms-1 read-more" disableRipple="true" color="accent" (click)="toggleReadMore()">
              {{'SINGLE_TRAINING.SHOW_MORE' | translate}}
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="otherTrainings && otherTrainings.length > 0">
    <div class="row d-none d-sm-flex">
      <div class="col-12 pb-3">
        <h4><span translate>SINGLE_TRAINING.OTHER_TRAININGS_BY</span> {{trainer.name}}</h4>
      </div>
      <div class="col-12 col-lg-6 mb-3" *ngFor="let otherTraining of otherTrainings">
        <app-trainer-other-training-card
          [title]="otherTraining.name"
          description=""
          [trainingCoverImageUrl]="otherTraining.lead_image?.url"
          [trainingUrl]="getTrainingLink(otherTraining.slug)">
        </app-trainer-other-training-card>
      </div>
    </div>
  </ng-container>
</ng-container>
