import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-signup-success-dialog',
  templateUrl: './signup-success-dialog.component.html',
  styleUrls: ['./signup-success-dialog.component.scss'],
})
export class SignupSuccessDialogComponent {
  constructor(private readonly _ref: MatDialogRef<SignupSuccessDialogComponent>) {
    this._ref.addPanelClass('custom-signup-success-dialog');
  }

  closeDialog() {
    this._ref.close();
  }
}
