<div class="container">
  <div class="headline" translate>SIGNUP_SUCCESS.TITLE</div>
  <div class="content">
    <span translate>SIGNUP_SUCCESS.INSTRUCTIONS</span>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button (click)="closeDialog()" cdkFocusInitial>
      <span translate>LABEL.OKAY</span>
    </button>
    <a mat-flat-button (click)="closeDialog()" routerLink="/login" color="accent">
      <span translate>SIGNUP_SUCCESS.BUTTON</span>
    </a>
  </div>
</div>
