<div class="container my-auto">
  <div *ngIf="!error" class="row card-container my-4">
    <div class="col-12 col-sm-6 signup-container">
      <div class="title" translate>SIGNUP.TITLE_FORM</div>
      <ng-container *ngIf="!campaignToken; else campaignSignup">
        <!-- Normal signup: user has to fill more mandatory fields, thus we also need a stepper -->
        <app-signup-form-default (submitSignup)="signup($event)">
          <!-- pass the tac-block -->
          <ng-template [ngTemplateOutlet]="tac"></ng-template>
        </app-signup-form-default>
      </ng-container>
      <ng-template #campaignSignup>
        <!-- Campaign signup: user has to fill only 4 mandatory fields, no stepper needed -->
        <app-signup-form-campaign (submitSignup)="signup($event)">
          <!-- pass the tac-block -->
          <ng-template [ngTemplateOutlet]="tac"></ng-template>
        </app-signup-form-campaign>
      </ng-template>
    </div>
    <div class="col-12 col-sm-6 benefits-container">
      <h1>{{ (content$ | async)?.headline }}</h1>
      <div class="benefits">
        <div class="mt-4 benefit" *ngFor="let benefit of (content$ | async)?.benefits">
          <mat-icon svgIcon="check"></mat-icon>
          <span class="ms-2">{{benefit}}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- Error is shown if BE returns an error after signup saying that the campaign-key is invalid -->
  <div *ngIf="error && !!errorType" class="row card-container error-container my-4 mx-auto">
    <div class="col-12 signup-container">
      <p class="error">
        <span translate>SIGNUP.ERROR.{{errorType.toUpperCase()}}_PRE</span>
        <a href="mailto:info@troodi.de">info@troodi.de</a>
        <span translate>SIGNUP.ERROR.{{errorType.toUpperCase()}}_POST</span>
      </p>
      <button (click)="resetCampaignData()" class="mt-3 w-auto" mat-flat-button color="accent">
        <span translate>SIGNUP.ERROR_BUTTON</span>
      </button>
    </div>
  </div>
</div>
<!-- this is the 'Accept Terms and Conditions'-block as a template for re-usage -->
<ng-template #tac>
  <div class="mt-4 text-center accept-terms">
    <span translate>SIGNUP.ACCEPT_TERMS_PRE</span>
    <a class="textlink" target="_blank" rel="noopener noreferrer" href="https://troodi.de/impressum/#ds">
      <span translate>SIGNUP.TERMS</span>
    </a>
    <span translate>SIGNUP.ACCEPT_TERMS_POST</span>
  </div>
</ng-template>
