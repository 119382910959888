/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetQuizzesByTypeformIdResponse} from './get-quizzes-by-typeform-id.response';
import {GetQuizzesByTypeformIdRequest} from './get-quizzes-by-typeform-id.request';
@Injectable()
export class QuizzesGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getQuizzesByTypeformId(
    typeform_id: string,
    queryParams: GetQuizzesByTypeformIdRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetQuizzesByTypeformIdResponse> {
    return this._httpClient.request('get', this._apiHost + `/quizzes/${typeform_id}`, {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
