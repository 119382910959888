import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserState} from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUserStateSlice = createSelector(selectUserState, (state) => state.user);

export const selectUser = createSelector(selectUserState, (state) => state.user.results);

export const selectUserEntitlements = createSelector(selectUser, (user) => (user ? user.entitlements : []));

export const selectUserFirstName = createSelector(selectUser, (user) => {
  if (user && user.first_name) {
    return user.first_name;
  }
  return null;
});

export const selectUserActive = createSelector(selectUser, (user) => {
  if (user) {
    return user.active;
  }
  return undefined;
});

/**
 * Returns whether the user has already seen the 'expiry-dialog' in this session (based on state).
 */
export const selectExpirationDialogShown = createSelector(selectUserState, (state) => state.expirationDialogShown.results);

export const selectUserContactedSales = createSelector(selectUser, (user) => {
  if (user) {
    return user.contacted_sales_at;
  }
  return undefined;
});

/**
 * Returns whether the user should see the 'expiry-dialog'.
 * Can return `undefined` if parts of the state are not ready yet.
 */
export const selectNotifyExpiredUser = createSelector(selectUserActive, selectUserContactedSales, (active, contacted) => {
  if (active !== undefined && contacted !== undefined) {
    return !active && !contacted;
  }
  return undefined;
});

export const selectDownloadInfoState = createSelector(selectUserState, (state) => state.downloadInfoShown);

export const selectDownloadInfoShown = createSelector(selectDownloadInfoState, (state) => {
  if (state.loaded && !state.loading) {
    return state.results;
  }
});

export const selectUserCmi5Settings = createSelector(selectUser, (user) => {
  if (user && user.cmi5_settings) {
    return user.cmi5_settings;
  }
  return null;
});

export const selectCmi5HideCertificate = createSelector(selectUserCmi5Settings, (settings) => {
  if (settings) {
    return settings.hide_certificates;
  }
  return false;
});
