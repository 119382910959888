import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {createScriptAndWait} from 'lib/util/dom';
import {CookieService} from 'ngx-cookie-service';
import {loadPrivacySettings, loadPrivacySettingsError, loadPrivacySettingsSuccess, updatePrivacySettings} from './shared.actions';
import {SharedSlice} from './shared.reducer';
import {selectEssentialsAccepted, selectOptionalsAccepted} from './shared.selectors';

@Injectable({
  providedIn: 'root',
})
export class PrivacyService {
  private readonly _storageKey = 'troodi_privacy';
  private readonly _cookiePath = '/';
  // we have to set an expiration date... so just use this beautiful construct to let it sit for 10 years
  private readonly _expiration = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
  private _prismicLoaded = false;
  private readonly _essentialsAccepted$ = this._store.select(selectEssentialsAccepted);
  private readonly _optionalsAccepted$ = this._store.select(selectOptionalsAccepted);

  private readonly COOKIE_VERSION = '8e89d519a0f66434c4a81bf541a2dd9a';

  constructor(
    private readonly _store: Store<SharedSlice>,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _cookie: CookieService
  ) {}

  saveState(settings: PrivacySettings) {
    const newCookie: CookieData = {
      version: this.COOKIE_VERSION,
      entries: settings,
    };
    this._cookie.set(this._storageKey, JSON.stringify(newCookie), this._expiration, this._cookiePath);
    this._store.dispatch(updatePrivacySettings({settings}));
  }

  getState() {
    // also trigger load-state, so reducers can react accordingly
    this._store.dispatch(loadPrivacySettings());
    const state = this._cookie.get(this._storageKey);
    if (!!state) {
      try {
        const parsed: CookieData = JSON.parse(state);
        if (parsed.version === this.COOKIE_VERSION) {
          this._store.dispatch(
            loadPrivacySettingsSuccess({
              settings: parsed.entries,
            })
          );
        } else {
          // Cookie has wrong version, meaning there was a change since they accepted/declined.
          // delete current cookie, they have to accept/decline again
          // TODO: throw specific error or something?
          this.resetCookies();
        }
      } catch (err) {
        // in case localStorage can't parse, delete it and start over
        this.resetCookies();
      }
    } else {
      this._store.dispatch(loadPrivacySettingsError());
    }
  }

  acceptAll() {
    const acceptedState: PrivacySettings = {
      essential: true,
      matomo: true,
    };

    this.saveState(acceptedState);
    this._store.dispatch(
      updatePrivacySettings({
        settings: acceptedState,
      })
    );
  }

  revokeAll() {
    // instead of setting as false, remove entry in storage
    // this ensures users will be asked again
    this._cookie.delete(this._storageKey, this._cookiePath);
    // state should know that user rejected
    this._store.dispatch(
      updatePrivacySettings({
        settings: {
          essential: false,
          matomo: false,
        },
      })
    );
  }

  accept(service: PrivacyGuardedServices, settings: PrivacySettings) {
    settings[service] = true;
    this.saveState(settings);
    this._store.dispatch(updatePrivacySettings({settings}));
  }

  revoke(service: PrivacyGuardedServices, settings: PrivacySettings) {
    settings[service] = false;
    this.saveState(settings);
    this._store.dispatch(updatePrivacySettings({settings}));
  }

  areCookiesEnabled() {
    return this._essentialsAccepted$;
  }

  areOptionalsEnabled() {
    // if additional optional cookies are added in the future, this should aggregate them
    return this._optionalsAccepted$;
  }

  /**
   * Prismic is only needed (and wanted) for explicit user-groups.
   * To adequately control this, inject it's script programmatically.
   */
  injectPrismic() {
    if (this._prismicLoaded) return;

    createScriptAndWait(this._document, `https://static.cdn.prismic.io/prismic.min.js?new=true&repo=eq-catalyst`);

    this._prismicLoaded = true;
  }

  private resetCookies() {
    this._cookie.delete(this._storageKey, this._cookiePath);
    this._store.dispatch(loadPrivacySettingsError());
  }
}

export type PrivacyGuardedServices = 'essential' | 'matomo';

export type PrivacySettings = Record<PrivacyGuardedServices, boolean>;

export interface CookieData {
  version: string;
  entries: PrivacySettings;
}
