import {PostCouponsByIdVerifyResponse} from '@backend-api/coupons-verify/post-coupons-by-id-verify.response';
import {PostOrderRequest} from '@backend-api/order/post-order.request';
import {OrderableTraining} from '@backend-api/orderable-trainings/get-orderable-trainings.response';
import {createAction, props} from '@ngrx/store';
import {createExecuteActions, ExecuteActions} from '@zeit-dev/ngrx-util';
import {PostOrderCampaignsByIdVerifyResponse} from '@backend-api/order-campaigns-verify/post-order-campaigns-by-id-verify.response';

export const trainingActions: ExecuteActions<OrderableTraining[]> = createExecuteActions('availableTrainings');

export const checkCouponCode = createAction('[Order] Check coupon code', props<{code: string}>());

export const couponValid = createAction('[Order] Coupon valid', props<{couponData: PostCouponsByIdVerifyResponse}>());

export const couponInvalid = createAction('[Order] Coupon invalid');

export const sendOrder = createAction('[Order] Send order', props<{orderData: PostOrderRequest}>());

export const orderDispatched = createAction('[Order] Order dispatched');

export const orderFailed = createAction('[Order] Order failed', props<{error?: string}>());

export const verifyOrderCampaign = createAction('[Order] Verify Order Campaign', props<{key: string}>());

export const verifyOrderCampaignSuccess = createAction(
  '[Order] Verify Order Campaign Success',
  props<{campaignData: PostOrderCampaignsByIdVerifyResponse}>()
);

export const verifyOrderCampaignError = createAction('[Order] Verify Order Campaign Error');
