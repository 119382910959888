<ng-container *ngIf="cookiesEnabled$ | async; else cookiesBlocked">
  <div class="vimeo-wrapper">
    <div class="vimeo">
      <div
        *ngIf="video"
        [class.was-started]="videoStarted"
        [class.is-playing]="videoPlaying"
        [plyrOptions]="options"
        mat-card-image
        plyr
        (plyrPlaying)="loaded($event)"
        (plyrPlay)="played($event)"
        (plyrPause)="paused($event)"></div>
      <div class="notes-overlay" *ngIf="notesEnabled">
        <button
          class="create-note"
          *ngIf="!noteCreation"
          mat-raised-button
          color="accent"
          (click)="openEditor()"
          [disabled]="!videoStarted"
          [matTooltip]="'NOTES.ADD_NEW' | translate"
          matTooltipPosition="below"
          matTooltipClass="primary-tooltip">
          <mat-icon svgIcon="note-create"></mat-icon>
        </button>
        <div *ngIf="noteCreation" class="notes-editor d-flex flex-column">
          <textarea matInput #noteCreationText placeholder="{{'NOTES.PLACEHOLDER' | translate}}"></textarea>
          <div class="button-container d-flex justify-content-end p-2">
            <button mat-flat-button (click)="resetNote()">
              <span translate>GENERIC.BUTTON_CANCEL</span>
            </button>
            <button mat-flat-button [disabled]="!noteCreationText.value" color="accent" (click)="saveNote(noteCreationText.value)">
              <span translate>GENERIC.BUTTON_SAVE</span>
            </button>
          </div>
        </div>
        <button
          *ngIf="notes"
          class="note-toggle ms-2"
          mat-raised-button
          color="accent"
          (click)="toggleNotes()"
          [@highlightAnimation]="getNotesCount()"
          [disabled]="!videoStarted"
          [matTooltip]="'NOTES.SHOW' | translate"
          matTooltipPosition="below"
          matTooltipClass="primary-tooltip">
          <mat-icon svgIcon="note-edit" [matBadge]="getNotesCount(notes)" matBadgePosition="above" matBadgeSize="small"> </mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="notesEnabled && showNotes && notes" class="notes-container" [@notesAnimation]="getNotesCount()">
      <div class="p-3 d-flex align-items-center">
        <h2 class="m-0" translate #notesContainer>
          <a routerLink="/e-learning/notes">
            <span translate>NOTES.HEADLINE</span>
          </a>
        </h2>
        <button mat-icon-button class="ms-2 info-dialog" color="primary" (click)="openInfoDialog()">
          <mat-icon svgIcon="info" inline="true"></mat-icon>
        </button>
        <button mat-button class="hide" disableRipple="true" color="accent" (click)="toggleNotes()">
          <span translate>NOTES.HIDE</span>
        </button>
      </div>
      <div class="notes mb-2 px-3">
        <div class="m-3" *ngIf="notesEmpty(notes)">
          <p class="font-weight-bold" translate>NOTES.EMPTY_EMPH.SLIDE</p>
          <p translate>NOTES.EMPTY_INSTRUCT.SLIDE</p>
        </div>
        <div class="note py-3" *ngFor="let note of notes; trackBy: trackNote">
          <app-editable-note
            [note]="note"
            (deleteNoteEvent)="deleteNote($event)"
            (editModeEvent)="editNote($event)"
            (timestampClickEvent)="jumpToTimestamp($event)"
            (saveNoteEvent)="saveEditedNote($event)">
          </app-editable-note>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #cookiesBlocked>
  <div class="cookies-blocked">
    <h2 translate>COOKIE.NOTICE_VIDEO</h2>
  </div>
</ng-template>
