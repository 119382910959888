import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AbstractAssessmentDialogComponent} from '../abstract-assessment-dialog/abstract-assessment-dialog.component';
import {finishExternalAssessment} from '../assessment.actions';
import {ExternalAssessmentViewModel} from '../assessment.data.service';
import {AssessmentStateSlice} from '../assessment.reducer';

@Component({
  selector: 'app-assessment-cancel-dialog',
  templateUrl: './assessment-cancel-dialog.component.html',
  styleUrls: ['./assessment-cancel-dialog.component.scss', '../abstract-assessment-dialog/abstract-assessment-dialog.component.scss'],
})
export class AssessmentCancelDialogComponent extends AbstractAssessmentDialogComponent<AssessmentCancelDialogComponent> {
  constructor(
    private readonly _store: Store<AssessmentStateSlice>,
    ref: MatDialogRef<AssessmentCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {assessment: ExternalAssessmentViewModel}
  ) {
    super(ref);
  }

  isParticipantsCountSufficient() {
    // todo: const?
    return this.data.assessment.participantsCount >= 3;
  }

  onYesClick() {
    this._store.dispatch(finishExternalAssessment({id: this.data.assessment.id}));
    // todo: in future, we will fetch different errors and display them.
    // Then, we will wait for the BE-Status before closing the dialog
    this._ref.close();
  }

  onNoClick() {
    this._ref.close();
  }
}
