/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {PostCouponsByIdVerifyResponse} from './post-coupons-by-id-verify.response';
import {PostCouponsByIdVerifyRequest} from './post-coupons-by-id-verify.request';
@Injectable()
export class CouponsVerifyGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  postCouponsByIdVerify(
    id: string,
    body: PostCouponsByIdVerifyRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostCouponsByIdVerifyResponse> {
    return this._httpClient.request('post', this._apiHost + `/coupons/${id}/verify`, {
      ...options,
      observe: 'body',
      body,
    });
  }
}
