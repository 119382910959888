<app-slide-separator
  *ngIf="isIntroShown"
  [type]="data?.type"
  [specialSlide]="data?.quiz ? 'quiz' : undefined"
  [introText]="data?.pre_text"
  [title]="getTitle()"
  (buttonClicked)="launch()"
  [buttonText]="getButtonText()"
  [skipLink]="nextLink"
  [backLink]="backLink">
</app-slide-separator>

<div *ngIf="data && !isIntroShown" class="interactive-content">
  <iframe *ngIf="data.launch_url" [src]="data.launch_url | trustUrl"></iframe>
</div>
