<ng-container *ngIf="training && session">
  <div class="total-progress d-flex mx-sm-4 px-3">
    <app-progress-bar [current]="training.current" [total]="training.total" type="small"></app-progress-bar>
    <span translate>SLIDE_NAVBAR.PROGRESS</span>
  </div>
  <nav class="navbar top mx-sm-4 px-3">
    <h1>{{ session.title }}</h1>
    <div>
      <mat-icon svgIcon="close" [routerLink]="getTrainingLink(training)" [fragment]="currentSessionId"></mat-icon>
    </div>
  </nav>

  <div class="row mx-0 main-content" [@fadeInSlides]="currentSlideId" [class.full-height]="!navbarShown">
    <div class="col-12 text-center my-auto px-5 py-5 py-xl-0 py-xxl-5 content-wrapper">
      <app-prismic-container
        *ngIf="currentSlideId !== undefined"
        [slide]="session.slides.objects[currentSlideId]"
        [currentProgress]="currentprogress"
        [training]="training"
        [nextLink]="getNextLink()"
        [backLink]="getBackLink()"
        [queryParams]="queryParams">
      </app-prismic-container>
    </div>
  </div>

  <nav class="navbar fixed-bottom px-3" *ngIf="navbarShown">
    <button [disabled]="!canGoBack()" mat-raised-button color="accent" [routerLink]="getBackLink()">
      <span class="d-none d-sm-inline" translate>SLIDE_NAVBAR.PREVIOUS_SLIDE</span>
      <span class="d-block d-sm-none">
        <i-feather name="chevron-left"></i-feather>
      </span>
    </button>
    <button [disabled]="!canContinue()" mat-raised-button color="accent" [routerLink]="getNextLink()">
      <span class="d-none d-sm-inline" translate>SLIDE_NAVBAR.NEXT_SLIDE</span>
      <span class="d-block d-sm-none">
        <i-feather name="chevron-right"></i-feather>
      </span>
    </button>
  </nav>
</ng-container>
