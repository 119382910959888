import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AppMaterialModule} from '../../app-material.module';
import {AssessmentAddParticipantDialogComponent} from './assessment-add-participant-dialog/assessment-add-participant-dialog.component';
import {AssessmentCancelDialogComponent} from './assessment-cancel-dialog/assessment-cancel-dialog.component';
import {AssessmentExternalComponent} from './assessment-external/assessment-external.component';
import {AssessmentFinishedComponent} from './assessment-finished/assessment-finished.component';
import {AssessmentListComponent} from './assessment-list/assessment-list.component';
import {AssessmentNewDialogComponent} from './assessment-new-dialog/assessment-new-dialog.component';
import {AssessmentSelfComponent} from './assessment-self/assessment-self.component';
import {AssessmentEffects} from './assessment.effects';
import {reducer} from './assessment.reducer';
import {ErrorLogoutComponent} from './error-logout/error-logout.component';
import {ErrorSameUserComponent} from './error-same-user/error-same-user.component';
import {QuestionnaireComponent} from './questionnaire/questionnaire.component';

@NgModule({
  declarations: [
    AssessmentSelfComponent,
    QuestionnaireComponent,
    AssessmentExternalComponent,
    ErrorSameUserComponent,
    ErrorLogoutComponent,
    AssessmentFinishedComponent,
    AssessmentListComponent,
    AssessmentNewDialogComponent,
    AssessmentCancelDialogComponent,
    AssessmentAddParticipantDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,

    FormsModule,

    StoreModule.forFeature('assessment', reducer),
    EffectsModule.forFeature([AssessmentEffects]),
  ],
})
export class AssessmentModule {}
