import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {loadQuestionsExternal, sendAnswersExternal, setQuestionRatingExternal} from '../assessment.actions';
import {AssessmentStateSlice} from '../assessment.reducer';
import {PageInfo, selectExternalQuestionsForPage, selectPage, selectPageSummaryExternal} from '../assessment.selectors';
import {QuestionViewModel} from '../question.model';

@Component({
  selector: 'app-assessment-external',
  templateUrl: './assessment-external.component.html',
  styleUrls: ['./assessment-external.component.scss'],
})
export class AssessmentExternalComponent implements OnInit {
  questions$: Observable<QuestionViewModel[]> = this.store.select(selectExternalQuestionsForPage);
  pageNo$: Observable<number> = this.store.select(selectPage);
  pageSummary$: Observable<PageInfo[]> = this.store.select(selectPageSummaryExternal);

  routerLink = '/external-assessment';

  constructor(private readonly store: Store<AssessmentStateSlice>) {}

  ngOnInit() {
    this.store.dispatch(loadQuestionsExternal());
  }

  onChange(e: {id: string; value: string}) {
    this.store.dispatch(setQuestionRatingExternal({id: e.id, value: e.value}));
  }

  sendAnswers() {
    this.store.dispatch(sendAnswersExternal());
  }
}
