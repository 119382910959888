import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {exhaustMap, mapTo, tap} from 'rxjs/operators';
import {finishApplicationUpdate, hideAppBlocker, initAngularRouting, startAngularRouting, startApplicationUpdate} from './app.actions';
import {AppUpdatesService} from './core/infrastructure/app-updates.service';

@Injectable()
export class AppEffects {
  startUpdate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(startApplicationUpdate),
        tap(() => {
          this._update.checkForUpdate();
        })
      ),
    {dispatch: false}
  );

  finishApplicationUpdate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(finishApplicationUpdate),
      exhaustMap(() => {
        return of(initAngularRouting(), hideAppBlocker());
      })
    )
  );

  initAngularRouting$ = createEffect(() =>
    this._actions$.pipe(
      ofType(initAngularRouting),
      tap(() => {
        this._router.initialNavigation();
      }),
      mapTo(startAngularRouting())
    )
  );

  constructor(private readonly _update: AppUpdatesService, private readonly _actions$: Actions, private readonly _router: Router) {}
}
