import {Location} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-training-coming-soon',
  templateUrl: './training-coming-soon.component.html',
  styleUrls: ['./training-coming-soon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingComingSoonComponent {
  @Input()
  title?: string;

  constructor(private readonly _location: Location) {}

  backClicked() {
    this._location.back();
  }
}
