import {Note} from '@backend-api/notes/get-notes.response';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {filter, find, flatMap, intersection} from 'lodash-es';
import {LiveTrainingSession} from 'src/app/backend/elearning-api/learning-paths/get-learning-paths.response';
import {LeanSession, Topic, Training} from 'src/app/backend/elearning-api/trainings/get-trainings.response';
import {UserTrainingProgress} from 'src/app/backend/elearning-api/user-training-progresses/get-user-training-progresses.response';
import {Dictionary, extractObjects, IndexedObjects} from 'src/app/shared/data.service';
import {selectRouteParamsSessionId, selectRouteParamsSlideId, selectRouteParamsTrainingSlug} from '../../shared/shared.selectors';
import {ELearningSlice, ELearningState} from './e-learning.reducer';

export const selectElearningState = createFeatureSelector<ELearningState>('elearning');

export const selectTrainingsState = createSelector(selectElearningState, (state) => state.trainings);

export const selectUserBookingState = createSelector(selectElearningState, (state) => state.bookings);

/**
 * Selects BE-trainings from the state and maps the users bookings to them.
 * This selector keeps the IndexedObjects-Structure, so selectors further down the chain can better access the values.
 * Param `filterVersions` (default `true`) defines whether or not this selector should filter out unbooked versions that are not the latest version of an aggregate.
 * Note that using a parametrized factory function, this selector will be correctly memoized,
 * though a new instance will be added to memory for each new param.
 */
export const selectTrainings = (filterVersions = true) =>
  createSelector(selectTrainingsState, selectUserBookingState, (state, bookingsState) => {
    // wait until trainings and bookings are loaded to prevent unneccessary computing and rendering
    if (state.loaded && bookingsState.loaded) {
      if (state.results) {
        const trainings: Dictionary<TrainingWithAccessibility, string> = {};
        // Part of versions-hotfix: remember already added aggregate-IDs from bookings to not add other versions
        const includedAggregates: string[] = [];
        // as we potentially throw out trainings from the data: also adjust the IDs
        const ids: string[] = [];
        // add all training-versions that are explicitely booked.
        // the BE has already figured out the complete logic whether a version should be booked or not (regarding progress etc. - see tickets)
        bookingsState.results.forEach((slug) => {
          const training = state.results?.objects[slug];
          if (!!training) {
            // add this training
            trainings[slug] = {
              ...training,
              // `true` as it is booked
              isAccessible: true,
            } as TrainingWithAccessibility;
            // add the aggregate-ID, so other (unbooked) versions for this agregate will not be added
            includedAggregates.push(training.versions_aggregate_id);
            // push slug to the array for later
            ids.push(training.slug);
          }
        });
        // now, check the other versions in the state
        state.results.ids.forEach((slug) => {
          // to check whether exactly this version is already added
          const training = state.results?.objects[slug];
          // indifferent of version filtering: skip if training is either not existent or already in the collection
          if (!!training && !trainings[slug]) {
            // this logic is a bit puzzling at first sight, so:
            // only add if either
            // - `filterVersions` is false (just add all the LPs)
            // - `filterVersions` is true AND user does not have a different version of this LP in their bookings AND this is the latest version of the LP
            if (
              !filterVersions ||
              (!includedAggregates.includes(training.versions_aggregate_id) && training.version === training.versions_aggregate_max_version)
            ) {
              trainings[slug] = {
                ...training,
                // no booking: not accessible
                isAccessible: false,
              } as TrainingWithAccessibility;
              // also remember the ID for this one
              ids.push(training.slug);
            }
          }
        });
        return {
          ids: ids,
          objects: trainings,
        } as IndexedObjects<TrainingWithAccessibility, string>;
      }
    }
    return undefined;
  });

export function selectSessionContent(state: ELearningSlice) {
  return state.elearning.sessionContent?.results;
}

export const selectCurrentSessionContent = createSelector(
  selectSessionContent,
  selectRouteParamsTrainingSlug,
  selectRouteParamsSessionId,
  (sessions, trainingSlug, sessionId) => {
    if (!sessionId || !trainingSlug || !sessions[trainingSlug]) {
      // one of them might not be available right now
      return null;
    }
    return sessions[trainingSlug][sessionId];
  }
);

export function selectUserTrainingProgressesState(state: ELearningSlice) {
  return state.elearning.userTrainingProgresses;
}

export const selectUserTrainingProgresses = createSelector(selectUserTrainingProgressesState, (state) => {
  if (state.loaded) {
    return state.results;
  } else {
    return undefined;
  }
});

export const selectCurrentUserTrainingProgress = createSelector(
  selectUserTrainingProgresses,
  selectRouteParamsTrainingSlug,
  (progresses, slug) => (progresses && slug ? progresses.objects[slug] : undefined)
);

export const selectCurrentTraining = createSelector(selectTrainings(), selectRouteParamsTrainingSlug, (trainings, slug) => {
  if (trainings && slug) {
    return trainings.objects[slug] ?? null;
  }
  return undefined;
});

export const selectTrainingList = createSelector(selectTrainings(), (trainings) => {
  if (trainings) {
    return extractObjects(trainings);
  }
  return null;
});

export const selectSortedTrainingList = createSelector(selectTrainingList, (trainingList) => {
  if (trainingList) {
    return trainingList?.sort((a, b) => {
      // ensure lexicographical sorting, ascending
      return a.name.localeCompare(b.name);
    });
  } else {
    return null;
  }
});

export const selectCertificatesAvailabilityState = createSelector(selectElearningState, (elearningState) => {
  return elearningState.certificatesAvailable;
});

export const selectCertificatesAvailabilityStateLoaded = createSelector(
  selectCertificatesAvailabilityState,
  (state) => state.loaded && !state.loading
);

export const selectCertificateAvailableForCurrentTraining = createSelector(
  selectCertificatesAvailabilityState,
  selectRouteParamsTrainingSlug,
  (certificateAvailability, slug) => {
    if (certificateAvailability.loaded && certificateAvailability.results && slug) {
      return certificateAvailability.results[slug];
    }
    return null;
  }
);

// Todo: (techdebt) As Progress is pretty volatile, this selector should generally be moved further to the end of the 'selector-chain'
export const selectTrainingsWithProgress = createSelector(
  selectSortedTrainingList,
  selectUserTrainingProgresses,
  selectCertificatesAvailabilityState,
  (trainings, progresses, certificateState) => {
    if (trainings && progresses && certificateState.results) {
      return trainings.map((training) => {
        return calculateTrainingProgress(training, progresses.objects[training.slug], certificateState.results[training.slug]);
      });
    }
  }
);

export const selectCurrentTrainingWithProgress = createSelector(
  selectCurrentUserTrainingProgress,
  selectCurrentTraining,
  selectCertificateAvailableForCurrentTraining,
  (currentProgress, currentTraining, currentCertificate) => {
    if (currentTraining) {
      return calculateTrainingProgress(currentTraining, currentProgress, currentCertificate);
    }
  }
);

export const selectSessionProgresses = createSelector(selectCurrentTraining, selectUserTrainingProgresses, (training, progresses) => {
  if (training && progresses && training.sessions) {
    const filteredSessions = filterSessionSlides(training.sessions, filterProgressRelevantSlides);
    const visitedSlides = progresses.objects[training.slug]?.slide_ids;
    if (visitedSlides) {
      const dict: Dictionary<Progress> = {};
      filteredSessions.map((session) => {
        const slides = session.slides.map((slide) => slide.id);
        dict[session.id] = {
          current: intersection(visitedSlides, slides).length,
          total: slides.length,
        };
      });
      return dict;
    }
  }
});

export const selectUserRatingState = createSelector(selectElearningState, (state) => state.userTrainingRatings);

export const selectUserRatingLoading = createSelector(selectUserRatingState, (state) => state.loading);

export const selectUserRatings = createSelector(selectUserRatingState, (state) => {
  if (state.loaded && !state.loading && state.results) {
    return state.results;
  }
});

export const selectCurrentUserTrainingRating = createSelector(selectUserRatings, selectRouteParamsTrainingSlug, (ratings, slug) => {
  if (ratings && slug) {
    return ratings[slug];
  }
  return null;
});

export const selectTrainingsByTrainers = createSelector(selectCurrentTraining, selectTrainings(), (currentTraining, allTrainings) => {
  if (currentTraining && allTrainings && currentTraining.trainers.length > 0) {
    const otherTrainings: Dictionary<Training[], string> = {};
    currentTraining.trainers.map((trainer) => {
      otherTrainings[trainer.id] = filter(allTrainings.objects, (training) => {
        const isNotSameTraining: boolean = training.slug !== currentTraining.slug;
        const hasSameTrainer: boolean = training.trainers.some((t) => t.id === trainer.id);
        const hasContent: boolean = training.has_content;

        return isNotSameTraining && hasSameTrainer && hasContent;
      });
    });
    return otherTrainings;
  }
});

export const selectMostRecentSessionSlug = createSelector(
  selectCurrentTraining,
  selectCurrentUserTrainingProgress,
  (training, trainingProgress) => {
    if (training && training.sessions && trainingProgress) {
      const slideId = trainingProgress.last_slide ? trainingProgress.last_slide : '';
      const sessionContainingSlide = find(training.sessions, (session) => session.slides.map((slide) => slide.id).includes(slideId));
      return sessionContainingSlide?.id;
    }
  }
);

export const selectVisitedSlides = createSelector(selectCurrentUserTrainingProgress, (progress) => {
  if (progress && progress.slide_ids) {
    return progress.slide_ids;
  }
});

export const selectNextSlideSlugs = createSelector(
  selectCurrentTraining,
  selectCurrentUserTrainingProgress,
  selectCurrentUserTrainingRating,
  (training, trainingProgress, trainingRating) => {
    if (training && training.sessions) {
      const filteredSessions = filterSessionSlides(training.sessions, filterTitleSlides);
      if (trainingProgress) {
        return filteredSessions.map((session) => {
          // if there is progress, filter the already visited slides and return the very first (not yet visited) ones ID for each session
          return session.slides.find((slide) => {
            // special case: feedback-slides are not represented in the progress! Thus, we have to check whether the user has given feedback
            if (slide.type === 'User_training_rating_slide') {
              return !trainingRating;
            }
            return !trainingProgress.slide_ids.includes(slide.id);
          })?.id;
        });
      } else {
        // if there is no progress yet, return the first slide of every session
        return filteredSessions.map((session) => session.slides[0]?.id);
      }
    }
  }
);

export const selectLearningPathState = createSelector(selectElearningState, (state) => {
  if (state) {
    return state.learningPaths;
  }
  return null;
});

export const selectLearningPaths = createSelector(selectLearningPathState, (learningPathState) => {
  if (learningPathState?.loaded) {
    return learningPathState.results;
  }
  return undefined;
});

/**
 * Take the learningPaths from BE/state and enrich them with complete data from the trainings in the state,
 * as we only have the slug initially.
 */
export const selectLearningPathsWithTrainings = createSelector(selectLearningPaths, selectTrainings(false), (learningPaths, trainings) => {
  if (learningPaths && trainings) {
    // this whole block is un-DRY, but can't appropriately be split into reusable functions as of the different types
    // see selectLearningPathsWithProgress
    return extractObjects(learningPaths).map((learningPath) => {
      return {
        ...learningPath,
        modules: learningPath.modules?.map((module) => {
          return {
            ...module,
            items: module.items.reduce((acc: EnrichedLearningPathModule['items'], moduleItem) => {
              if (moduleItem.type === 'training') {
                const mappedTraining = trainings.objects[moduleItem.training];
                // there COULD be a case where the data is erronous, skip
                if (mappedTraining) {
                  acc.push({
                    ...moduleItem,
                    training: mappedTraining,
                  } as EnrichedLearningPathTraining);
                }
              } else if (moduleItem.type === 'live_training_session') {
                // LTS should always be added
                acc.push(moduleItem);
              }
              return acc;
            }, []),
          } as EnrichedLearningPathModule;
        }),
      } as EnrichedLearningPath;
    });
  }
  return undefined;
});

/**
 * Take the learningPaths mapped with content from the actual trainings and fill them with the current progress.
 * UserProgress is the most volatile part of the data, thus we put this step in a separate selector for better memoization
 */
export const selectLearningPathsWithProgress = createSelector(
  selectLearningPathsWithTrainings,
  selectUserTrainingProgresses,
  selectCertificatesAvailabilityState,
  (learningPaths, progresses, certificateAvailability) => {
    if (!!learningPaths && !!progresses && certificateAvailability.results) {
      // this whole block is un-DRY, but can't appropriately be split into reusable functions as of the different types
      // see selectLearningPathsWithTrainings
      return learningPaths.map((learningPath) => {
        return {
          ...learningPath,
          modules: learningPath.modules?.map((module) => {
            return {
              ...module,
              items: module.items.map((moduleItem) => {
                if (moduleItem.type === 'training') {
                  return {
                    ...moduleItem,
                    training: calculateTrainingProgress(
                      moduleItem.training,
                      progresses.objects[moduleItem.training.slug],
                      certificateAvailability.results[moduleItem.training.slug]
                    ),
                  } as EnrichedLearningPathTraining;
                }
                return moduleItem;
              }),
            } as EnrichedLearningPathModule;
          }),
        } as EnrichedLearningPath;
      });
    }
  }
);

export const selectLoadPreview = createSelector(selectElearningState, (state) => {
  if (state && state.loadPreviews.loaded) {
    // actually, `loaded` would suffice, as it can only be set to true...
    return state.loadPreviews.results;
  }
  return false;
});

export type TrainingWithAccessibility = Training & {isAccessible: boolean};

// Used to show the current progress in list and dashboard
export type TrainingWithProgress = TrainingWithAccessibility & Progress & {certificateIssued: null | Date};

export interface Progress {
  current: number;
  total: number;
}

export const selectCurrentTypeformResponse = createSelector(selectElearningState, (state) => {
  if (state.currentQuiz) {
    return state.currentQuiz.results;
  }
  return null;
});

export function filterSessionSlides(sessions: LeanSession[], filterFn: (s: LeanSession['slides']) => LeanSession['slides']): LeanSession[] {
  return sessions.map((session) => ({
    ...session,
    slides: filterFn(session.slides),
  }));
}

function calculateTrainingProgress(
  training: Training,
  progress: UserTrainingProgress | undefined,
  certificate: Date | null
): TrainingWithProgress {
  const relevantSlideIds = flatMap(filterSessionSlides(training.sessions, filterProgressRelevantSlides), (session) =>
    session.slides.map((slide) => slide.id)
  );
  // default: user hasn't visited any slides. Use as fallback
  let current = 0;
  if (!!progress) {
    // do the math
    current = intersection(relevantSlideIds, progress.slide_ids).length;
  }

  return {
    ...training,
    current,
    total: relevantSlideIds.length,
    certificateIssued: certificate,
  } as TrainingWithProgress;
}

export function filterProgressRelevantSlides(slides: LeanSession['slides']): LeanSession['slides'] {
  return slides.filter((slide) => slide.is_progress_relevant);
}

export function filterTitleSlides(slides: LeanSession['slides']): LeanSession['slides'] {
  return slides.filter((slide) => slide.type !== 'Title_slide');
}

/**
 * We hydrate the topics with additional data. This interface bundles this data and is used in the unionized type.
 */
export interface TopicData {
  accessibleTrainingsCount: number;
  trainings: TrainingWithProgress[];
}

/**
 * Type to group a BE-Topic with additionally mapped data from the state.
 */
export type TopicWithTrainings = Topic & TopicData;

/**
 * In Learningpaths from the BE/state, we only have the slug available. So we need a type containing more data
 */
export interface EnrichedLearningPathTraining {
  type: 'training';
  // for better memoization, we need them without progress first and then enrich the data in a next step
  training: Training | TrainingWithProgress;
  starts_at: string | null;
}

/**
 * To contain EnrichedLearningPathTraining
 */
export interface EnrichedLearningPathModule {
  name: string;
  items: Array<LiveTrainingSession | EnrichedLearningPathTraining>;
}

/**
 * To contain EnrichedLearningPathTraining
 */
export interface EnrichedLearningPath {
  name: string;
  modules?: EnrichedLearningPathModule[];
}

export function selectUserNotesState(state: ELearningSlice) {
  return state.elearning.userNotes;
}

export const selectUserNotes = createSelector(selectUserNotesState, (state) => {
  if (!!state && state.results) {
    return extractObjects(state.results);
  }
});

export type EditableNote = Note & {route?: {url: string; timestamp: number}};

export interface SlideNotes {
  slideTitle: string;
  notes: EditableNote[];
}

export interface TrainingNotes {
  training: string;
  trainingTitle: string;
  slideNotes: SlideNotes[];
}

/**
 * Selector that maps the users notes with training-data.
 * It groups the notes in the respective trainings and slides, preserving the slides order in the training.
 */
export const selectUserNotesByTrainings = createSelector(selectUserNotes, selectTrainings(), (notes, trainings) => {
  if (!!notes && !!trainings) {
    /* NOTE: It would be *way* more elegant (and efficient...) to use the given trainings-dict and just iterate over the notes,
     * mapping the trainings data onto the note.
     * However, there is no way to deduce the correct order from this data alone, so we have to use the inherent sorting in the trainings.
     */
    const groupedNotes = new Array<TrainingNotes>();
    extractObjects(trainings).forEach((training) => {
      const currentTrainingNotes: TrainingNotes = {
        training: training.slug,
        trainingTitle: training.name,
        slideNotes: [],
      };
      // remove the sessions from the structure, keeping only the ordered slides
      flatMap(training.sessions, (session) => session.slides.map((slide) => ({...slide, session: session.id}))).forEach((slide) => {
        const currentSlideNotes: SlideNotes = {
          slideTitle: slide.title ?? '',
          // add all notes belonging to this slide, sorted ascending by timestamp
          notes: notes
            .filter((note) => note.slide_id === slide.id)
            .map(
              (note) =>
                ({
                  ...note,
                  route: {
                    url: `/e-learning/trainings/${training.slug}/${slide.session}/${slide.id}`,
                    timestamp: note.seconds,
                  },
                } as EditableNote)
            )
            .sort((a, b) => a.seconds - b.seconds),
        };
        if (currentSlideNotes.notes.length > 0) {
          // add this to the list of this slide has any notes
          currentTrainingNotes.slideNotes.push(currentSlideNotes);
        }
      });
      if (currentTrainingNotes.slideNotes.length > 0) {
        // add training if it has a slide with notes in it
        groupedNotes.push(currentTrainingNotes);
      }
    });
    return groupedNotes;
  }
});

export const selectUserNotesByCurrentSlide = createSelector(selectUserNotes, selectRouteParamsSlideId, (notes, slideId) => {
  if (!!notes) {
    return notes.filter((note) => note.slide_id === slideId).sort((a, b) => a.seconds - b.seconds);
  }
});

const trainingProgressCatchZero = (t: TrainingWithProgress) => {
  return t.total > 0 ? t.current / t.total : 0;
};

/**
 * Comparator for sorting trainings. This is used for the more complex 'grouped trainings' view.
 * Takes the currently checked topic as input.
 * Sorting here is based on the trainings accessibility, its primary topic and progress:
 * 1. Accessible trainings first
 * 2. Trainings not having certificates (i. e. 'not finished') first
 * 3. Trainings having the given topic as their primary topic first
 * 4. Trainings with higher progress first
 * 5. If same classes for above metrics: Sort lexicographically
 */
function trainingComparatorAll(topic: string) {
  return (a: TrainingWithProgress, b: TrainingWithProgress) => {
    const progressA = trainingProgressCatchZero(a);
    const progressB = trainingProgressCatchZero(b);
    // trainings have to be handled differently based whether or not they are accessible
    if ((a.isAccessible && b.isAccessible) || (!a.isAccessible && !b.isAccessible)) {
      // check for issued certificates
      if ((a.certificateIssued && b.certificateIssued) || (!a.certificateIssued && !b.certificateIssued)) {
        // trainings have to be handled differently based whether or not they have the 'current topic' set as their primary topic
        if ((a.topics[0].id === topic && b.topics[0].id === topic) || (a.topics[0].id !== topic && b.topics[0].id !== topic)) {
          if (progressA === progressB) {
            // a and b being in the same class (accessibility, primary topic, progress): ensure lexicographical sorting
            return a.name.localeCompare(b.name);
          } else {
            // sort ascending by progress
            return progressB - progressA;
          }
        } else {
          // one of the trainings does not have the given topic as its primary topic
          // put the one with the matching primary topic first!
          return a.topics[0].id === topic ? (b.topics[0].id === topic ? 0 : -1) : 1;
        }
      } else {
        // in this case, one of the trainings is finished, while the other isn't.
        // in which case special sorting is needed:
        // unfinished trainings should be first
        return !a.certificateIssued ? (!b.certificateIssued ? 0 : -1) : 1;
      }
    } else {
      // else: trainings being accessible first
      return a.isAccessible ? (b.isAccessible ? 0 : -1) : 1;
    }
  };
}

/**
 * Simple comparator for sorting trainings. This is used for the 'my available trainings' view,
 * for which the sorting is rather simple, as unaccessible trainings are filtered.
 * Thus, simply sort trainings without certificate (i. e. unfinished) first, then sort by progress, then lexicographically.
 */
const trainingComparatorAvailable: (a: TrainingWithProgress, b: TrainingWithProgress) => number = (a, b) => {
  const progressA = trainingProgressCatchZero(a);
  const progressB = trainingProgressCatchZero(b);
  if ((a.certificateIssued && b.certificateIssued) || (!a.certificateIssued && !b.certificateIssued)) {
    if (progressA === progressB) {
      // a and b being in the same class (i. e. same progress): ensure lexicographical sorting
      return a.name.localeCompare(b.name);
    } else {
      // sort ascending by progress
      return progressB - progressA;
    }
  } else {
    // in this case, one of the trainings is finished, while the other isn't.
    // in which case special sorting is needed:
    // unfinished trainings should be first
    return !a.certificateIssued ? (!b.certificateIssued ? 0 : -1) : 1;
  }
};

/**
 * As topics get data-fields from BE and we generate two topics at runtime, we want to provide the values somewhere organized!
 */
export const GENERATED_TOPICS_IDS = {
  FINISHED: 'finished',
  STARTED: 'started',
};

/**
 * We have to distinguish between normal topics from the BE and topics we generate on-the-fly.
 * The flag is possibly undefined to reduce boilerplate-mappings where possible.
 */
export type ExtractedTopic = TopicWithTrainings & {generated?: boolean};

/**
 * Selects all topics (unique) from the trainings and returns them in a sorted (!) array,
 * also containing information about the amount of accessible trainings in them as well as the trainings in a sorted array.
 * Sorting is based on these factors:
 *   - There are two generated topics, which will be omitted if no training fulfills the constraint:
 *      - one that collects all trainings with progress, this will always be first
 *      - one that collects all finished trainings, this will be second
 *   - All other topics are sorted by the amount of available (i. e. accessible) trainings in them
 *   - Lastly, they are sorted based on a fixed given order
 *   - The trainings inside a topic are sorted this way:
 *     - They are split into three groups: Accessible (unfinished), Finished, Unaccessible
 *     - (only for the first group:) They are sorted descending by their current progress
 *     - Finally, sort everything else lexicographically
 * Always returns at least an empty Array.
 */
export const selectTopics = (props: {mode: 'all' | 'available'}) =>
  createSelector(selectTrainingsWithProgress, (trainings) => {
    // fallback: if everything fails, return an empty array
    let topicsArray: ExtractedTopic[] = [];
    if (!!trainings) {
      // this will be the generated 'started'-topic
      const started: ExtractedTopic = {
        id: GENERATED_TOPICS_IDS.STARTED,
        title_de: 'TRAININGS.TOPIC_TITLES.STARTED',
        title_en: 'TRAININGS.TOPIC_TITLES.STARTED',
        position: null,
        accessibleTrainingsCount: 0,
        trainings: [],
        generated: true,
      };
      // this will be the generated 'finished'-topic
      const finished: ExtractedTopic = {
        id: GENERATED_TOPICS_IDS.FINISHED,
        title_de: 'TRAININGS.TOPIC_TITLES.FINISHED',
        title_en: 'TRAININGS.TOPIC_TITLES.FINISHED',
        position: null,
        accessibleTrainingsCount: 0,
        trainings: [],
        generated: true,
      };
      // for easier management, use a dict and transform it into an array later on
      const topics: Dictionary<ExtractedTopic> = {};
      trainings.forEach((training) => {
        // first, check the mode: if in mode 'available', we will skip all trainings that are not accessible by the user
        if (!(props.mode === 'available' && !training.isAccessible)) {
          if (training.current > 0) {
            if (!training.certificateIssued) {
              // every training having progress > 0, but < 100 should be added to the 'started'-topic
              if (training.isAccessible) started.accessibleTrainingsCount++;
              started.trainings.push(training);
            } else {
              // already finished trainings should be put into a separate group
              if (training.isAccessible) finished.accessibleTrainingsCount++;
              finished.trainings.push(training);
            }
          }
          // then map it to its topic
          // for mode 'available', take only the first topic, which is defined as the primary topic
          const conditionalTopics = props.mode === 'available' ? training.topics.slice(0, 1) : training.topics;
          conditionalTopics.forEach((topic) => {
            // add this training to all the topics it is linked with
            if (topics[topic.id]) {
              // to prevent unneccessary computations later on, count the trainings set to 'accessible'
              if (training.isAccessible) topics[topic.id].accessibleTrainingsCount++;
              topics[topic.id].trainings.push(training);
            } else {
              // if this topic isn't in the dictionary yet
              topics[topic.id] = {
                ...topic,
                accessibleTrainingsCount: training.isAccessible ? 1 : 0,
                trainings: [training],
              };
            }
          });
        } else if (training.has_content) {
          // we still want to construct all the topics nonetheless
          // however, skip those without content
          const primaryTopic = training.topics[0];
          if (!topics[primaryTopic.id]) {
            // add an empty record to the dict, if not already present
            topics[primaryTopic.id] = {
              ...primaryTopic,
              accessibleTrainingsCount: 0,
              trainings: [],
            };
          }
        }
      });
      // Start the sorting
      // from now on, we need an array instead of a dict
      topicsArray = Object.values(topics);
      // sort the topics
      topicsArray.sort((a, b) => {
        const countA = a.accessibleTrainingsCount;
        const countB = b.accessibleTrainingsCount;
        if (countA === countB && !!a.position && !!b.position) {
          // if both have the same count, ensure predefined given order
          // if the order is for some reason not set: go on with lexicographically sorting, as precedence is otherwise not defined
          return a.position - b.position;
        }
        // else, sort ascending by accessible trainings
        return countB - countA;
      });
      // sort the trainings inside the regular topics
      topicsArray = topicsArray.map(
        (topic) =>
          ({
            ...topic,
            trainings:
              props.mode === 'available'
                ? topic.trainings.sort(trainingComparatorAvailable)
                : topic.trainings.sort(trainingComparatorAll(topic.id)),
          } as ExtractedTopic)
      );
      // then (if needed), add the two generated topics sorted by their own measures
      // if there are finished trainings, add this to our dict, otherwise it should not be included!
      // TODO: check for mode only POC here, prevent unneeded calculations above!
      if (props.mode === 'available' && finished.trainings.length > 0) {
        // sort the trainings inside this topic based on the certificates
        finished.trainings.sort((a, b) => {
          // can't be null programmatically, but needs checking nonetheless
          if (a.certificateIssued && b.certificateIssued) {
            const diff = a.certificateIssued.getTime() - b.certificateIssued.getTime();
            if (diff === 0) {
              // incase they were issued exactly at the same time (e. g. automated task, ...), sort lexicographically
              return a.name.localeCompare(b.name);
            }
            // else, sort by issued-date
            return diff;
          }
          // not supposed to be reached by any means, but has to be defined
          return 0;
        });
        // it should always be the second (or first, resp.) topic, so use `unshift`
        topicsArray.unshift(finished);
      }
      // if there are started trainings, add this to our dict, otherwise it should not be included!
      if (props.mode === 'available' && started.trainings.length > 0) {
        // sort the trainings exactly as for the normal topics here
        started.trainings.sort(
          props.mode === 'available'
            ? trainingComparatorAvailable
            : // pass empty string as topic, there is no topic-based sorting needed
              trainingComparatorAll('')
        );
        // it should always be the first topic, so use `unshift`
        topicsArray.unshift(started);
      }
    }
    // return the constructed and sorted Array
    return topicsArray;
  });

export const selectDepProjectsState = createSelector(selectElearningState, (state) => state.depProjects);

export const selectDepProjects = createSelector(selectDepProjectsState, (depProjectsState) => {
  if (depProjectsState?.loaded) {
    return depProjectsState.results;
  }
  return undefined;
});

/**
 * In DepProjects from the BE/state, we only have the slug available. So we need a type containing more data
 */
export interface EnrichedDepProjectTraining {
  training: TrainingWithProgress;
  starts_at: string;
  ends_at: string;
}

/**
 * To contain EnrichedDepProjectTraining
 */
export interface EnrichedDepProjectModule {
  name: string;
  trainings: EnrichedDepProjectTraining[];
}

/**
 * To contain EnrichedDepProjectModule
 */
export interface EnrichedDepProject {
  name: string;
  modules: EnrichedDepProjectModule[];
}

export const selectDepProjectsWithTrainingData = createSelector(
  selectDepProjects,
  selectTrainings(false),
  selectUserTrainingProgresses,
  selectCertificatesAvailabilityState,
  (depProjects, trainings, progresses, certificateAvailability) => {
    if (depProjects && trainings && progresses && certificateAvailability) {
      return depProjects.map((depProject) => {
        return {
          ...depProject,
          modules: depProject.modules?.map((depModule) => {
            return {
              ...depModule,
              trainings: depModule.trainings.reduce((acc: EnrichedDepProjectTraining[], moduleItem) => {
                const mappedTraining = trainings.objects[moduleItem.training];
                if (mappedTraining) {
                  acc.push({
                    ...moduleItem,
                    training: calculateTrainingProgress(
                      mappedTraining,
                      progresses.objects[moduleItem.training],
                      certificateAvailability.results[moduleItem.training]
                    ),
                  } as EnrichedDepProjectTraining);
                }
                return acc;
              }, []),
            } as EnrichedDepProjectModule;
          }),
        } as EnrichedDepProject;
      });
    }
    return undefined;
  }
);

export const selectSlugRedirects = createSelector(selectElearningState, (state) => state.slugRedirects.results);

export const selectSlugRedirectForCurrentSlug = createSelector(selectSlugRedirects, selectRouteParamsTrainingSlug, (slugRedirects, slug) =>
  !!slug ? slugRedirects[slug] : undefined
);
