import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {delay, distinctUntilChanged, take} from 'rxjs/operators';
import {Language} from '../../user/user.data.service';
import {OrderState} from '../order.reducer';
import {selectIsOrderPlacedSuccessfully} from '../order.selectors';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  readonly isCheckout$ = this._store$.select(selectIsOrderPlacedSuccessfully);

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _store$: Store<OrderState>,
    private readonly _router: Router
  ) {
    // force language "de" here, too!
    this._translateService.use('de' as Language);
  }

  ngOnInit(): void {
    this.isCheckout$
      .pipe(
        distinctUntilChanged(),
        // to prevent redirecting a user that has a valid order just because the selector didn't evaluate fast enough
        delay(50),
        take(1)
      )
      .subscribe((isCheckout) => {
        if (isCheckout === undefined || !isCheckout) {
          this._router.navigateByUrl('/login');
        }
      });
  }
}
