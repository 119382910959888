import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface GenericDialogData {
  message: string;
  title: string;
  suffix?: string;
  checkboxText?: string;
}

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent {
  dialogData?: GenericDialogData;
  private _checked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly _data: GenericDialogData,
    private readonly _dialogRef: MatDialogRef<GenericDialogComponent>
  ) {
    this.dialogData = _data;
  }

  setChecked(isChecked: boolean) {
    this._checked = isChecked;
  }

  close() {
    if (!!this.dialogData?.checkboxText) {
      this._dialogRef.close(this._checked);
    } else {
      this._dialogRef.close();
    }
  }
}
