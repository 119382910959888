/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetOrderableTrainingsResponse} from './get-orderable-trainings.response';
import {GetOrderableTrainingsRequest} from './get-orderable-trainings.request';
@Injectable()
export class OrderableTrainingsGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getOrderableTrainings(
    queryParams: GetOrderableTrainingsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetOrderableTrainingsResponse> {
    return this._httpClient.request('get', this._apiHost + '/orderable_trainings', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
