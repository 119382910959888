import {HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {PostSignUpCampaignsByIdVerifyResponse} from '@backend-api/sign-up-campaigns-verify/post-sign-up-campaigns-by-id-verify.response';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATION} from '@ngrx/router-store';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY, of} from 'rxjs';
import {catchError, exhaustMap, map, mapTo, mergeMap, switchMapTo, tap} from 'rxjs/operators';
import {allowedLanguages} from '../app.component';
import {WINDOW} from '../core/window';
import {BeErrorMessage} from '../features/user/user.effects';
import {DataService} from './data.service';
import {PrivacyService} from './privacy.service';
import {
  changeUserLanguage,
  confirmAccount,
  confirmAccountError,
  confirmAccountSuccess,
  resetPassword,
  resetPasswordError,
  resetPasswordSuccess,
  sendResetMail,
  sendResetMailSuccess,
  setPageinfoTitle,
  showSnackbar,
  signup,
  signupError,
  signupSuccess,
  updatePrivacySettings,
  verifySignupCampaign,
  verifySignupCampaignError,
  verifySignupCampaignSuccess,
} from './shared.actions';

@Injectable()
export class SharedEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _dataService: DataService,
    private readonly _router: Router,
    private readonly _snackBar: MatSnackBar,
    private readonly _translateService: TranslateService,
    private readonly _privacyService: PrivacyService,
    // TODO: also inject everywhere else :)
    @Inject(WINDOW) private readonly _window: Window
  ) {}

  resetPagetitle$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(() => setPageinfoTitle({title: ''}))
    )
  );

  sendResetMail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(sendResetMail),
      exhaustMap((action) =>
        this._dataService.sendResetMail(action.email).pipe(
          mergeMap(() => {
            this._router.navigateByUrl('login');
            return [
              showSnackbar({
                messageType: 'success',
                message: this._translateService.instant('SNACKBAR.RESET_MAIL_SUCCESS', {
                  email: action.email,
                }),
              }),
              sendResetMailSuccess(),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            const message = error.status === 404 ? 'SNACKBAR.RESET_MAIL_ERROR_UNKNOWN' : 'SNACKBAR.RESET_MAIL_ERROR';
            return of(
              showSnackbar({
                messageType: 'error',
                message: this._translateService.instant(message),
              })
            );
          })
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this._actions$.pipe(
      ofType(resetPassword),
      exhaustMap((action) =>
        this._dataService.resetPassword(action.reset_password_token, action.password, action.password_confirmation).pipe(
          // HOTFIX: there are currently occurring errors that are not correctly thrown to error monitoring
          // tap this observable for extra-checks
          tap({
            error: (err: HttpErrorResponse) => {
              if (err instanceof HttpErrorResponse) {
                // the current guess: there occurrs either a 422 with non-standard error-object OR some other error-code we don't expect
                if (err.error[0] === undefined || err.status !== 422) {
                  // in this case: throw this error, as we want to see it in error monitoring for further inspection
                  // as this is a tapped observable, the other stream will not be terminated by this
                  throw err;
                }
              }
            },
          }),
          mergeMap(() => {
            this._router.navigateByUrl('/');
            return [
              showSnackbar({
                messageType: 'success',
                message: this._translateService.instant('SNACKBAR.RESET_PASSWORD_SUCCESS'),
              }),
              resetPasswordSuccess(),
            ];
          }),
          catchError((err: HttpErrorResponse) => {
            // BE always returns array with 1 element
            // HOTFIX: Errors occurred without applicable objects inside
            const error = err.error[0] as BeMappableErrorMessage;
            // so, check first, if this error is _really_ what we're expecting
            if (!!error && !!error.attribute && !!error.type) {
              // then pass this forward with mappable error
              return of(resetPasswordError({error}));
            } else {
              // if not, throw a generic message, so the users are not clueless
              // the cause for this will be thrown by the `tap` above
              const genericError = {
                attribute: 'RESET_PASSWORD_TOKEN',
                type: 'GENERIC',
              } as BeMappableErrorMessage;
              return of(resetPasswordError({error: genericError}));
            }
          })
        )
      )
    )
  );

  signupNewUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(signup),
      exhaustMap((action) =>
        this._dataService.signupNewUser(action.signupData).pipe(
          mapTo(signupSuccess()),
          catchError((err: HttpErrorResponse) => {
            // todo: this will get out of hand, make a unified concept for BE-Errormessages!
            if (err.status === 404) {
              // Special case: if there is a 404, the user tried to signup with an invalid campaign-key
              // this is treated like a 'Dead End'-Error, because he will never be able to signup this way and has to contact support
              return of(signupError());
            }
            // in every other error-case, a snackbar is sufficient
            return of(this.checkAndThrowSpecificError(err, [422]));
          })
        )
      )
    )
  );

  confirmAccount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(confirmAccount),
      exhaustMap((action) =>
        this._dataService.confirmUserAccount(action.token).pipe(
          mapTo(confirmAccountSuccess()),
          catchError((err: HttpErrorResponse) => {
            // BE always returns array with 1 element
            const error = err.error[0] as BeMappableErrorMessage;
            // we have to track this error in the component for further user-guidance!
            // also pass any specific error-messages the BE has thrown
            return of(confirmAccountError({error}));
          })
        )
      )
    )
  );

  verifySignupCampaign$ = createEffect(() =>
    this._actions$.pipe(
      ofType(verifySignupCampaign),
      exhaustMap((action) =>
        this._dataService.verifySignupCampaign(action.key).pipe(
          map((response: PostSignUpCampaignsByIdVerifyResponse) => verifySignupCampaignSuccess(response)),
          catchError((err: HttpErrorResponse) => {
            const found = err.status === 400;
            return of(verifySignupCampaignError({found}));
          })
        )
      )
    )
  );

  showSnacks$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(showSnackbar),
        tap((action) => {
          const message = action.message;
          const type = action.messageType;
          this._snackBar.open(message, this._translateService.instant('SNACKBAR.DISMISS_TEXT'), {
            duration: 3500,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            panelClass: type,
          });
        }),
        switchMapTo(EMPTY)
      ),
    {dispatch: false}
  );

  // simplify sending the acceptedLanguage with every call
  changeLanguage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(changeUserLanguage),
        map((action) => {
          if (!!action.language && allowedLanguages.includes(action.language)) {
            this._dataService.setLanguage(action.language);
          }
        })
      ),
    {dispatch: false}
  );

  // save preferences for the optional cookies to the backend
  putCookieSettings$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updatePrivacySettings),
        exhaustMap((action) =>
          this._dataService.setVisitorCookieSetting(action.settings).pipe(
            map(() => {
              // refresh browser to safely ensure that unwanted scripts won't load
              this._window.location.reload();
            })
          )
        )
      ),
    {dispatch: false}
  );

  // check if there is a flash added to the error. Check for error-code and throw specific message in given cases
  checkAndThrowSpecificError(err: HttpErrorResponse, codes: number[], fallbackLanguageKey = 'ERROR.GENERIC') {
    const flash = (err.error as BeErrorMessage).flash;
    if (codes.includes(err.status) && flash && flash.alert) {
      return showSnackbar({
        messageType: 'error',
        message: flash.alert,
      });
    }
    // else throw generic error
    return showSnackbar({
      messageType: 'error',
      message: this._translateService.instant(fallbackLanguageKey),
    });
  }
}

export interface BeMappableErrorMessage {
  attribute: string;
  type: string;
}

// extracts both parts of the error and builds a translate-key from it
export function getTranslateStringFromMappableError(error: BeMappableErrorMessage) {
  return `MAPPABLE_BE_ERROR.${error.attribute.toUpperCase()}.${error.type.toUpperCase()}`;
}
