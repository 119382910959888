import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {
  LinkSlide,
  PassiveContentSlide,
  PrismicLink,
} from 'src/app/backend/elearning-api/trainings/get-by-training-id-sessions-by-id.response';
import {PrismicHtmlPipe} from 'src/app/pipes/prismic-html.pipe';
import {EVENT_ACTIONS, EVENT_CATEGORIES, TrackingService} from 'src/app/shared/tracking.service';
import {GenericDialogComponent, GenericDialogData} from 'src/app/ui-components/generic-dialog/generic-dialog.component';
import {downloadInfoUpdate} from '../../user/user.actions';
import {selectDownloadInfoShown} from '../../user/user.selectors';

@Component({
  selector: 'app-prismic-link',
  templateUrl: './prismic-link.component.html',
  styleUrls: ['./prismic-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrismicLinkComponent implements OnChanges {
  @Input() data?: LinkSlide | PassiveContentSlide;
  @Input() nextLink?: string;
  @Input() backLink?: string;
  @Output() trackProgress = new EventEmitter();

  downloaded = false;
  loading = false;
  private readonly _htmlPipe = new PrismicHtmlPipe();
  // instead of passing the state down the whole chain only for this specific component, use store
  readonly showDownloadInfoDialog$ = this._store$.select(selectDownloadInfoShown);
  @ViewChild('downloadLink') hiddenLink?: ElementRef;

  constructor(
    private readonly _ref: ChangeDetectorRef,
    private readonly _router: Router,
    private readonly _store$: Store,
    private readonly _dialog: MatDialog,
    private readonly _translate: TranslateService,
    private readonly _tracker: TrackingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // data changes when user navigates from one linkslide to another
    if (changes.data) {
      // re-init after data changed
      this.downloaded = false;
      this.loading = false;
    }
  }

  isInternalDocumentUrl(url: string): boolean {
    // TODO: This is only a temporary solution for our current training structure
    if (!url) return false;

    return /^https:\/\/lernprogramme\.s3\.eu-central-1\.amazonaws\.com.*$/.test(url);
  }

  /**
   * Opens a dialog containing information about the downloaded PDFs.
   * Upon closing the dialog, triggers a click on a hidden link to start the download,
   * sets the dialog to 'seen' and marks the link_slide as downloaded
   */
  showInfoDialog() {
    // open the dialog
    const dialogData: GenericDialogData = {
      title: this._translate.instant('LINK_SLIDE.DIALOG.HEADLINE'),
      message: this._translate.instant('LINK_SLIDE.DIALOG.TEXT_PRE'),
      checkboxText: this._translate.instant('GENERIC.DO_NOT_SHOW_DIALOG'),
    };
    const dialog = this._dialog.open(GenericDialogComponent, {
      data: dialogData,
    });
    // make the dialog narrow
    dialog.addPanelClass('custom-narrow-dialog');

    // track event: Dialog is shown to user
    this._tracker.trackCustomEvent(EVENT_CATEGORIES.DOWNLOAD_DIALOG_INTERACTION, EVENT_ACTIONS.DOWNLOAD_DIALOG_SHOW);

    // listen on dialog-close
    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((persist: boolean) => {
        // trigger click on download-link
        this.hiddenLink?.nativeElement.click();
        // dialog should not be shown again, either set cookie or persist in DB, based on checkbox in dialog
        this._store$.dispatch(downloadInfoUpdate({persist}));
        // mark this link as downloaded, so user can proceed normally
        this.markAsDownloaded();
        // track event: User closed dialog, also track wether or not the checkbox was clicked!
        this._tracker.trackCustomEvent(
          EVENT_CATEGORIES.DOWNLOAD_DIALOG_INTERACTION,
          persist ? EVENT_ACTIONS.DOWNLOAD_DIALOG_CLOSE_BE : EVENT_ACTIONS.DOWNLOAD_DIALOG_CLOSE_COOKIE
        );
      });
  }

  markAsDownloaded() {
    this.loading = true;
    this.downloaded = true;
    // Trigger progress-tracking when user downloads the document
    this.trackProgress.emit();
    setTimeout(() => {
      this.loading = false;
      // have to mark for check
      this._ref.markForCheck();
    }, 1500);
  }

  continue() {
    if (this.nextLink) {
      this._router.navigateByUrl(this.nextLink);
    }
  }

  getPrismicHtmlText() {
    if (this.data?.type === 'Link_slide' && this.data.text) {
      return this._htmlPipe.transform(this.data.text);
    }
    if (this.data?.type === 'Passive_content_slide' && this.data.pre_text) {
      return this._htmlPipe.transform(this.data.pre_text);
    }
  }

  getTitle() {
    if (this.data?.title) {
      return this.data.title;
    }
    return this._translate.instant('INTRO_SLIDE.HEADER.LINK');
  }

  getUrl(): string | null {
    if (this.data?.type === 'Link_slide' && this.data?.links?.length) {
      return (this.data?.links[0].link as PrismicLink)?.url;
    }
    if (this.data?.type === 'Passive_content_slide' && this.data.document_url) {
      return this.data.document_url;
    }
    return null;
  }

  getSpecialSlide() {
    if (this.data?.type === 'Link_slide') {
      return this.data?.special_slide_type;
    }
  }

  isHandbook(): boolean {
    return this.data?.type === 'Passive_content_slide' && !!this.data.handbook;
  }

  getDownloadedText() {
    if (this.data?.type === 'Link_slide') {
      return this._translate.instant('LINK_SLIDE.TEXT_DOWNLOADED');
    }
    if (this.data?.type === 'Passive_content_slide') {
      return this._translate.instant('PASSIVE_CONTENT_SLIDE.TEXT_DOWNLOADED');
    }
    return null;
  }
}
