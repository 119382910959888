import {Component, OnDestroy, OnInit} from '@angular/core';
import {PostContactSalesRequest} from '@backend-api/user/post-contact-sales.request';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {expirationDialogShown, sendUserContactSales} from 'src/app/features/user/user.actions';
import {UserSlice} from 'src/app/features/user/user.reducer';
import {selectUserActive, selectUserContactedSales} from 'src/app/features/user/user.selectors';
import {bookingsActions, progressesActions, trainingActions} from '../../e-learning.actions';
import {ELearningSlice} from '../../e-learning.reducer';
import {ExtractedTopic, selectTopics, TopicWithTrainings} from '../../e-learning.selectors';
import {TopicFilterStore} from '../topic-filter/topic-filter.store';

@Component({
  selector: 'app-my-trainings',
  templateUrl: './my-trainings.component.html',
  styleUrls: ['./my-trainings.component.scss'],
  providers: [TopicFilterStore],
})
export class MyTrainingsComponent implements OnInit, OnDestroy {
  private readonly _referenceTopics$ = this._store.select(selectTopics({mode: 'available'}));
  private readonly _subscriptions = new Subscription();
  readonly topics$ = this._topicFilterStore.mappedSelectedTopics$;
  readonly selectedNonAvailable$ = this._topicFilterStore.selectedNonAvailableTopics$;

  // boolean whether or not the user has any topic containing an available training
  readonly emptyTopics$ = this._referenceTopics$.pipe(
    filter((topics) => !!topics && topics.length > 0),
    distinctUntilChanged(),
    map((topics) => topics.every((topic) => topic.accessibleTrainingsCount === 0))
  );
  readonly userActive$ = this._store.select(selectUserActive);
  readonly userContactedSales$ = this._store.select(selectUserContactedSales);

  constructor(
    private readonly _store: Store<ELearningSlice & UserSlice>,
    private readonly _topicFilterStore: TopicFilterStore,
    private readonly _translate: TranslateService
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
      // The component-store needs the IDs of the available topics
      this._referenceTopics$
        .pipe(
          filter((topics) => !!topics),
          // If the count of the topics change, reset this
          distinctUntilChanged((x, y) => {
            return x.length === y.length;
          })
        )
        .subscribe((topics) => {
          this._topicFilterStore.loadTopics(topics);
        })
    );

    this._store.dispatch(trainingActions.execute({params: undefined}));
    this._store.dispatch(progressesActions.execute({params: undefined}));
    this._store.dispatch(bookingsActions.execute({params: undefined}));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  trackTopic(index: number, topic: TopicWithTrainings) {
    return topic.id;
  }

  getTitles(topics: ExtractedTopic[]) {
    return (
      topics
        // get only the title for the currently used language, surround with quotes
        .map((topic) => `"${this._getTopicTitle(topic)}"`)
        // join string-array together using comma and space
        .join(', ')
        // replace last comma with translated 'and' for better readability. Will keep everything normal for 1 element
        .replace(/, ([^,]*)$/, ` ${this._translate.instant('GENERIC.AND')} $1`)
    );
  }

  private _getTopicTitle(topic: ExtractedTopic) {
    // TODO: this is just a duplicate, make a unDRY-solution for this!
    if (topic.generated && topic.title_en) {
      // in case this is generated, use the translator, as the title is set in the translations-file
      // indifferent whether de- or en-field is used, both contain the key in this case
      return this._translate.instant(topic.title_en);
    }
    // otherwise, the BE already delivers a translated title in the fields
    return this._translate.currentLang === 'de' ? topic.title_de : topic.title_en;
  }

  send(event: {selection: PostContactSalesRequest['type']}) {
    // remember that the user saw this, so the dialog won't open unwanted
    this._store.dispatch(expirationDialogShown({shown: true}));
    // user selected one of the presented options: send to BE
    this._store.dispatch(sendUserContactSales({selection: event.selection}));
  }
}
