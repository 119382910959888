/* tslint:disable */
import {NgModule} from '@angular/core';
import {ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {API_HOST} from './api-host';
import {QuestionnairesGateway} from './questionnaires/questionnaires.gateway';
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [QuestionnairesGateway],
})
export class GuestApiModule {
  static forRoot(apiHost: string): ModuleWithProviders<GuestApiModule> {
    return {
      ngModule: GuestApiModule,
      providers: [{provide: API_HOST, useValue: apiHost}],
    };
  }
}
