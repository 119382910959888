import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LiveTrainingSession} from 'src/app/backend/elearning-api/learning-paths/get-learning-paths.response';
import {TrainingLock, TrainingLockReason} from 'src/app/features/e-learning/learningpath/learningpath.component';

@Component({
  selector: 'app-livetraining-card',
  templateUrl: './livetraining-card.component.html',
  styleUrls: ['./livetraining-card.component.scss'],
})
export class LivetrainingCardComponent implements OnInit {
  @Input() liveTraining?: LiveTrainingSession;
  @Input() isLocked?: TrainingLock | null;
  expanded = false;
  startDate?: Date;
  endDate?: Date;

  constructor(private readonly _translate: TranslateService) {}

  ngOnInit(): void {
    if (this.liveTraining?.starts_at && this.liveTraining?.ends_at) {
      this.startDate = new Date(this.liveTraining.starts_at);
      this.endDate = new Date(this.liveTraining.ends_at);
    }
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  getMonth() {
    return this.startDate?.toLocaleDateString(this._translate.currentLang, {month: 'short'}) ?? '--';
  }

  getStartTime() {
    return this.startDate?.toLocaleTimeString(this._translate.currentLang, {hour: '2-digit', minute: '2-digit'}) ?? '--';
  }

  getEndTime() {
    return this.endDate?.toLocaleTimeString(this._translate.currentLang, {hour: '2-digit', minute: '2-digit'}) ?? '--';
  }

  getDay() {
    return this.startDate?.getDate() ?? '--';
  }

  getLockReason() {
    if (this.isLocked) {
      if (this.isLocked.reason === TrainingLockReason.OVER) {
        return 'done';
      }
      return 'undecided';
    }
  }

  getLockReasonTooltip() {
    if (this.isLocked) {
      return this._translate.instant(`LTS.LOCKED.${this.isLocked.reason}`);
    }
  }

  getIcsFile() {
    if (this.liveTraining?.ics_file) {
      return `data:text/calendar;base64,${btoa(this.liveTraining.ics_file)}`;
    }
    // for the pipe to be happy, can't occurr if correctly fetched in template
    return '';
  }
}
