import {createFeatureSelector, createSelector} from '@ngrx/store';
import {flatMap, get} from 'lodash-es';
import {extractObjects} from 'src/app/shared/data.service';
import {selectRouterState} from '../../shared/shared.selectors';
import {AssessmentState, AssessmentStateSlice} from './assessment.reducer';
import {QuestionViewModel} from './question.model';

export const selectPage = createSelector(selectRouterState, (state) => Number.parseInt(get(state, 'state.params.page') || '1', 10));
export const selectExternalToken = createSelector(selectRouterState, (state) => get(state, 'state.queryParams.token') || '');

export const selectAllSelfQuestions = (state: AssessmentStateSlice) =>
  flatMap(extractObjects(state.assessment.questionnaireSelf.questions), (questionReference) => {
    const matchedRating = state.assessment.questionnaireSelf.ratings.objects[questionReference.id];
    if (!matchedRating) return [];
    return [
      {
        id: questionReference.id,
        text: questionReference.text,
        rating: matchedRating.rating,
      },
    ];
  });
export const selectAllExternalQuestions = (state: AssessmentStateSlice) =>
  flatMap(extractObjects(state.assessment.questionnaireExternal.questions), (questionReference) => {
    const matchedRating = state.assessment.questionnaireExternal.ratings.objects[questionReference.id];
    if (!matchedRating) return [];
    return [
      {
        id: questionReference.id,
        text: questionReference.text,
        rating: matchedRating.rating,
      },
    ];
  });

function questionsForPage(questions: QuestionViewModel[], page: number) {
  const start = (page - 1) * 4;
  const end = start + 4;
  return questions.slice(start, end);
}

export const selectExternalQuestionsForPage = createSelector(selectAllExternalQuestions, selectPage, questionsForPage);

export const selectSelfQuestionsForPage = createSelector(selectAllSelfQuestions, selectPage, questionsForPage);

function getPageSummary(questions: QuestionViewModel[], page: number) {
  const ret = new Array<PageInfo>();
  for (let i = 0; i < questions.length / 4; i++) {
    const start = i * 4;
    const end = start + 4;
    ret.push({
      pageNo: i + 1,
      isCurrent: i + 1 === page,
      isComplete: questions.slice(start, end).every((question) => question.rating !== '-1'),
      isTouched: questions.slice(start, end).some((question) => question.rating !== '-1'),
    });
  }
  return ret;
}

export const selectPageSummarySelf = createSelector(selectAllSelfQuestions, selectPage, getPageSummary);

export const selectPageSummaryExternal = createSelector(selectAllExternalQuestions, selectPage, getPageSummary);

export const selectAssessmentState = createFeatureSelector<AssessmentState>('assessment');

export const selectExternalAssessments = createSelector(selectAssessmentState, (state) => state.externalAssessments);

// Helper-Interface for the fast-nav
// TODO: move?
export interface PageInfo {
  pageNo: number;
  isCurrent: boolean;
  isComplete: boolean;
  isTouched: boolean;
}
