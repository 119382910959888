import {Directionality} from '@angular/cdk/bidi';
import {CdkStep, CdkStepper} from '@angular/cdk/stepper';
import {DOCUMENT} from '@angular/common';
import {ChangeDetectorRef, Component, ElementRef, Inject, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {closeOnboarding} from '../store/actions';

@Component({
  selector: 'app-custom-stepper',
  templateUrl: `./custom-stepper.component.html`,
  styleUrls: [`./custom-stepper.component.scss`],
  providers: [{provide: CdkStepper, useExisting: CustomStepperComponent}],
})
export class CustomStepperComponent extends CdkStepper {
  @Input() slides: string[] = [];

  constructor(
    private readonly dir: Directionality,
    private readonly cdr: ChangeDetectorRef,
    private readonly el: ElementRef,
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    super(dir, cdr, el, _document);
  }

  get stepControlSteps(): CdkStep[] {
    return this.steps.toArray().splice(1);
  }

  onClick(index: number): void {
    this.selectedIndex = index;
  }

  close(): void {
    this.store.dispatch(closeOnboarding());
  }

  onSwipeLeft(): void {
    if (this.selectedIndex === this.stepControlSteps.length) {
      this.close();
    } else {
      this.next();
    }
  }

  onSwipeRight(): void {
    this.previous();
  }
}
