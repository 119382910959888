<div class="container my-2">
  <div class="row">
    <p class="col text-center" translate>COOKIE.BAR.NOTICE</p>
  </div>
  <div class="row mt-2 align-items-center justify-content-center">
    <p class="col col-sm-12 col-lg text-center mb-sm-2 mb-lg-0">
      <span translate>COOKIE.BAR.FURTHER</span>
      <a target="_blank" rel="noopener noreferrer" href="https://troodi.de/impressum/#ds" class="ms-1 textlink" translate
        >COOKIE.BAR.LINKTITLE</a
      >
    </p>
    <button class="col-12 col-sm-4 col-lg-3 mt-2 mt-sm-0 me-0 me-sm-2" mat-button color="accent" (click)="openSettings()">
      <span translate>COOKIE.BAR.SETTINGS</span>
    </button>
    <button class="col-12 col-sm-4 col-lg-3 mt-2 mt-sm-0" mat-button mat-flat-button color="accent" (click)="acceptAll()">
      <span translate>COOKIE.BAR.ACCEPT_ALL</span>
    </button>
  </div>
</div>
