/* tslint:disable */
import {NgModule} from '@angular/core';
import {ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {API_HOST} from './api-host';
import {SkillsGateway} from './skills/skills.gateway';
import {SkillProfilesGateway} from './skill-profiles/skill-profiles.gateway';
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [SkillsGateway, SkillProfilesGateway],
})
export class RecommendationApiModule {
  static forRoot(apiHost: string): ModuleWithProviders<RecommendationApiModule> {
    return {
      ngModule: RecommendationApiModule,
      providers: [{provide: API_HOST, useValue: apiHost}],
    };
  }
}
