<div *ngIf="skills$ | async as skills">
  <div class="skill-description">
    <p>
      Hier kannst Du Dein Kompetenzprofil einstellen. Verschiebe dazu einfach die blauen Slider.
      <br />
      Basierend darauf empfehlen wir Dir Lernprogramme!
    </p>
  </div>
  <div class="sticky-container">
    <div class="legend-dots">
      <div class="legend-item">
        <div class="dot self"></div>
        <span>Selbsteinschätzung</span>
      </div>
      <div class="legend-item">
        <div class="dot external"></div>
        <span>Fremdeinschätzung</span>
      </div>
    </div>

    <div class="legend-container">
      <div class="spacer"></div>
      <div class="legend">
        <div>Schwach</div>
        <div>Stark</div>
      </div>
    </div>
  </div>
  <div class="flexcontainer" *ngFor="let skill of skills">
    <div class="flexitem skill-text">
      <span class="skill-title">{{skill.name}}</span>
    </div>
    <div class="slider-wrapper">
      <mat-slider
        class="flexitem"
        [value]="skill.value"
        [max]="10"
        [min]="0"
        [thumbLabel]="true"
        [tickInterval]="1"
        (change)="onChange($event, skill.id)">
      </mat-slider>
      <div
        class="dot external"
        [ngClass]="{'intercept': isOverlaying(skill.selfValue, skill.externalValue), 'overlay': isOverlaying(skill.externalValue, skill.value)}"
        [style.left]="getPosition(skill.externalValue)"
        *ngIf="skill.externalValue !== -1"></div>
      <div
        class="dot self"
        [ngClass]="{'intercept': isOverlaying(skill.selfValue, skill.externalValue), 'overlay': isOverlaying(skill.selfValue, skill.value)}"
        [style.left]="getPosition(skill.selfValue)"
        *ngIf="skill.selfValue !== -1"></div>
    </div>
  </div>
</div>
