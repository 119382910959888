import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {
  collapseLeftOnLeaveAnimation,
  expandRightOnEnterAnimation,
  fadeInExpandRightOnEnterAnimation,
  fadeInOnEnterAnimation,
  fadeOutCollapseLeftOnLeaveAnimation,
} from 'angular-animations';
import {debounce} from 'lodash-es';
import {SearchStore} from '../search.store';

@Component({
  selector: 'app-trainings-search',
  templateUrl: './trainings-search.component.html',
  styleUrls: ['./trainings-search.component.scss'],
  animations: [
    expandRightOnEnterAnimation({duration: 100}),
    collapseLeftOnLeaveAnimation({duration: 100}),
    fadeInOnEnterAnimation({delay: 250}),
  ],
})
export class TrainingsSearchComponent {
  @Input() layout?: 'header' | 'subheader';

  // for setting focus
  @ViewChild('searchfield') searchfield?: ElementRef;

  private readonly _debounceSearch = debounce((value: string) => this._searchStore.setSearchTerm(value), 300);

  readonly searchTerm$ = this._searchStore.searchTerm$;
  readonly isSearch$ = this._searchStore.isSearch$;
  // The searchfield should be shown on button-interaction
  searchActive = false;

  constructor(private readonly _searchStore: SearchStore) {}

  search(input: string) {
    this._debounceSearch(input);
  }

  clearSearch() {
    // triggered by the 'delete'-button in the input
    // also triggers the `lostFocus`
    this._searchStore.setSearchTerm('');
  }

  openSearch() {
    this.searchActive = true;
    // a bit hacky, but needed: We want to set focus on the input after the user tapped the button
    // as the element is rendered as a reaction on above line, we have to wait a little bit for the element to exist!
    setTimeout(() => {
      if (!!this.searchfield) {
        (this.searchfield.nativeElement as HTMLInputElement).focus();
      }
    }, 200);
  }

  lostFocus(input: string) {
    // called by the input as soon as it loses focus.
    // if there is currently no input, this will remove the input and show the button again
    if (input.length === 0) {
      this.searchActive = false;
    }
  }
}
