import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {LeanSession} from '../../../backend/elearning-api/trainings/get-trainings.response';
import {Progress} from '../e-learning.selectors';

@Component({
  selector: 'app-training-session-card',
  templateUrl: './training-session-card.component.html',
  styleUrls: ['./training-session-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingSessionCardComponent {
  @Input() session?: LeanSession;
  @Input() index?: number;
  @Input() expanded?: boolean;
  @Input() sessionsCount?: number;
  @Input() sessionProgress?: Progress;
  @Input() locked?: boolean;
  @Output() openInfoDialogEmitter = new EventEmitter();

  constructor(private readonly _deviceService: DeviceDetectorService) {}

  isMobile() {
    return this._deviceService.isMobile();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  get number(): number | undefined {
    return this.session?.number;
  }

  get isFinal(): boolean {
    if (!this.sessionsCount || !this.index) return false;

    return this.index === this.sessionsCount - 1;
  }

  get isMilestone(): boolean {
    if (!this.session) return false;
    return !!this.session.milestone;
  }

  get percentage(): number {
    if (!this.sessionProgress || this.sessionProgress.total === 0) {
      return 0;
    }
    return Math.min((this.sessionProgress.current / this.sessionProgress.total) * 100, 100);
  }

  get isFinished(): boolean {
    if (!this.percentage) return false;

    return this.percentage === 100;
  }

  openInfoDialog() {
    this.openInfoDialogEmitter.emit();
  }
}
