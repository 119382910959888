import {Injectable} from '@angular/core';
import {UserGateway} from '@backend-api/user/user.gateway';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {EMPTY, of} from 'rxjs';
import {exhaustMap, map, withLatestFrom} from 'rxjs/operators';
import {userShowOnboarding} from '../../app.selectors';
import {checkOnboarding, closedOnboarding, closeOnboarding, openOnboarding} from './actions';

@Injectable()
export class OnboardingEffects {
  constructor(private readonly _actions$: Actions, private readonly _store$: Store, private readonly userGateway: UserGateway) {}

  checkOnboarding$ = createEffect(() =>
    this._actions$.pipe(
      ofType(checkOnboarding),
      withLatestFrom(this._store$.select(userShowOnboarding)),
      exhaustMap(([, showOnboarding]) => {
        return showOnboarding ? of(openOnboarding()) : EMPTY;
      })
    )
  );

  closeOnboarding$ = createEffect(() =>
    this._actions$.pipe(
      ofType(closeOnboarding),
      exhaustMap(() => this.userGateway.postShowOnboarding({show: false})),
      map((user) => closedOnboarding({user}))
    )
  );
}
