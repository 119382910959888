import {Action, createReducer, on} from '@ngrx/store';
import {cloneDeep} from 'lodash-es';
import {IndexedObjects, indexObjects} from '../../shared/data.service';
import {
  addExternalAssessment,
  loadExternalAssessmentsSuccess,
  loadQuestionsExternalSuccess,
  loadQuestionsSelfSuccess,
  setQuestionRatingExternal,
  setQuestionRatingSelf,
} from './assessment.actions';
import {ExternalAssessmentViewModel, Question} from './assessment.data.service';
import {QuestionRating} from './question.model';

export interface AssessmentStateSlice {
  assessment: AssessmentState;
}

export interface QuestionnaireViewModel {
  questions: IndexedObjects<Question, string>;
  ratings: IndexedObjects<QuestionRating, string>;
  id: string;
}

export interface AssessmentState {
  questionnaireSelf: QuestionnaireViewModel;
  questionnaireExternal: QuestionnaireViewModel;
  externalToken: string;
  externalAssessments: ExternalAssessmentViewModel[];
}

export const initialState: AssessmentState = {
  questionnaireSelf: {
    questions: {ids: [], objects: {}},
    ratings: {ids: [], objects: {}},
    id: '-1',
  },
  questionnaireExternal: {
    questions: {ids: [], objects: {}},
    ratings: {ids: [], objects: {}},
    id: '-1',
  },
  externalToken: '',
  externalAssessments: [],
};

const assessmentReducer = createReducer(
  initialState,
  on(setQuestionRatingSelf, (state, {id, value}) => {
    const newSelfRatings = cloneDeep(state.questionnaireSelf.ratings.objects);
    newSelfRatings[id] = {id, rating: value};
    return {
      ...state,
      questionnaireSelf: {
        ...state.questionnaireSelf,
        ratings: {
          ...state.questionnaireSelf.ratings,
          objects: newSelfRatings,
        },
      },
    };
  }),
  on(setQuestionRatingExternal, (state, {id, value}) => {
    const newExternalRatings = cloneDeep(state.questionnaireExternal.ratings.objects);
    newExternalRatings[id] = {id, rating: value};
    return {
      ...state,
      questionnaireExternal: {
        ...state.questionnaireExternal,
        ratings: {
          ...state.questionnaireExternal.ratings,
          objects: newExternalRatings,
        },
      },
    };
  }),
  on(loadQuestionsSelfSuccess, (state, {questionnaire}) => ({
    ...state,
    questionnaireSelf: {
      // as we get reference-data, index it at this point
      questions: indexObjects(
        questionnaire.questions,
        (q) => q.id,
        (q) => ({
          id: q.id,
          text: q.text,
        })
      ),
      id: questionnaire.id,
      // populate empty ratings with given ids
      ratings: indexObjects(
        questionnaire.questions,
        (q) => q.id,
        (q) => ({
          id: q.id,
          rating: '-1',
        })
      ),
    },
  })),
  on(loadQuestionsExternalSuccess, (state, {questionnaire, token}) => ({
    ...state,
    externalToken: token,
    questionnaireExternal: {
      // as we get reference-data, index it at this point
      questions: indexObjects(
        questionnaire.questions,
        (q) => q.id,
        (q) => ({
          id: q.id,
          text: q.text,
        })
      ),
      id: questionnaire.id,
      // populate empty ratings with given ids
      ratings: indexObjects(
        questionnaire.questions,
        (q) => q.id,
        (q) => ({
          id: q.id,
          rating: '-1',
        })
      ),
    },
  })),
  on(loadExternalAssessmentsSuccess, (state, {assessments}) => ({
    ...state,
    externalAssessments: assessments,
  })),
  on(addExternalAssessment, (state, {assessment}) => {
    const newExternalAssessments = cloneDeep(state.externalAssessments);
    newExternalAssessments.push(assessment);
    return {
      ...state,
      externalAssessments: newExternalAssessments,
    };
  })
);

export function reducer(state: AssessmentState | undefined, action: Action) {
  return assessmentReducer(state, action);
}
