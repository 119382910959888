<ng-template #template role="dialog" aria-label="Dialog" class="dialog-container">
  <div class="hover-content-wrapper p-3" #dialog *ngIf="training" [@fadeInOnEnter] [@fadeOutOnLeave]>
    <div class="card-header d-flex align-items-center justify-content-between">
      <div>
        <mat-icon svgIcon="language" class="me-2"></mat-icon>
        <span translate>{{getLanguageHeader()}}</span>
      </div>
      <button mat-icon-button (click)="close()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
    <div class="card-content training-keypoints d-flex flex-column" *ngIf="training.learnings?.length > 0">
      <span class="training-highlight d-flex my-2" *ngFor="let learning of training.learnings">
        <mat-icon svgIcon="check" class="me-2"></mat-icon>
        {{learning}}
      </span>
    </div>
    <div class="footer d-flex align-items-center">
      <app-training-duration class="d-flex" *ngIf="training.duration" [duration]="training.duration"> </app-training-duration>
      <div class="trainers ms-5" *ngIf="training.trainers.length > 0">
        <mat-icon svgIcon="trainer"></mat-icon>
        <span class="trainer-name" *ngFor="let trainer of training.trainers"> {{trainer.name}} </span>
      </div>
    </div>
  </div>
</ng-template>
