import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState} from './app.reducer';
import {User} from './features/user/user.data.service';
import {selectUser} from './features/user/user.selectors';

export const selectAppState = createFeatureSelector<AppState>('app');

export function getUserHomePath(user: User): string {
  if (user.entitlements.includes('assessment_access') || user.entitlements.includes('recommendation_access')) {
    // legacy: Users having access to the other parts of the app can access the old dashboard
    return '/dashboard';
  }
  if (user.has_learning_paths) {
    // users having learningpaths setup are redirected to them
    return '/e-learning/learningpaths';
  }
  if (user.has_dep_projects) {
    // users having dep-projects set up are redirected to them
    return '/e-learning/dep-projects';
  }
  // everyone else is directed to the trainings-list
  return '/e-learning/trainings/available';
}

export const selectUserHomePath = createSelector(selectUser, (user) => user && getUserHomePath(user));

export const userHasHybrid = createSelector(selectUser, (user) => user && !!user.has_learning_paths);
export const userShowOnboarding = createSelector(selectUser, (user) => user && !!user.show_onboarding);

export const getAppBlockerActive = createSelector(selectAppState, (state) => {
  return state.appBlockerActive;
});
