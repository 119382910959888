/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {PostSignUpCampaignsByIdVerifyResponse} from './post-sign-up-campaigns-by-id-verify.response';
import {PostSignUpCampaignsByIdVerifyRequest} from './post-sign-up-campaigns-by-id-verify.request';
@Injectable()
export class SignUpCampaignsVerifyGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  postSignUpCampaignsByIdVerify(
    id: string,
    body: PostSignUpCampaignsByIdVerifyRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostSignUpCampaignsByIdVerifyResponse> {
    return this._httpClient.request('post', this._apiHost + `/sign_up_campaigns/${id}/verify`, {
      ...options,
      observe: 'body',
      body,
    });
  }
}
