<div class="content-wrapper">
  <div class="illustration">
    <div class="bg">
      <img src="assets/img/undraw_under_construction.svg" alt="under construction icon" />
    </div>
  </div>
  <div class="content">
    <div class="title">Coming soon</div>
    <p *ngIf="title">Das Training '{{title}}' ist noch nicht fertig, wird aber in Zukunft verfügbar sein!</p>
    <p *ngIf="!title">Dieses Training ist noch nicht fertig, wird aber in Zukunft verfügbar sein!</p>
    <button mat-flat-button color="primary" (click)="backClicked()">Zurück</button>
  </div>
</div>
