<ng-container *ngIf="!!learningPath">
  <div class="hybrid py-3 py-xxl-5">
    <h1 class="hybrid-title p-3 pb-xxl-3 pb-2">{{learningPath.name}}</h1>
    <app-carousel #moduleCarousel>
      <div class="module" *ngFor="let module of learningPath.modules; trackBy: trackModule">
        <div class="module-title">
          <h2>{{module.name}}</h2>
        </div>
        <div class="trainings d-flex">
          <div
            *ngFor="let learningPathTraining of module.items"
            #trainingCards
            [attr.current]="isCurrentCard(learningPathTraining)"
            class="p-3 d-flex card">
            <ng-container *ngIf="learningPathTraining.type === 'training'; else lts">
              <ng-container *ngIf="learningPathTraining.training as training">
                <app-training-card
                  [training]="training"
                  [isLocked]="isLocked(learningPathTraining.starts_at)"
                  cdkOverlayOrigin
                  #CdkOverlayOrigin="cdkOverlayOrigin">
                </app-training-card>
              </ng-container>
            </ng-container>
            <ng-template #lts>
              <app-livetraining-card
                [liveTraining]="learningPathTraining"
                [isLocked]="isLocked(learningPathTraining.starts_at, learningPathTraining.ends_at)"></app-livetraining-card>
            </ng-template>
          </div>
        </div>
      </div>
    </app-carousel>
  </div>
</ng-container>
