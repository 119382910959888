import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input() total?: number;
  @Input() current?: number;
  // TODO: ugh... change this
  @Input() type?: 'small' | 'full';

  get currentProgress() {
    if (!this.current || !this.total) return '0%';

    const division = Math.min(this.current / this.total, 1);
    return `${Math.round(division * 100)}%`;
  }
}
