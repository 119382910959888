import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TitleSlide} from '../../../backend/elearning-api/trainings/get-by-training-id-sessions-by-id.response';
import {Training} from '../../../backend/elearning-api/trainings/get-trainings.response';
import {SlideUtilService} from '../slide-utils.service';

@Component({
  selector: 'app-title-slide',
  templateUrl: './title-slide.component.html',
  styleUrls: ['./title-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleSlideComponent implements OnInit {
  @Input() training?: Training;
  @Input() data?: TitleSlide;
  @Input() nextLink?: string;
  @Input() backLink?: string;

  constructor(private readonly _slideUtilService: SlideUtilService) {}

  ngOnInit() {
    this._slideUtilService.setNavbar(true);
  }

  getSessionDescription() {
    // TODO: Decide whether this should be solved on a higher layer
    if (this.training && this.data) {
      const id = this.data.id;
      const currentSession = this.training.sessions.find((session) => session.slides.map((slide) => slide.id).includes(id));
      return currentSession?.description;
    }
  }
}
