<ng-container *ngIf="certificateStateLoaded$ | async">
  <ng-container *ngIf="(certificateAvailable$ | async) as certificateAvailable; else notavailable">
    <app-slide-separator
      *ngIf="isIntroShown"
      [type]="data?.type"
      [title]="'CERTIFICATE_SLIDE.TITLE' | translate"
      [buttonText]="'CERTIFICATE_SLIDE.BUTTON.SHOW_CERTIFICATE' | translate"
      (buttonClicked)="hideIntro()"
      [backLink]="backLink">
    </app-slide-separator>

    <div *ngIf="!isIntroShown && training" class="certificate-embed container">
      <iframe class="d-none d-sm-block" [src]="certificateEmbedUrl() | trustUrl"></iframe>

      <div class="d-block d-sm-none">
        <h1 translate>CERTIFICATE_SLIDE.NOTE_MOBILES.HEADLINE</h1>
        <span translate>CERTIFICATE_SLIDE.NOTE_MOBILES.TEXT</span>
      </div>
      <div class="mt-4 d-flex flex-column flex-md-row justify-content-center align-items-center">
        <a mat-raised-button color="accent" [href]="certificateDownloadUrl()" class="file-download" target="_blank">
          <img src="assets/img/download.svg" class="link-icon" alt="download" />
          <span class="d-none d-sm-inline" translate>CERTIFICATE_SLIDE.BUTTON.DOWNLOAD_CERTIFICATE</span>
          <span class="d-inline d-sm-none" translate>CERTIFICATE_SLIDE.BUTTON.DOWNLOAD_CERTIFICATE_SHORT</span>
        </a>
        <a class="ms-0 ms-md-3 mt-4 mt-md-0" rel="noopener noreferrer" [href]="shareCertificateUrl(certificateAvailable)" target="_blank">
          <img
            src="https://download.linkedin.com/desktop/add2profile/buttons/{{linkedInLanguage}}.png"
            alt="LinkedIn Add to Profile button" />
        </a>
      </div>
    </div>
  </ng-container>

  <ng-template #notavailable>
    <span translate>CERTIFICATE_SLIDE.CERTIFICATE_NOT_AVAILABLE_YET</span>
  </ng-template>
</ng-container>
