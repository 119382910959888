import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {map, mapTo} from 'rxjs/operators';
import {ExternalAssessmentsGateway} from 'src/app/backend/assessment-api/external-assessments/external-assessments.gateway';
import {ExternalAssessment} from 'src/app/backend/assessment-api/external-assessments/get-external-assessments.response';
import {PostExternalAssessmentsRequest} from 'src/app/backend/assessment-api/external-assessments/post-external-assessments.request';
import {GetSelfResponse} from 'src/app/backend/assessment-api/questionnaires/get-self.response';
import {PostByQuestionnaireIdAnswerSetsRequest} from 'src/app/backend/assessment-api/questionnaires/post-by-questionnaire-id-answer-sets.request';
import {PostByQuestionnaireIdAnswerSetsResponse} from 'src/app/backend/assessment-api/questionnaires/post-by-questionnaire-id-answer-sets.response';
import {QuestionnairesGateway} from 'src/app/backend/assessment-api/questionnaires/questionnaires.gateway';
import {GetExternalResponse} from 'src/app/backend/guest-api/questionnaires/get-external.response';
import {PostByQuestionnaireIdAnswerSetsRequest as GuestPostByQuestionnaireIdAnswerSetsRequest} from 'src/app/backend/guest-api/questionnaires/post-by-questionnaire-id-answer-sets.request';
import {PostByQuestionnaireIdAnswerSetsResponse as GuestPostByQuestionnaireIdAnswerSetsResponse} from 'src/app/backend/guest-api/questionnaires/post-by-questionnaire-id-answer-sets.response';
import {QuestionnairesGateway as GuestQuestionnairesGateway} from 'src/app/backend/guest-api/questionnaires/questionnaires.gateway';
import {QuestionnaireViewModel} from './assessment.reducer';

// generate Type from generated interface (from json-schema)
export type Duration = PostExternalAssessmentsRequest['external_assessment']['duration'];
export interface DraftAssessment {
  name: string;
  emailAddresses: string[];
  duration: Duration;
}

export type AllowedAssessmentStatus = ExternalAssessment['status'];
export interface ExternalAssessmentViewModel {
  id: string;
  name: string;
  startDate: Date;
  endDate: null | Date;
  duration: number;
  remainingDays: number;
  participantsCount: number;
  answerSetsCount: number;
  status: AllowedAssessmentStatus;
}

@Injectable({
  providedIn: 'root',
})
export class AssessmentDataService {
  constructor(
    private readonly _questionnairesGateway: QuestionnairesGateway,
    private readonly _externalAssessmentsGateway: ExternalAssessmentsGateway,
    private readonly _guestQuestionnairesGateway: GuestQuestionnairesGateway
  ) {}

  getSelfQuestions(): Observable<Questionnaire> {
    return this._questionnairesGateway.getSelf({});
  }

  getExternalQuestions(token: string): Observable<Questionnaire> {
    return this._guestQuestionnairesGateway.getExternal({token});
  }

  postSelfAnswers(questionnaire: QuestionnaireViewModel): Observable<PostByQuestionnaireIdAnswerSetsResponse> {
    const body: PostByQuestionnaireIdAnswerSetsRequest = {
      answer_set: {
        answers_attributes: Object.values(questionnaire.ratings.objects).map((question) => {
          return {
            question_id: question.id,
            value: Number.parseInt(question.rating, 10),
          };
        }),
      },
    };
    return this._questionnairesGateway.postByQuestionnaireIdAnswerSets(questionnaire.id, body);
  }

  postExternalAnswers(questionnaire: QuestionnaireViewModel, token: string): Observable<GuestPostByQuestionnaireIdAnswerSetsResponse> {
    const body: GuestPostByQuestionnaireIdAnswerSetsRequest = {
      token,
      answer_set: {
        answers_attributes: Object.values(questionnaire.ratings.objects).map((question) => {
          return {
            question_id: question.id,
            value: Number.parseInt(question.rating, 10),
          };
        }),
      },
    };
    return this._guestQuestionnairesGateway.postByQuestionnaireIdAnswerSets(questionnaire.id, body);
  }

  getExternalAssessments(): Observable<ExternalAssessmentViewModel[]> {
    return this._externalAssessmentsGateway.getExternalAssessments({}).pipe(
      map((assessments) => {
        return assessments.map(mapToViewModel);
      })
    );
  }

  createExternalAssessment(assessment: DraftAssessment): Observable<ExternalAssessmentViewModel> {
    if (assessment.emailAddresses.length >= 3) {
      const mappedAssessment: PostExternalAssessmentsRequest = {
        external_assessment: {
          name: assessment.name,
          duration: assessment.duration,
          external_assessment_tokens_attributes: [
            // schema enforces at least three elements
            {email: assessment.emailAddresses[0]},
            {email: assessment.emailAddresses[1]},
            {email: assessment.emailAddresses[2]},
          ],
        },
      };
      // append all further addresses
      assessment.emailAddresses.slice(3).forEach((address) => {
        mappedAssessment.external_assessment.external_assessment_tokens_attributes.push({
          email: address,
        });
      });
      return this._externalAssessmentsGateway.postExternalAssessments(mappedAssessment).pipe(map(mapToViewModel));
    } else {
      return throwError('Need at least 3 participants');
    }
  }

  postFinishExternalAssessment(id: string): Observable<ExternalAssessmentViewModel> {
    return this._externalAssessmentsGateway.postByIdFinish(id, {}).pipe(map(mapToViewModel));
  }

  addExternalAssessmentParticipant(id: string, email: string): Observable<void> {
    return this._externalAssessmentsGateway.postByIdAddParticipant(id, {email}).pipe(mapTo(undefined));
  }
}

/**
 * Utility to map the BE's ExternalAssessment to the Viewmodel, as there are multiple fields and this is used several times
 * @param assessment An ExternalAssessment from the BE-API
 */
function mapToViewModel(assessment: ExternalAssessment): ExternalAssessmentViewModel {
  return {
    id: assessment.id,
    name: assessment.name,
    startDate: new Date(assessment.start_date),
    endDate: assessment.end_date ? new Date(assessment.end_date) : null,
    duration: assessment.duration,
    remainingDays: assessment.remaining_days,
    participantsCount: assessment.participants_count,
    answerSetsCount: assessment.answer_sets_count,
    status: assessment.status,
  };
}

export type Questionnaire = GetExternalResponse | GetSelfResponse;
export type Question = GetExternalResponse['questions'][0];
