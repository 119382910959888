import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {filter, take} from 'rxjs/operators';
import {getUserHomePath} from '../app.selectors';
import {loadUser} from '../features/user/user.actions';
import {UserSlice} from '../features/user/user.reducer';
import {selectUser} from '../features/user/user.selectors';
import {MappedBeError} from '../reset-password/reset-password.component';
import {confirmAccount, confirmAccountError, confirmAccountSuccess} from '../shared/shared.actions';
import {getTranslateStringFromMappableError} from '../shared/shared.effects';
import {SharedSlice} from '../shared/shared.reducer';
import {selectTokenParam} from '../shared/shared.selectors';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss'],
})
export class AccountActivationComponent implements OnInit {
  readonly token$ = this._store.select(selectTokenParam);
  readonly user$ = this._store.select(selectUser);
  isConfirmed = false;
  isLoading = false;
  error?: MappedBeError;

  constructor(
    private readonly _store: Store<SharedSlice & UserSlice>,
    private readonly _actions$: Actions,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    // first, setup the subscriptions to ensure that they're done when effects run
    this._actions$.pipe(ofType(confirmAccountSuccess), take(1)).subscribe(() => {
      this.isLoading = false;
      this.isConfirmed = true;
    });
    this._actions$.pipe(ofType(confirmAccountError), take(1)).subscribe((action) => {
      this.isLoading = false;
      this.isConfirmed = false;
      this.error = {
        message: getTranslateStringFromMappableError(action.error),
        attribute: action.error.attribute,
        type: action.error.type,
      };
    });

    // special use-case: some users bookmark the welcome-link or permanently use the welcome-mail to enter troodi
    // this leads to puzzled users, as they will get error-messages about the wrong key
    // So: if a user is already logged in, directly navigate to the home-path
    this.user$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe((user) => {
        // just ignore the other states, as the redirect has precedence!
        if (!!user) {
          this._router.navigateByUrl(getUserHomePath(user));
        }
      });

    // secondly, dispatch all the corresponsing actions for loading and checking
    // we have to check the user, so we may have to load him first from BE
    this._store.dispatch(loadUser());
    // fetch the confirmation-token from URL and validate in BE
    this.token$
      .pipe(
        filter((token) => !!token),
        take(1)
      )
      .subscribe((token) => {
        if (token && !this.isConfirmed) {
          this.isLoading = true;
          this._store.dispatch(confirmAccount({token}));
        }
      });
  }
}
