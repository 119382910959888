<div class="container" *ngIf="!!dialogData">
  <div class="headline">
    <h1 mat-dialog-title>{{ dialogData.title }}</h1>
  </div>
  <div mat-dialog-content class="content">
    <span class="dialog-message">{{ dialogData.message }}</span>
    <ng-container *ngIf="!!dialogData.suffix">
      <a class="textlink" href="mailto:info@troodi.de">info@troodi.de</a>
      <span class="dialog-message mailto-suffix">{{dialogData.suffix}}</span>
    </ng-container>
  </div>
  <mat-checkbox *ngIf="!!dialogData.checkboxText" class="mt-3" (change)="setChecked($event.checked)">
    {{dialogData.checkboxText}}
  </mat-checkbox>
  <div mat-dialog-actions>
    <button mat-flat-button color="accent" cdkFocusInitial (click)="close()">
      <span translate>TRAININGS.INFO_DIALOG.CLOSE</span>
    </button>
  </div>
</div>
