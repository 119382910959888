import {ActionReducerMap, createReducer, on} from '@ngrx/store';
import {createExecuteReducer, initialRS, ResourceState} from '@zeit-dev/ngrx-util';
import {cloneDeep} from 'lodash';
import {closedOnboarding} from '../../onboarding/store/actions';
import {
  downloadInfoActions,
  expirationDialogShown,
  loadDownloadInfoShownSuccess,
  loadUserError,
  loadUserSuccess,
  setUserData,
  setUserSuccess,
} from './user.actions';
import {User} from './user.data.service';

export interface UserSlice {
  user: UserState;
}

export interface UserState {
  user: ResourceState<User | undefined>;
  expirationDialogShown: ResourceState<boolean>;
  downloadInfoShown: ResourceState<boolean>;
}

export const initialState: UserState = {
  user: initialRS<User | undefined, void>(undefined),
  expirationDialogShown: initialRS<boolean, void>(false),
  downloadInfoShown: initialRS<boolean, void>(false),
};

export const reducers: ActionReducerMap<{
  user: UserState['user'];
  expirationDialogShown: UserState['expirationDialogShown'];
  downloadInfoShown: UserState['downloadInfoShown'];
}> = {
  user: createReducer(
    initialState['user'],
    on(loadUserSuccess, setUserSuccess, (state, {user}) => {
      return {
        ...state,
        loaded: true,
        loading: false,
        results: user,
      };
    }),
    on(setUserData, (state, {firstName, lastName}) => {
      if (state.loaded && !!state.results) {
        const updatedUser = cloneDeep(state.results);
        updatedUser.first_name = firstName;
        updatedUser.last_name = lastName;
        return {
          ...state,
          results: updatedUser,
        };
      }
      return {
        ...state,
        results: undefined,
      };
    }),
    on(loadUserError, (state) => {
      return {
        ...state,
        loaded: true,
        loading: false,
        results: undefined,
      };
    }),
    on(closedOnboarding, (state, {user}) => {
      return {
        ...state,
        results: user,
      };
    })
  ),
  expirationDialogShown: createReducer(
    initialState['expirationDialogShown'],
    on(expirationDialogShown, (state, {shown}) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results: shown,
      };
    })
  ),
  downloadInfoShown: createReducer(
    initialState['downloadInfoShown'],
    ...createExecuteReducer<boolean, UserState['downloadInfoShown']>(downloadInfoActions),
    on(loadDownloadInfoShownSuccess, (state, {show}) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results: show,
      };
    })
  ),
};
