import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Params} from '@angular/router';
import {findIndex, flatMap} from 'lodash-es';
import {LeanSession, Training} from 'src/app/backend/elearning-api/trainings/get-trainings.response';
import {UserTrainingProgress} from '../../../backend/elearning-api/user-training-progresses/get-user-training-progresses.response';
import {SessionResponse} from '../e-learning.data.service';
import {TrainingWithProgress} from '../e-learning.selectors';

@Component({
  selector: 'app-session-content',
  templateUrl: './session-content.component.html',
  styleUrls: ['./session-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInSlides', [
      // use * => * to cover all slide-Indices
      transition('* => *', [
        // short fade-In
        style({
          opacity: 0,
        }),
        animate(
          '500ms',
          style({
            opacity: 1,
          })
        ),
      ]),
    ]),
  ],
})
export class SessionContentComponent implements OnInit {
  @Input()
  session?: SessionResponse;

  @Input()
  training?: TrainingWithProgress;

  @Input()
  currentSessionId?: string;

  @Input()
  currentSlideId?: string;

  @Input()
  currentprogress?: UserTrainingProgress;

  @Input()
  navbarShown?: boolean;

  @Input()
  queryParams?: Params;

  private readonly _baseUrl = '/e-learning/trainings';

  private _flattenedSlides?: LeanSession['slides'];

  isTitleSlide(): boolean {
    return this.currentSlideId?.endsWith('title') || false;
  }

  isFirstSession(): boolean {
    return findIndex(this.training?.sessions, (session) => session.id === this.currentSessionId) === 0;
  }

  /**
   * Check if there is a previous session or slide
   */
  canGoBack(): boolean {
    if (!(this.isTitleSlide() && this.isFirstSession())) {
      return true;
    }
    return false;
  }

  /**
   * Check if there are further slides or sessions
   */
  canContinue(): boolean {
    return (
      this._flattenedSlides !== undefined &&
      this.currentSlideId !== undefined &&
      findIndex(this._flattenedSlides, (slide) => slide.id === this.currentSlideId) < this._flattenedSlides.length - 1
    );
  }

  /**
   * Get a tuple of {session, slide} pointing to the next slide.
   * Returns the current slide if there are none left.
   */
  getNextSlide() {
    if (
      !this.session ||
      this.currentSlideId === undefined ||
      this.currentSessionId === undefined ||
      !this.training ||
      !this.training.sessions
    )
      return;

    const sessionIndex = findIndex(this.training?.sessions, (session) => session.id === this.currentSessionId);
    const slideIndex = findIndex(this.session?.slides.ids, (id) => id === this.currentSlideId);
    const slideCount = this.session.slides.ids.length;
    let nextSessionId = this.currentSessionId;
    let nextSlideId = this.currentSlideId;
    // check if next slide would outbound this session
    if (slideIndex + 1 > slideCount - 1) {
      // check if there are sessions left
      if (sessionIndex < this.training.sessions.length - 1) {
        // next session available
        const nextSession = this.training.sessions[sessionIndex + 1];
        nextSessionId = nextSession.id;
        nextSlideId = nextSession.slides[0].id;
      }
    } else {
      // This session does have at least one more slide
      nextSlideId = this.session.slides.ids[slideIndex + 1];
    }
    return {nextSessionId, nextSlideId};
  }

  /**
   * Get a tuple of {session, slide} pointing to the previous slide.
   * Returns the current slide if there are none left.
   */
  getPreviousSlide() {
    if (
      !this.session ||
      this.currentSlideId === undefined ||
      this.currentSessionId === undefined ||
      !this.training ||
      !this.training.sessions
    )
      return;

    const sessionIndex = findIndex(this.training?.sessions, (session) => session.id === this.currentSessionId);
    const slideIndex = findIndex(this.session?.slides.ids, (id) => id === this.currentSlideId);
    let previousSessionId = this.currentSessionId;
    let previousSlideId = this.currentSlideId;
    // check if this slide is first of session (i.e. titleslide)
    if (slideIndex === 0) {
      // check if there are sessions left
      if (sessionIndex > 0) {
        // previous session available
        const previousSession = this.training.sessions[sessionIndex - 1];
        previousSessionId = previousSession.id;
        previousSlideId = previousSession.slides[previousSession.slides.length - 1].id;
      }
    } else {
      // This session does have at least one slide before this one
      previousSlideId = this.session.slides.ids[slideIndex - 1];
    }
    return {previousSessionId, previousSlideId};
  }

  /**
   * Build a URL to the slide defined by the parameters
   * @param sessionId
   * @param slideIndex
   */
  getSlideLink(sessionId: string, slideIndex: string) {
    if (!this.training) return;
    return `${this._baseUrl}/${this.training.slug}/${sessionId}/${slideIndex}`;
  }

  /**
   * Convenience-method for component
   */
  getNextLink() {
    const next = this.getNextSlide();
    if (!next) return;

    return this.getSlideLink(next.nextSessionId, next.nextSlideId);
  }

  /**
   * Convenience-method for component
   */
  getBackLink() {
    const previous = this.getPreviousSlide();
    if (!previous) return;

    return this.getSlideLink(previous.previousSessionId, previous.previousSlideId);
  }

  ngOnInit() {
    if (this.training) {
      this._flattenedSlides = flatMap(this.training.sessions, (session) => session.slides);
    }
  }

  getTrainingLink(training: Training): string | undefined {
    if (!training) return;

    return `/e-learning/trainings/${training.slug}`;
  }
}
