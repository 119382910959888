import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-slide-summary',
  templateUrl: './slide-summary.component.html',
  styleUrls: ['./slide-summary.component.scss'],
})
export class SlideSummaryComponent {
  @Input() icon?: string;
  @Input() title?: string;
  @Input() duration?: string;
  @Input() disabled?: boolean;
  @Input() link?: string;
  @Input() visited?: boolean;

  get iconPath(): string {
    return `assets/img/${this.icon}.svg`;
  }
}
