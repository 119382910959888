<ng-container *ngIf="cookiesEnabled$ | async; else cookiesBlocked">
  <ng-container [ngSwitch]="state">
    <app-slide-separator
      *ngSwitchCase="'INTRO'"
      [type]="data?.type"
      [title]="'QUIZ_SLIDE.TITLE' | translate"
      (buttonClicked)="startQuiz()"
      [buttonText]="'QUIZ_SLIDE.BUTTON.SHOW_QUIZ' | translate"
      [skipLink]="nextLink"
      [backLink]="backLink">
    </app-slide-separator>
    <app-slide-separator
      *ngSwitchCase="'RESULT'"
      [type]="data?.type"
      [title]="'QUIZ_SLIDE.TITLE' | translate"
      [introText]="getScoreText(typeformResponse)"
      (buttonClicked)="restartQuiz()"
      [buttonText]="'QUIZ_SLIDE.BUTTON.REDO_QUIZ' | translate"
      [skipLink]="nextLink"
      [backLink]="backLink"
      [skipText]="'SLIDE_NAVBAR.NEXT_SLIDE' | translate">
    </app-slide-separator>
    <div class="quiz-embed" id="embedTypeform" *ngSwitchCase="'QUIZ'" #embedTypeform></div>
    <div *ngSwitchCase="'LOADING'">
      <img class="troodi-spinner" src="assets/img/troodi_spinner.gif" />
    </div>
    <div class="quiz-embed" *ngSwitchCase="'ARTICULATE'">
      <iframe *ngIf="hasArticulateUrl" [src]="data?.url?.url | trustUrl"></iframe>
    </div>
  </ng-container>
</ng-container>
<ng-template #cookiesBlocked>
  <div class="cookies-blocked">
    <h2 translate>COOKIE.NOTICE_QUIZ</h2>
  </div>
</ng-template>
