import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TrainingWithProgress} from '../../e-learning.selectors';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent {
  @Input()
  readonly trainings?: TrainingWithProgress[];

  trackTraining(index: number, training: TrainingWithProgress) {
    return training.slug;
  }
}
