import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-create-note-dialog',
  templateUrl: './create-note-dialog.component.html',
  styleUrls: ['./create-note-dialog.component.scss'],
})
export class CreateNoteDialogComponent {
  constructor(private readonly _dialogRef: MatDialogRef<CreateNoteDialogComponent>) {
    this._dialogRef.addPanelClass('custom-wide-dialog');
  }

  close(noteText?: string) {
    this._dialogRef.close(noteText);
  }
}
