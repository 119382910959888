import {Component, Input} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Trainer, Training} from '../../../backend/elearning-api/trainings/get-trainings.response';

@Component({
  selector: 'app-trainer-card',
  templateUrl: './trainer-card.component.html',
  styleUrls: ['./trainer-card.component.scss'],
})
export class TrainerCardComponent {
  collapsed = true;

  @Input() trainer?: Trainer;
  @Input() otherTrainings?: Training[];

  constructor(private readonly _deviceService: DeviceDetectorService) {}

  isMobile() {
    return this._deviceService.isMobile();
  }

  toggleReadMore() {
    this.collapsed = !this.collapsed;
  }

  getTrainingLink(slug: string) {
    // TODO: put into some util/shared
    return `/e-learning/trainings/${slug}`;
  }
}
