import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FeatherModule} from 'angular-feather';
import {
  ArrowLeft,
  Award,
  Calendar,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Edit2,
  FileText,
  Filter,
  Info,
  List,
  Lock,
  MapPin,
  Menu,
  MessageCircle,
  PlusCircle,
  ShoppingBag,
  User,
  Users,
} from 'angular-feather/icons';

const materialModules = [
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatSlideToggleModule,
  MatListModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSliderModule,
  MatToolbarModule,
  MatTooltipModule,
  MatDialogModule,
  MatInputModule,
  MatSelectModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatMenuModule,
  MatStepperModule,
  MatCheckboxModule,
  MatBottomSheetModule,
  MatBadgeModule,
  MatChipsModule,
  MatDividerModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...materialModules,
    FeatherModule.pick({
      ArrowLeft,
      Award,
      Calendar,
      CheckCircle,
      ChevronDown,
      ChevronLeft,
      ChevronRight,
      ChevronUp,
      Edit2,
      FileText,
      Filter,
      Info,
      List,
      Lock,
      MapPin,
      Menu,
      MessageCircle,
      PlusCircle,
      ShoppingBag,
      User,
      Users,
    }),
  ],
  exports: [...materialModules, FeatherModule],
  declarations: [],
})
export class AppMaterialModule {}
