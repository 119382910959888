import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {initialiseHyphenation} from './hyphenation';

const WASM_MODULE_URL = '/portal/assets/wasm/de.wasm';

@Injectable({
  providedIn: 'root',
})
export class HyphenationService {
  private _initialisedHyphenationFunction: ((inputString: string) => string) | null = null;
  private _initialisingPromise: Promise<(inputString: string) => string> | null = null;

  constructor(private readonly _http: HttpClient) {}

  /**
   * This method fetches the Hyphenation Wasm module and initialises it. We
   * only fetch the module if needed and we also only fetch the German module
   * for now.
   */
  async hyphenateString(inputString: string) {
    if (this._initialisedHyphenationFunction !== null) {
      return this._initialisedHyphenationFunction(inputString);
    }

    if (this._initialisingPromise !== null) {
      const fn = await this._initialisingPromise;
      return fn(inputString);
    }

    this._initialisingPromise = this.downloadWasmModuleAndInitialise();
    this._initialisedHyphenationFunction = await this._initialisingPromise;

    return this._initialisedHyphenationFunction(inputString);
  }

  async downloadWasmModuleAndInitialise() {
    // TODO: deprecated and doesn't work with latest rxjs!
    // maybe we kinda have to revisit this whole hyphenopoly-part again...
    const bytes = await this._http.get(WASM_MODULE_URL, {responseType: 'arraybuffer'}).toPromise();
    const wasmModule = await WebAssembly.instantiate(bytes);
    return initialiseHyphenation(wasmModule);
  }
}
