import {Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AbstractAssessmentDialogComponent} from '../abstract-assessment-dialog/abstract-assessment-dialog.component';
import {createExternalAssessment} from '../assessment.actions';
import {DraftAssessment, Duration} from '../assessment.data.service';
import {AssessmentStateSlice} from '../assessment.reducer';

@Component({
  selector: 'app-assessment-new-dialog',
  templateUrl: './assessment-new-dialog.component.html',
  styleUrls: ['./assessment-new-dialog.component.scss', '../abstract-assessment-dialog/abstract-assessment-dialog.component.scss'],
})
export class AssessmentNewDialogComponent extends AbstractAssessmentDialogComponent<AssessmentNewDialogComponent> {
  // possible values according to defined type
  readonly possibleDurations: Duration[] = [5, 7, 10];

  name = '';
  emailAddresses = ['', '', ''];
  duration?: Duration;

  constructor(private readonly _store: Store<AssessmentStateSlice>, ref: MatDialogRef<AssessmentNewDialogComponent>) {
    super(ref);
  }

  listPosition(index: number): number {
    return index;
  }

  saveAssessment(form: NgForm) {
    if (form.valid) {
      this._store.dispatch(
        createExternalAssessment({
          assessment: {
            duration: this.duration,
            name: this.name,
            emailAddresses: this.emailAddresses,
          } as DraftAssessment,
        })
      );
      this._ref.close();
    }
  }

  addAddress() {
    this.emailAddresses.push('');
  }

  removeAddress(index: number) {
    this.emailAddresses.splice(index, 1);
  }

  isAddressRemovable() {
    return this.emailAddresses.length > 3;
  }

  cancel() {
    this._ref.close();
  }

  noEmailDuplicates() {
    // TODO: convert to form validation
    // no duplicate emails are allowed
    return this.emailAddresses.length === new Set(this.emailAddresses).size;
  }
}
