import {AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {CarouselComponent} from 'src/app/ui-components/carousel/carousel.component';
import {EnrichedDepProject, EnrichedDepProjectModule, EnrichedDepProjectTraining} from '../e-learning.selectors';
import {TrainingLock, TrainingLockReason} from '../learningpath/learningpath.component';

@Component({
  selector: 'app-dep',
  templateUrl: './dep.component.html',
  styleUrls: ['./dep.component.scss'],
})
export class DepComponent implements AfterViewInit {
  @Input() depProject?: EnrichedDepProject;

  // The carousel provides scrolling to a specific element
  @ViewChild('moduleCarousel') moduleCarousel?: CarouselComponent;
  @ViewChildren('trainingCards', {read: ElementRef}) trainingCards?: QueryList<ElementRef>;

  ngAfterViewInit() {
    if (this.trainingCards && this.moduleCarousel) {
      // every training/lts that has already started is a 'scroll-candidate'
      const candidates = this.trainingCards.filter((card) => card.nativeElement.getAttribute('current') === 'true');
      if (candidates.length > 0) {
        // get the last one, as this is the most current available training
        // tell the carousel to scroll this into view if needed upon initializing the view
        this.moduleCarousel.initialHorizontalScrollIntoView(candidates[candidates.length - 1].nativeElement);
      }
      // pass the cards as references to enable card-wise scrolling via the buttons
      this.moduleCarousel.setScrollTargets(this.trainingCards.toArray());
    }
  }

  /**
   * Returns either an enum-entry explaining why a card is not yet available or returns `null` if it is available.
   * @param startDate the models starts_at
   */
  isLocked(startDate: string): TrainingLock | null {
    const now = new Date(Date.now());
    const start = new Date(startDate);
    if (now < start) {
      return {reason: TrainingLockReason.NOT_STARTED, notice: start};
    }
    // everything's fine, trainings that have 'ended' should not be locked
    return null;
  }

  isCurrentCard(item: EnrichedDepProjectTraining) {
    // trainings that are currently available are potential candidates to be scrolled to
    if (new Date(item.starts_at) < new Date(Date.now()) && new Date(item.ends_at) > new Date(Date.now())) {
      return true;
    }
    return false;
  }

  trackModule(index: number, module: EnrichedDepProjectModule) {
    return module.name;
  }

  trackDepTraining(index: number, training: EnrichedDepProjectTraining) {
    return training.training.slug;
  }
}
