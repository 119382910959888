/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {PostPasswordResponse} from './post-password.response';
import {PostPasswordRequest} from './post-password.request';
import {PutPasswordResponse} from './put-password.response';
import {PutPasswordRequest} from './put-password.request';
@Injectable()
export class PasswordGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  postPassword(
    body: PostPasswordRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostPasswordResponse> {
    return this._httpClient.request('post', this._apiHost + '/password', {
      ...options,
      observe: 'body',
      body,
    });
  }

  putPassword(
    body: PutPasswordRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PutPasswordResponse> {
    return this._httpClient.request('put', this._apiHost + '/password', {
      ...options,
      observe: 'body',
      body,
    });
  }
}
