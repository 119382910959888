import {Component, OnDestroy} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {CookieSettingsDialogComponent} from '../cookie-settings-dialog/cookie-settings-dialog.component';
import {PrivacyService} from '../shared/privacy.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnDestroy {
  private readonly _subscriptions$ = new Subscription();

  constructor(
    private readonly _privacyService: PrivacyService,
    private readonly _ref: MatBottomSheetRef<CookieBannerComponent>,
    private readonly _dialog: MatDialog
  ) {}

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  acceptAll() {
    this._privacyService.acceptAll();
    this._ref.dismiss();
  }

  openSettings() {
    const dialogRef = this._dialog.open(CookieSettingsDialogComponent);

    // listen for acceptance of cookies and dismiss banner
    this._subscriptions$.add(
      dialogRef.afterClosed().subscribe((accepted) => {
        if (accepted) this._ref.dismiss();
      })
    );
  }
}
