<div class="container">
  <div class="headline">Fremdeinschätzung erstellen</div>
  <form #newAssessmentForm="ngForm" (ngSubmit)="saveAssessment(newAssessmentForm)">
    <div class="content">
      <mat-form-field appearance="standard">
        <mat-label>Name (z. B. "Freunde" oder "Kollegen")</mat-label>
        <input matInput id="name-input" name="name-input" [(ngModel)]="name" class="form-control" required #nameInput="ngModel" />
        <mat-error *ngIf="nameInput.invalid && (nameInput.dirty || nameInput.touched) && nameInput.errors?.required">
          Bitte einen Namen eingeben.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Laufzeit des Fragebogens</mat-label>
        <mat-select [(ngModel)]="duration" class="form-control" required #durationInput name="duration">
          <mat-option *ngFor="let duration of possibleDurations" [value]="duration"> {{duration}} Tage </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="form-field-wrapper" *ngFor="let address of emailAddresses; let i = index; trackBy: listPosition">
        <mat-form-field appearance="standard">
          <mat-label>E-Mail-Adresse</mat-label>
          <input
            matInput
            type="email"
            id="email-input_{{i}}"
            [(ngModel)]="emailAddresses[i]"
            class="form-control"
            required
            email
            #mail="ngModel"
            name="email-{{i}}" />
          <mat-error *ngIf="mail.invalid && (mail.dirty || mail.touched) && mail.errors?.required">
            Bitte eine E-Mail-Adresse eingeben.
          </mat-error>
          <mat-error *ngIf="mail.invalid && (mail.dirty || mail.touched) && mail.errors?.email">
            Bitte eine gültige E-Mail-Adresse eingeben.
          </mat-error>
        </mat-form-field>
        <button
          type="button"
          *ngIf="isAddressRemovable()"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Remove"
          (click)="removeAddress(i)">
          <img src="assets/img/x-circle.svg" alt="x-circle icon" />
        </button>
      </div>
    </div>
    <button type="button" mat-flat-button color="primary" (click)="addAddress()">+</button>
    <div mat-dialog-actions>
      <button type="button" mat-flat-button (click)="cancel()">Abbrechen</button>
      <button type="submit" [disabled]="newAssessmentForm.invalid || !noEmailDuplicates()" mat-flat-button color="primary">
        Speichern
      </button>
    </div>
  </form>
</div>
