import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {bookingsActions, progressesActions, trainingActions} from '../../e-learning.actions';
import {ELearningSlice} from '../../e-learning.reducer';
import {selectTrainingsWithProgress, TrainingWithProgress} from '../../e-learning.selectors';

@Component({
  selector: 'app-all-trainings',
  templateUrl: './all-trainings.component.html',
  styleUrls: ['./all-trainings.component.scss'],
})
export class AllTrainingsComponent implements OnInit {
  readonly sortedTrainings$ = this._store.select(selectTrainingsWithProgress);

  constructor(private readonly _store: Store<ELearningSlice>) {}

  ngOnInit(): void {
    this._store.dispatch(trainingActions.execute({params: undefined}));
    this._store.dispatch(progressesActions.execute({params: undefined}));
    this._store.dispatch(bookingsActions.execute({params: undefined}));
  }

  // TODO: now used at more components, export it somewhere...
  trackTraining(index: number, training: TrainingWithProgress) {
    return training.slug;
  }
}
