/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {PostSkillProfilesResponse} from './post-skill-profiles.response';
import {PostSkillProfilesRequest} from './post-skill-profiles.request';
import {GetCurrentResponse} from './get-current.response';
import {GetCurrentRequest} from './get-current.request';
import {GetBySkillProfileIdRecommendationsResponse} from './get-by-skill-profile-id-recommendations.response';
import {GetBySkillProfileIdRecommendationsRequest} from './get-by-skill-profile-id-recommendations.request';
@Injectable()
export class SkillProfilesGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  postSkillProfiles(
    body: PostSkillProfilesRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostSkillProfilesResponse> {
    return this._httpClient.request('post', this._apiHost + '/skill_profiles', {
      ...options,
      observe: 'body',
      body,
    });
  }

  getCurrent(
    queryParams: GetCurrentRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetCurrentResponse> {
    return this._httpClient.request('get', this._apiHost + '/skill_profiles/current', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }

  getBySkillProfileIdRecommendations(
    skill_profile_id: string,
    queryParams: GetBySkillProfileIdRecommendationsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetBySkillProfileIdRecommendationsResponse> {
    return this._httpClient.request('get', this._apiHost + `/skill_profiles/${skill_profile_id}/recommendations`, {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
