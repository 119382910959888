<button
  *ngIf="!searchActive && (isSearch$ | async) === false"
  mat-icon-button
  (click)="openSearch()"
  [@fadeInOnEnter]
  [class.small]="layout === 'header'">
  <mat-icon svgIcon="search"></mat-icon>
</button>
<!-- additional div is needed for the animation to correctly work -->
<div
  *ngIf="searchActive || !!(isSearch$ | async)"
  [@expandRightOnEnter]
  [@collapseLeftOnLeave]
  class="form-search"
  [class.small]="layout === 'header'">
  <mat-form-field appearance="outline">
    <input
      #searchfield
      type="search"
      matInput
      placeholder="{{'SEARCH.PLACEHOLDER' | translate}}"
      (input)="search($event.target.value)"
      [value]="searchTerm$ | async"
      (blur)="lostFocus($event.target.value)" />
    <div matSuffix class="search-suffix" *ngIf="(searchTerm$ | async)?.length > 0">
      <button mat-icon-button (click)="clearSearch()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </mat-form-field>
</div>
