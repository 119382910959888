<div class="carousel d-flex">
  <div class="scroller scroller-left" (click)="scroll('left')" *ngIf="isLeftShown">
    <i-feather name="chevron-left"></i-feather>
  </div>
  <div class="carousel-content" cdkScrollable #scrollContainer>
    <!-- Padders are used to enable 'overflow' of content without margin-hacking... -->
    <div class="d-none d-sm-block padder"></div>
    <ng-content></ng-content>
    <div class="d-none d-sm-block padder"></div>
  </div>
  <div class="scroller scroller-right" (click)="scroll('right')" *ngIf="isRightShown">
    <i-feather name="chevron-right"></i-feather>
  </div>
</div>
