import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {NestedEllipsisModule} from 'ngx-nested-ellipsis';
import {PlyrModule} from 'ngx-plyr';
import {AppMaterialModule} from 'src/app/app-material.module';
import {PipesModule} from '../../pipes/pipes.module';
import {SharedModule} from '../../shared/shared.module';
import {UiComponentsModule} from '../../ui-components/ui-components.module';
import {CertificateSlideComponent} from './certificate-slide/certificate-slide.component';
import {DepListComponent} from './dep-list/dep-list.component';
import {DepComponent} from './dep/dep.component';
import {ELearningEffects} from './e-learning.effects';
import {reducers} from './e-learning.reducer';
import {ExpirationDialogComponent} from './expiration-dialog/expiration-dialog.component';
import {ExpirationNoticeComponent} from './expiration-dialog/expiration-notice/expiration-notice.component';
import {InfoDialogComponent} from './info-dialog/info-dialog.component';
import {LearningPathListComponent} from './learningpath-list/learningpath-list.component';
import {LearningPathComponent} from './learningpath/learningpath.component';
import {NotesListComponent} from './notes-list/notes-list.component';
import {PrismicContainerComponent} from './prismic-container/prismic-container.component';
import {PrismicFloatimageComponent} from './prismic-floatimage/prismic-floatimage.component';
import {PrismicImageComponent} from './prismic-image/prismic-image.component';
import {PrismicLinkComponent} from './prismic-link/prismic-link.component';
import {PrismicQuizComponent} from './prismic-quiz/prismic-quiz.component';
import {PrismicRatingComponent} from './prismic-rating/prismic-rating.component';
import {PrismicTaskComponent} from './prismic-task/prismic-task.component';
import {PrismicTextComponent} from './prismic-text/prismic-text.component';
import {PrismicVideoComponent} from './prismic-video/prismic-video.component';
import {SessionContentComponent} from './session-content/session-content.component';
import {SessionSidebarComponent} from './session-sidebar/session-sidebar.component';
import {SessionWrapperComponent} from './session-wrapper/session-wrapper.component';
import {SessionComponent} from './session/session.component';
import {SingleTrainingTitleSectionComponent} from './single-training-title-section/single-training-title-section.component';
import {SingleTrainingComponent} from './single-training/single-training.component';
import {SlideSeparatorComponent} from './slide-separator/slide-separator.component';
import {TitleSlideComponent} from './title-slide/title-slide.component';
import {TrainerCardComponent} from './trainer-card/trainer-card.component';
import {TrainingCategoryComponent} from './training-category/training-category.component';
import {TrainingComingSoonComponent} from './training-coming-soon/training-coming-soon.component';
import {AllTrainingsComponent} from './training-list/all-trainings/all-trainings.component';
import {GroupedTrainingsComponent} from './training-list/grouped-trainings/grouped-trainings.component';
import {MyTrainingsComponent} from './training-list/my-trainings/my-trainings.component';
import {SearchResultsComponent} from './training-list/search-results/search-results.component';
import {TopicFilterComponent} from './training-list/topic-filter/topic-filter.component';
import {TrainingListComponent} from './training-list/training-list.component';
import {TrainingsSearchComponent} from './training-list/trainings-subheader/trainings-search/trainings-search.component';
import {TrainingsSubheaderComponent} from './training-list/trainings-subheader/trainings-subheader.component';
import {TrainingSessionCardComponent} from './training-session-card/training-session-card.component';
import {PrismicInteractiveContentComponent} from './prismic-interactive-content/prismic-interactive-content.component';
import {Cmi5TerminationSlideComponent} from './cmi5-termination-slide/cmi5-termination-slide.component';

@NgModule({
  declarations: [
    SingleTrainingComponent,
    SessionComponent,
    SessionWrapperComponent,
    SessionContentComponent,
    SessionSidebarComponent,
    TrainingComingSoonComponent,
    TrainingListComponent,
    TitleSlideComponent,
    CertificateSlideComponent,
    PrismicQuizComponent,
    PrismicContainerComponent,
    PrismicFloatimageComponent,
    PrismicImageComponent,
    PrismicLinkComponent,
    PrismicTextComponent,
    PrismicVideoComponent,
    PrismicTaskComponent,
    SingleTrainingTitleSectionComponent,
    TrainingSessionCardComponent,
    TrainerCardComponent,
    SlideSeparatorComponent,
    InfoDialogComponent,
    LearningPathListComponent,
    LearningPathComponent,
    TrainingCategoryComponent,
    ExpirationDialogComponent,
    PrismicRatingComponent,
    NotesListComponent,
    MyTrainingsComponent,
    AllTrainingsComponent,
    GroupedTrainingsComponent,
    TopicFilterComponent,
    TrainingsSubheaderComponent,
    SearchResultsComponent,
    TrainingsSearchComponent,
    ExpirationNoticeComponent,
    DepListComponent,
    DepComponent,
    PrismicInteractiveContentComponent,
    Cmi5TerminationSlideComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AppMaterialModule,
    FormsModule,
    SharedModule,
    PipesModule,
    StoreModule.forFeature('elearning', reducers),
    EffectsModule.forFeature([ELearningEffects]),
    PlyrModule,
    TranslateModule,
    UiComponentsModule,
    NestedEllipsisModule,
    OverlayModule,
  ],
  exports: [TrainingsSearchComponent],
})
export class ELearningModule {}
