import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

const CUSTOM_ICONS = [
  'calendar',
  'chapters',
  'chat',
  'check_nobg',
  'check',
  'clock',
  'close',
  'delete',
  'download',
  'edit',
  'expand',
  'final',
  'info',
  'language',
  'linkedin',
  // new lock-icon. maybe switch every occurrence of 'lock' for this, tbd
  'lock-filled',
  'lock',
  'map-pin',
  'menu',
  'milestone',
  'note-create',
  'note-edit',
  'play',
  'preview-play',
  'rating',
  'reduce',
  'search',
  'separator_anecdote',
  'separator_certificate',
  'separator_feedback',
  'separator_link',
  'separator_video',
  'separator_interactive',
  'separator_nextgen_quiz',
  'separator_protocol',
  'trainer',
  'user',
  'xing',
];

@NgModule({
  imports: [MatIconModule, HttpClientModule],
  exports: [MatIconModule],
})
export class IconsModule {
  constructor(private readonly _matIconRegistry: MatIconRegistry, private readonly _domSanitizer: DomSanitizer) {
    CUSTOM_ICONS.forEach((icon: string) => {
      this._matIconRegistry.addSvgIcon(icon, this._domSanitizer.bypassSecurityTrustResourceUrl(`/portal/assets/img/${icon}.svg`));
    });
  }
}
