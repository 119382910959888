import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

export enum COOKIE_TYPE {
  DOWNLOAD_INFO = 'download_info',
}

export interface GenericCookie {
  version: string;
  value: string | boolean | string[];
}

@Injectable({
  providedIn: 'root',
})
export class CookieHandlerService {
  private readonly _cookiePath = '/';
  private readonly COOKIE_VERSION = '74ca532336ea8f62789594f72f9d3e7d';

  constructor(private readonly _cookie: CookieService) {}

  saveCookie(type: COOKIE_TYPE, value: GenericCookie['value'], validityMonths = 12) {
    const newCookie: GenericCookie = {
      version: this.COOKIE_VERSION,
      value,
    };
    this._cookie.set(type, JSON.stringify(newCookie), this._calculateExpirationDate(validityMonths), this._cookiePath);
  }

  checkCookie(type: COOKIE_TYPE) {
    const readCookie = this._cookie.get(type);
    if (!!readCookie) {
      try {
        const parsed: GenericCookie = JSON.parse(readCookie);
        if (parsed.version === this.COOKIE_VERSION) {
          return parsed.value;
        } else {
          // delete the cookie
          this._cookie.delete(type, this._cookiePath);
          return undefined;
        }
      } catch (err) {
        // delete the cookie
        this._cookie.delete(type, this._cookiePath);
        return undefined;
      }
    }
  }

  private _calculateExpirationDate(months: number) {
    const now = new Date();
    return new Date(now.setMonth(now.getMonth() + months));
  }
}
