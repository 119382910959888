/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {PostTrainingsByIdUserTrainingRatingResponse} from './post-trainings-by-id-user-training-rating.response';
import {PostTrainingsByIdUserTrainingRatingRequest} from './post-trainings-by-id-user-training-rating.request';
import {GetTrainingsByIdUserTrainingRatingResponse} from './get-trainings-by-id-user-training-rating.response';
import {GetTrainingsByIdUserTrainingRatingRequest} from './get-trainings-by-id-user-training-rating.request';
@Injectable()
export class TrainingsUserTrainingRatingGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  postTrainingsByIdUserTrainingRating(
    id: string,
    body: PostTrainingsByIdUserTrainingRatingRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostTrainingsByIdUserTrainingRatingResponse> {
    return this._httpClient.request('post', this._apiHost + `/trainings/${id}/user_training_rating`, {
      ...options,
      observe: 'body',
      body,
    });
  }

  getTrainingsByIdUserTrainingRating(
    id: string,
    queryParams: GetTrainingsByIdUserTrainingRatingRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetTrainingsByIdUserTrainingRatingResponse> {
    return this._httpClient.request('get', this._apiHost + `/trainings/${id}/user_training_rating`, {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
