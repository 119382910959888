import {animate, query, stagger, style, transition, trigger} from '@angular/animations';

/* Staggered animation for lists of elements.
 * Every element will be faded in after another,
 * pass the list of elements to this animation in the component!
 */
export const staggerElementsAnimation = trigger('staggerElementsAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({
          opacity: 0,
        }),
        // wait between two elements
        stagger('100ms', [
          // fade in quickly
          animate(
            '200ms',
            style({
              opacity: 1,
            })
          ),
        ]),
      ],
      {optional: true}
    ),
  ]),
]);

/**
 * simple animation to fade the whole page (or elements) in
 */
export const fadeInAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('fadeInAnimation', [
    // route 'enter' transition
    transition(':enter', [
      // css styles at start of transition
      style({opacity: 0}),

      // animation and styles at end of transition
      animate('.3s', style({opacity: 1})),
    ]),
  ]);

/**
 * Simple animation to fade elements in and out
 */
export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  // 'enter' transition
  transition(':enter', [
    // css styles at start of transition
    style({opacity: 0}),
    // animation and styles at end of transition
    animate('.3s', style({opacity: 1})),
  ]),
  // 'leave' transition
  transition(':leave', [
    // animation and styles at end of transition
    animate('.3s', style({opacity: 0})),
  ]),
]);
