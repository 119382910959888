import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {fadeInExpandRightOnEnterAnimation, fadeInOnEnterAnimation, fadeOutCollapseLeftOnLeaveAnimation} from 'angular-animations';
import {SharedSlice} from 'src/app/shared/shared.reducer';
import {selectRouterUrl} from 'src/app/shared/shared.selectors';

@Component({
  selector: 'app-trainings-subheader',
  templateUrl: './trainings-subheader.component.html',
  styleUrls: ['./trainings-subheader.component.scss'],
  animations: [fadeInExpandRightOnEnterAnimation(), fadeOutCollapseLeftOnLeaveAnimation(), fadeInOnEnterAnimation()],
})
export class TrainingsSubheaderComponent {
  readonly links = [
    {
      url: '/e-learning/trainings/all',
      linktitle: 'NAVBAR.TRAININGS.ALL',
    },
    {
      url: '/e-learning/trainings/available',
      linktitle: 'NAVBAR.TRAININGS.AVAILABLE',
    },
    {
      url: '/e-learning/trainings/grouped',
      linktitle: 'NAVBAR.TRAININGS.GROUPED',
    },
  ];
  readonly currentRoute$ = this._store.select(selectRouterUrl);

  constructor(private readonly _store: Store<SharedSlice>) {}
}
