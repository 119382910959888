<div class="content">
  <div class="title">Übersicht Fremdeinschätzungen</div>
  <div class="card-list" *ngIf="(assessments$ | async) as assessments" [@staggerElementsAnimation]="assessments">
    <div class="card" [ngClass]="assessment.status" *ngFor="let assessment of assessments">
      <div class="diameter">
        <svg viewBox="0 0 32 32">
          <circle r="16" cx="16" cy="16" [style.stroke-dasharray]="getDiameter(assessment)" />
        </svg>
        <div class="diameter-inner">
          <div class="assessment-progress">{{assessment.answerSetsCount}} / {{assessment.participantsCount}}</div>
        </div>
      </div>
      <div class="assessment-name">{{assessment.name}}</div>

      <ng-container [ngSwitch]="assessment.status">
        <ng-container *ngSwitchCase="'active'">
          <div class="assessment-duration">{{getDurationString(assessment.remainingDays)}}</div>
          <button mat-icon-button (click)="addParticipantTo(assessment)" matTooltip="Weiteren Teilnehmer hinzufügen">
            <i-feather name="plus-circle"></i-feather>
          </button>
        </ng-container>
        <div *ngSwitchCase="'finished'" class="assessment-status">Beendet</div>
        <div *ngSwitchCase="'cancelled'" class="assessment-status">Abgebrochen</div>
      </ng-container>
      <button mat-stroked-button color="warn" *ngIf="showCancelButton(assessment)" (click)="openCancelDialog(assessment)">Beenden</button>
    </div>
  </div>
  <div class="actions">
    <button mat-flat-button color="accent" (click)="openAssessmentDialog()">Neue Fremdeinschätzung starten</button>
  </div>
</div>
