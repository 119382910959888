/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetCertificatesResponse} from './get-certificates.response';
import {GetCertificatesRequest} from './get-certificates.request';
@Injectable()
export class CertificatesGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getCertificates(
    queryParams: GetCertificatesRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetCertificatesResponse> {
    return this._httpClient.request('get', this._apiHost + '/certificates', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
