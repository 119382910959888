<div class="assessment-content">
  <div class="grid-header">
    <div class="header header-o1">Trifft überhaupt nicht zu</div>
    <div class="header header-o5">Trifft exakt zu</div>
  </div>
  <div class="grid-question-wrapper" [@staggerElementsAnimation]="questions">
    <div class="grid-question" *ngFor="let question of questions; trackBy: trackQuestion">
      <div class="question-text">{{question.text}}</div>
      <mat-radio-group [value]="question.rating" (change)="onChange($event, question.id)">
        <mat-radio-button class="o1" value="1"></mat-radio-button>
        <mat-radio-button class="o2" value="2"></mat-radio-button>
        <mat-radio-button class="o3" value="3"></mat-radio-button>
        <mat-radio-button class="o4" value="4"></mat-radio-button>
        <mat-radio-button class="o5" value="5"></mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="nav-bar">
    <a mat-flat-button class="nav-prev" [routerLink]="getPrevPage()" [disabled]="isFirst()" [class.link-disabled]="isFirst()"> Zurück </a>
    <a
      matRipple
      class="indicator"
      *ngFor="let summary of pageSummary; trackBy: trackPage"
      [class.indicator-current]="summary.isCurrent"
      [class.indicator-complete]="!summary.isCurrent && summary.isComplete"
      [class.indicator-incomplete]="!summary.isCurrent && !summary.isComplete && summary.isTouched"
      [routerLink]="getPageLink(summary.pageNo)"
      [matTooltip]="getIndicatorTooltipText(summary)">
      {{summary.pageNo}}
    </a>
    <a mat-flat-button class="nav-next" *ngIf="!isLast()" color="accent" [routerLink]="getNextPage()">Weiter</a>
    <button
      mat-flat-button
      class="nav-next"
      *ngIf="isLast()"
      color="accent"
      (click)="sendAnswers()"
      [class.link-disabled]="!isAssessmentComplete()"
      [disabled]="!isAssessmentComplete()">
      Fertig stellen
    </button>
  </div>
</div>
