import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {get} from 'lodash-es';
import {PostNotesRequest} from 'src/app/backend/elearning-api/notes/post-notes.request';
import {EmbeddedVideoSlide} from 'src/app/backend/elearning-api/trainings/get-by-training-id-sessions-by-id.response';
import {TrainingWithProgress} from '../e-learning.selectors';
import {SlideUtilService} from '../slide-utils.service';

@Component({
  selector: 'app-prismic-video',
  templateUrl: './prismic-video.component.html',
  styleUrls: ['./prismic-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrismicVideoComponent implements OnChanges {
  @Input() training?: TrainingWithProgress;
  @Input() data?: EmbeddedVideoSlide;
  @Input() nextLink?: string;
  @Input() backLink?: string;
  @Input() queryParams?: string;
  @Output() trackProgress = new EventEmitter();
  isIntroShown = true;
  timestamp?: number;
  readonly notes$ = this._slideUtilService.getCurrentNotes();

  constructor(private readonly _slideUtilService: SlideUtilService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      // reset if user navigates between two video-slides
      this.isIntroShown = true;
      // also, if there are query-params, handle this page differently
      // needed in the onChanges incase a user has the path video->notes->video
      if (!!this.queryParams) {
        const timestampParam = get(this.queryParams, 'timestamp') as undefined | string;
        if (!!timestampParam) {
          this.timestamp = Number.parseFloat(timestampParam);
          this.hideIntro();
        }
      }
    }
  }

  handleContinueClick() {
    this.hideIntro();
  }

  hideIntro() {
    this.isIntroShown = false;
    this.trackProgress.emit();
    // navbar should be shown during video
    this._slideUtilService.setNavbar(true);
  }

  addNewNote(e: {data: PostNotesRequest}) {
    this._slideUtilService.addNote(e.data);
  }

  editNote(e: {id: string; note: string}) {
    this._slideUtilService.editNote(e.id, e.note);
  }

  deleteNote(e: {id: string}) {
    this._slideUtilService.deleteNote(e.id);
  }
}
