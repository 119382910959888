import {GetCertificatesResponse} from '@backend-api/certificates/get-certificates.response';
import {GetDepProjectsResponse} from '@backend-api/dep-projects/get-dep-projects.response';
import {LearningPath} from '@backend-api/learning-paths/get-learning-paths.response';
import {Note} from '@backend-api/notes/get-notes.response';
import {PostNotesRequest} from '@backend-api/notes/post-notes.request';
import {GetQuizzesByTypeformIdResponse} from '@backend-api/quizzes/get-quizzes-by-typeform-id.response';
import {GetTrainingBookingsResponse} from '@backend-api/training-bookings/get-training-bookings.response';
import {UserTrainingRating} from '@backend-api/trainings-user-training-rating/post-trainings-by-id-user-training-rating.request';
import {Training} from '@backend-api/trainings/get-preview.response';
import {UserTrainingProgress} from '@backend-api/user-training-progresses/get-user-training-progresses.response';
import {createAction, props} from '@ngrx/store';
import {createExecuteActions, ExecuteActions} from '@zeit-dev/ngrx-util';
import {IndexedObjects} from 'src/app/shared/data.service';
import {SessionResponse} from './e-learning.data.service';

export const trainingActions: ExecuteActions<IndexedObjects<Training, string>> = createExecuteActions('trainings');
export const progressesActions: ExecuteActions<IndexedObjects<UserTrainingProgress, string>> =
  createExecuteActions('userTrainingProgresses');
export const notesActions: ExecuteActions<IndexedObjects<Note, string>> = createExecuteActions('userNotes');
export const learningPathActions: ExecuteActions<IndexedObjects<LearningPath, string>> = createExecuteActions('learningPaths');
export const bookingsActions: ExecuteActions<GetTrainingBookingsResponse> = createExecuteActions('bookings');
export const depProjectsActions: ExecuteActions<GetDepProjectsResponse> = createExecuteActions('depProjects');

export const checkCurrentTraining = createAction('[ELEARNING] Check current training');

export const loadSessionContent = createAction('[ELEARNING] Load Session Content');

export const loadSessionContentSuccess = createAction(
  '[ELEARNING] Load Session Content Success',
  props<{slug: string; sessionId: string; sessionContent: SessionResponse}>()
);

export const loadError = createAction('[ELEARNING] Load Error');

export const updateUserTrainingProgress = createAction(
  '[ELEARNING] Update User Training Progress',
  props<{slug: string; slideId: string}>()
);

export const updateUserTrainingProgressSuccess = createAction(
  '[ELEARNING] Update User Training Progress Success',
  props<{userTrainingProgress: UserTrainingProgress}>()
);

export const initializeProgress = createAction('[ELEARNING] Initialize User Training Progress', props<{slug: string}>());

export const loadQuizResult = createAction('[ELEARNING] Load Quiz Result', props<{typeformQuizId: string}>());

export const loadQuizResultForEventId = createAction(
  '[ELEARNING] Load Quiz Result For Event Id',
  props<{typeformQuizId: string; typeformEventId: string}>()
);

export const loadQuizResultSuccess = createAction('[ELEARNING] Load Quiz Result Success', props<GetQuizzesByTypeformIdResponse>());

export const invalidateTrainings = createAction('[ELEARNING] Invalidate Trainings');

export const loadPreviews = createAction('[ELEARNING] Change Preview loading');

export const addNote = createAction('[ELEARNING] Add Note', props<{note: PostNotesRequest}>());

export const deleteNote = createAction('[ELEARNING] Delete Note', props<{id: string}>());

export const editNote = createAction('[ELEARNING] Edit Note', props<{id: string; text: string}>());

export const loadCertificateAvailability = createAction('[ELEARNING] Load certificate availability for all trainings');

// We need both forced and idempotent loading for certificates
export const loadCertificateAvailabilityIfNeeded = createAction('[ELEARNING] Idempotent load certificate availability for all trainings');

export const loadCertificateAvailabilitySuccess = createAction(
  '[ELEARNING] Load certificate availability for all trainings success',
  props<{certificates: GetCertificatesResponse}>()
);

// this basically just says 'nothing was done, but it was done successfully' :)
export const idempotentCertificateSuccess = createAction('[ELEARNING] Certificate availability for all trainings idempotent success');

export const loadUserTrainingRatings = createAction('[ELEARNING] Load user ratings for training', props<{slug: string}>());

export const loadUserTrainingRatingsSuccess = createAction(
  '[ELEARNING] Load user ratings for training success',
  props<{slug: string; rating: UserTrainingRating | null}>()
);

export const loadUserTrainingRatingsError = createAction('[ELEARNING] Load user ratings for training error');

export const updateUserTrainingRating = createAction(
  '[ELEARNING] Update user rating for training',
  props<{slug: string; rating: UserTrainingRating}>()
);

export const updateUserTrainingRatingSuccess = createAction(
  '[ELEARNING] Update user rating for training success',
  props<{slug: string; rating: UserTrainingRating}>()
);
