import {ChangeDetectionStrategy, Component} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {staggerElementsAnimation} from 'src/app/animations';
import {RecommendedTraining} from 'src/app/backend/recommendation-api/skill-profiles/get-by-skill-profile-id-recommendations.response';
import {RecommendationSlice} from '../recommendation.reducer';
import {selectTopMatches} from '../recommendation.selectors';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [staggerElementsAnimation],
})
export class RecommendationsComponent {
  readonly trainings$: Observable<RecommendedTraining[]> = this._store.pipe(select(selectTopMatches));

  constructor(private readonly _store: Store<RecommendationSlice>) {}

  trackTraining(index: number, training: RecommendedTraining): string {
    return training.training_id;
  }

  getTrainingLink(id: string): string {
    return `/e-learning/trainings/${id}`;
  }
}
