/* tslint:disable */
import {NgModule} from '@angular/core';
import {ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {API_HOST} from './api-host';
import {PasswordGateway} from './password/password.gateway';
import {RegistrationGateway} from './registration/registration.gateway';
import {ConfirmationGateway} from './confirmation/confirmation.gateway';
import {OrderableTrainingsGateway} from './orderable-trainings/orderable-trainings.gateway';
import {CouponsVerifyGateway} from './coupons-verify/coupons-verify.gateway';
import {OrderGateway} from './order/order.gateway';
import {VisitorCookieSettingGateway} from './visitor-cookie-setting/visitor-cookie-setting.gateway';
import {SignUpCampaignsVerifyGateway} from './sign-up-campaigns-verify/sign-up-campaigns-verify.gateway';
import {OrderCampaignsVerifyGateway} from './order-campaigns-verify/order-campaigns-verify.gateway';
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    PasswordGateway,
    RegistrationGateway,
    ConfirmationGateway,
    OrderableTrainingsGateway,
    CouponsVerifyGateway,
    OrderGateway,
    VisitorCookieSettingGateway,
    SignUpCampaignsVerifyGateway,
    OrderCampaignsVerifyGateway,
  ],
})
export class PublicUserApiModule {
  static forRoot(apiHost: string): ModuleWithProviders<PublicUserApiModule> {
    return {
      ngModule: PublicUserApiModule,
      providers: [{provide: API_HOST, useValue: apiHost}],
    };
  }
}
