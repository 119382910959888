<div class="container py-4" *ngIf="(notes$ | async) as notes">
  <div class="d-flex my-3 justify-content-between">
    <h1 class="my-0" translate>NOTELIST.HEADLINE</h1>
    <a mat-button color="accent" [href]="notesPdfLink()" class="textlink download">
      <mat-icon class="me-2" svgIcon="download"></mat-icon>
      <span translate>NOTELIST.DOWNLOAD.ALL</span>
    </a>
  </div>
  <app-collapsible-card class="mb-4 mb-sm-5" *ngFor="let training of notes">
    <ng-container header> {{training.trainingTitle}} </ng-container>
    <ng-container body>
      <div class="training-download">
        <a mat-button color="accent" [href]="notesPdfLink(training.training)" class="textlink download">
          <mat-icon class="me-2" svgIcon="download"></mat-icon>
          <span translate>NOTELIST.DOWNLOAD.SINGLE</span>
        </a>
      </div>
      <div class="slide" *ngFor="let slide of training.slideNotes">
        <h3>{{slide.slideTitle}}</h3>
        <div class="note py-3" *ngFor="let note of slide.notes; trackBy: trackNote">
          <app-editable-note [note]="note" (deleteNoteEvent)="deleteNote($event)" (saveNoteEvent)="editNote($event)"> </app-editable-note>
        </div>
      </div>
    </ng-container>
  </app-collapsible-card>
  <div
    *ngIf="notes.length === 0"
    class="empty-content d-flex flex-column flex-grow-1 justify-content-center align-items-center text-center m-5 p-3">
    <p class="fw-bold" translate>NOTES.EMPTY_EMPH.LIST</p>
    <p translate>NOTES.EMPTY_INSTRUCT.LIST</p>
  </div>
</div>
