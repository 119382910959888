import {Component, Input} from '@angular/core';
import {Training} from '../../../backend/elearning-api/trainings/get-trainings.response';

@Component({
  selector: 'app-single-training-title-section',
  templateUrl: './single-training-title-section.component.html',
  styleUrls: ['./single-training-title-section.component.scss'],
})
export class SingleTrainingTitleSectionComponent {
  @Input()
  training?: Training;

  learningsAvailable(training: Training): boolean {
    return !!training.learnings && training.learnings.length > 0;
  }
}
