import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-training-duration',
  templateUrl: './training-duration.component.html',
  styleUrls: ['./training-duration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingDurationComponent {
  @Input() duration?: number;
  @Input() color?: 'default' | 'accent';

  constructor(private readonly _translateService: TranslateService) {}

  getDuration() {
    // duration is in minutes,
    // we want to show hours and minutes
    const hours = this._translateService.instant('DURATION.HOURS');
    const mins = this._translateService.instant('DURATION.MINUTES');
    if (this.duration) {
      let str = `${Math.floor(this.duration / 60)} ${hours}`;
      const minutes = this.duration % 60;
      if (minutes !== 0) {
        str += ` ${minutes} ${mins}`;
      }
      return str;
    }
  }

  get iconClass(): string {
    return this.color || 'default';
  }
}
