import {createReducer, on} from '@ngrx/store';
import {closedOnboarding, openOnboarding} from './actions';

export interface OnboardingState {
  isOpen: boolean;
}

export const initialState: OnboardingState = {
  isOpen: false,
};

export const reducers = createReducer(
  initialState,
  on(openOnboarding, (state) => ({
    ...state,
    isOpen: true,
  })),
  on(closedOnboarding, (state) => ({
    ...state,
    isOpen: false,
  }))
);
