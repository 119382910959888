import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {from, Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getUserHomePath} from './app.selectors';
import {loadUser, loadUserError} from './features/user/user.actions';
import {UserDataService} from './features/user/user.data.service';
import {UserSlice} from './features/user/user.reducer';
import {setAfterSignInPath, showSnackbar} from './shared/shared.actions';
import {SharedSlice} from './shared/shared.reducer';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly _store: Store<UserSlice | SharedSlice>,
    private readonly _router: Router,
    private readonly _dataService: UserDataService,
    private readonly _translateService: TranslateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // to check whether the user is still logged in or not, we have to always check back with the BE!
    return this._dataService.loadUser().pipe(
      switchMap((user) => {
        // this guard should also ensure that the user is loaded in the state, trigger idempotent loading
        this._store.dispatch(loadUser());
        // running into this, user is already logged in
        // redirect based on the data available
        if (state.url === '/') {
          // accessing the root-path should redirect logged-in users to their homepage
          return from(this._router.navigateByUrl(getUserHomePath(user)));
        }
        // allow every other navigation
        return of(true);
      }),
      catchError((error: HttpErrorResponse) => {
        // pass this error to the reducer, so user is now logged out considered by state
        this._store.dispatch(loadUserError({message: error.message}));
        if (state.url !== '/') {
          // also, open a snackbar informing the user
          // if the path is anything protected
          // root-path should be excluded from this message
          this._store.dispatch(
            showSnackbar({
              messageType: 'error',
              message: this._translateService.instant('ERROR.SESSION_LOST'),
            })
          );
        }
        // BE throws error if the user isn't signed in. Thus, user can't proceed!
        // store the current path and redirect the user to the login
        // the stored path will be used after the user successfully logged in
        this._store.dispatch(setAfterSignInPath({path: state.url}));
        return from(this._router.navigateByUrl('/login'));
      })
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // in order to correctly check every routing, we have to check this on every child
    // just call the parent-function
    return this.canActivate(route, state);
  }
}
