import {animate, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CarouselComponent} from 'src/app/ui-components/carousel/carousel.component';
import {Language} from '../../user/user.data.service';
import {ExtractedTopic, TopicWithTrainings, TrainingWithProgress} from '../e-learning.selectors';

@Component({
  selector: 'app-training-category',
  templateUrl: './training-category.component.html',
  styleUrls: ['./training-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('cardsAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '150ms',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '150ms',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class TrainingCategoryComponent implements AfterViewInit {
  @Input() topic?: TopicWithTrainings;
  @Input() shownTrainings?: string[];

  // The carousel provides scrolling to a specific element
  @ViewChild('trainingCarousel') trainingCarousel?: CarouselComponent;
  @ViewChildren('trainingCards', {read: ElementRef}) trainingCards?: QueryList<ElementRef>;

  private readonly _usedLanguage = this._translate.currentLang as Language;

  constructor(private readonly _translate: TranslateService) {}

  ngAfterViewInit() {
    if (this.trainingCards && this.trainingCarousel) {
      // pass the cards as references to enable card-wise scrolling via the buttons
      this.trainingCarousel.setScrollTargets(this.trainingCards.toArray());
    }
  }

  trackTraining(index: number, training: TrainingWithProgress) {
    return training.slug;
  }

  /**
   * Returns the german or english title for the given topic based on the currently used language (app-wide)
   */
  getTopicTitle(topic: ExtractedTopic) {
    if (topic.generated && topic.title_en) {
      // in case this is generated, use the translator, as the title is set in the translations-file
      // indifferent whether de- or en-field is used, both contain the key in this case
      return this._translate.instant(topic.title_en);
    }
    // otherwise, the BE already delivers a translated title in the fields
    return this._usedLanguage === 'de' ? topic.title_de : topic.title_en;
  }
}
