import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PrismicEmbed} from '@backend-api/trainings/get-preview.response';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent {
  video?: PrismicEmbed;

  constructor(@Inject(MAT_DIALOG_DATA) private readonly _data: PrismicEmbed, private readonly _ref: MatDialogRef<VideoDialogComponent>) {
    this._ref.addPanelClass('custom-video-dialog');
    this.video = this._data;
  }

  close() {
    this._ref.close();
  }
}
