import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SlideUtilService} from '../slide-utils.service';

@Component({
  selector: 'app-slide-separator',
  templateUrl: './slide-separator.component.html',
  styleUrls: ['./slide-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideSeparatorComponent implements OnInit {
  @Input() type?:
    | 'Video_slide'
    | 'Link_slide'
    | 'Quiz_slide'
    | 'Certificate_slide'
    | 'Title_slide'
    | 'User_training_rating_slide'
    | 'Interactive_content_slide'
    | 'Passive_content_slide';
  @Input() specialSlide?: 'extrawissen' | 'fotoprotokoll' | 'anekdote' | 'quiz' | 'handbook';
  @Input() title?: string;
  @Input() introText?: string;
  @Input() url?: string;
  @Input() buttonText?: string;
  @Input() loading?: boolean;
  @Input() skipLink?: string;
  @Input() skipText?: string;
  @Input() backLink?: string;
  // TODO: this is more of a workaround for the special case for link-slides triggering a dialog
  @Input() dialogButton?: boolean;

  @Output() readonly buttonClicked = new EventEmitter();

  constructor(private readonly _slideUtilService: SlideUtilService) {}

  ngOnInit(): void {
    // Separators should always hide the navbar!
    this._slideUtilService.setNavbar(false);
  }

  onButtonClick() {
    this.buttonClicked.emit();
  }
}
