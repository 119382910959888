/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {PostRegistrationResponse} from './post-registration.response';
import {PostRegistrationRequest} from './post-registration.request';
@Injectable()
export class RegistrationGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  postRegistration(
    body: PostRegistrationRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostRegistrationResponse> {
    return this._httpClient.request('post', this._apiHost + '/registration', {
      ...options,
      observe: 'body',
      body,
    });
  }
}
