export const troodiPalette = {
  primaryBlue: '#015A91',
  primaryRed: '#F95F4E',
  secondaryDark: '#012438',
  secondaryMedium: '#A3B3C1',
  secondaryLight: '#E1EEF9',
  secondaryBlue: '#0380BF',
  success: '#63BA2B',
  accent: '#F7BF07',
  error: '#B22D18',
};
