<div *ngIf="liveTraining" [class.locked]="!!isLocked" class="card">
  <div *ngIf="!!isLocked" class="lock">
    <div class="lock-icon">
      <ng-container [ngSwitch]="getLockReason()">
        <mat-icon *ngSwitchCase="'done'" svgIcon="check_nobg"></mat-icon>
        <mat-icon *ngSwitchCase="'undecided'" svgIcon="lock-filled"></mat-icon>
      </ng-container>
    </div>
    <span class="mt-3 mt-md-2 mx-3 mx-md-2 tooltip">{{getLockReasonTooltip()}}</span>
  </div>
  <div class="lts-header" *ngIf="liveTraining.name">
    <div class="date d-flex">
      <span class="day">{{getDay()}}</span>
      <span class="month">{{getMonth()}}</span>
    </div>
    <div class="title-time">
      <span class="title"> {{liveTraining.name}} </span>
      <div class="time">
        <mat-icon svgIcon="clock" class="me-2"></mat-icon>
        <span translate [translateParams]="{from: getStartTime(), to: getEndTime()}">LTS.TIME</span>
      </div>
    </div>
  </div>
  <div class="lts-info d-flex">
    <div class="trainer subtitle d-flex flex-column mb-3">
      <ng-container [ngSwitch]="liveTraining.trainer_gender">
        <span *ngSwitchCase="'m'" translate>LTS.WITH_TRAINER.MALE</span>
        <span *ngSwitchCase="'f'" translate>LTS.WITH_TRAINER.FEMALE</span>
        <!-- In unspecified case (wanted, wrong data, ...), print a generic headline -->
        <span *ngSwitchDefault translate>LTS.WITH_TRAINER.UNDEFINED</span>
      </ng-container>
      <span class="name" *ngIf="liveTraining.trainer_name">{{liveTraining.trainer_name}}</span>
      <span class="name" *ngIf="!liveTraining.trainer_name">---</span>
    </div>
    <div class="loc">
      <mat-icon class="me-2" svgIcon="map-pin"></mat-icon>
      <ng-container *ngIf="liveTraining.location; else virtualloc"> {{liveTraining.location}} </ng-container>
      <ng-template #virtualloc>
        <span translate>LTS.VIRTUAL</span>
      </ng-template>
    </div>
    <div *ngIf="!!liveTraining.ics_file" class="reserve mt-2">
      <mat-icon class="me-2" svgIcon="calendar"></mat-icon>
      <a class="textbutton" [href]="getIcsFile() | trustUrl">
        <span translate>LTS.RESERVE</span>
      </a>
    </div>
    <div class="coop mt-2" *ngIf="liveTraining.logo_url">
      <p translate>LTS.COOPERATION_WITH</p>
      <img [src]="liveTraining.logo_url" />
    </div>
    <ng-container *ngIf="liveTraining.appointment_type === 'virtual'">
      <a
        mat-flat-button
        color="accent"
        class="mt-3"
        [class.disabled]="!liveTraining.url"
        [disabled]="!liveTraining.url"
        [href]="liveTraining.url"
        target="_blank"
        rel="noopener noreferrer">
        <span translate>LTS.LINK</span>
      </a>
    </ng-container>
  </div>
</div>
