export type FetchOptions = string[];

export const cloudinaryUrl = 'https://res.cloudinary.com';

export function getFetchUrl(host: string, url: string, options: FetchOptions): string {
    return [
        cloudinaryUrl,
        host,
        'image/fetch',
        ...(options.length ? [options.join(',')] : []),
        url,
    ].join('/');
}
