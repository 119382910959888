import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GetMeResponse} from '@backend-api/user/get-me.response';
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from 'angular-animations';

@Component({
  selector: 'app-contact-float',
  templateUrl: './contact-float.component.html',
  styleUrls: ['./contact-float.component.scss'],
  animations: [fadeInOnEnterAnimation({duration: 200}), fadeOutOnLeaveAnimation({duration: 200})],
})
export class ContactFloatComponent {
  @Input()
  user?: GetMeResponse;

  @Output()
  readonly openOnboardingTour = new EventEmitter();

  contactWindowOpened = false;

  toggleContactWindowOpened() {
    this.contactWindowOpened = !this.contactWindowOpened;
  }

  openOnboardingTourClicked() {
    this.toggleContactWindowOpened();
    this.openOnboardingTour.emit();
  }
}
