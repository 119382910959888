import {PostCouponsByIdVerifyResponse} from '@backend-api/coupons-verify/post-coupons-by-id-verify.response';
import {PostOrderCampaignsByIdVerifyResponse} from '@backend-api/order-campaigns-verify/post-order-campaigns-by-id-verify.response';
import {OrderableTraining} from '@backend-api/orderable-trainings/get-orderable-trainings.response';
import {ActionReducerMap, createReducer, on} from '@ngrx/store';
import {createExecuteReducer, initialRS, ResourceState} from '@zeit-dev/ngrx-util';
import {
  checkCouponCode,
  couponInvalid,
  couponValid,
  orderDispatched,
  orderFailed,
  sendOrder,
  trainingActions,
  verifyOrderCampaign,
  verifyOrderCampaignError,
  verifyOrderCampaignSuccess,
} from './order.actions';

export interface OrderSlice {
  order: OrderState;
}

export interface OrderState {
  orderableTrainings: ResourceState<OrderableTraining[] | undefined>;
  coupon: ResourceState<PostCouponsByIdVerifyResponse | undefined>;
  // TODO: currently nothing gets returned, just use a boolean - we need the loading-state for decisions
  order: ResourceState<string | undefined>;
  orderCampaign: ResourceState<PostOrderCampaignsByIdVerifyResponse | undefined>;
}

export const initialState: OrderState = {
  orderableTrainings: initialRS<OrderableTraining[] | undefined, void>([]),
  coupon: initialRS<PostCouponsByIdVerifyResponse | undefined, void>(undefined),
  order: initialRS<string | undefined, void>(undefined),
  orderCampaign: initialRS<PostOrderCampaignsByIdVerifyResponse | undefined, void>(undefined),
};

export const reducers: ActionReducerMap<{
  orderableTrainings: OrderState['orderableTrainings'];
  coupon: OrderState['coupon'];
  order: OrderState['order'];
  orderCampaign: OrderState['orderCampaign'];
}> = {
  orderableTrainings: createReducer(
    initialState['orderableTrainings'],
    ...createExecuteReducer<OrderableTraining[], OrderState['orderableTrainings']>(trainingActions)
  ),
  coupon: createReducer(
    initialState['coupon'],
    on(checkCouponCode, (state) => {
      return {
        ...state,
        loaded: undefined,
        loading: true,
        results: undefined,
        failed: undefined,
      };
    }),
    on(couponValid, (state, {couponData}) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results: couponData,
      };
    }),
    on(couponInvalid, (state) => {
      return {
        ...state,
        loading: false,
        failed: true,
      };
    })
  ),
  order: createReducer(
    initialState['order'],
    on(sendOrder, (state) => {
      return {
        ...state,
        loading: true,
        loaded: undefined,
        results: undefined,
        failed: undefined,
      };
    }),
    on(orderDispatched, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        results: undefined,
        failed: false,
      };
    }),
    on(orderFailed, (state, {error}) => {
      return {
        ...state,
        loading: false,
        loaded: false,
        results: error,
        failed: true,
      };
    })
  ),
  orderCampaign: createReducer(
    initialState['orderCampaign'],
    on(verifyOrderCampaign, (state) => {
      return {
        ...state,
        loading: true,
        loaded: undefined,
        results: undefined,
        failed: undefined,
      };
    }),
    on(verifyOrderCampaignSuccess, (state, {campaignData}) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        failed: false,
        results: campaignData,
      };
    }),
    on(verifyOrderCampaignError, (state) => {
      return {
        ...state,
        loading: false,
        loaded: false,
        results: undefined,
        failed: true,
      };
    })
  ),
};
