<div class="container">
  <div class="headline" translate>EXPIRED_USER.DIALOG.TITLE</div>
  <div class="content d-flex flex-column">
    <span translate>EXPIRED_USER.DIALOG.DESCRIPTION_TOP</span>
    <mat-chip-list class="col-12 my-3" selectable>
      <mat-chip color="primary" (click)="select(1)" [selected]="selected === 1">
        <span translate>EXPIRED_USER.DIALOG.SELECTION.ORDER</span>
      </mat-chip>
      <mat-chip color="primary" (click)="select(2)" [selected]="selected === 2">
        <span translate>EXPIRED_USER.DIALOG.SELECTION.CONTACT</span>
      </mat-chip>
    </mat-chip-list>
    <div>
      <span translate>EXPIRED_USER.DIALOG.DESCRIPTION_BOTTOM.PRE</span>
      <a class="textlink" href="mailto:info@troodi.de">info@troodi.de</a>
      <span translate>EXPIRED_USER.DIALOG.DESCRIPTION_BOTTOM.POST</span>
    </div>
  </div>
  <div mat-dialog-actions class="mt-3">
    <a *ngIf="hasCancelButton" mat-flat-button (click)="cancel()">
      <span translate>EXPIRED_USER.DIALOG.DISCARD</span>
    </a>
    <button [disabled]="selected === 0" mat-flat-button color="accent" cdkFocusInitial (click)="send()">
      <span translate>EXPIRED_USER.DIALOG.SEND</span>
    </button>
  </div>
</div>
