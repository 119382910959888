import {Routes} from '@angular/router';
import {DepListComponent} from './dep-list/dep-list.component';
import {LearningPathListComponent} from './learningpath-list/learningpath-list.component';
import {NotesListComponent} from './notes-list/notes-list.component';
import {SessionComponent} from './session/session.component';
import {SingleTrainingComponent} from './single-training/single-training.component';
import {AllTrainingsComponent} from './training-list/all-trainings/all-trainings.component';
import {GroupedTrainingsComponent} from './training-list/grouped-trainings/grouped-trainings.component';
import {MyTrainingsComponent} from './training-list/my-trainings/my-trainings.component';
import {TrainingListComponent} from './training-list/training-list.component';

export type TrainingsSubroutes = 'all' | 'available' | 'grouped';

export const elearningRoutesProtected: Routes = [
  {
    path: 'e-learning',
    children: [
      {
        path: 'trainings',
        component: TrainingListComponent,
        children: [
          {
            path: 'all',
            component: AllTrainingsComponent,
          },
          {
            path: 'available',
            component: MyTrainingsComponent,
          },
          {
            path: 'grouped',
            component: GroupedTrainingsComponent,
          },
        ],
      },
      {
        path: 'trainings/:trainingSlug',
        component: SingleTrainingComponent,
      },
      {
        path: 'trainings/:trainingSlug/:sessionId/:slideId',
        component: SessionComponent,
        data: {hideHeader: true, hideContactFloat: true},
      },
      {
        path: 'learningpaths',
        component: LearningPathListComponent,
      },
      {
        path: 'notes',
        component: NotesListComponent,
      },
      {
        path: 'dep-projects',
        component: DepListComponent,
      },
    ],
  },
];
