import {Component} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {sendResetMail} from '../shared/shared.actions';
import {SharedSlice} from '../shared/shared.reducer';

export const validateEmail: ValidatorFn = (control) => {
  if (!control.value) return null;

  const EMAIL_REGEXP = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

  return EMAIL_REGEXP.test(control.value)
    ? null
    : {
        validateEmail: {
          valid: false,
        },
      };
};

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, validateEmail]),
  });

  constructor(private readonly _store: Store<SharedSlice>) {}

  sendResetMail() {
    const email = this.forgotPasswordForm.value['email'] as string;
    this._store.dispatch(sendResetMail({email}));
  }

  get email() {
    return this.forgotPasswordForm.get('email');
  }
}
