<div class="content-wrapper">
  <div class="illustration">
    <div class="bg">
      <img src="assets/img/404.svg" alt="not found icon" />
    </div>
  </div>
  <div class="content">
    <div class="title">404</div>
    <p>Die von Ihnen angeforderte Seite ist nicht verfügbar!</p>
    <a [routerLink]="[]" (click)="goBack()" class="link">Zurück</a>
  </div>
</div>
