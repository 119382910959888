import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATED} from '@ngrx/router-store';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import confetti from 'canvas-confetti';
import {combineLatest, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, first, map, take, withLatestFrom} from 'rxjs/operators';
import {fadeInAnimation, fadeInOutAnimation} from 'src/app/animations';
import {selectQueryParams, selectRouteParamsSessionId, selectRouteParamsSlideId} from '../../../shared/shared.selectors';
import {downloadInfoActions} from '../../user/user.actions';
import {Language} from '../../user/user.data.service';
import {
  bookingsActions,
  loadCertificateAvailabilityIfNeeded,
  loadSessionContent,
  loadUserTrainingRatings,
  notesActions,
  progressesActions,
  trainingActions,
} from '../e-learning.actions';
import {ELearningSlice} from '../e-learning.reducer';
import {
  selectCurrentSessionContent,
  selectCurrentTrainingWithProgress,
  selectCurrentUserTrainingProgress,
  selectTrainingsState,
} from '../e-learning.selectors';
import {SlideUtilService} from '../slide-utils.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
  animations: [fadeInAnimation, fadeInOutAnimation],
  providers: [SlideUtilService],
})
export class SessionComponent implements OnInit, OnDestroy {
  readonly session$ = this._store.select(selectCurrentSessionContent);
  readonly training$ = this._store.select(selectCurrentTrainingWithProgress);
  readonly trainingState$ = this._store.select(selectTrainingsState);
  readonly trainingCheck$ = combineLatest([this.training$, this.trainingState$]).pipe(
    first(([training, trainingState]) => !!trainingState.loaded && training !== undefined),
    map(([training]) => {
      return !!training;
    })
  );
  readonly currentSessionId$ = this._store.select(selectRouteParamsSessionId);
  readonly currentSlideId$ = this._store.select(selectRouteParamsSlideId);
  readonly currentprogress$ = this._store.select(selectCurrentUserTrainingProgress);
  readonly queryParams$ = this._store.select(selectQueryParams);

  private readonly _subscriptions = new Subscription();
  readonly navbarShown$ = this._slideUtilService.navbarShown;
  private _userLanguage?: Language;
  private _trainingLanguage?: Language;
  private _trainingProgress?: number;

  congratsText?: string;

  constructor(
    private readonly _store: Store<ELearningSlice>,
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _slideUtilService: SlideUtilService,
    private readonly _translate: TranslateService
  ) {}

  ngOnInit() {
    // Used when the user is navigating within a training
    this._subscriptions.add(
      this._actions$.pipe(ofType(ROUTER_NAVIGATED), withLatestFrom(this.session$)).subscribe(([, session]) => {
        // if this session is not already loaded, load it into the state
        if (!session) {
          this._store.dispatch(loadSessionContent());
        }
      })
    );

    // we need to redirect in case the requested training does not exist
    this._subscriptions.add(
      this.trainingCheck$.subscribe((existing) => {
        if (!existing) {
          this._router.navigate(['notfound'], {skipLocationChange: true});
        }
      })
    );

    this._subscriptions.add(
      // for the possible language-switch: act when the training is available
      this.training$
        .pipe(
          filter((training) => !!training),
          take(1)
        )
        .subscribe((training) => {
          // store both languages for resetting values on destruction of the component
          this._userLanguage = this._translate.currentLang as Language;
          this._trainingLanguage = training?.language as Language;
          // in case we have everything setup and the training is english, we temporarily set the language to english
          // also, in case the user also has english selected, we can omit the API-call
          if (this._userLanguage && this._trainingLanguage === 'en' && this._userLanguage !== 'en') {
            // ensure that this wil be reset on destruction of this component!
            this._translate.use(this._trainingLanguage);
          }
        })
    );

    this.training$
      .pipe(
        filter((training) => !!training),
        take(1)
      )
      .subscribe((training) => {
        if (!!training) {
          // ensure that ratings for this training are loaded, as we will need them in the rating-slide
          this._store.dispatch(loadUserTrainingRatings({slug: training.slug}));
        }
      });

    // check for progress-transitions over the 25%, 50% and 75% mark respectively
    this._subscriptions.add(
      this.training$
        .pipe(
          filter((training) => !!training),
          map((training) => (training?.current ?? 0) / (training?.total ?? 1)),
          distinctUntilChanged()
        )
        .subscribe((progress) => {
          // `trainingProgress` stores the last value, so we can register changes
          if (this._trainingProgress) {
            // check each milestone, we only want to show confetti once!
            if (this._trainingProgress < 0.25 && progress >= 0.25) {
              this._confettiCannon('SNACKBAR.CONGRATULATIONS.25');
            } else if (this._trainingProgress < 0.5 && progress >= 0.5) {
              this._confettiCannon('SNACKBAR.CONGRATULATIONS.50');
            } else if (this._trainingProgress < 0.75 && progress >= 0.75) {
              this._confettiCannon('SNACKBAR.CONGRATULATIONS.75');
            }
          }
          // remember the progress
          this._trainingProgress = progress;
        })
    );

    // Load SessionContent initially
    this._store.dispatch(loadSessionContent());
    // Initially load the progresses
    this._store.dispatch(progressesActions.execute({params: undefined}));
    // Load the training if needed
    this._store.dispatch(trainingActions.execute({params: undefined}));
    // Idempotently load the certificateAvailability incase the user *directly* visits a slide (via bookmark e.g.)
    this._store.dispatch(loadCertificateAvailabilityIfNeeded());
    // Load the users notes initially
    this._store.dispatch(notesActions.execute({params: undefined}));
    // Load the users bookings intially
    this._store.dispatch(bookingsActions.execute({params: undefined}));
    //
    this._store.dispatch(downloadInfoActions.execute({params: undefined}));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    // in case we changed the language for this training, reset it to the originally user-selected language!
    if (this._userLanguage && this._trainingLanguage === 'en' && this._userLanguage !== 'en') {
      this._translate.use(this._userLanguage);
    }
  }

  /**
   * Fires up two confetti-cannons from the bottom right and left corners of the screen.
   * Used colors are from the troodi-palette and will be randomly used.
   * Also sets the big "congratulations"-text to the given message (as a translate-key) and unsets this after 4 seconds.
   * @param message The congratulations-message that is to be shown for 4s as a translation-key
   */
  private _confettiCannon(message: string) {
    const commonOptions: confetti.Options = {
      colors: ['#015A91', '#F95F4E', '#012438', '#A3B3C1', '#E1EEF9', '#0380BF', '#F7BF07'],
      spread: 60,
      startVelocity: 70,
      particleCount: 100,
      // package will check for the `prefersReducedMotion`-Flag and disable the confetti accordingly if set
      disableForReducedMotion: true,
      // higher value = confetti fades slower
      ticks: 300,
    };
    // there should be a little delay before the confetti is shown
    setTimeout(() => {
      // set the var, this will show the message on screen
      this.congratsText = message;
      confetti({
        ...commonOptions,
        origin: {x: 0, y: 1},
        angle: 45,
      });
      confetti({
        ...commonOptions,
        origin: {x: 1, y: 1},
        angle: 135,
      });
      // unset the var after waiting 4s
      setTimeout(() => {
        this.congratsText = undefined;
      }, 5000);
    }, 1000);
  }
}
