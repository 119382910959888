<div class="container">
  <div class="headline">
    <span translate>TRAININGS.INFO_DIALOG.HEADLINE</span>
  </div>
  <div class="content">
    <span translate>TRAININGS.INFO_DIALOG.CONTENT.PRE</span>
    <ol>
      <li translate>TRAININGS.INFO_DIALOG.CONTENT.POINT1</li>
      <li translate>TRAININGS.INFO_DIALOG.CONTENT.POINT2</li>
    </ol>
    <p>
      <span translate>TRAININGS.INFO_DIALOG.CONTENT.POST1</span>
      <a class="textlink" href="mailto:info@troodi.de">info@troodi.de</a>
      <span translate>TRAININGS.INFO_DIALOG.CONTENT.POST2</span>
    </p>
  </div>
  <div class="mt-3 mat-dialog-actions">
    <button mat-flat-button color="accent" cdkFocusInitial (click)="close()">
      <span translate>TRAININGS.INFO_DIALOG.CLOSE</span>
    </button>
  </div>
</div>
