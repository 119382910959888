import {ErrorHandler, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {createExecuteEffect} from '@zeit-dev/ngrx-util';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {CookieHandlerService, COOKIE_TYPE} from 'src/app/shared/cookie-handler.service';
import {showSnackbar} from 'src/app/shared/shared.actions';
import {SharedSlice} from 'src/app/shared/shared.reducer';
import {
  downloadInfoActions,
  downloadInfoUpdate,
  loadDownloadInfoShownSuccess,
  loadUser,
  loadUserError,
  loadUserSuccess,
  sendUserContactSales,
  setUserData,
  setUserSuccess,
} from './user.actions';
import {UserDataService} from './user.data.service';
import {UserSlice} from './user.reducer';
import {selectDownloadInfoState, selectUser, selectUserStateSlice} from './user.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _dataService: UserDataService,
    private readonly _translateService: TranslateService,
    private readonly _store$: Store<UserSlice | SharedSlice>,
    private readonly _errorHandler: ErrorHandler,
    private readonly _cookieService: CookieHandlerService
  ) {}

  loadUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadUser),
      withLatestFrom(this._store$.select(selectUserStateSlice)),
      exhaustMap(([, state]) => {
        if (state.loaded && state.results) {
          return of(loadUserSuccess({user: state.results}));
        }
        return this._dataService.loadUser().pipe(
          map((user) => {
            return loadUserSuccess({user});
          }),
          catchError((err) => {
            // normally, the error is packed as a BeErrorMessage, for the login, it is already stripped
            const flash = err.error as Flash;
            if (flash) {
              if (flash.alert) {
                // alert is used for 'real' errors
                this._store$.dispatch(showSnackbar({messageType: 'error', message: flash.alert}));
              }
              if (flash.notice) {
                // notice is used for logout-message
                this._store$.dispatch(showSnackbar({messageType: 'success', message: flash.notice}));
              }
            }
            return of(loadUserError({message: err.message}));
          })
        );
      })
    )
  );

  setUserData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setUserData),
      exhaustMap((user) => this._dataService.setUserData(user.firstName, user.lastName, user.language)),
      mergeMap((user) => [
        setUserSuccess({user}),
        showSnackbar({messageType: 'success', message: this._translateService.instant('SNACKBAR.USER_SUCCESS')}),
      ])
    )
  );

  contactSales$ = createEffect(() =>
    this._actions$.pipe(
      ofType(sendUserContactSales),
      exhaustMap((action) => this._dataService.contactSales(action.selection)),
      mergeMap((user) => [
        setUserSuccess({user}),
        showSnackbar({messageType: 'success', message: this._translateService.instant('EXPIRED_USER.SNACKBAR')}),
      ])
    )
  );

  /**
   * Checks whether or not the info about downloading PDFs should be shown.
   * It checks:
   *  1) the current state for idempotency
   *  2) flag at the user
   *  3) a given cookie
   *  4) if nothing is set, return `false` as bootstrap
   */
  readonly loadDownloadInfoShown$ = createEffect(() =>
    this._actions$.pipe(
      createExecuteEffect(
        downloadInfoActions,
        (params, state) => {
          const user = selectUser(state);
          const slice = selectDownloadInfoState(state);

          // idempotent, skip checking user and cookie
          if (slice.loaded && slice.results !== undefined) {
            return of(slice.results);
          }

          if (!!user && !user.show_download_info) {
            this._store$.dispatch(loadDownloadInfoShownSuccess({show: false}));
            return of(false);
          }

          if (!!this._cookieService.checkCookie(COOKIE_TYPE.DOWNLOAD_INFO)) {
            this._store$.dispatch(loadDownloadInfoShownSuccess({show: false}));
            return of(false);
          }

          // If all else failed, the user has never seen this dialog, so show the dialog to bootstrap
          return of(true);
        },
        this._store$,
        this._errorHandler
      )
    )
  );

  readonly updateDownloadInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(downloadInfoUpdate),
      withLatestFrom(this._store$.select(selectUser)),
      exhaustMap(([action, user]) => {
        if (action.persist && !!user) {
          // update user, return to subscribe on action
          return this._dataService.setUserData(user.first_name ?? '', user.last_name ?? '', user.language, false).pipe(
            // load updated user into state
            mergeMap((response) => [setUserSuccess({user: response}), loadDownloadInfoShownSuccess({show: false})])
          );
        } else {
          // store cookie for 12 months
          this._cookieService.saveCookie(COOKIE_TYPE.DOWNLOAD_INFO, true, 12);
          return of(loadDownloadInfoShownSuccess({show: false}));
        }
      })
    )
  );
}

export interface BeErrorMessage {
  flash: Flash;
}

export interface Flash {
  notice: null | string;
  alert: null | string;
}
