import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {RouterReducerState} from '@ngrx/router-store';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY, from, of} from 'rxjs';
import {catchError, concatMap, exhaustMap, map, switchMapTo, withLatestFrom} from 'rxjs/operators';
import {showSnackbar} from 'src/app/shared/shared.actions';
import {
  addExternalAssessment,
  addExternalAssessmentParticipant,
  addExternalAssessmentParticipantSuccess,
  createExternalAssessment,
  finishExternalAssessment,
  loadExternalAssessments,
  loadExternalAssessmentsSuccess,
  loadQuestionsExternal,
  loadQuestionsExternalSuccess,
  loadQuestionsSelf,
  loadQuestionsSelfSuccess,
  sendAnswersExternal,
  sendAnswersSelf,
} from './assessment.actions';
import {AssessmentDataService, ExternalAssessmentViewModel, Questionnaire} from './assessment.data.service';
import {AssessmentStateSlice} from './assessment.reducer';
import {selectExternalToken} from './assessment.selectors';

@Injectable()
export class AssessmentEffects {
  loadQuestionsSelf$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadQuestionsSelf),
      exhaustMap(() => this._dataService$.getSelfQuestions()),
      map((questionnaire: Questionnaire) => loadQuestionsSelfSuccess({questionnaire}))
    )
  );

  loadQuestionsExternal = createEffect(() =>
    this._actions$.pipe(
      ofType(loadQuestionsExternal),
      withLatestFrom(this._store$.select(selectExternalToken)),
      exhaustMap(([, guestToken]) =>
        this._dataService$.getExternalQuestions(guestToken).pipe(
          catchError((error: HttpErrorResponse) => {
            switch (error.status) {
              case 400:
                return from(this._router$.navigateByUrl('/assessment-error/same-user', {skipLocationChange: true})).pipe(
                  switchMapTo(EMPTY)
                );
              case 401:
                return from(this._router$.navigateByUrl('/assessment-error/logout', {skipLocationChange: true})).pipe(switchMapTo(EMPTY));
              default:
                console.error(error);
                return from(this._router$.navigateByUrl('/')).pipe(switchMapTo(EMPTY));
            }
          }),
          map((response: Questionnaire) =>
            loadQuestionsExternalSuccess({
              questionnaire: response,
              token: guestToken,
            })
          )
        )
      )
    )
  );

  sendAnswersSelf$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(sendAnswersSelf),
        withLatestFrom(this._store$),
        exhaustMap(([, state]) =>
          this._dataService$
            .postSelfAnswers(state.assessment.questionnaireSelf)
            .pipe(map(() => from(this._router$.navigateByUrl('/recommendation')).pipe(switchMapTo(EMPTY))))
        )
      ),
    {dispatch: false}
  );

  sendAnswersExternal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(sendAnswersExternal),
        withLatestFrom(this._store$),
        exhaustMap(([, state]) =>
          this._dataService$
            .postExternalAnswers(state.assessment.questionnaireExternal, state.assessment.externalToken)
            .pipe(map(() => from(this._router$.navigateByUrl('/assessment-finished')).pipe(switchMapTo(EMPTY))))
        )
      ),
    {dispatch: false}
  );

  loadExternalAssessments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadExternalAssessments),
      exhaustMap(() => this._dataService$.getExternalAssessments()),
      map((assessments: ExternalAssessmentViewModel[]) => loadExternalAssessmentsSuccess({assessments}))
    )
  );

  createExternalAssessment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createExternalAssessment),
      exhaustMap((action) => this._dataService$.createExternalAssessment(action.assessment)),
      map((assessment) => addExternalAssessment({assessment}))
    )
  );

  addExternalAssessmentParticipant$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addExternalAssessmentParticipant),
      exhaustMap((action) => {
        return this._dataService$.addExternalAssessmentParticipant(action.assessmentId, action.emailAddress).pipe(
          concatMap(() => {
            return of(
              showSnackbar({messageType: 'success', message: this._translateService.instant('SNACKBAR.PARTICIPANT_SUCCESS')}),
              addExternalAssessmentParticipantSuccess({assessmentId: action.assessmentId}),
              loadExternalAssessments()
            );
          })
        );
      })
    )
  );

  finishExternalAssessment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(finishExternalAssessment),
      exhaustMap((action) => this._dataService$.postFinishExternalAssessment(action.id)),
      map((response) => /* currently, don't catch the errors */ loadExternalAssessments())
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dataService$: AssessmentDataService,
    private readonly _store$: Store<AssessmentStateSlice & RouterReducerState>,
    private readonly _router$: Router,
    private readonly _translateService: TranslateService
  ) {}
}
