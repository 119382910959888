import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {getFetchUrl} from 'lib/cloudinary/image-fetch';
import {hasChanged} from 'lib/util';

function isLocalhost(imageUrl: string): boolean {
  return /^https?:\/\/localhost/.test(imageUrl);
}

@Directive({
  selector: '[appSrcset]',
})
export class SrcsetDirective implements OnChanges {
  private readonly _cloudinaryHost?: string;
  private readonly _factors = [1.5, 3];
  @Input('appSrcset') imageUrl?: string;
  @Input() pxWidth?: number;
  @Input() pxHeight?: number;
  @Input() cropping?: string[];

  constructor(private readonly _el: ElementRef<HTMLImageElement>) {
    // eslint-disable-next-line
    // @ts-ignore
    if (window._appConfig && window._appConfig.CLOUDINARY_HOST) {
      // eslint-disable-next-line
      // @ts-ignore
      this._cloudinaryHost = window._appConfig.CLOUDINARY_HOST;
    } else {
      console.warn('Configuration of CLOUDINARY_HOST missing. Falling back to direct access.');
    }
  }

  ngOnChanges(input: SimpleChanges): void {
    if (hasChanged(input, 'imageUrl') || hasChanged(input, 'pxWidth') || hasChanged(input, 'pxHeight') || hasChanged(input, 'cropping')) {
      if (!this.imageUrl || this.imageUrl === '') {
        this._reset();
        return;
      }

      if (!this._cloudinaryHost || isLocalhost(this.imageUrl)) {
        this._fallback(this.imageUrl);
        return;
      }
      const cloudinaryHost = this._cloudinaryHost;

      const common: string[] = this.cropping || [];
      const baseOptions: string[] = ['f_auto'];
      const optionsX: Array<{factor: number; options: string[]}> = this._factors.map((factor) => ({
        factor,
        options: ['f_auto'],
      }));

      if (this.pxWidth) {
        const width1x = this.pxWidth;
        baseOptions.push(`w_${this.pxWidth}`);
        optionsX.forEach(({factor, options}) => {
          const width = factor * width1x;
          options.push(`w_${width}`);
        });
      }

      if (this.pxHeight) {
        const height1x = this.pxHeight;
        baseOptions.push(`h_${this.pxHeight}`);
        optionsX.forEach(({factor, options}) => {
          const height = factor * height1x;
          options.push(`h_${height}`);
        });
      }

      const imageUrl = this.imageUrl;
      this._el.nativeElement.src = `${getFetchUrl(this._cloudinaryHost, imageUrl, [...baseOptions, ...common])}`;
      this._el.nativeElement.srcset = optionsX
        .map(({factor, options}) => {
          return `${getFetchUrl(cloudinaryHost, imageUrl, [...options, ...common])} ${factor.toFixed(1)}x`;
        })
        .join(', ');
    }
  }

  private _fallback(url: string): void {
    this._el.nativeElement.src = url;
    this._el.nativeElement.removeAttribute('srcset');
    if (this.pxWidth) this._el.nativeElement.width = this.pxWidth;
    if (this.pxHeight) this._el.nativeElement.height = this.pxHeight;
  }

  private _reset(): void {
    this._el.nativeElement.removeAttribute('srcset');
    this._el.nativeElement.removeAttribute('src');
    this._el.nativeElement.removeAttribute('width');
    this._el.nativeElement.removeAttribute('height');
  }
}
