import {Component, OnInit} from '@angular/core';
import {Note} from '@backend-api/notes/get-notes.response';
import {Store} from '@ngrx/store';
import {first} from 'rxjs/operators';
import {selectUser} from '../../user/user.selectors';
import {bookingsActions, deleteNote, editNote, notesActions, trainingActions} from '../e-learning.actions';
import {ELearningSlice} from '../e-learning.reducer';
import {selectUserNotesByTrainings} from '../e-learning.selectors';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnInit {
  readonly notes$ = this._store.select(selectUserNotesByTrainings);
  private readonly _userModel$ = this._store.select(selectUser);

  private _userId = '';
  private _userIdHmac = '';

  constructor(private readonly _store: Store<ELearningSlice>) {}

  ngOnInit(): void {
    this._store.dispatch(trainingActions.execute({params: undefined}));
    this._store.dispatch(bookingsActions.execute({params: undefined}));
    this._store.dispatch(notesActions.execute({params: undefined}));

    this._userModel$.pipe(first((user) => !!user)).subscribe((user) => {
      if (!!user) {
        this._userId = user.id;
        this._userIdHmac = user.user_id_hmac;
      }
    });
  }

  deleteNote(event: {id: string}) {
    this._store.dispatch(deleteNote({id: event.id}));
  }

  editNote(event: {id: string; note: string}) {
    this._store.dispatch(editNote({id: event.id, text: event.note}));
  }

  // TODO: as usual, decide on a component that will export this...
  trackNote(index: number, note: Note) {
    return note.id;
  }

  /**
   * Generates links to the corresponding notes-pdf for either all user-notes or the notes for a specific training.
   * @param slug optional - slug of the training you want the notes of. If omitted, returns a link to all notes.
   * @returns href directing to a PDF of the user's notes
   */
  notesPdfLink(slug?: string) {
    // always append user-ID and HMAC
    const params = `.pdf?user_id=${this._userId}&user_id_hmac=${this._userIdHmac}`;
    if (!!slug) {
      return `/elearning/notes/${slug}${params}`;
    }
    return `/elearning/notes${params}`;
  }
}
