import {createAction, props} from '@ngrx/store';
import {RecommendedTraining} from 'src/app/backend/recommendation-api/skill-profiles/get-by-skill-profile-id-recommendations.response';
import {GetCurrentResponse} from 'src/app/backend/recommendation-api/skill-profiles/get-current.response';
import {Skill} from 'src/app/backend/recommendation-api/skills/get-skills.response';
import {IndexedObjects} from '../../shared/data.service';

export const setRecommendations = createAction(
  '[SKILLPROFILE] Set Recommendations',
  props<{recommendations: IndexedObjects<RecommendedTraining, string>}>()
);

export const setSkillValue = createAction('[SKILL] Set Value', props<{id: string; value: number}>());

export const loadSkills = createAction('[SKILL] Load');

export const loadSkillSuccess = createAction('[SKILL] Load Success', props<{skills: IndexedObjects<Skill, string>}>());

export const loadSkillProfiles = createAction('[SKILLPROFILE] Load Skillprofiles Results');

export const loadSkillProfilesSuccess = createAction(
  '[SKILLPROFILE] Load Skillprofiles Results Success',
  props<{response: GetCurrentResponse}>()
);
