import {Component, HostBinding} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {fadeInAnimation} from '../animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [fadeInAnimation],
})
export class DashboardComponent {
  @HostBinding('@fadeInAnimation') loadAnimation = true;

  constructor(private readonly _dialog: MatDialog) {}
}
