import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  constructor(private readonly _ref: MatDialogRef<InfoDialogComponent>) {
    this._ref.addPanelClass('custom-info-dialog');
  }

  close() {
    this._ref.close();
  }
}
