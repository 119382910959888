<div class="separator-content mx-auto">
  <div class="intro-icon mb-4" [ngSwitch]="type">
    <ng-container *ngSwitchCase="'Link_slide'">
      <a *ngIf="url" [href]="url" target="_blank" rel="noopener noreferrer" (click)="onButtonClick()">
        <ng-template [ngTemplateOutlet]="linkIcons"></ng-template>
      </a>
      <a *ngIf="!url && dialogButton" [routerLink]="undefined" (click)="onButtonClick()">
        <ng-template [ngTemplateOutlet]="linkIcons"></ng-template>
      </a>
      <a *ngIf="!url && skipLink && !dialogButton" [routerLink]="skipLink">
        <ng-template [ngTemplateOutlet]="linkIcons"></ng-template>
      </a>
      <ng-template #linkIcons>
        <mat-icon svgIcon="separator_link"></mat-icon>
      </ng-template>
    </ng-container>
    <mat-icon
      *ngSwitchCase="'Video_slide'"
      [svgIcon]="(specialSlide === 'anekdote') ? 'separator_anecdote' : 'separator_video'"
      (click)="onButtonClick()">
    </mat-icon>
    <mat-icon *ngSwitchCase="'Quiz_slide'" svgIcon="separator_nextgen_quiz" (click)="onButtonClick()"></mat-icon>
    <mat-icon *ngSwitchCase="'Certificate_slide'" svgIcon="separator_certificate" (click)="onButtonClick()"></mat-icon>
    <mat-icon *ngSwitchCase="'User_training_rating_slide'" svgIcon="separator_feedback" (click)="onButtonClick()"></mat-icon>
    <ng-container *ngSwitchCase="'Interactive_content_slide'">
      <ng-container [ngSwitch]="specialSlide">
        <mat-icon *ngSwitchCase="'quiz'" svgIcon="separator_nextgen_quiz" (click)="onButtonClick()"></mat-icon>
        <mat-icon *ngSwitchDefault svgIcon="separator_interactive" (click)="onButtonClick()"></mat-icon>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Passive_content_slide'">
      <ng-container [ngSwitch]="specialSlide">
        <mat-icon *ngSwitchCase="'handbook'" svgIcon="separator_protocol" (click)="onButtonClick()"></mat-icon>
        <mat-icon *ngSwitchDefault svgIcon="separator_link" (click)="onButtonClick()"></mat-icon>
      </ng-container>
    </ng-container>
  </div>

  <h1 class="mb-2" *ngIf="title">
    <span>{{title}}</span>
  </h1>

  <ng-container *ngIf="!loading">
    <div [ngSwitch]="type">
      <span *ngSwitchCase="'Video_slide'" [innerHTML]="introText | prismicHtml"></span>
      <span *ngSwitchCase="'Interactive_content_slide'" [innerHTML]="introText | prismicHtml"></span>

      <ng-container *ngSwitchCase="'Quiz_slide'">
        <span *ngIf="introText" [innerHTML]="introText"></span>
        <span *ngIf="!introText" [innerHTML]="'INTRO_SLIDE.TEXT.QUIZ' | translate"></span>
      </ng-container>
      <span *ngSwitchDefault [innerHTML]="introText"></span>
    </div>

    <div class="cta-container container mt-5">
      <div class="row">
        <a
          *ngIf="url"
          mat-raised-button
          class="primary-cta col-12"
          color="accent"
          [href]="url"
          target="_blank"
          (click)="onButtonClick()"
          rel="noopener noreferrer">
          <span>{{buttonText}}</span>
        </a>
        <button *ngIf="!url && buttonText" class="primary-cta col-12" mat-raised-button color="accent" (click)="onButtonClick()">
          <span>{{buttonText}}</span>
        </button>
      </div>
      <div class="row justify-content-between mt-4 bottom-row">
        <a
          *ngIf="backLink"
          class="back-link col-12 order-last order-sm-first col-sm"
          mat-stroked-button
          color="accent"
          [routerLink]="backLink">
          <span translate>INTRO_SLIDE.BACK</span>
        </a>
        <a
          *ngIf="buttonText && skipLink"
          class="skip-link col-12 order-first order-sm-last mb-2 mb-sm-0 col-sm"
          mat-stroked-button
          color="accent"
          [routerLink]="skipLink">
          <!-- there might be cases where we want to show custom text instead of 'skip' for a specific slide -->
          <span>{{skipText ? skipText : 'INTRO_SLIDE.SKIP' | translate}}</span>
        </a>
        <!-- This button is used for intro slides and slides where there is no interaction left -->
        <a
          *ngIf="!buttonText && skipLink"
          class="primary-cta col-12 col-sm order-first order-sm-last mb-4 mb-sm-0"
          mat-raised-button
          color="accent"
          [routerLink]="skipLink">
          <span>{{skipText ? skipText : 'INTRO_SLIDE.SKIP' | translate}}</span>
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <img class="troodi-spinner" src="assets/img/troodi_spinner.gif" />
  </ng-container>
</div>
