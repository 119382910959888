<div class="circle" *ngIf="percentage !== undefined">
  <svg viewBox="0 0 32 32">
    <circle r="16" cx="16" cy="16" [style.stroke-dasharray]="percentage + ' 100'" />
  </svg>
  <div class="inner">
    <div class="inner">
      <mat-icon *ngIf="showIcon()" [svgIcon]="icon" [ngClass]="{success: isFinished}"></mat-icon>
      <span *ngIf="!showIcon()" class="number">{{number}}</span>
    </div>
  </div>
</div>
