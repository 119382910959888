import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {bookingsActions, progressesActions, trainingActions} from '../../e-learning.actions';
import {ELearningSlice} from '../../e-learning.reducer';
import {selectTopics, TopicWithTrainings} from '../../e-learning.selectors';
import {TopicFilterStore} from '../topic-filter/topic-filter.store';

@Component({
  selector: 'app-grouped-trainings',
  templateUrl: './grouped-trainings.component.html',
  styleUrls: ['./grouped-trainings.component.scss'],
  providers: [TopicFilterStore],
})
export class GroupedTrainingsComponent implements OnInit, OnDestroy {
  private readonly _referenceTopics$ = this._store.select(selectTopics({mode: 'all'}));
  private readonly _subscriptions = new Subscription();
  readonly topics$ = this._topicFilterStore.mappedSelectedTopics$;

  constructor(private readonly _store: Store<ELearningSlice>, private readonly _topicFilterStore: TopicFilterStore) {}

  ngOnInit(): void {
    this._subscriptions.add(
      // The component-store needs the IDs of the available topics
      this._referenceTopics$
        .pipe(
          filter((topics) => !!topics),
          // If the count of the topics change, reset this
          distinctUntilChanged((x, y) => {
            return x.length === y.length;
          })
        )
        .subscribe((topics) => {
          this._topicFilterStore.loadTopics(topics);
        })
    );

    this._store.dispatch(trainingActions.execute({params: undefined}));
    this._store.dispatch(progressesActions.execute({params: undefined}));
    this._store.dispatch(bookingsActions.execute({params: undefined}));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  trackTopic(index: number, topic: TopicWithTrainings) {
    return topic.id;
  }
}
