<ng-container *ngIf="session">
  <div *ngIf="!!locked" class="session-lock">
    <div class="lock-icon" (click)="openInfoDialog()">
      <mat-icon svgIcon="lock"></mat-icon>
    </div>
    <span class="mt-3 mt-md-2 mx-3 mx-md-0 tooltip" translate>
      SINGLE_TRAINING.LOCKED
      <i-feather name="info" (click)="openInfoDialog()"></i-feather>
    </span>
  </div>
  <div
    class="row no-gutters header d-flex mx-0"
    (click)="toggleExpand()"
    [matTooltipDisabled]="expanded"
    [matTooltip]="'SINGLE_TRAINING.EXPAND' | translate"
    matTooltipPosition="above">
    <div class="col-2 col-sm-2 col-lg-1 align-self-center">
      <app-circle [number]="number" [percentage]="percentage" [isMilestone]="isMilestone" [isFinal]="isFinal" [isFinished]="isFinished">
      </app-circle>
    </div>
    <div class="col col-sm-8 col-lg-10 align-self-center title">
      <ng-template *ngIf="isMobile()" nestedEllipsis> {{session.title}} </ng-template>
      <ng-container *ngIf="!isMobile()"> {{session.title}} </ng-container>
    </div>
    <div class="col-2 col-sm-2 col-lg-1 align-self-center text-end">
      <mat-icon *ngIf="!expanded" svgIcon="expand"></mat-icon>
      <mat-icon *ngIf="expanded" svgIcon="reduce"></mat-icon>
    </div>
  </div>
  <div class="row body">
    <ng-container *ngIf="expanded">
      <div class="col-12 col-lg-5">
        <div>
          <img *ngIf="session.image" class="img-fluid" [appSrcset]="session.image?.url" [alt]="session.image?.alt" [pxWidth]="640" />
        </div>
        <div class="p-3 p-sm-0 description" [innerHTML]="session.description | prismicHtml | hyphenateHtml | async"></div>
      </div>
      <div class="col mt-4 mt-lg-0">
        <ng-content></ng-content>
      </div>
    </ng-container>
  </div>
</ng-container>
