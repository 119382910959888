import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {AppMaterialModule} from 'src/app/app-material.module';
import {SharedModule} from 'src/app/shared/shared.module';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserEffects} from './user.effects';
import {reducers} from './user.reducer';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,

    SharedModule,

    StoreModule.forFeature('user', reducers),
    EffectsModule.forFeature([UserEffects]),
    TranslateModule,
  ],
  declarations: [UserProfileComponent],
})
export class UserModule {}
