<div class="activate-account my-auto">
  <mat-card class="activate-account-card my-4">
    <ng-container *ngIf="!isConfirmed && isLoading && !error">
      <img class="troodi-spinner" src="assets/img/troodi_spinner.gif" />
      <span class="loading-text" translate>ACTIVATE_ACCOUNT.LOADING</span>
    </ng-container>
    <ng-container *ngIf="isConfirmed">
      <div class="title" translate>ACTIVATE_ACCOUNT.TITLE</div>
      <div class="subtitle mb-3" translate>ACTIVATE_ACCOUNT.INSTRUCTIONS</div>
      <mat-card-content>
        <div *ngIf="(token$ | async) as token" class="button-container text-center">
          <a class="mb-4" mat-flat-button color="accent" routerLink="/e-learning/trainings/available">
            <span translate>ACTIVATE_ACCOUNT.BUTTON</span>
          </a>
        </div>
      </mat-card-content>
    </ng-container>
    <!-- Error is shown if BE returns any error or no token is given via params -->
    <ng-container *ngIf="!!error || !isLoading && !isConfirmed">
      <mat-card-content class="error-card">
        <p class="error">
          <ng-container *ngIf="error; else genericError">
            <span translate>{{error.message}}.PRE</span>
            <a href="mailto:info@troodi.de">info@troodi.de</a>
            <span translate>{{error.message}}.POST</span>
          </ng-container>
          <ng-template #genericError>
            <span translate>MAPPABLE_BE_ERROR.GENERIC.PRE</span>
            <a href="mailto:info@troodi.de">info@troodi.de</a>
            <span translate>MAPPABLE_BE_ERROR.GENERIC.POST</span>
          </ng-template>
        </p>
        <a class="mt-3" mat-flat-button color="accent" routerLink="/">
          <span translate>MAPPABLE_BE_ERROR.BUTTON.HOME</span>
        </a>
      </mat-card-content>
    </ng-container>
  </mat-card>
</div>
