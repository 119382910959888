<div class="content">
  <div class="header">
    <div class="title" translate>USER_PROFILE.TITLE</div>
    <div class="subtitle" translate>USER_PROFILE.SUBTITLE</div>
  </div>
  <form [formGroup]="profileForm" (ngSubmit)="setUserData()">
    <div class="form-field-wrapper">
      <p>
        <mat-form-field appearance="standard">
          <mat-label translate>USER_PROFILE.FIRSTNAME</mat-label>
          <input matInput type="text" formControlName="firstName" required />
          <mat-error *ngIf="(firstName?.dirty || firstName?.touched) && firstName?.errors?.required" translate>
            USER_PROFILE.ERROR_FIRSTNAME
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="standard">
          <mat-label translate>USER_PROFILE.LASTNAME</mat-label>
          <input matInput type="text" formControlName="lastName" required />
          <mat-error *ngIf="(lastName?.dirty || lastName?.touched) && lastName?.errors?.required" translate>
            USER_PROFILE.ERROR_LASTNAME
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="standard">
          <mat-label translate>USER_PROFILE.LANGUAGE</mat-label>
          <mat-select formControlName="language">
            <mat-option *ngFor="let lang of languageMapping | keyvalue" [value]="lang.key">{{lang.value}}</mat-option>
          </mat-select>
        </mat-form-field>
      </p>
    </div>
    <div class="actions">
      <button type="button" mat-raised-button (click)="cancel()">
        <span translate>USER_PROFILE.CANCEL</span>
      </button>
      <button type="submit" [disabled]="profileForm.invalid" mat-raised-button color="accent">
        <span translate>USER_PROFILE.SUBMIT</span>
      </button>
    </div>
  </form>

  <div class="onboarding">
    <div class="header">
      <div class="title" translate>ONBOARDING.ONBOARDING</div>
      <div class="subtitle" translate>ONBOARDING.REVIEW</div>
    </div>

    <p class="onboarding-button">
      <button mat-flat-button color="accent" (click)="openOnboarding()">
        <span translate>ONBOARDING.START_TOUR</span>
      </button>
    </p>
  </div>
</div>
