import {DOCUMENT} from '@angular/common';
import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';
import {Store} from '@ngrx/store';
import {debounceTime} from 'rxjs/operators';
import {finishApplicationUpdate} from 'src/app/app.actions';
import {environment} from 'src/environments/environment';
import {LoggerService} from './logger.service';

export function isDomException(e: unknown): e is DOMException {
  return typeof e === 'object' && !!e && 'code' in e;
}

@Injectable({
  providedIn: 'root',
})
export class AppUpdatesService {
  private _updateAvailable = false;

  constructor(
    private readonly _swUpdate: SwUpdate,
    private readonly _logger: LoggerService,
    private readonly _router: Router,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _store: Store,
    private readonly _errorHandler: ErrorHandler
  ) {
    this._swUpdate.unrecoverable.subscribe((event) => {
      console.log(`An error occurred that we cannot recover from:\n${event.reason}\n\n` + 'Please reload the page.');
    });
  }

  async checkForUpdate() {
    // No service-worker enabled in dev
    if (!environment.production) return this._finishUpdate();

    // When user SHIFT + CMD + reloads (`navigator.serviceWorker.controller` will be `null` then)
    if (navigator.serviceWorker && !navigator.serviceWorker.controller) {
      try {
        await navigator.serviceWorker.ready;
        this._document.location?.reload();
        return;
      } catch (e) {
        // the promise might return an error
        /* Special case: Firefox throws if Setting "Delete cookies when closing Firefox" is enabled.
         * This is a DOMException with code 18 ('SecurityError: The operation is insecure').
         * Also see:
         * - https://bugzilla.mozilla.org/show_bug.cgi?id=1429714
         * - https://github.com/angular/angular/issues/28373
         * This is due to implementation-restrictions FF has in deleting PWA-data.
         * Thus, they simply completely reject serviceworkers
         * In every other case: we definitely want this error to be reported
         */
        if (!(isDomException(e) && e.code === 18)) this._errorHandler.handleError(e);

        // as there is no chance to register a ServiceWorker here, just proceed without it
        return this._finishUpdate();
      }
    }

    // Service worker not activated in browser
    if (!this._swUpdate.isEnabled) {
      this._finishUpdate();
      return;
    }

    this._registerListeners();

    // Happens *after* `swUpdate.available` emitted a value
    await this._swUpdate.checkForUpdate();

    this._updateAvailable ? await this._doUpdate() : this._finishUpdate();
  }

  private async _doUpdate() {
    this._logger.log('A newer version is now available. Forcing update.');
    await this._swUpdate.activateUpdate();

    // Latest version downloaded and activated. To reduce caching issues, we reload the page now
    this._document.location?.reload();
  }

  private _registerListeners() {
    // Happens before `checkForUpdate` resolves
    // TODO: deprecated, change to new format
    this._swUpdate.available.subscribe(() => {
      this._updateAvailable = true;
      this._logger.log('A newer version is available, but not downloaded yet');
    });
    this._router.events.pipe(debounceTime(10000)).subscribe(() => {
      this._swUpdate.checkForUpdate().catch((err) => {
        this._logger.debug(err);
      });
    });
  }

  private _finishUpdate() {
    // Tell global effects that we are on the latest version available
    this._store.dispatch(finishApplicationUpdate());
  }
}
