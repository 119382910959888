/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetTrainingsResponse} from './get-trainings.response';
import {GetTrainingsRequest} from './get-trainings.request';
import {GetPreviewResponse} from './get-preview.response';
import {GetPreviewRequest} from './get-preview.request';
import {GetByTrainingIdSessionsByIdResponse} from './get-by-training-id-sessions-by-id.response';
import {GetByTrainingIdSessionsByIdRequest} from './get-by-training-id-sessions-by-id.request';
@Injectable()
export class TrainingsGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getTrainings(
    queryParams: GetTrainingsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetTrainingsResponse> {
    return this._httpClient.request('get', this._apiHost + '/trainings', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }

  getPreview(
    queryParams: GetPreviewRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetPreviewResponse> {
    return this._httpClient.request('get', this._apiHost + '/trainings/preview', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }

  getByTrainingIdSessionsById(
    training_id: string,
    id: string,
    queryParams: GetByTrainingIdSessionsByIdRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetByTrainingIdSessionsByIdResponse> {
    return this._httpClient.request('get', this._apiHost + `/trainings/${training_id}/sessions/${id}`, {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
