import {createFeatureSelector, createSelector} from '@ngrx/store';
import {flatMap} from 'lodash-es';
import {RecommendedTraining} from 'src/app/backend/recommendation-api/skill-profiles/get-by-skill-profile-id-recommendations.response';
import {extractObjects} from '../../shared/data.service';
import {RecommendationSlice, RecommendationState} from './recommendation.reducer';

export const selectRecommendationState = createFeatureSelector<RecommendationState>('recommendation');

// Merge working and reference skills for the UI, 'extractObjects' creates a sorted array from the state
export const selectAllSkillViewModels = (state: RecommendationSlice) =>
  flatMap(extractObjects(state.recommendation.skills), (skill) => {
    const matchedSkillValue = state.recommendation.currentSkillProfile.objects[skill.id];
    const matchedExternal = state.recommendation.externalSkillProfile.objects[skill.id];
    const matchedSelf = state.recommendation.selfSkillProfile.objects[skill.id];
    if (!matchedSkillValue) return [];
    return [
      {
        id: skill.id,
        name: skill.name,
        value: matchedSkillValue.value,
        externalValue: matchedExternal ? matchedExternal.value : -1,
        selfValue: matchedSelf ? matchedSelf.value : -1,
      },
    ];
  });

export function selectRecommendations(state: RecommendationSlice) {
  return extractObjects(state.recommendation.recommendations);
}

export const selectOrderedRecommendations = createSelector(selectRecommendations, (recommendations) =>
  recommendations.sort((a, b) => b.score - a.score)
);

export const selectTopMatches = createSelector(selectOrderedRecommendations, (orderedRecommendations: RecommendedTraining[]) => {
  const topMatches: RecommendedTraining[] = [];
  // Three distinct best fits and alternative fits
  let bestThresh = 2,
    altThresh = 5;
  let lastMatch;
  for (let i = 0; i <= altThresh && i < orderedRecommendations.length; i++) {
    const tRecommendation = orderedRecommendations[i];
    // if this value is reoccurring, increase the thresholds, as we want to include them in the same class
    if (tRecommendation.score === lastMatch) {
      bestThresh++;
      altThresh++;
    }
    topMatches.push(tRecommendation);
    lastMatch = tRecommendation.score;
  }
  return topMatches;
});
