import {PostSignUpCampaignsByIdVerifyResponse} from '@backend-api/sign-up-campaigns-verify/post-sign-up-campaigns-by-id-verify.response';
import {createAction, props} from '@ngrx/store';
import {Language} from '../features/user/user.data.service';
import {SignupData} from '../signup/signup.component';
import {PrivacySettings} from './privacy.service';
import {BeMappableErrorMessage} from './shared.effects';

export type snackbarType = 'error' | 'success' | 'notify';

export const setPageinfoTitle = createAction('[Shared] Set Pageinfo-Title', props<{title: string}>());

export const setAfterSignInPath = createAction('[Shared] Set After-Sign-In Path', props<{path: string}>());

export const sendResetMail = createAction('[Shared] Send mail for password-reset', props<{email: string}>());

export const sendResetMailSuccess = createAction('[Shared] Send mail for password-reset success');

export const sendResetMailError = createAction('[Shared] Send mail for password-reset error');

export const resetPassword = createAction(
  '[Shared] Reset password',
  props<{reset_password_token: string; password: string; password_confirmation: string}>()
);

export const resetPasswordSuccess = createAction('[Shared] Reset password success');

export const resetPasswordError = createAction('[Shared] Reset password error', props<{error: BeMappableErrorMessage}>());

export const showSnackbar = createAction('[Shared] Show Snackbar', props<{messageType: snackbarType; message: string}>());

export const signup = createAction('[Shared] New user signup', props<{signupData: SignupData}>());

export const signupSuccess = createAction('[Shared] Signup Success');

export const signupError = createAction('[Shared] Signup Error');

export const changeUserLanguage = createAction('[Shared] Change user language', props<{language: Language}>());

export const confirmAccount = createAction('[Shared] Confirm User Account', props<{token: string}>());

export const confirmAccountSuccess = createAction('[Shared] Confirm User Account Success');

export const confirmAccountError = createAction('[Shared] Confirm User Account Error', props<{error: BeMappableErrorMessage}>());

export const loadPrivacySettings = createAction('[Shared] Load Privacy Settings');

export const loadPrivacySettingsSuccess = createAction('[Shared] Load Privacy Settings Success', props<{settings: PrivacySettings}>());

export const loadPrivacySettingsError = createAction('[Shared] Load Privacy Settings Error');

export const updatePrivacySettings = createAction('[Shared] Update Privacy Settings', props<{settings: PrivacySettings}>());

export const verifySignupCampaign = createAction('[Shared] Verify Signup Campaign', props<{key: string}>());

export const verifySignupCampaignSuccess = createAction(
  '[Shared] Verify Signup Campaign Success',
  props<PostSignUpCampaignsByIdVerifyResponse>()
);

export const verifySignupCampaignError = createAction('[Shared] Verify Signup Campaign Error', props<{found: boolean}>());
