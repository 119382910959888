/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetMeResponse} from './get-me.response';
import {GetMeRequest} from './get-me.request';
import {PutUserResponse} from './put-user.response';
import {PutUserRequest} from './put-user.request';
import {PostContactSalesResponse} from './post-contact-sales.response';
import {PostContactSalesRequest} from './post-contact-sales.request';
import {PostShowOnboardingResponse} from './post-show-onboarding.response';
import {PostShowOnboardingRequest} from './post-show-onboarding.request';
@Injectable()
export class UserGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getMe(queryParams: GetMeRequest, options?: Parameters<HttpClient['request']>[2]): Observable<GetMeResponse> {
    return this._httpClient.request('get', this._apiHost + '/user/me', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }

  putUser(body: PutUserRequest, options?: Parameters<HttpClient['request']>[2]): Observable<PutUserResponse> {
    return this._httpClient.request('put', this._apiHost + '/user', {
      ...options,
      observe: 'body',
      body,
    });
  }

  postContactSales(
    body: PostContactSalesRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostContactSalesResponse> {
    return this._httpClient.request('post', this._apiHost + '/user/contact_sales', {
      ...options,
      observe: 'body',
      body,
    });
  }

  postShowOnboarding(
    body: PostShowOnboardingRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostShowOnboardingResponse> {
    return this._httpClient.request('post', this._apiHost + '/user/show_onboarding', {
      ...options,
      observe: 'body',
      body,
    });
  }
}
