import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AppMaterialModule} from '../../app-material.module';
import {SharedModule} from '../../shared/shared.module';
import {RecommendationEffects} from './recommendation.effects';
import {reducer} from './recommendation.reducer';
import {RecommendationsComponent} from './recommendations/recommendations.component';
import {ResultsComponent} from './results/results.component';
import {SkillComponent} from './skill/skill.component';

@NgModule({
  declarations: [SkillComponent, RecommendationsComponent, ResultsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AppMaterialModule,

    SharedModule,

    StoreModule.forFeature('recommendation', reducer),
    EffectsModule.forFeature([RecommendationEffects]),
  ],
})
export class RecommendationModule {}
