import {ChangeDetectionStrategy, Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {fadeInAnimation} from 'src/app/animations';
import {bookingsActions, loadCertificateAvailability, progressesActions, trainingActions} from '../e-learning.actions';
import {ELearningSlice} from '../e-learning.reducer';
import {SearchStore} from './trainings-subheader/search.store';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class TrainingListComponent implements OnInit {
  readonly results$ = this._componentStore.searchResults$;
  readonly isSearch$ = this._componentStore.isSearch$;

  @HostBinding('@fadeInAnimation') loadAnimation = true;

  constructor(private readonly _store: Store<ELearningSlice>, private readonly _componentStore: SearchStore) {}

  ngOnInit() {
    // As we have idempotent loading, just be safe
    this._store.dispatch(trainingActions.execute({params: undefined}));
    this._store.dispatch(progressesActions.execute({params: undefined}));
    this._store.dispatch(bookingsActions.execute({params: undefined}));
    this._store.dispatch(loadCertificateAvailability());
  }
}
