import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {AppMaterialModule} from 'src/app/app-material.module';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {UiComponentsModule} from 'src/app/ui-components/ui-components.module';
import {CheckoutComponent} from './checkout/checkout.component';
import {OrderEffects} from './order.effects';
import {reducers} from './order.reducer';
import {OrderComponent} from './order/order.component';

@NgModule({
  declarations: [OrderComponent, CheckoutComponent],
  imports: [
    CommonModule,
    AppMaterialModule,
    ReactiveFormsModule,
    PipesModule,
    StoreModule.forFeature('order', reducers),
    EffectsModule.forFeature([OrderEffects]),
    TranslateModule,
    UiComponentsModule,
  ],
})
export class OrderModule {}
