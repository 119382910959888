import {Injectable} from '@angular/core';
import {DepProjectsGateway} from '@backend-api/dep-projects/dep-projects.gateway';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CertificatesGateway} from 'src/app/backend/elearning-api/certificates/certificates.gateway';
import {LearningPathsGateway} from 'src/app/backend/elearning-api/learning-paths/learning-paths.gateway';
import {NotesGateway} from 'src/app/backend/elearning-api/notes/notes.gateway';
import {PostNotesRequest} from 'src/app/backend/elearning-api/notes/post-notes.request';
import {TrainingBookingsGateway} from 'src/app/backend/elearning-api/training-bookings/training-bookings.gateway';
import {UserTrainingRating} from 'src/app/backend/elearning-api/trainings-user-training-rating/post-trainings-by-id-user-training-rating.request';
import {TrainingsUserTrainingRatingGateway} from 'src/app/backend/elearning-api/trainings-user-training-rating/trainings-user-training-rating.gateway';
import {
  CertificateSlide,
  EmbeddedVideoSlide,
  FloatingImageSlide,
  GetByTrainingIdSessionsByIdResponse,
  ImageSlide,
  LinkSlide,
  QuizSlide,
  TaskSlide,
  TextSlide,
  TitleSlide,
  UserTrainingRatingSlide,
} from 'src/app/backend/elearning-api/trainings/get-by-training-id-sessions-by-id.response';
import {GetPreviewResponse} from 'src/app/backend/elearning-api/trainings/get-preview.response';
import {GetTrainingsResponse, LeanSession, Training} from 'src/app/backend/elearning-api/trainings/get-trainings.response';
import {
  GetUserTrainingProgressesResponse,
  UserTrainingProgress,
} from 'src/app/backend/elearning-api/user-training-progresses/get-user-training-progresses.response';
import {userTrainingProgressesGateway} from 'src/app/backend/elearning-api/user-training-progresses/user-training-progresses.gateway';
import {IndexedObjects, indexObjects} from 'src/app/shared/data.service';
import {QuizzesGateway} from '../../backend/elearning-api/quizzes/quizzes.gateway';
import {TrainingsGateway} from '../../backend/elearning-api/trainings/trainings.gateway';
import {PostTrainingsByIdTrackProgressResponse} from '../../backend/elearning-api/user-training-progresses/post-trainings-by-id-track-progress.response';

export type Slide =
  | QuizSlide
  | EmbeddedVideoSlide
  | FloatingImageSlide
  | ImageSlide
  | LinkSlide
  | TextSlide
  | TaskSlide
  | CertificateSlide
  | TitleSlide
  | UserTrainingRatingSlide;

export type LeanSlide = LeanSession['slides'][0];

export interface SessionResponse {
  id: GetByTrainingIdSessionsByIdResponse['id'];
  title: GetByTrainingIdSessionsByIdResponse['title'];
  slides: IndexedObjects<Slide, string>;
}

function mapTraining(training: Training) {
  // apply custom numbering to the sessions:
  // numbering starts at 1 and milestones should increment the remaining counts
  let acc = 1;
  return {
    ...training,
    sessions: training.sessions.map((session) => {
      return {
        ...session,
        number: session.milestone ? acc : acc++,
      };
    }),
  };
}

@Injectable({
  providedIn: 'root',
})
export class ELearningDataService {
  constructor(
    private readonly _trainingsGateway: TrainingsGateway,
    private readonly _progressesGateway: userTrainingProgressesGateway,
    private readonly _quizzesGateway: QuizzesGateway,
    private readonly _notesGateway: NotesGateway,
    private readonly _learningPathsGateway: LearningPathsGateway,
    private readonly _certificatesGateway: CertificatesGateway,
    private readonly _trainingBookingsGateway: TrainingBookingsGateway,
    private readonly _userTrainingRatingsGateway: TrainingsUserTrainingRatingGateway,
    private readonly _depProjectsGateway: DepProjectsGateway
  ) {}

  getUserTrainingProgresses(): Observable<IndexedObjects<UserTrainingProgress, string>> {
    return this._progressesGateway.getUserTrainingProgresses({}).pipe(
      map((source: GetUserTrainingProgressesResponse) => {
        return indexObjects(
          source,
          (progress) => progress.slug,
          (progress) => progress
        );
      })
    );
  }

  putUserTrainingProgress(slug: string, slideId: string): Observable<PostTrainingsByIdTrackProgressResponse> {
    // send new progress to BE
    return this._progressesGateway
      .postTrainingsByIdTrackProgress(slug, {
        user_training_progress: {
          slide_id: slideId,
        },
      })
      .pipe(
        // BE answers with new progress
        map((response) => ({
          slug,
          slide_ids: response.slide_ids,
          last_slide: response.last_slide,
        }))
      );
  }

  getTrainings(): Observable<IndexedObjects<Training, string>> {
    return this._trainingsGateway.getTrainings({}).pipe(
      map((trainings: GetTrainingsResponse) => {
        return indexObjects(trainings, (training) => training.slug, mapTraining);
      })
    );
  }

  getTrainingPreviews(ref: string) {
    return this._trainingsGateway.getPreview({ref}).pipe(
      map((trainings: GetPreviewResponse) => {
        return indexObjects(trainings, (training) => training.slug, mapTraining);
      })
    );
  }

  getSessionContent(slug: string, session: string, ref?: string): Observable<SessionResponse> {
    return this._trainingsGateway.getByTrainingIdSessionsById(slug, session, !!ref ? {ref} : {}).pipe(
      map((response) => {
        return {
          id: response.id,
          title: response.title,
          slides: indexObjects(
            response.slides,
            (slide) => slide.id,
            (slide) => slide
          ),
        } as SessionResponse;
      })
    );
  }

  getQuizResponse(typeformQuizId: string, typeformSubmissionId?: string) {
    if (typeformSubmissionId) {
      return this._quizzesGateway.getQuizzesByTypeformId(typeformQuizId, {typeform_submission_id: typeformSubmissionId});
    }
    return this._quizzesGateway.getQuizzesByTypeformId(typeformQuizId, {});
  }

  getUserNotes() {
    return this._notesGateway.getNotes({}).pipe(
      map((response) => {
        return indexObjects(
          response,
          (note) => note.id,
          (note) => note
        );
      })
    );
  }

  putNote(note: PostNotesRequest) {
    return this._notesGateway.postNotes(note);
  }

  deleteNote(id: string) {
    return this._notesGateway.deleteByNoteId(id, {});
  }

  editNote(id: string, text: string) {
    return this._notesGateway.putByNoteId(id, {text});
  }

  getLearningPaths() {
    return this._learningPathsGateway.getLearningPaths({}).pipe(
      map((learningPaths) =>
        indexObjects(
          learningPaths,
          (path) => path.name,
          (path) => path
        )
      )
    );
  }

  getBookedTrainings() {
    return this._trainingBookingsGateway.getTrainingBookings({});
  }

  getCertificates() {
    return this._certificatesGateway.getCertificates({});
  }

  getUserTrainingRatings(id: string) {
    return this._userTrainingRatingsGateway.getTrainingsByIdUserTrainingRating(id, {});
  }

  updateUserTrainingRatings(id: string, ratings: UserTrainingRating) {
    return this._userTrainingRatingsGateway.postTrainingsByIdUserTrainingRating(id, {user_training_rating: ratings});
  }

  getDepProjects() {
    return this._depProjectsGateway.getDepProjects({});
  }
}
