import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-onboarding-slide',
  templateUrl: './onboarding-slide.component.html',
  styleUrls: ['./onboarding-slide.component.scss'],
})
export class OnboardingSlideComponent {
  @Input() slide?: string;

  get asset(): string {
    return `${this.slide}.ASSET`;
  }

  get header(): string {
    return `${this.slide}.HEADER`;
  }

  get body(): string {
    return `${this.slide}.BODY`;
  }
}
