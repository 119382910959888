import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {userHasHybrid} from '../app.selectors';
import {CustomStepperComponent} from './custom-stepper/custom-stepper.component';
import {SLIDES_HYBRID, SLIDES_SELECT} from './slides';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit, OnDestroy {
  @ViewChild('stepper', {static: true}) stepper?: CustomStepperComponent;

  private readonly _destroy$ = new Subject<boolean>();

  slides: BehaviorSubject<string[]> = new BehaviorSubject([] as string[]);

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.store.pipe(takeUntil(this._destroy$), select(userHasHybrid)).subscribe((isHybrid) => {
      this.slides.next(isHybrid ? SLIDES_HYBRID : SLIDES_SELECT);
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }
}
