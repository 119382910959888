<ng-container *ngIf="(showDownloadInfoDialog$ | async); else downloadView">
  <app-slide-separator
    [dialogButton]="true"
    (buttonClicked)="showInfoDialog()"
    [type]="data?.type"
    [title]="getTitle()"
    [introText]="getPrismicHtmlText()"
    [buttonText]="'INTRO_SLIDE.BUTTON.LINK' | translate"
    [skipLink]="nextLink"
    [backLink]="backLink"
    [specialSlide]="isHandbook() ? 'handbook' : getSpecialSlide()">
  </app-slide-separator>
  <a #downloadLink class="d-none" [href]="getUrl()" target="_blank" rel="noopener noreferrer">download</a>
</ng-container>
<ng-template #downloadView>
  <app-slide-separator
    *ngIf="!downloaded"
    (buttonClicked)="markAsDownloaded()"
    [type]="data?.type"
    [title]="getTitle()"
    [introText]="getPrismicHtmlText()"
    [buttonText]="'INTRO_SLIDE.BUTTON.LINK' | translate"
    [skipLink]="nextLink"
    [url]="getUrl()"
    [backLink]="backLink"
    [specialSlide]="isHandbook() ? 'handbook' : getSpecialSlide()">
  </app-slide-separator>
  <app-slide-separator
    *ngIf="downloaded"
    [type]="data?.type"
    [title]="getTitle()"
    [introText]="getDownloadedText()"
    [loading]="loading"
    [skipLink]="nextLink"
    [backLink]="backLink"
    [skipText]="'SLIDE_NAVBAR.NEXT_SLIDE' | translate"
    [specialSlide]="isHandbook() ? 'handbook' : getSpecialSlide()">
  </app-slide-separator>
</ng-template>
