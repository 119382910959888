<ng-container *ngIf="vm$ | async as vm">
  <mat-toolbar *ngIf="headerShown" id="navbar" class="navbar-top">
    <div class="container">
      <div class="d-flex flex-row">
        <a class="left-content logo" routerLink="/e-learning/trainings/available">
          <img src="assets/img/troodi-de.svg" alt="troodi Logo" class="header-logo" />
        </a>
        <ng-container *ngIf="!blankHeader">
          <!-- logged-in users have navigation an menu-button -->
          <ng-container *ngIf="(user$ | async) as user">
            <!-- navigation-links -->
            <div class="nav-links d-none d-lg-flex mx-3 align-self-center">
              <a
                *ngIf="user.has_dep_projects"
                class="textlink mx-3"
                [class.highlighted]="vm.currentNav === 'dep-projects'"
                routerLink="/e-learning/dep-projects"
                translate>
                NAVBAR.DEP_PROJECTS
              </a>
              <a
                *ngIf="user.has_learning_paths"
                class="textlink mx-3"
                [class.highlighted]="vm.currentNav === 'learningpaths'"
                routerLink="/e-learning/learningpaths"
                translate>
                NAVBAR.LEARNINGPATHS
              </a>
              <a
                class="textlink mx-3"
                routerLink="/e-learning/trainings/available"
                [class.highlighted]="vm.currentNav === 'trainings'"
                translate>
                NAVBAR.TRAININGS.MAIN
              </a>
              <a class="textlink mx-3" routerLink="/e-learning/notes" [class.highlighted]="vm.currentNav === 'notes'" translate>
                NAVBAR.NOTES
              </a>
            </div>
            <!-- menu-button -->
            <div class="d-none d-lg-flex align-self-center flex-grow-1 justify-content-end">
              <div
                class="user-greeting d-flex align-items-center me-4"
                [class.interactive]="showExpirationNotification"
                matBadge="!"
                [matBadgeHidden]="!showExpirationNotification"
                [matBadgeDescription]="'EXPIRED_USER.MENU_ITEM' | translate"
                matBadgeSize="small"
                matBadgeColor="accent"
                matBadgeOverlap="true"
                [matTooltip]="'EXPIRED_USER.MENU_ITEM' | translate"
                [matTooltipDisabled]="!showExpirationNotification"
                matTooltipPosition="below"
                matTooltipClass="primary-tooltip"
                (click)="showExpirationInformation()">
                <span class="name" *ngIf="user.first_name" [translateParams]="{name: user.first_name}" translate
                  >WELCOME_HEADER.WITH_NAME</span
                >
                <span class="name" *ngIf="!user.first_name" translate>WELCOME_HEADER.WITHOUT_NAME</span>
              </div>
              <img class="user-menu-icon" src="assets/img/user.svg" alt="troodi Logo" [matMenuTriggerFor]="userMenu" />
              <mat-menu #userMenu="matMenu">
                <a routerLink="/settings" mat-menu-item>
                  <span translate>NAVBAR.MY_PROFILE</span>
                </a>
                <a href="/sign_out" mat-menu-item>
                  <span translate>NAVBAR.LOGOUT</span>
                </a>
              </mat-menu>
            </div>
          </ng-container>
          <!-- Visitors can change language and are prompted to sign up -->
          <div *ngIf="!(user$ | async)" class="d-none d-lg-block align-self-center text-end flex-grow-1">
            <span class="language-selection me-3">
              <button
                mat-icon-button
                color="accent"
                *ngFor="let lang of languageMapping | keyvalue"
                (click)="selectLanguage(lang.key)"
                [matTooltip]="lang.value"
                matTooltipPosition="below"
                matTooltipClass="primary-tooltip">
                <img src="assets/img/flag_{{lang.key}}.png" />
              </button>
            </span>
            <a mat-flat-button class="nav-button" routerLink="signup" color="accent">
              <span translate>SIGNUP.BUTTON</span>
            </a>
          </div>
          <!-- Burger-Menu for small devices, if user is not active: show badge on the menu -->
          <div
            class="d-flex d-lg-none align-self-center text-end collapse-menu flex-grow-1 justify-content-end"
            matBadge="!"
            [matBadgeHidden]="!showExpirationNotification"
            [matBadgeDescription]="'EXPIRED_USER.MENU_ITEM' | translate"
            matBadgeSize="small"
            matBadgeColor="accent">
            <app-trainings-search class="d-flex d-lg-none" *ngIf="!!(subNavigationShown$ | async)" layout="header"></app-trainings-search>
            <mat-icon svgIcon="menu" class="collapse-menu-icon" [matMenuTriggerFor]="collapseMenu" aria-label="Collapsible menu"></mat-icon>
            <mat-menu #collapseMenu="matMenu">
              <!-- Menu-Items for logged-in users -->
              <ng-container *ngIf="(user$ | async) as user">
                <!-- If the user is expired, show an extra menut-item for this -->
                <button *ngIf="showExpirationNotification" (click)="showExpirationInformation()" mat-menu-item>
                  <span class="highlighted" translate>EXPIRED_USER.MENU_ITEM</span>
                </button>
                <a *ngIf="user.has_dep_projects" routerLink="/e-learning/dep-projects" mat-menu-item>
                  <span translate>NAVBAR.DEP_PROJECTS</span>
                </a>
                <a *ngIf="user.has_learning_paths" routerLink="/e-learning/learningpaths" mat-menu-item>
                  <span translate>NAVBAR.LEARNINGPATHS</span>
                </a>
                <button [matMenuTriggerFor]="trainingsSubMenu" mat-menu-item>
                  <span translate>NAVBAR.TRAININGS.MAIN</span>
                </button>
                <a routerLink="/e-learning/notes" mat-menu-item>
                  <span translate>NAVBAR.NOTES</span>
                </a>
                <a routerLink="/settings" mat-menu-item>
                  <span translate>NAVBAR.MY_PROFILE</span>
                </a>
                <a href="/sign_out" mat-menu-item>
                  <span translate>NAVBAR.LOGOUT</span>
                </a>
              </ng-container>
              <!-- Menu-Items for visitors -->
              <ng-container *ngIf="!(user$ | async)">
                <!-- No Self-Signup currently -->
                <a mat-menu-item routerLink="signup" color="accent">
                  <span translate>SIGNUP.BUTTON</span>
                </a>
                <a mat-menu-item *ngFor="let lang of languageMapping | keyvalue" (click)="selectLanguage(lang.key)">
                  <img src="assets/img/flag_{{lang.key}}.png" />
                  <span class="ms-3">{{lang.value}}</span>
                </a>
              </ng-container>
            </mat-menu>
            <mat-menu #trainingsSubMenu="matMenu">
              <a routerLink="/e-learning/trainings/all" mat-menu-item>
                <span translate>NAVBAR.TRAININGS.ALL</span>
              </a>
              <a routerLink="/e-learning/trainings/available" mat-menu-item>
                <span translate>NAVBAR.TRAININGS.AVAILABLE</span>
              </a>
              <a routerLink="/e-learning/trainings/grouped" mat-menu-item>
                <span translate>NAVBAR.TRAININGS.GROUPED</span>
              </a>
            </mat-menu>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-toolbar>

  <ngx-loading-bar [color]="getAccentColor()"></ngx-loading-bar>

  <app-onboarding *ngIf="showOnboarding$ | async"></app-onboarding>

  <!-- Custom launcher for support-contact -->
  <app-contact-float [user]="user$ | async" *ngIf="!hideContactFloat" (openOnboardingTour)="openOnboardingTour()"></app-contact-float>

  <div [class.splash]="splashShown" [class.main]="headerShown" [class.main-without-header]="!headerShown" #appContainer cdkScrollable>
    <div class="main-content">
      <router-outlet class="outer-router-outlet" #outlet="outlet"></router-outlet>
    </div>
    <footer *ngIf="headerShown">
      <mat-toolbar class="footer p-4">
        <div class="container">
          <div class="row justify-content-around">
            <div class="col-12 col-sm-3">
              <span class="sectiontitle" translate>FOOTER.LEGAL</span>
              <div class="mt-3 d-flex flex-column legal">
                <a class="textlink pb-2" href="https://troodi.de/impressum/">
                  <i-feather name="chevron-right"></i-feather>
                  <span translate>FOOTER.IMPRINT</span>
                </a>
                <a class="textlink py-2" target="_blank" rel="noopener noreferrer" href="https://troodi.de/impressum/#ds">
                  <i-feather name="chevron-right"></i-feather>
                  <span translate>FOOTER.PRIVACY_POLICY</span>
                </a>
                <a target="_blank" rel="noopener noreferrer" class="textlink py-2" href="https://troodi.de">
                  <i-feather name="chevron-right"></i-feather>
                  <span translate>FOOTER.TROODI</span>
                </a>
                <a class="textlink py-2" (click)="openCookieDialog()">
                  <i-feather name="chevron-right"></i-feather>
                  <span translate>FOOTER.COOKIES</span>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-3 pt-4 pt-sm-0">
              <span class="sectiontitle" translate>FOOTER.CONTACT</span>
              <address class="mt-3 d-flex flex-column">
                <span>troodi GmbH</span>
                <span>Lichtstr. 25</span>
                <span>50825 Köln</span>
                <a class="textlink" href="mailto:info@troodi.de">info@troodi.de</a>
                <a class="textlink" href="tel:+4922156095669">+49 221 56095669</a>
              </address>
            </div>
            <div class="col-12 col-sm-3 pt-4 pt-sm-0">
              <span class="sectiontitle" translate>FOOTER.SOCIAL</span>
              <div class="mt-3 d-flex social">
                <a class="pe-4" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/troodi-gmbh/">
                  <mat-icon svgIcon="linkedin"></mat-icon>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.xing.com/companies/troodigmbh">
                  <mat-icon svgIcon="xing"></mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </mat-toolbar>
    </footer>
  </div>
</ng-container>
