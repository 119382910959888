import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrls: ['./collapsible-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleCardComponent {
  // optionally collapse the card initially
  @Input() expanded = true;
  // Tooltips used for the tooltips on the collapsible header. Can be overwritten
  @Input() tooltipKeyOpen = 'TBD';
  @Input() tooltipKeyClose = 'TBD';

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }
}
