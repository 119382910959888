import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleComponent {
  @Input() number?: number;
  @Input() percentage?: number;
  @Input() isMilestone?: boolean;
  @Input() isFinal?: boolean;
  @Input() isFinished?: boolean;

  showIcon(): boolean {
    return !!this.isMilestone || !!this.isFinal || !!this.isFinished;
  }

  get icon(): string | undefined {
    if (!!this.isFinished) {
      return 'check';
    }
    if (!!this.isMilestone) {
      return 'milestone';
    }
    if (!!this.isFinal) {
      return 'final';
    }
    return;
  }
}
