<div class="checkout my-auto container">
  <mat-card class="checkout-card my-4">
    <ng-container *ngIf="!!(isCheckout$ | async); else loading">
      <div class="title" translate>CHECKOUT.TITLE</div>
      <mat-card-content class="py-2">
        <p class="pb-2" translate>CHECKOUT.TEXT_LINE1</p>
        <p translate>CHECKOUT.TEXT_LINE2</p>
      </mat-card-content>
    </ng-container>
    <ng-template #loading>
      <!-- 
        In case someone stumbled on this page or bookmarked it for some reason...
        Then redirect him to the login-page and show a loading-spinner during evaluation
      -->
      <img class="troodi-spinner" src="assets/img/troodi_spinner.gif" />
    </ng-template>
  </mat-card>
</div>
