import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {EditableNote} from 'src/app/features/e-learning/e-learning.selectors';

@Component({
  selector: 'app-editable-note',
  templateUrl: './editable-note.component.html',
  styleUrls: ['./editable-note.component.scss'],
})
export class EditableNoteComponent {
  @Input() note?: EditableNote;
  @Input() hasNavLink = true;

  @Output() editModeEvent = new EventEmitter<{edit: boolean}>();
  @Output() deleteNoteEvent = new EventEmitter<{id: string}>();
  @Output() saveNoteEvent = new EventEmitter<{id: string; note: string}>();
  @Output() timestampClickEvent = new EventEmitter<{timestamp: number}>();

  // for setting the focus
  @ViewChild('noteEditText') editArea?: ElementRef;

  edit = false;

  constructor(private readonly _router: Router) {}

  editNote() {
    if (!!this.note) {
      this.editModeEvent.emit({edit: true});
      this.edit = true;
      setTimeout(() => {
        if (this.editArea) {
          this.editArea.nativeElement.focus();
        }
      }, 100);
    }
  }

  saveNote(text: string) {
    if (!!this.note) {
      this.edit = false;
      this.editModeEvent.emit({edit: false});
      this.saveNoteEvent.emit({id: this.note.id, note: text});
    }
  }

  reset() {
    this.edit = false;
    this.editModeEvent.emit({edit: false});
  }

  delete() {
    if (!!this.note) {
      this.deleteNoteEvent.emit({id: this.note?.id});
    }
  }

  timestampClicked() {
    if (!!this.note) {
      this.timestampClickEvent.emit({timestamp: this.note.seconds});
      // if there is a 'navigate'-button, also let the timestamp take the user to the video!
      if (this.hasNavLink && !!this.note.route) {
        this._router.navigate([this.note.route.url], {queryParams: {timestamp: this.note.route.timestamp}} as NavigationExtras);
      }
    }
  }
}
