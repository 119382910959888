/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetSkillsResponse} from './get-skills.response';
import {GetSkillsRequest} from './get-skills.request';
@Injectable()
export class SkillsGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getSkills(
    queryParams: GetSkillsRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetSkillsResponse> {
    return this._httpClient.request('get', this._apiHost + '/skills', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }
}
