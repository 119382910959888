<header>
  <button mat-icon-button (click)="close()" class="close-button">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</header>
<div (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()" (swipedown)="close()" (swipeup)="close()">
  <ng-container [ngTemplateOutlet]="selected?.content"></ng-container>
</div>
<!-- This is the navigation bar of the stepper -->
<footer class="onboarding-footer">
  <ng-container *ngIf="selectedIndex === 0; else footer">
    <button mat-flat-button color="primary" (click)="close()">
      <span translate>ONBOARDING.SKIP_TOUR</span>
    </button>
    <button mat-flat-button color="accent" cdkStepperNext>
      <span translate>ONBOARDING.START_TOUR</span>
    </button>
  </ng-container>

  <ng-template #footer>
    <div class="desktop">
      <button mat-flat-button color="primary" cdkStepperPrevious>
        <span translate>LABEL.BACK</span>
      </button>
      <app-onboarding-step-navigator
        [stepControlSteps]="stepControlSteps"
        (stepClicked)="onClick($event)"
        [selectedIndex]="selectedIndex"></app-onboarding-step-navigator>
      <button mat-flat-button color="accent" cdkStepperNext *ngIf="selectedIndex !== stepControlSteps.length">
        <span translate>LABEL.NEXT</span>
      </button>
      <button mat-flat-button color="accent" *ngIf="selectedIndex === stepControlSteps.length" (click)="close()">
        <span translate>ONBOARDING.FINISH_TOUR</span>
      </button>
    </div>

    <div class="mobile">
      <app-onboarding-step-navigator
        [stepControlSteps]="stepControlSteps"
        (stepClicked)="onClick($event)"
        [selectedIndex]="selectedIndex"></app-onboarding-step-navigator>

      <div class="buttons">
        <button mat-flat-button color="primary" cdkStepperPrevious>
          <span translate>LABEL.BACK</span>
        </button>

        <button mat-flat-button color="accent" cdkStepperNext *ngIf="selectedIndex !== stepControlSteps.length">
          <span translate>LABEL.NEXT</span>
        </button>
        <button mat-flat-button color="accent" *ngIf="selectedIndex === stepControlSteps.length" (click)="close()">
          <span translate>ONBOARDING.FINISH_TOUR</span>
        </button>
      </div>
    </div>
  </ng-template>
</footer>
