import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {Store} from '@ngrx/store';
import {from, Observable, of} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {getUserHomePath} from './app.selectors';
import {loadUser} from './features/user/user.actions';
import {UserSlice} from './features/user/user.reducer';
import {selectUserStateSlice} from './features/user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class RedirectLoggedInUsersGuard implements CanActivate {
  constructor(private readonly _store: Store<UserSlice>, private readonly _router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._store.select(selectUserStateSlice).pipe(
      // Make sure the user is fetched, idempotency ensured in effects
      tap((userState) => {
        if (!userState.loading && !userState.loaded) {
          this._store.dispatch(loadUser());
        }
      }),
      // ensure state is loaded
      filter((userState) => !!userState.loaded),
      take(1),
      switchMap((user) => {
        if (!!user.results) {
          // these pages should redirect when the user is already logged in
          return from(this._router.navigateByUrl(getUserHomePath(user.results)));
        }
        // otherwise, the user can safely continue
        return of(true);
      })
    );
  }
}
