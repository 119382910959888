import {ScrollDispatcher} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Subscription} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollContainer', {static: false}) scrollContainer?: ElementRef;
  // For the left/right-scrolling, we want to be able to scroll the next element into view
  private scrollTargets?: ElementRef[];
  // TODO: somehow a nicer solution possible for this?
  // carousel 'includes' the scroll-buttons, but the actual 'viewport' of the carousel lies between...
  private readonly MARGIN: number;
  private readonly _subscriptions = new Subscription();
  isLeftShown = true;
  isRightShown = true;
  // prevent exceptions when targets AND initialscroll are set
  private _isScrollCheckBlocked = false;

  /**
   * Makes a complete check over all passed elements whether or not some of them are overflowing right or left from the viewport.
   * Uses `some` to preemptively end the check. Sets respective booleans for the template.
   * Marks changeDetection for a check afterwards!
   */
  private _viewportCheck() {
    if (this.scrollTargets) {
      this.isLeftShown = this.scrollTargets.some((element) => this.isLeftFromViewport(element.nativeElement.getBoundingClientRect()));
      this.isRightShown = this.scrollTargets.some((element) => this.isRightFromViewport(element.nativeElement.getBoundingClientRect()));
    }
    this._cdr.detectChanges();
  }

  /**
   * Lets this carousel make a smooth scroll to the given offset from the left.
   * Doesn't use the cdkscrollable, but the native element for scrolling. This way, the polyfill can be used for safari.
   * (Safari doesn't support the smooth behaviour in desktop AND mobile)
   * @param offset Offset to scroll to (from the left) in px.
   */
  private _scrollTo(offset: number) {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.scrollTo({
        left: offset,
        behavior: 'smooth',
      });
    }
  }

  constructor(
    private readonly _scroll: ScrollDispatcher,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _deviceService: DeviceDetectorService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _zone: NgZone
  ) {
    // for mobiles, there should be no additional margin, as the scroll-buttons are overlaying everything
    this.MARGIN = this._deviceService.isMobile() ? 0 : 60;
  }

  /**
   * Determines whether given object flows out of the left side of the viewport
   */
  isLeftFromViewport(elementBounding: DOMRect) {
    return elementBounding.left < this.MARGIN;
  }

  /**
   * Determines whether given object flows out of the right side of the viewport
   */
  isRightFromViewport(elementBounding: DOMRect) {
    return elementBounding.right > (window.innerWidth - this.MARGIN || this._document.documentElement.clientWidth - this.MARGIN);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    // we have to listen on the scroll-event to check for overflowing elements
    if (this.scrollContainer) {
      const scrollAncestors = this._scroll.getAncestorScrollContainers(this.scrollContainer);
      const scrollable = scrollAncestors[scrollAncestors.length - 1];
      this._subscriptions.add(
        scrollable
          ?.elementScrolled()
          .pipe(
            // only take scroll-events and debounce them (smooth-scroll-animation triggers multiple scrolls)
            filter((event) => event.type === 'scroll'),
            debounceTime(20)
          )
          .subscribe(() => {
            // scroll lies outside ng-Zone! Make sure to reenter!
            this._zone.run(() => {
              if (this.scrollTargets) {
                this._viewportCheck();
              }
            });
          })
      );
    }
  }

  setScrollTargets(targets: ElementRef[]) {
    this.scrollTargets = targets;
    // after the targets are set, we have to run this once to initially hide/set the scrollbuttons correctly
    // however, not needed if the initialscrolling is set, as this will check this itself.
    // triggering both checks will result in exceptions
    if (!this._isScrollCheckBlocked) {
      this._viewportCheck();
    }
  }

  /**
   * Simplistic function checking whether passed element is out of the right bounds and scrolls it into view
   */
  initialHorizontalScrollIntoView(toBeScrolledTo: HTMLElement) {
    if (!!this.scrollContainer) {
      const bounding = toBeScrolledTo.getBoundingClientRect();
      if (this.isRightFromViewport(bounding)) {
        this._isScrollCheckBlocked = true;
        // we have to scroll right
        // TODO: currently just tries to scroll the element to the middle of the viewport
        // here, more sophisticated positioning could be implemented, once agreed upon
        const offset = bounding.left + bounding.width / 2 - window.innerWidth / 2;
        this._scrollTo(offset);
      }
    }
  }

  /**
   * Scrolls the carousel left or right based on the contained elements.
   * It determines the first element overflwoing the carousel in the corresponding direction and scrolls this into view.
   * The elements have to be provided by the parent-component in order to correctly determine the scrolling-targets.
   * Currently uses `scrollable.scrollTo` with the `smooth`-Option, with is not supported by Safari...
   */
  scroll(direction: 'left' | 'right') {
    if (this.scrollContainer && this.scrollTargets) {
      // overly complex but needed way of getting the carousel as a cdkScrollabe :)
      const scrollAncestors = this._scroll.getAncestorScrollContainers(this.scrollContainer);
      const scrollable = scrollAncestors[scrollAncestors.length - 1];
      // we take measurements from the left
      const offset = scrollable.measureScrollOffset('left');
      // this is added to the scroll-targets to make the scrolling a bit more natural
      const scrollPadding = 5;
      let scrollDestination = offset;
      if (direction === 'left') {
        // scroll to the first element outflowing on the left
        // get all cards left from the viewport
        const outflowed = this.scrollTargets.filter((target) => this.isLeftFromViewport(target.nativeElement.getBoundingClientRect()));
        if (outflowed.length > 0) {
          // get the last one (as we want the 'rightmost' of them)
          const toBeScrolledTo = outflowed[outflowed.length - 1].nativeElement;
          // this will be a negative value, so just for better readability further on, make it positive
          // also, ceil to circumvent weird decimal-pixel-values that may come from the browser
          // and add a little padding to make the scrolling more natural
          const outflowedOffset = Math.ceil(-1 * toBeScrolledTo.getBoundingClientRect().left) + this.MARGIN + scrollPadding;
          scrollDestination = offset - outflowedOffset;
        } else {
          // incase there is no such element, scroll to 0 (as there might be some pixels left)
          scrollDestination = 0;
        }
      } else {
        // scroll to the first element outflowing on the right
        // get all cards right from the viewport
        const outflowed = this.scrollTargets.filter((target) => this.isRightFromViewport(target.nativeElement.getBoundingClientRect()));
        if (outflowed.length > 0) {
          // ceil to circumvent weird decimal-pixel-values that may come from the browser
          // and add a little padding to make the scrolling more natural
          const outflowedOffset = Math.ceil(outflowed[0].nativeElement.getBoundingClientRect().right + this.MARGIN + scrollPadding);
          // we want this element to be on the right edge, so add width of the viewport
          scrollDestination = offset + outflowedOffset - window.innerWidth;
        }
      }
      this._scrollTo(scrollDestination);
    }
  }
}
