import {Component, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-onboarding-step-navigator',
  template: ` <div class="step-control-wrapper">
    <button
      *ngFor="let step of stepControlSteps; let i = index"
      (click)="stepClicked.emit(i + 1)"
      class="step-control"
      [ngClass]="{active: selectedIndex === i + 1}"></button>
  </div>`,
  styleUrls: ['./onboarding-step-navigator.component.scss'],
})
export class OnboardingStepNavigatorComponent {
  @Input() stepControlSteps?: [];
  @Input() selectedIndex?: number;

  @Output() stepClicked = new EventEmitter<number>();
}
