/* tslint:disable */
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';
import {API_HOST} from '../api-host';
import {GetUserTrainingProgressesResponse} from './get-user-training-progresses.response';
import {GetUserTrainingProgressesRequest} from './get-user-training-progresses.request';
import {PostTrainingsByIdTrackProgressResponse} from './post-trainings-by-id-track-progress.response';
import {PostTrainingsByIdTrackProgressRequest} from './post-trainings-by-id-track-progress.request';
@Injectable()
export class userTrainingProgressesGateway {
  constructor(private readonly _httpClient: HttpClient, @Inject(API_HOST) private readonly _apiHost: string) {}

  getUserTrainingProgresses(
    queryParams: GetUserTrainingProgressesRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<GetUserTrainingProgressesResponse> {
    return this._httpClient.request('get', this._apiHost + '/user_training_progresses', {
      ...options,
      observe: 'body',
      params: (queryParams as unknown) as {[param: string]: string | string[]},
    });
  }

  postTrainingsByIdTrackProgress(
    id: string,
    body: PostTrainingsByIdTrackProgressRequest,
    options?: Parameters<HttpClient['request']>[2],
  ): Observable<PostTrainingsByIdTrackProgressResponse> {
    return this._httpClient.request('post', this._apiHost + `/trainings/${id}/track_progress`, {
      ...options,
      observe: 'body',
      body,
    });
  }
}
