<div class="forgot-password my-auto">
  <mat-card class="forgot-password-card my-4">
    <div class="title" translate>FORGOT_PASSWORD.TITLE</div>
    <div class="subtitle mb-3" translate>FORGOT_PASSWORD.INSTRUCTIONS</div>
    <mat-card-content>
      <form [formGroup]="forgotPasswordForm" class="forgot-password-form" (ngSubmit)="sendResetMail()">
        <mat-form-field appearance="fill">
          <mat-label translate>LABEL.EMAIL</mat-label>
          <input matInput formControlName="email" />
          <mat-error *ngIf="(email?.dirty || email?.touched) && email?.errors?.required">
            <span translate>ERROR.EMAIL_REQUIRED</span>
          </mat-error>
          <mat-error *ngIf="(email?.dirty || email?.touched) && email?.errors?.validateEmail">
            <span translate>ERROR.EMAIL_VALID</span>
          </mat-error>
        </mat-form-field>
        <div class="button-container">
          <button mat-flat-button [disabled]="forgotPasswordForm.invalid" type="submit" color="accent" translate>
            <span translate>FORGOT_PASSWORD.BUTTON</span>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
