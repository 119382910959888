import {CdkStepperModule, STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {HammerModule} from '@angular/platform-browser';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {CustomStepperComponent} from './custom-stepper/custom-stepper.component';
import {OnboardingSlideComponent} from './onboarding-slide/onboarding-slide.component';
import {OnboardingStepNavigatorComponent} from './onboarding-step-navigator/onboarding-step-navigator.component';
import {OnboardingComponent} from './onboarding.component';
import {OnboardingEffects} from './store/effects';
import {reducers} from './store/reducer';

@NgModule({
  declarations: [OnboardingComponent, OnboardingSlideComponent, CustomStepperComponent, OnboardingStepNavigatorComponent],
  exports: [OnboardingComponent, OnboardingSlideComponent, CustomStepperComponent, OnboardingStepNavigatorComponent],
  imports: [
    HammerModule,
    CommonModule,
    TranslateModule,
    MatButtonModule,
    StoreModule.forFeature('onboarding', reducers),
    EffectsModule.forFeature([OnboardingEffects]),
    MatIconModule,
    CdkStepperModule,
    SharedModule,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
  ],
})
export class OnboardingModule {}
