import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {InteractiveContentSlide} from '@backend-api/trainings/get-by-training-id-sessions-by-id.response';
import {TranslateService} from '@ngx-translate/core';
import {SlideUtilService} from '../slide-utils.service';

@Component({
  selector: 'app-prismic-interactive-content',
  templateUrl: './prismic-interactive-content.component.html',
  styleUrls: ['./prismic-interactive-content.component.scss'],
})
export class PrismicInteractiveContentComponent implements OnInit, OnChanges {
  @Input() data?: InteractiveContentSlide;
  @Input() nextLink?: string;
  @Input() backLink?: string;
  @Output() trackProgress = new EventEmitter();

  isIntroShown = true;

  // to check origin of messages in eventhandler
  private _contentOrigin?: string;

  constructor(private readonly _slideUtilService: SlideUtilService, private readonly _translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      // reset if user navigates between two interactive slides
      this.isIntroShown = true;
      this._slideUtilService.setNavbar(false);
    }
  }

  ngOnInit(): void {
    this._slideUtilService.setNavbar(false);
  }

  launch() {
    this.isIntroShown = false;
    this.trackProgress.emit();
    this._slideUtilService.setNavbar(true);
    // update origin of content (_might_) change between slides
    this._contentOrigin = new URL(this.data?.launch_url ?? '').origin;
  }

  getTitle() {
    if (this.data?.quiz) {
      return this._translate.instant('QUIZ_SLIDE.TITLE');
    }

    return this.data?.title;
  }

  getButtonText() {
    if (this.data?.quiz) {
      return this._translate.instant('QUIZ_SLIDE.BUTTON.SHOW_QUIZ');
    }

    return this._translate.instant('INTERACTIVE_SLIDE.BUTTON');
  }
}
