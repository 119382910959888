import {Inject, Pipe, PipeTransform} from '@angular/core';
import {WINDOW} from '../core/window';
import {HyphenationService} from '../shared/hyphenation.service';

const asyncForEach = async <T>(array: T[], callback: (value: T, index: number, array: T[]) => Promise<void>) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const isWasmSupported = () => {
  try {
    if (typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function') {
      const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
      if (module instanceof WebAssembly.Module) {
        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
      }
    }
  } catch (e) {
    console.error('WASM error', e);
  }
  return false;
};

/**
 * IE Edge 79+ supports TextDecoder. Unfortunately some of our users are using
 * IE Edge 12-18 which does not support it.
 */
const isTextDecoderSupported = () => {
  return typeof TextDecoder === 'function';
};

const excludedPlatforms = ['macintel', 'iphone', 'ipad', 'android'];

@Pipe({
  name: 'hyphenateHtml',
})
export class HyphenateHtmlPipe implements PipeTransform {
  constructor(private readonly hyphenationService: HyphenationService, @Inject(WINDOW) private readonly _window: Window) {}

  async transform(value: string | null): Promise<string | null> {
    if (typeof value !== 'string') return value;
    if (!isWasmSupported()) return value;
    if (!isTextDecoderSupported()) return value;

    /**
     * Only hyphenate if the platform cannot handle the css feature.
     * https://caniuse.com/#feat=css-hyphens
     */
    if (excludedPlatforms.includes(this._window.navigator.platform.toLowerCase())) return value;

    return this.hyphenateTransform(value);
  }

  /**
   * A string could be a normal string but it could also be stringified HTML.
   * We do not want to hyphenate HTML tag names, therefore we need to ignore them.
   * In order not to build some sort of regex that will ignore the tags I have
   * opted for a DOMParser and NodeIterator which only returns the Node Type 3
   * which are the TextNodes. We map them into hyhenated strings, and then
   * return the text representation of the now stringified HTML.
   */
  private async hyphenateTransform(inputString: string): Promise<string> {
    const parser = new DOMParser();
    const doc = parser.parseFromString(inputString, 'text/html');

    const nodeList: Node[] = [];
    const nodeIterator = doc.createNodeIterator(doc.body, NodeFilter.SHOW_TEXT, {
      acceptNode() {
        return NodeFilter.FILTER_ACCEPT;
      },
    });

    let node: Node | null;
    // eslint-disable-next-line no-cond-assign
    while ((node = nodeIterator.nextNode())) {
      nodeList.push(node);
    }

    if (nodeList.length === 0) {
      return '';
    }

    /*
     * If we use Promise.all to hyphenate the strings through the hyphenation service
     * the Wasm module will be requested n times because it has not been initialised
     * with the first call. Doing this sequentially should be fine but I am open
     * to suggestions regarding a better way of initialising the HyphenationService.
     */
    await asyncForEach(nodeList, async (currentNode: Node) => {
      currentNode.textContent = await this.hyphenationService.hyphenateString(currentNode.textContent || '');
    });

    return doc.body.innerHTML;
  }
}
