import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject} from 'rxjs';
import {delay} from 'rxjs/operators';
import {PostNotesRequest} from 'src/app/backend/elearning-api/notes/post-notes.request';
import {addNote, deleteNote, editNote, updateUserTrainingProgress} from './e-learning.actions';
import {ELearningSlice} from './e-learning.reducer';
import {selectUserNotesByCurrentSlide} from './e-learning.selectors';

@Injectable()
export class SlideUtilService {
  private readonly _navbarState = new BehaviorSubject<boolean>(true);
  readonly currentUserNotes$ = this._store.select(selectUserNotesByCurrentSlide);
  readonly navbarShown = this._navbarState.pipe(delay(0));

  constructor(private readonly _store: Store<ELearningSlice>) {}

  setNavbar(shown: boolean) {
    this._navbarState.next(shown);
  }

  trackProgress(slug: string, slideId: string) {
    this._store.dispatch(
      updateUserTrainingProgress({
        slug,
        slideId,
      })
    );
  }

  addNote(note: PostNotesRequest) {
    this._store.dispatch(addNote({note}));
  }

  getCurrentNotes() {
    return this.currentUserNotes$;
  }

  deleteNote(id: string) {
    this._store.dispatch(deleteNote({id}));
  }

  editNote(id: string, text: string) {
    this._store.dispatch(editNote({id, text}));
  }
}
