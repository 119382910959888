import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {bookingsActions, depProjectsActions, progressesActions, trainingActions} from '../e-learning.actions';
import {ELearningSlice} from '../e-learning.reducer';
import {selectDepProjectsWithTrainingData} from '../e-learning.selectors';

@Component({
  selector: 'app-dep-list',
  templateUrl: './dep-list.component.html',
  styleUrls: ['./dep-list.component.scss'],
})
export class DepListComponent implements OnInit {
  readonly depProjects$ = this._store.select(selectDepProjectsWithTrainingData);
  constructor(private readonly _store: Store<ELearningSlice>) {}

  ngOnInit(): void {
    // idempotent loading. Ensure that this data is loaded
    this._store.dispatch(trainingActions.execute({params: undefined}));
    this._store.dispatch(progressesActions.execute({params: undefined}));
    this._store.dispatch(depProjectsActions.execute({params: undefined}));
    this._store.dispatch(bookingsActions.execute({params: undefined}));
  }
}
