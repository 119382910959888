import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HyphenateHtmlPipe} from './hyphenate-html.pipe';
import {KeepHtmlPipe} from './keep-html.pipe';
import {PrismicHtmlPipe} from './prismic-html.pipe';
import {PrismicTextPipe} from './prismic-text.pipe';
import {TrustUrlPipe} from './trust-url.pipe';

@NgModule({
  declarations: [KeepHtmlPipe, TrustUrlPipe, PrismicHtmlPipe, PrismicTextPipe, HyphenateHtmlPipe],
  imports: [CommonModule],
  exports: [KeepHtmlPipe, TrustUrlPipe, PrismicHtmlPipe, PrismicTextPipe, HyphenateHtmlPipe],
})
export class PipesModule {}
